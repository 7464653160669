import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash-es';
import TextField from '@beewise/text-field';
import Card from 'components/reusables/Card';
import TechButton from 'components/reusables/TechButton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import constants from 'appConstants';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import { getFrameScaleData } from '../../selectors';

const FrameScaleCenter = ({ bhome }) => {
    const [zeroOffset, setZeroOffset] = useState('');
    const [calibrationFactor, setCalibrationFactor] = useState('');
    const [weightOnScale, setWeightOnScale] = useState('');

    const dispatch = useDispatch();
    const frameScaleData = useSelector(getFrameScaleData, shallowEqual);

    const handleSendFrameScaleCommand =
        (command, params = {}) =>
        () => {
            dispatch(fetchSendTechnicianAction(bhome?.id, { type: command, params }));
        };

    return (
        <div className="frame-scale-center">
            <Card className="frame-scale" title="Frame scale">
                <div className="frame-scale-line">
                    <TechButton
                        className="btn-primary"
                        showToManufacturer
                        onClick={handleSendFrameScaleCommand(constants.COMMANDS.FRAME_SCALE_GET_WEIGHT)}
                    >
                        Get Weight
                    </TechButton>
                    <TechButton
                        className="btn-primary"
                        showToManufacturer
                        onClick={handleSendFrameScaleCommand(constants.COMMANDS.FRAME_SCALE_GET_CALIBRATIONS)}
                    >
                        Get Calibrations
                    </TechButton>
                    <TechButton
                        className="btn-primary"
                        showToManufacturer
                        onClick={handleSendFrameScaleCommand(
                            constants.COMMANDS.FRAME_SCALE_CALIBRATE_ZERO_OFFSET_NO_SEQUENCE
                        )}
                    >
                        Calibrate ZO No Sequence
                    </TechButton>
                </div>
                <div className="frame-scale-line">
                    <TextField label="Zero Offset" value={zeroOffset} onChange={setZeroOffset} size="small" />
                    <TextField
                        label="Calib. Factor"
                        value={calibrationFactor}
                        onChange={setCalibrationFactor}
                        size="small"
                    />
                    <TechButton
                        className="btn-primary"
                        showToManufacturer
                        onClick={handleSendFrameScaleCommand(constants.COMMANDS.FRAME_SCALE_SET_CALIBRATIONS, {
                            ...(Number(zeroOffset) ? { zero_offset: Number(zeroOffset) } : {}),
                            ...(Number(calibrationFactor) ? { calibration_factor: Number(calibrationFactor) } : {}),
                        })}
                    >
                        Set Calibrations
                    </TechButton>
                </div>
                <div className="frame-scale-line">
                    <TextField label="Weight" value={weightOnScale} onChange={setWeightOnScale} size="small" />
                    <TechButton
                        className="btn-primary"
                        showToManufacturer
                        onClick={handleSendFrameScaleCommand(
                            constants.COMMANDS.FRAME_SCALE_CALIBRATE_FACTOR_NO_SEQUENCE,
                            {
                                ...(Number(weightOnScale) ? { weight_on_scale: Number(weightOnScale) } : {}),
                            }
                        )}
                    >
                        Calibrate Factor No Sequence
                    </TechButton>
                </div>
                {isNumber(frameScaleData?.weight) && (
                    <div>
                        <span className="bold">Weight:</span> {frameScaleData?.weight}
                    </div>
                )}
                {isNumber(frameScaleData?.zero_offset) && (
                    <div>
                        <span className="bold">Zero Offset:</span> {frameScaleData?.zero_offset}
                    </div>
                )}
                {isNumber(frameScaleData?.calibration_factor) && (
                    <div>
                        <span className="bold">Calibration Factor:</span> {frameScaleData?.calibration_factor}
                    </div>
                )}
                {frameScaleData?.initiated_by && (
                    <div>
                        <span className="bold">Initiated By:</span> {frameScaleData?.initiated_by}
                    </div>
                )}
            </Card>
        </div>
    );
};

FrameScaleCenter.propTypes = {
    bhome: PropTypes.shape(),
};

export default FrameScaleCenter;
