import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@beewise/text-field';
import { JsonEditor as Editor } from 'components/reusables/JsonEditor';
import Button from '@beewise/button';
import { useDispatch } from 'react-redux';
import { fetchSendTechnicianAction } from 'actions/app.actions';

const CommandSender = ({ bhome }) => {
    const [command, setCommand] = useState('');
    const [params, setParams] = useState({});
    const jsonEditor = useRef();
    const dispatch = useDispatch();

    const handleSendClick = useCallback(() => {
        if (command && params) {
            dispatch(
                fetchSendTechnicianAction(bhome.id, {
                    type: command,
                    params,
                })
            );
        }
    }, [command, params, dispatch, bhome.id]);

    return (
        <div className="command-sender">
            <div className="command-sender-inputs">
                <TextField label="Command" value={command} onChange={setCommand} size="small" />
                <h3>Params:</h3>
                <Editor ref={jsonEditor} mode="text" value={params} onChange={setParams} />
            </div>
            <Button className="btn-primary" onClick={handleSendClick} disabled={!command}>
                Send
            </Button>
        </div>
    );
};

CommandSender.propTypes = {
    bhome: PropTypes.shape(),
};

export default CommandSender;
