import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const LarvaeMissing = forwardRef(({ color = 'currentColor', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <path
      d="M4.13094 1.63019H10.1734C10.5134 1.63019 10.828 1.81038 11 2.1037L13.6836 6.68036C13.8591 6.97965 13.8591 7.35043 13.6836 7.64972L11 12.2264C10.828 12.5197 10.5134 12.6999 10.1734 12.6999H4.67978C4.33976 12.6999 4.0252 12.5197 3.85321 12.2264L1.16963 7.64971C0.99414 7.35043 0.994142 6.97966 1.16964 6.68037L3.11672 3.35983"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2507 4.6291C10.2507 4.6291 10.2016 4.08307 9.5229 4.08307C8.84416 4.08307 8.65749 4.71005 8.81497 5.05347"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.815 5.05364C9.33283 5.22069 9.898 5.05339 10.2507 4.62927"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2507 4.62927C10.4968 4.69035 10.7075 4.85249 10.8341 5.07792C10.9606 5.30335 10.9915 5.57245 10.9194 5.82167"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9192 5.82147C11.3227 6.50708 11.2677 7.37863 10.7816 8.00487"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7817 8.00494C10.9504 8.94566 10.4171 9.86995 9.53259 10.168"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.53266 10.1682C9.28246 11.0292 8.40919 11.5274 7.56586 11.2902"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.81496 5.05341C8.50736 5.34605 8.44947 5.82389 8.67711 6.18521"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.67743 6.1853C8.47972 6.52676 8.43335 6.93861 8.54963 7.3176"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.54924 7.31781C8.05987 7.60505 7.7346 8.11675 7.67426 8.69246"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.67427 8.6925C7.16797 8.80902 6.7674 9.20714 6.6369 9.7235"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.63667 9.72357C6.3428 9.77803 6.09014 9.94679 5.92211 10.1823C5.70183 10.4906 5.76635 10.9243 6.06635 11.1512C6.49071 11.4726 7.06716 11.5405 7.56586 11.29"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.6366 9.72357C6.6859 10.3682 7.03104 10.9498 7.56579 11.2903"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.67426 8.6925C7.83051 9.60354 8.63558 10.243 9.53264 10.1682"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.54926 7.31781C9.10903 7.95902 9.97101 8.22419 10.7817 8.00514"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.67737 6.18525C9.42357 6.5689 10.3248 6.4227 10.9194 5.82147"
      stroke={color}
      strokeWidth="0.7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.13519 0.780556C1.76112 0.406481 1.15463 0.406482 0.780555 0.780556C0.406482 1.15463 0.406482 1.76112 0.780555 2.1352L12.6853 14.04C13.0594 14.414 13.6659 14.414 14.0399 14.04C14.414 13.6659 14.414 13.0594 14.0399 12.6853L2.13519 0.780556Z"
      fill={color}
      stroke="#FEEDED"
      strokeLinecap="round"
    />
  </svg>
));

LarvaeMissing.propTypes = {
  color: PropTypes.string
};

LarvaeMissing.displayName = 'LarvaeMissing';

export default LarvaeMissing;
