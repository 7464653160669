import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import SlotsMultipickerV4 from 'components/reusables/SlotsMultipicker/SlotsMultipickerV4';
import constants from 'appConstants';

const ScanBundleParams = ({ setParams }) => {
    const handleOnChange = useCallback(
        slots =>
            setParams({
                slots,
            }),
        [setParams]
    );

    return (
        <SlotsMultipickerV4 type={constants.FRAME_TYPES.COMB_FRAME} onlyInsideMarkedHive onChange={handleOnChange} />
    );
};

ScanBundleParams.propTypes = {
    setParams: PropTypes.func.isRequired,
};

export default ScanBundleParams;
