export const getVentsOptions = isECTester => {
    if (isECTester) {
        return [
            {
                label: 'EC Fans',
                value: 'ec_fan_ctrl',
            },
        ];
    }
    return [
        {
            label: 'Beehome Fans',
            value: 'bh_fan_ctrl',
        },
        {
            label: 'EC Fans',
            value: 'ec_fan_ctrl',
        },
        {
            label: 'Incubator Fans',
            value: 'incubator_fan_ctrl',
        },
    ];
};
