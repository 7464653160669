import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Sliders = forwardRef(({ color = '#222222', width = 20, height = 18, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.125 16.75C14.2986 16.75 15.25 15.7986 15.25 14.625C15.25 13.4514 14.2986 12.5 13.125 12.5C11.9514 12.5 11 13.4514 11 14.625C11 15.7986 11.9514 16.75 13.125 16.75ZM13.125 17.75C14.8509 17.75 16.25 16.3509 16.25 14.625V15.25H20V14.25H16.25V14.625C16.25 12.8991 14.8509 11.5 13.125 11.5C11.3991 11.5 10 12.8991 10 14.625V14.25H0V15.25H10V14.625C10 16.3509 11.3991 17.75 13.125 17.75Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.875 1.25C5.7014 1.25 4.75 2.2014 4.75 3.375C4.75 4.54861 5.7014 5.5 6.875 5.5C8.0486 5.5 9 4.54861 9 3.375C9 2.2014 8.0486 1.25 6.875 1.25ZM6.875 0.25C5.14911 0.25 3.75 1.64911 3.75 3.375V2.75L0 2.75V3.75L3.75 3.75V3.375C3.75 5.10089 5.14911 6.5 6.875 6.5C8.60089 6.5 10 5.10089 10 3.375V3.75L20 3.75V2.75L10 2.75V3.375C10 1.64911 8.60089 0.25 6.875 0.25Z"
      fill={color}
    />
  </svg>
));

Sliders.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Sliders.displayName = 'Sliders';

export default Sliders;
