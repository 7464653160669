import { FETCH_CALIBRATIONS_DATA } from '../actionTypes';

const initialState = {
    calibrations: [],
};

const camerasCalibrations = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CALIBRATIONS_DATA.success:
            return {
                ...state,
                calibrations: action.data,
            };
        default:
            return state;
    }
};

export default camerasCalibrations;
