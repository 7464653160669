import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ImagePlaceholder from 'components/reusables/ImagePlaceholder';
import Video from 'components/reusables/Video';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toggleLiveVideo } from 'components/views/ActionsAndMonitoring/actions';
import TechButton from 'components/reusables/TechButton';
import { setLiveVideoUrl } from 'actions/app.actions';
import { noop } from 'lodash-es';
import { SelectField } from '@beewise/select-field';
import constants from 'appConstants';
import { cameraIds, cameraTypes, qualities } from '../utils';

/* eslint-disable react/no-danger */
const Stream = ({ bhomeId, isCameraView, streamNum }) => {
    const dispatch = useDispatch();
    const [pickedCameraType, setPickedCameraType] = useState(null);
    const [pickedCameraId, setPickedCameraId] = useState(null);
    const [pickedQuality, setPickedQuality] = useState(qualities[0].value);
    const [showStream, setShowStream] = useState(false);

    const stream = useSelector(
        state =>
            isCameraView
                ? state.cameras.videos.find(item => item.id === bhomeId)
                : state.actionsMonitoring.liveVideos?.find(video => video.cameraNum === streamNum),
        shallowEqual
    );

    useEffect(() => {
        setShowStream(false);
    }, [bhomeId]);

    useEffect(() => {
        setShowStream(!!stream?.url);
        if (stream?.url) {
            setPickedQuality(stream?.quality);
            setPickedCameraId(stream?.cameraId);
            setPickedCameraType(stream?.cameraType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stream?.url]);

    const handleStreamToggle = useCallback(() => {
        if (pickedCameraType && pickedCameraId) {
            setShowStream(!showStream);
            const body = {
                status: showStream ? 'off' : 'on',
                cameraType: pickedCameraType,
                cameraId: pickedCameraId,
                quality: pickedQuality,
                cameraNum: streamNum,
                from: constants.MQ_MESSAGE_CREATORS.TECHNICIAN,
            };

            dispatch(toggleLiveVideo(bhomeId, body, !showStream ? data => dispatch(setLiveVideoUrl(data)) : noop));
        }
    }, [showStream, pickedCameraType, pickedCameraId, pickedQuality, streamNum, dispatch, bhomeId]);

    return (
        <>
            <div className="handlers">
                <SelectField
                    className="live-video-type-select"
                    value={pickedCameraType}
                    options={cameraTypes}
                    onChange={setPickedCameraType}
                    search
                    placeholder="Type"
                    disabled={showStream}
                    size="small"
                />
                <SelectField
                    className="live-video-id-select"
                    value={pickedCameraId}
                    options={cameraIds}
                    onChange={setPickedCameraId}
                    search
                    placeholder="Id"
                    disabled={showStream}
                    size="small"
                />
                <SelectField
                    className="live-video-quality-select"
                    value={pickedQuality}
                    options={qualities}
                    onChange={setPickedQuality}
                    search
                    placeholder="Quality"
                    disabled={showStream}
                    size="small"
                />
                <div>
                    <TechButton className="btn-primary" name="frames-stream" onClick={handleStreamToggle} alwaysEnabled>
                        {showStream ? 'Switch off' : 'Enable video'}
                    </TechButton>
                </div>
            </div>
            <div className="live-feed">
                {showStream && stream?.url ? (
                    <Video className="stream" url={stream.url} />
                ) : (
                    <ImagePlaceholder
                        text={
                            // eslint-disable-next-line no-nested-ternary
                            stream?.error
                                ? `Error loading stream: ${JSON.stringify(stream.error)}`
                                : showStream
                                ? 'Loading the stream...'
                                : 'Camera is off'
                        }
                        className="stream-placeholder"
                    />
                )}
            </div>
        </>
    );
};

Stream.propTypes = {
    bhomeId: PropTypes.number,
    isCameraView: PropTypes.bool,
    streamNum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Stream;
