import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { loading, useToggle } from '@beewise/react-utils';
import { isEqual, get, setWith, clone } from 'lodash-es';
import { FETCH_CHANGE_BHOME_STATUS } from 'actionTypes';
import { getCurrentBhome } from 'selectors';
import GlobalSettings from './components/GlobalSettings';
import BhomeSettings from './components/BhomeSettings';
import getDataForSave from './utils/getDataForSave';
import {
    fetchBhomeSettings,
    fetchSaveSettings,
    fetchGlobalBhomeSettings,
    toggleSettingsError,
    fetchUpdateGlobalSettings,
    cleanGlobalSettingsConflicts,
} from './actions/config.actions';
import {
    FETCH_BHOME_SETTINGS,
    FETCH_SAVE_BHOME_SETTINGS,
    FETCH_GLOBAL_BHOME_SETTINGS,
    FETCH_UPDATE_GLOBAL_SETTINGS,
} from './actionTypes';
import editableFields from './utils/editableFields';

const Config = () => {
    const dispatch = useDispatch();
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const baseSettings = useSelector(state => state.config.currentBhomeSettings, shallowEqual);
    const globalSettings = useSelector(state => state.config.globalSettings, shallowEqual);
    const conflicts = useSelector(state => state.config.conflicts);
    const error = useSelector(state => state.config.error);

    const [currentSettings, setCurrentSettings] = useState(null);
    const [isFetched, setIsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isViewMode, toggleViewMode] = useToggle();
    const [disableHashCheck, toggleHashCheck] = useToggle();
    const [enforceImmediateAction, toggleEnforceImmediateAction] = useToggle();
    const [isTextMode, toggleMode] = useToggle();

    // added to hack JSONEditor inner rendering procedure
    const [fooKey, setFooKey] = useState(1);

    useEffect(() => {
        dispatch(fetchGlobalBhomeSettings());
    }, [dispatch]);

    useEffect(() => {
        if (currentBhome) {
            dispatch(
                fetchBhomeSettings(currentBhome.id, () => {
                    setIsFetched(true);
                    setFooKey(fooKey + 1);
                })
            );
        }
        // eslint-disable-next-line
    }, [currentBhome, dispatch, setFooKey]);

    useEffect(() => {
        setCurrentSettings({ ...baseSettings });
        setFooKey(fooKey + 1);
        // eslint-disable-next-line
    }, [baseSettings]);

    const handleToggleMode = useCallback(() => {
        setFooKey(fooKey + 1);
        toggleMode();
    }, [fooKey, toggleMode]);

    const handleToggleViewMode = useCallback(() => {
        setFooKey(fooKey + 1);
        toggleViewMode();
    }, [fooKey, toggleViewMode]);

    const handleSettingsChange = useCallback(
        (tmpSettings, isComment = false) => {
            let newSettings = { ...currentSettings };

            if (!isComment) {
                editableFields(currentSettings).forEach(field => {
                    newSettings = setWith(clone(newSettings), field, get({ data: tmpSettings }, field), clone);
                });
            } else {
                newSettings = {
                    ...newSettings,
                    ...tmpSettings,
                };
            }
            setCurrentSettings({ ...newSettings });
        },
        [currentSettings]
    );

    const handleSaveSettings = useCallback(() => {
        dispatch(
            fetchSaveSettings(
                currentBhome.id,
                {
                    data: currentSettings.data,
                    comment: currentSettings.comment,
                },
                get(baseSettings, 'data.settings', null),
                disableHashCheck,
                enforceImmediateAction
            )
        );
    }, [dispatch, currentBhome?.id, currentSettings, baseSettings, disableHashCheck, enforceImmediateAction]);

    const handleSaveGlobalSettings = useCallback(
        tmpSettings => () => {
            const data = getDataForSave(tmpSettings, globalSettings, dispatch);

            if (data) {
                dispatch(fetchUpdateGlobalSettings(globalSettings.id, data));
            }
        },
        [dispatch, globalSettings]
    );

    const clearConfigError = useCallback(() => {
        if (error) {
            dispatch(toggleSettingsError(null));
        }
    }, [error, dispatch]);

    const handleCleanConflicts = useCallback(() => {
        dispatch(cleanGlobalSettingsConflicts());
    }, [dispatch]);

    const handleFetchSaveConflicts = useCallback(
        (tmpGlobalSettings, checkedConflicts, isAllChecked) => () => {
            const data = getDataForSave(tmpGlobalSettings, globalSettings, dispatch);
            if (data) {
                setIsLoading(true);
                dispatch(
                    fetchUpdateGlobalSettings(globalSettings.id, data, true, checkedConflicts, isAllChecked, () =>
                        setIsLoading(false)
                    )
                );
            }
        },
        [dispatch, globalSettings]
    );

    const isSubmitDisabled = useMemo(
        () => isEqual(currentSettings, baseSettings) && !disableHashCheck,
        [currentSettings, baseSettings, disableHashCheck]
    );

    return (
        <div className="config">
            {error && <div className="error">{error}</div>}
            {!currentBhome && (
                <GlobalSettings
                    clearConfigError={clearConfigError}
                    settings={globalSettings}
                    handleGlobalSettingsSave={handleSaveGlobalSettings}
                    conflicts={conflicts}
                    cleanGlobalSettingsConflicts={handleCleanConflicts}
                    fetchSaveConflicts={handleFetchSaveConflicts}
                    isLoading={isLoading}
                    isTextMode={isTextMode}
                    toggleMode={handleToggleMode}
                />
            )}
            {currentBhome && !currentSettings && isFetched && <div>Selected Bhome doesn&#39;t have settings.</div>}
            {currentBhome && currentSettings?.data?.settings && (
                <BhomeSettings
                    currentSettings={currentSettings}
                    fooKey={fooKey}
                    handleSaveSettings={handleSaveSettings}
                    handleSettingsChange={handleSettingsChange}
                    isSubmitDisabled={isSubmitDisabled}
                    disableHashCheck={disableHashCheck}
                    toggleHashCheck={toggleHashCheck}
                    isTextMode={isTextMode}
                    toggleMode={handleToggleMode}
                    isViewMode={isViewMode}
                    toggleViewMode={handleToggleViewMode}
                    setFooKey={setFooKey}
                    enforceImmediateAction={enforceImmediateAction}
                    toggleEnforceImmediateAction={toggleEnforceImmediateAction}
                />
            )}
        </div>
    );
};

export default loading([
    FETCH_BHOME_SETTINGS.default,
    FETCH_SAVE_BHOME_SETTINGS.default,
    FETCH_GLOBAL_BHOME_SETTINGS.default,
    FETCH_UPDATE_GLOBAL_SETTINGS.default,
    FETCH_CHANGE_BHOME_STATUS.default,
])(Config);
