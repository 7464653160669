import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const BhomeMarker = forwardRef(({ color = '#FFFFFF', pointer, size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    id="Beehome"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    {...rest}
  >
    <g>
      <path d="m12.69.26l9.13,5.27c.43.25.69.71.69,1.2v10.54c0,.5-.26.96-.69,1.2l-9.13,5.27c-.43.25-.96.25-1.39,0l-9.13-5.27c-.43-.25-.69-.71-.69-1.2V6.73c0-.5.26-.96.69-1.2L11.31.26c.43-.25.96-.25,1.39,0Z" />
      <path
        d="m12.69,1.3l8.24,4.75c.43.25.69.71.69,1.2v9.51c0,.5-.26.96-.69,1.2l-8.24,4.75c-.43.25-.96.25-1.39,0l-8.24-4.75c-.43-.25-.69-.71-.69-1.2V7.25c0-.5.26-.96.69-1.2L11.3,1.3c.43-.25.96-.25,1.39,0ZM3.95,6.96c-.18-.11-.42-.04-.53.14-.11.18-.04.42.14.53h0l8.04,4.64v9.32c0,.21.17.38.38.38s.38-.17.38-.38h0v-9.29s8.08-4.66,8.08-4.66c.18-.11.25-.34.14-.53-.11-.18-.34-.25-.53-.14h0l-8.05,4.65L3.95,6.96Zm1.68,3.9c-.18-.11-.42-.04-.53.14-.11.18-.04.42.14.53h0l3.23,1.86c.12.07.19.2.19.33h0v6.18c0,.21.17.38.38.38s.38-.17.38-.38h0v-6.62c0-.14-.07-.26-.19-.33h0l-3.61-2.09Zm11.05,7c-.11-.18-.34-.25-.53-.14h0l-2.37,1.37c-.23.13-.33.39-.22.57.11.18.38.23.61.09h0l2.37-1.37c.18-.11.25-.34.14-.53Zm0-1.88c-.11-.18-.34-.25-.53-.14h0l-2.37,1.37c-.23.13-.33.39-.22.57.11.18.38.23.61.09h0l2.37-1.37c.18-.11.25-.34.14-.53Zm3.54-.17c-.11-.18-.38-.23-.61-.09h0l-2.37,1.37c-.18.11-.25.34-.14.53s.34.25.53.14h0l2.37-1.37c.23-.13.33-.39.22-.57Zm-3.54-1.66c-.11-.18-.34-.25-.53-.14h0l-2.37,1.37c-.23.13-.33.39-.22.57.11.18.38.23.61.09h0l2.37-1.37c.18-.11.25-.34.14-.53Zm3.54-.22c-.11-.18-.38-.23-.61-.09h0l-2.37,1.37c-.18.11-.25.34-.14.53.11.18.34.25.53.14h0l2.37-1.37c.23-.13.33-.39.22-.57Zm-3.54-1.66c-.11-.18-.34-.25-.53-.14h0l-2.37,1.37c-.23.13-.33.39-.22.57.11.18.38.23.61.09h0l2.37-1.37c.18-.11.25-.34.14-.53Zm3.54-.17c-.11-.18-.38-.23-.61-.09h0l-2.37,1.37c-.18.11-.25.34-.14.53.11.18.34.25.53.14h0l2.37-1.37c.23-.13.33-.39.22-.57Zm0-1.88c-.11-.18-.38-.23-.61-.09h0l-2.37,1.37c-.18.11-.25.34-.14.53.11.18.34.25.53.14h0l2.37-1.37c.23-.13.33-.39.22-.57Z"
        fill={color}
      />
    </g>
  </svg>
));

BhomeMarker.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pointer: PropTypes.bool
};

BhomeMarker.displayName = 'BhomeMarker';

export default BhomeMarker;
