import constants from '@beewise/constants';
import {
  createMoveFrameCommand,
  createCloseHiveCommand,
  createMakeSpaceCommand,
  getFrameData,
  createHomeXCommand,
  calculateLayout
} from './utils';

/**
 * Generates a sequence of actions to expand the hive and hive layout with new coordinates.
 * @param {Object} param0 - Object containing movedFrames, settings, stations, and currentPartitionIndex.
 * @param {Array} param0.movedFrames - Array of frames that have been moved.
 * @param {Object} param0.settings - Settings for the hive operation.
 * @param {Object} param0.stations - Stations involved in the hive operation.
 * @param {number} param0.initialPartitionIndex - Initial index of the partition frame.
 * @param {number} param0.currentPartitionIndex - Index of the partition frame.
 *  * @param {boolean} param0.isFirstMove - Is first move of the frame.
 * @returns {Object} An object representing the contract hive command with its sequences and hive layout with new coordinates.
 */
export const getExpandHiveActionWithLayout = ({
  movedFrames,
  settings,
  stations,
  currentPartitionIndex,
  initialPartitionIndex,
  isFirstMove
}) => {
  const expandHiveSequences = [];
  const partitionFrame = movedFrames[currentPartitionIndex];
  const untuckDistanceX = settings?.sequences?.untuck?.x_untuck_distance ?? 0;
  const defaultFrameWidth = constants.DEFAULT_FRAME_WIDTH.COMB_FRAME ?? 0;
  const combWidth = settings?.physical_measurements?.comb_width ?? defaultFrameWidth;

  const preparedMovedFrames = movedFrames.toSpliced(0, currentPartitionIndex);

  const inputFrameIndex = movedFrames.findIndex((frame) => frame.finalPositionX);

  preparedMovedFrames.forEach((frame, index) => {
    if (frame.initialFrameIndex >= initialPartitionIndex) {
      return;
    }

    const initialPartitionPositionFrameX = partitionFrame.place.position.x;

    const homeXCommand = createHomeXCommand();

    const moveOutCommand = createMoveFrameCommand({
      frame: getFrameData({ frame, positionX: frame.place.position.x }),
      place: {
        station: constants.STATIONS.SYSTEM_SLOT,
        position: {
          x: stations?.system_slot?.x
        }
      },
      shake: false,
      untuckDirection: -1
    });

    const isNextToStatic =
      preparedMovedFrames[index + 1].type === constants.FRAME_TYPES.STATIC_PARTITION;

    const makeSpaceCommand = createMakeSpaceCommand({
      frameType: frame.type,
      place: {
        station: frame.place.station,
        position: {
          x: isNextToStatic
            ? frame.finalPositionX + constants.DEFAULT_FRAME_WIDTH.STATIC_PARTITION / 2
            : frame.finalPositionX + combWidth / 2
        }
      },
      direction: -1
    });

    const moveFrameCommand = createMoveFrameCommand({
      frame: getFrameData({
        frame,
        station: constants.STATIONS.SYSTEM_SLOT,
        positionX: stations?.system_slot?.x
      }),
      place: {
        station: frame.place.station,
        position: { x: frame.finalPositionX }
      },
      shake: false,
      untuckDirection: 0
    });

    const partitionFrameNewPositionX = initialPartitionPositionFrameX - untuckDistanceX;
    const closeHiveCommand = createCloseHiveCommand({
      frame: getFrameData({
        frame: partitionFrame,
        positionX: partitionFrameNewPositionX
      })
    });

    const sequences = isFirstMove ? [homeXCommand] : [];

    expandHiveSequences.push({
      command: constants.COMMANDS.EXPAND_HIVE,
      params: {
        sequences: [
          ...sequences,
          moveOutCommand,
          makeSpaceCommand,
          moveFrameCommand,
          closeHiveCommand,
          homeXCommand
        ]
      }
    });
  });

  return {
    actions: expandHiveSequences,
    layout: calculateLayout({
      movedFrames,
      combWidth,
      inputFrame: movedFrames[inputFrameIndex],
      inputFrameIndex
    })
  };
};
