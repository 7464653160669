import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const MoveWithBees = forwardRef(({ fill = '#4E4540', ...rest }, ref) => (
  <svg
    ref={ref}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M13.4446 1L17.0001 4.55556L13.4446 8.11111" stroke={fill} />
    <path d="M2.77783 4.55542H17.0001" stroke={fill} />
    <circle cx="9.00007" cy="4.42854" r="2.28571" fill={fill} />
    <circle cx="9.00007" cy="13.5714" r="2.28571" fill={fill} />
    <path d="M4.55556 17L1 13.4445L4.55556 9.88892" stroke={fill} />
    <path d="M15.2222 13.4446H1" stroke={fill} />
  </svg>
));

MoveWithBees.propTypes = {
  strokeLinecap: PropTypes.string,
  fill: PropTypes.string
};

MoveWithBees.displayName = 'MoveWithBees';

export default MoveWithBees;
