import { CLEAR_POWER_MANAGEMENT_VIEW, FETCH_SOLAR_HISTORY_DATA, CLEAR_HISTORY_DATA } from '../actionTypes';

export const fetchSolarHistoryData = (bhomeId, start, end) => ({
    type: 'FETCH',
    params: {
        type: FETCH_SOLAR_HISTORY_DATA.default,
        method: 'POST',
        url: `/bhomes/${bhomeId}/get-solar-history-data`,
        body: {
            start,
            end,
        },
    },
});

export const clearPowerManagementView = () => ({
    type: CLEAR_POWER_MANAGEMENT_VIEW,
});

export const clearHistoryData = () => ({
    type: CLEAR_HISTORY_DATA,
});
