import { shallowEqual, useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';

export default (permissionName = '', app = '') => {
  const permission = useSelector((state) => state.app.permission, shallowEqual);

  if (isEmpty(permission) || !permissionName) {
    return false;
  }

  if (!app && permissionName) {
    return permission?.[permissionName];
  }

  return permission?.[app]?.[permissionName];
};
