import {
    CLEAR_SCRIPTS_VIEW,
    FETCH_CANCEL_JENKINS_JOB,
    FETCH_GET_MANUFACTURING_TESTS,
    FETCH_GET_TESTS_S3_DATA,
    FETCH_RUN_JENKINS_BUILD,
    REMOVE_TEST_S3_DATA,
} from '../actionTypes';

export const clearScriptsView = () => ({
    type: CLEAR_SCRIPTS_VIEW,
});

export const removeTestS3Data = () => ({
    type: REMOVE_TEST_S3_DATA,
});

export const fetchRunJenkinsJob = (id, body, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_RUN_JENKINS_BUILD.default,
        method: 'POST',
        url: `/bhomes/${id}/run-jenkins-job`,
        body,
        resolver,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchGetManufacturingTests = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_GET_MANUFACTURING_TESTS.default,
        url: `/bhomes/${id}/manufacturing-tests`,
    },
});

export const fetchTestS3Data = (bhomeId, testId) => ({
    type: 'FETCH',
    params: {
        type: FETCH_GET_TESTS_S3_DATA.default,
        url: `/bhomes/${bhomeId}/get-manufacturing-test-file/${testId}`,
    },
});

export const fetchCancelJenkinsJob = (bhomeId, jenkinsId) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CANCEL_JENKINS_JOB.default,
        method: 'PUT',
        url: `/bhomes/${bhomeId}/cancel-jenkins-job/${jenkinsId}`,
    },
});
