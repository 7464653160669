import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Cell = forwardRef(({ color = 'currentColor', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <path
      d="M7.50781 9.8125L5.99219 7.1875L7.50781 4.5625H10.5859L12.1016 7.1875L11.2144 9.03577"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.91406 7.1875H5.99225"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.91406 7.1875L1.39844 4.5625L2.91406 1.9375H5.99219L7.50781 4.5625"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.60719 9.81251L7.50781 9.81251L5.99219 12.4375H2.91406L1.39844 9.81251L2.21375 8.40063"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.99219 12.4375L7.50774 15.0625"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.538 2.8269L10.536 4.56246"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.98908 12.4062L1.98267 14.1494"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8271 13.6161L13.1782 11.3062"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8702 11.2638L13.135 13.6585"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8632 15.6875C13.6753 15.6875 15.1444 14.2184 15.1444 12.4062C15.1444 10.5941 13.6753 9.125 11.8632 9.125C10.051 9.125 8.58191 10.5941 8.58191 12.4062C8.58191 14.2184 10.051 15.6875 11.8632 15.6875Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8632 15.6875C13.6753 15.6875 15.1444 14.2184 15.1444 12.4062C15.1444 10.5941 13.6753 9.125 11.8632 9.125C10.051 9.125 8.58191 10.5941 8.58191 12.4062C8.58191 14.2184 10.051 15.6875 11.8632 15.6875Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

Cell.propTypes = {
  color: PropTypes.string
};

Cell.displayName = 'Cell';

export default Cell;
