export default {
  left: [
    [0, 1],
    [2, 3],
    [4, 5],
    [6, 7],
    [8, 9],
    [10, 11],
    [12, 13]
  ],
  right: [
    [15, 14],
    [17, 16],
    [19, 18],
    [20, null],
    [21, null],
    [22, null],
    [23, null]
  ]
};
