import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@beewise/checkbox-field';
import { SelectField } from '@beewise/select-field';
import { untuckOptions } from 'components/views/ActionsAndMonitoring/utils';
import TextField from '@beewise/text-field';
import { stationOptions } from '../../Calibrations/utils';

const MoveFrameParams = ({ params, setParams }) => {
    const toggleShake = useCallback(() => {
        setParams(params => ({
            ...params,
            shake: !params?.shake,
        }));
    }, [setParams]);

    const handleUntuckChange = useCallback(
        value => {
            setParams(params => ({
                ...params,
                untuck_direction: value,
            }));
        },
        [setParams]
    );

    const handleDestinationStationChange = useCallback(
        value => {
            setParams(params => ({
                ...params,
                place: {
                    ...(params?.place ?? {}),
                    station: value,
                },
            }));
        },
        [setParams]
    );

    const handleChangeDestinationPosition = useCallback(
        axis => e => {
            const currentValue = typeof e === 'object' && e.target ? e.target.value : e;
            setParams(params => ({
                ...params,
                place: {
                    ...(params?.place ?? {}),
                    position: {
                        ...(params?.place?.position ?? {}),
                        [axis]: currentValue?.endsWith('.') ? currentValue : Number(currentValue),
                    },
                },
            }));
        },
        [setParams]
    );

    return (
        <>
            <Checkbox label="Shake" checked={params?.shake ?? false} onChange={toggleShake} />
            <SelectField
                value={params?.untuck_direction ?? null}
                options={untuckOptions}
                onChange={handleUntuckChange}
                placeholder="Untuck Direction"
                className="nowrap frame-type-select"
                size="small"
            />
            <div>Destination:</div>
            <SelectField
                value={params?.place?.station ?? null}
                options={stationOptions}
                onChange={handleDestinationStationChange}
                placeholder="Select station"
                className="nowrap"
                size="small"
            />
            <TextField
                label="x"
                value={params?.place?.position?.x ?? ''}
                onChange={handleChangeDestinationPosition('x')}
                size="small"
            />
        </>
    );
};

MoveFrameParams.propTypes = {
    params: PropTypes.shape(),
    setParams: PropTypes.func.isRequired,
};

export default MoveFrameParams;
