import constants from '@beewise/constants';
import {
  createMoveFrameCommand,
  createCloseHiveCommand,
  createHomeXCommand,
  getFrameData
} from './utils';

/**
 * Swaps two specified frames and their place values within an array of frames.
 *
 * @param {Object} firstFrame - The first frame.
 * @param {Object} secondFrame - The second frame.
 * @param {Array} frames - The array of all frames.
 * @returns {Array} - The modified array of frames with the specified frames and their place values swapped.
 */
export const swapFrames = (firstFrame, secondFrame, frames) => {
  const preparedFrames = structuredClone(frames);
  const firstFrameIndex = preparedFrames.findIndex((frame) => frame.frameId === firstFrame.frameId);
  const secondFrameIndex = preparedFrames.findIndex(
    (frame) => frame.frameId === secondFrame.frameId
  );

  if (firstFrameIndex === -1 || secondFrameIndex === -1) {
    throw new Error('One or both frames not found in the array');
  }

  [preparedFrames[firstFrameIndex], preparedFrames[secondFrameIndex]] = [
    preparedFrames[secondFrameIndex],
    preparedFrames[firstFrameIndex]
  ];
  [preparedFrames[firstFrameIndex].place, preparedFrames[secondFrameIndex].place] = [
    preparedFrames[secondFrameIndex].place,
    preparedFrames[firstFrameIndex].place
  ];

  return preparedFrames;
};

/**
 * Function to find the nearest frame object with type "partition" from the left side of a given frame.
 * @param {Object} selectedFrame - The specific frame object.
 * @param {Array} frames - Array of all frame objects.
 * @return {Object|null} - The nearest partition frame to the left or null if none found.
 */
const findNearestPartitionToTheLeft = (selectedFrame, frames) => {
  const specificFrameIndex = frames.findIndex((frame) => frame.frameId === selectedFrame.frameId);

  // eslint-disable-next-line no-plusplus
  for (let i = specificFrameIndex - 1; i >= 0; i--) {
    if (frames[i].type === constants.FRAME_TYPES.PARTITION) {
      return frames[i];
    }
  }

  return null;
};

/**
 * Function to get the commands for balancing hives.
 * @param {Object} firstFrame - The first frame object.
 * @param {Object} secondFrame - The second frame object.
 * @param {Object} context - The context containing stations, frames, and settings.
 * @return {Object} - The balance hive command object.
 */
export const getBalanceHiveCommand = (firstFrame, secondFrame, context) => {
  const { stations, frames, settings } = context;
  const untuckDistanceX = settings?.sequences?.untuck?.x_untuck_distance ?? 0;
  const homeXCommand = createHomeXCommand();

  const moveFirstFrameToSysSlot = createMoveFrameCommand({
    frame: getFrameData({ frame: firstFrame, positionX: firstFrame.place.position.x }),
    place: {
      station: constants.STATIONS.SYSTEM_SLOT,
      position: {
        x: stations?.system_slot?.x
      }
    },
    shake: true,
    extra_shake: true,
    untuckDirection: -1
  });

  const moveSecondFrameToFirst = createMoveFrameCommand({
    frame: getFrameData({ frame: secondFrame, positionX: secondFrame.place.position.x }),
    place: {
      station: firstFrame.place.station,
      position: {
        x: firstFrame.place.position.x
      }
    },
    shake: true,
    extra_shake: true,
    untuckDirection: -1
  });

  const partitionFrameFirstHive = findNearestPartitionToTheLeft(firstFrame, frames);

  const closeHiveFirstMovement = createCloseHiveCommand({
    frame: getFrameData({
      frame: partitionFrameFirstHive,
      positionX: partitionFrameFirstHive.place.position.x - untuckDistanceX
    })
  });

  const moveFirstFrameToSecond = createMoveFrameCommand({
    frame: getFrameData({
      frame: firstFrame,
      station: constants.STATIONS.SYSTEM_SLOT,
      positionX: stations?.system_slot?.x
    }),
    place: {
      station: secondFrame.place.station,
      position: {
        x: secondFrame.place.position.x
      }
    },
    shake: false,
    untuckDirection: 0
  });

  const partitionFrameSecondHive = findNearestPartitionToTheLeft(secondFrame, frames);

  const closeHiveSecondMovement = createCloseHiveCommand({
    frame: getFrameData({
      frame: partitionFrameSecondHive,
      positionX: partitionFrameSecondHive.place.position.x - untuckDistanceX
    })
  });

  return {
    command: constants.COMMANDS.BALANCE_HIVES,
    params: {
      sequences: [
        homeXCommand,
        moveFirstFrameToSysSlot,
        moveSecondFrameToFirst,
        closeHiveFirstMovement,
        homeXCommand,
        moveFirstFrameToSecond,
        closeHiveSecondMovement,
        homeXCommand
      ]
    }
  };
};
