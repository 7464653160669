import { shallowEqual, useSelector } from 'react-redux';
import constants from 'appConstants';

const useSuperAdmin = () => {
    const user = useSelector(state => state.app.user, shallowEqual);

    return user?.role === constants.ROLES.SUPER_ADMIN;
};

export default useSuperAdmin;
