import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import StationFrame from './StationFrame';
import StationHive from './StationHive';
import { getCurrentStationData, getHiveId } from '../../utils';
import LockModal from './LockModal';

import './Station.scss';

const defaultStationStart = 0;
const defaultStationEnd = constants.DEFAULT_BHOME_4_SLOT_WIDTH * constants.SLOTS_PER_BHOME_4_STATION;

const Station = ({
    stationName,
    layout,
    handleOnFrameClick,
    stations,
    hives,
    settings,
    pickedFrames = [],
    bhomeId,
}) => {
    const [selectedFrame, setSelectedFrame] = useState(null);
    const [hiveToLock, setHiveToLock] = useState(null);

    const stationEnd = useMemo(() => stations?.[stationName]?.end?.x ?? defaultStationEnd, [stationName, stations]);

    const stationStart = useMemo(
        () => stations?.[stationName]?.start?.x ?? defaultStationStart,
        [stationName, stations]
    );

    const currentStationData = useMemo(
        () => getCurrentStationData({ currentLayout: layout, stationName }),
        [layout, stationName]
    );

    const handleFrameClick = useCallback(
        frame => e => {
            e.stopPropagation();
            setSelectedFrame(frame);
            if (frame?.frameId) {
                handleOnFrameClick(frame);
            }
        },
        [handleOnFrameClick]
    );

    const currentStationHives = useMemo(() => hives.filter(hive => hive.station === stationName), [hives, stationName]);

    const renderedFrames = useMemo(
        () =>
            currentStationData.map((frame, index) => (
                <StationFrame
                    key={`shf-${frame.frameId || frame?.place?.position?.x}-${index}`}
                    frame={frame}
                    onFrameClick={handleFrameClick(frame)}
                    stationStart={stationStart}
                    stationEnd={stationEnd}
                    stationName={stationName}
                    hives={hives}
                    isSelected={pickedFrames?.some(item => item.frameId === frame.frameId)}
                />
            )),
        [currentStationData, handleFrameClick, hives, pickedFrames, stationEnd, stationName, stationStart]
    );

    const renderedHives = useMemo(
        () =>
            currentStationHives.map(hive => (
                <StationHive
                    hive={hive}
                    key={getHiveId(hive)}
                    stationStart={stationStart}
                    stationEnd={stationEnd}
                    settings={settings}
                    setHiveToLock={setHiveToLock}
                />
            )),
        [currentStationHives, settings, stationEnd, stationStart]
    );

    return (
        <div className="station-wrapper">
            <div className={cx('station', { 'is-selected': selectedFrame })} role="presentation">
                <div className="station-content">{renderedFrames}</div>
                <div className="station-hives-wrapper">{renderedHives}</div>
            </div>
            <LockModal setHiveToLock={setHiveToLock} hiveToLock={hiveToLock} bhomeId={bhomeId} />
        </div>
    );
};

Station.propTypes = {
    layout: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    hives: PropTypes.arrayOf(PropTypes.shape()),
    pickedFrames: PropTypes.arrayOf(PropTypes.shape()),
    stationName: PropTypes.string.isRequired,
    handleOnFrameClick: PropTypes.func,
    stations: PropTypes.shape(),
    settings: PropTypes.shape(),
    bhomeId: PropTypes.number,
};

export default Station;
