import React, { memo } from 'react';
import Icon from '@beewise/icon';

const SuspenseLoading = () => (
  <div className="preloader-wrapper" style={{ height: '100vh' }}>
    <div className="preloader">
      <Icon type="loading" className="beewise-preloader" />
    </div>
  </div>
);

export default memo(SuspenseLoading);
