import ReleaseRenderer from '../components/ReleaseRenderer';

export const getHivesColumnDefs = (setHiveToLock, setBhomeId) => [
    {
        headerName: 'ID',
        field: 'id',
    },
    {
        headerName: 'Hive ID',
        field: 'hive',
        valueGetter: params => params.data.hive.hiveId,
    },
    {
        headerName: 'Locked Timestamp',
        field: 'hive',
        valueGetter: params => new Date(params.data.hive.lockedAt).toLocaleString(),
    },
    {
        headerName: 'Locked By',
        field: 'hive',
        valueGetter: params => params.data.hive.lockedBy,
    },
    {
        headerName: 'Locked Reason',
        field: 'hive',
        valueGetter: params => params.data.hive.lockedReason,
    },
    {
        headerName: 'Release Hive',
        field: 'hive',
        cellRenderer: ReleaseRenderer,
        cellRendererParams: {
            setHiveToLock,
            setBhomeId,
        },
    },
];

export const getFilteredSortedLockedHives = (actionData, stateHives) =>
    [
        ...actionData.markedHives.reduce((acc, hive) => {
            if (!hive.isLocked) {
                return acc;
            }
            return [...acc, { id: actionData.bhomeId, hive }];
        }, []),
        ...stateHives.filter(hive => hive.id !== actionData.bhomeId),
    ].sort((a, b) => a.id - b.id);
