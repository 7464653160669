import React from 'react';
import PropTypes from 'prop-types';
import ActionCenterV4 from './ActionCenterV4';

const FrameActionsCenter = ({ bhome, isEMTester, isFLTester }) => (
    <ActionCenterV4 bhome={bhome} isEMTester={isEMTester} isFLTester={isFLTester} />
);

FrameActionsCenter.propTypes = {
    isEMTester: PropTypes.bool,
    isFLTester: PropTypes.bool,
    bhome: PropTypes.shape(),
};

export default FrameActionsCenter;
