import React, { PureComponent, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

export const useToggle = (initialValue = false) => {
  const [isShown, setToggleValue] = useState(initialValue);
  const toggleValue = useCallback(() => setToggleValue((state) => !state), []);
  const toggleShow = useCallback(() => setToggleValue(() => true), []);
  const toggleHide = useCallback(() => setToggleValue(() => false), []);

  return [isShown, toggleValue, toggleShow, toggleHide];
};

/**
 * Toggle decorator
 * Provides a "toggle" prop to the Component
 * With "show", "hide", "toggle" methods and "isShown" flag
 * @param {Function} ComposingComponent - Component to decorate
 * @returns {Class} - decorated Component
 */
/* eslint-disable */
export default function (ComposingComponent) {
  return class Toggle extends PureComponent {
    static propTypes = {
      isShown: PropTypes.bool,
      isShownByKey: PropTypes.shape()
    };

    constructor(props, context) {
      super(props, context);

      // Set to state to provide public members via props
      // Takes passed "isShown" prop as initial state if provided
      this.state = {
        isShown: !!props.isShown,
        show: this.show,
        hide: this.hide,
        toggle: this.toggle,
        prevIsShownProp: props.isShown,
        isShownByKey: {}
      };
    }

    static getDerivedStateFromProps(props, state) {
      const { isShown } = props;
      const { prevIsShownProp } = state;
      if (isShown !== prevIsShownProp) {
        return {
          ...state,
          isShown: !!isShown,
          prevIsShownProp: isShown
        };
      }
      return null;
    }

    isShown = (key) => (key ? this.state.isShownByKey[key] : this.state.isShown);

    show = (key) => {
      if (key && typeof key === 'string') {
        return this.setState({
          ...this.state,
          isShownByKey: {
            ...this.state.isShownByKey,
            [key]: true
          }
        });
      }
      return this.setState({ isShown: true });
    };

    hide = (key) => {
      if (key && typeof key === 'string') {
        return this.setState({
          ...this.state,
          isShownByKey: {
            ...this.state.isShownByKey,
            [key]: false
          }
        });
      }
      return this.setState({ isShown: false });
    };

    toggle = (key) => {
      if (key && typeof key === 'string') {
        return this.setState({
          ...this.state,
          isShownByKey: {
            ...this.state.isShownByKey,
            [key]: !this.state.isShownByKey[key]
          }
        });
      }
      return this.setState({
        isShown: !this.state.isShown
      });
    };

    /**
     * Renders original Component with additional (decoration) prop
     */
    render() {
      return <ComposingComponent {...this.props} toggle={this.state} />;
    }
  };
}
