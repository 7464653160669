import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Brood = forwardRef(({ color = 'currentColor', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <g clipPath="url(#clip0_286_4837)">
      <path
        d="M14.5188 2.47331C14.5188 2.47331 14.45 1.70728 13.4978 1.70728C12.5456 1.70728 12.2837 2.58687 12.5047 3.06865"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5045 3.06873C13.231 3.30308 14.0238 3.06839 14.5187 2.47339"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5188 2.47339C14.864 2.55908 15.1596 2.78655 15.3371 3.1028C15.5147 3.41905 15.5581 3.79656 15.4569 4.1462"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4566 4.14624C16.0227 5.10808 15.9456 6.33077 15.2635 7.20933"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2638 7.20947C15.5005 8.5292 14.7524 9.82588 13.5115 10.244"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5115 10.2444C13.1605 11.4523 11.9354 12.1512 10.7523 11.8184"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5047 3.0686C12.0732 3.47915 11.992 4.14951 12.3113 4.65641"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3117 4.65649C12.0343 5.13552 11.9693 5.71331 12.1324 6.24499"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.132 6.24512C11.4455 6.64809 10.9892 7.36594 10.9045 8.17361"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9045 8.17383C10.1943 8.33729 9.6323 8.89581 9.44922 9.6202"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.44887 9.62036C9.0366 9.69676 8.68215 9.93352 8.44642 10.2639C8.1374 10.6965 8.2279 11.3049 8.64877 11.6232C9.24411 12.074 10.0528 12.1693 10.7524 11.818"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.44897 9.62036C9.51814 10.5247 10.0023 11.3407 10.7525 11.8183"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9045 8.17383C11.1238 9.45192 12.2532 10.3491 13.5117 10.2441"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1321 6.24512C12.9174 7.14467 14.1266 7.51667 15.264 7.20936"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3116 4.65658C13.3585 5.1948 14.6228 4.9897 15.457 4.14624"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.40979 5.26025C6.42889 5.75477 6.64117 6.21917 7.00256 6.55571"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00283 6.55578C7.7517 6.43459 8.06851 6.17709 8.06151 5.58095C8.05533 4.98477 7.69533 4.20028 7.13881 3.9828C6.58293 3.76494 5.77848 3.74558 5.3541 4.17261"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00293 6.55615C6.82658 7.03478 6.34485 7.33593 5.83715 7.2842"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.8374 7.28394C5.85773 7.81824 5.58503 8.32564 5.12406 8.60883"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.12366 8.6084C5.46014 9.1912 5.41518 9.92177 5.00877 10.4693"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.0087 10.4693C5.62885 11.2592 5.63154 11.9874 5.32475 12.9518"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.35388 4.17288C4.79161 4.10194 4.23268 4.35132 3.90825 4.81784"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.90842 4.81791C2.91234 4.95096 2.12802 5.74096 2.00904 6.73166"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.00891 6.73169C1.51772 7.06515 1.2014 7.59816 1.14673 8.18344C1.09274 8.76918 1.30588 9.34433 1.72816 9.75015"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.72778 9.75C1.20003 10.5325 1.13482 11.7855 2.17552 12.4289"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.17558 12.4288C1.94006 14.0874 3.86339 15.7826 5.27658 14.9168C6.29338 14.2939 5.79671 13.018 5.3242 12.9519"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.90839 4.81784C3.90659 5.98641 4.69857 6.99934 5.83712 7.28361"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.00876 6.73156C2.52796 7.95866 3.77974 8.71294 5.12352 8.60847"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.72674 9.75531C2.52792 10.6978 3.86956 10.9878 5.00954 10.4647"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.17579 12.4288C3.03065 13.1889 4.253 13.3924 5.32473 12.9518"
        stroke={color}
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_286_4837">
        <rect width="16" height="16" fill="white" transform="translate(0.333374 0.707275)" />
      </clipPath>
    </defs>
  </svg>
));

Brood.propTypes = {
  color: PropTypes.string
};

Brood.displayName = 'Brood';

export default Brood;
