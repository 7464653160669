import { ON_REPORT_POWER_DATA, ON_REPORT_SOLAR_DATA } from 'actionTypes';
import { CLEAR_POWER_MANAGEMENT_VIEW, FETCH_SOLAR_HISTORY_DATA, CLEAR_HISTORY_DATA } from '../actionTypes';

const initialState = {
    newRow: null,
    historyData: null,
};

const powerManagement = (state = initialState, action) => {
    switch (action.type) {
        case ON_REPORT_SOLAR_DATA:
            return {
                ...state,
                newRow: action.payload.solar_data,
            };
        case ON_REPORT_POWER_DATA:
            return {
                ...state,
                newRow: action.payload.data,
            };
        case FETCH_SOLAR_HISTORY_DATA.success:
            return {
                ...state,
                historyData: action.data.data,
            };
        case CLEAR_POWER_MANAGEMENT_VIEW:
        case CLEAR_HISTORY_DATA: {
            return initialState;
        }
        default:
            return state;
    }
};

export default powerManagement;
