import { inRange } from 'lodash-es';
import { UI_ENV } from '../config';

export const isLimitedBayUser = user => user?.email?.match(/bay0[1-7]@beewise.ag/);

export const LIMITED_USERS_RANGE = {
    FROM: 40000,
    TO: 50000,
};

export const isProduction = UI_ENV === 'prod';

export const isInLimitedBhomeBayRange = bhomeId => inRange(bhomeId, LIMITED_USERS_RANGE.FROM, LIMITED_USERS_RANGE.TO);
export const LIMITED_MANUFACTURING_BHOME_LIST = [1020, 1021];

export const isLimitedTestUser = user => isLimitedBayUser(user) || user?.email?.match(/ectest0[1-7]@beewise.ag/);

export const HIVES = [
    {
        label: 'Station A',
        value: 'a',
    },
    {
        label: 'Station B',
        value: 'b',
    },
];
