import React from 'react';
import dayjs from 'dayjs';
import { inRange } from 'lodash-es';
import S3LinkRenderer from '../components/JenkinsTests/S3LinkRenderer';
import CsvRenderer from '../components/JenkinsTests/ResultRenderers/csvRenderer';
import ImageRenderer from '../components/JenkinsTests/ResultRenderers/imageRenderer';
import CancelRenderer from '../components/JenkinsTests/CancelRenderer';

export const JENKINS_BUILD_NAME = 'QA/manufacture_test';

export const CALIBRATE_LOAD_CELL_TEST =
    'test/step_defs/zz_man_test/test_end_module_station.py::test_calibrate_load_cell';

const getTesterJobs = ({ isEMTester, isFLTester, isGripperTester, isECTester }) => {
    if (isEMTester) {
        return [
            {
                label: 'EM Z and G Homing Test',
                value: 'test/step_defs/zz_man_test/test_end_module_station.py::test_z_and_g_homing',
            },
            {
                label: 'EM Wedge Test',
                value: 'test/step_defs/zz_man_test/test_end_module_station.py::test_wedge_check',
            },
            {
                label: 'EM IR Sensors Test',
                value: 'test/step_defs/zz_man_test/test_end_module_station.py::test_check_ir',
            },
            {
                label: 'EM Shaker Test',
                value: 'test/step_defs/zz_man_test/test_end_module_station.py::test_shaker_shake',
            },
        ];
    }
    if (isGripperTester) {
        return [
            {
                label: 'Gripper Stroke Test',
                value: 'test/step_defs/zz_man_test/test_gripper_station.py::test_gripper_stroke',
            },
            {
                label: 'Gripper Burn-in Test',
                value: 'test/step_defs/zz_man_test/test_gripper_station.py::test_gripper_burning_test',
            },
        ];
    }
    if (isFLTester) {
        return [
            {
                label: 'FL Test',
                value: 'test/step_defs/zz_man_test/test_fl.py::test_fl_with_end_module',
            },
            {
                label: 'FL Y friction test',
                value: 'test/step_defs/zz_man_test/test_axis_y_friction.py',
            },
            {
                label: 'FL Z friction test',
                value: 'test/step_defs/zz_man_test/test_axis_z_friction.py',
            },
            {
                label: 'IR Check test',
                value: 'test/step_defs/zz_man_test/test_end_module_station.py::test_check_ir',
            },
            {
                label: 'Calibrate load cell',
                value: CALIBRATE_LOAD_CELL_TEST,
            },
        ];
    }
    if (isECTester) {
        return [
            {
                label: 'EC Incubator Test',
                value: 'test/step_defs/zz_man_test/test_incubator.py::test_ec_incubator_station_test',
            },
        ];
    }
};

export const getJenkinsTestsOptions = ({ isEMTester, isFLTester, isGripperTester, isECTester }) => {
    if (isEMTester || isFLTester || isGripperTester || isECTester) {
        return getTesterJobs({ isEMTester, isFLTester, isGripperTester, isECTester });
    }

    return [
        {
            label: 'X Friction Test',
            value: 'test/step_defs/zz_man_test/test_axis_x_friction.py',
        },
        {
            label: 'Y Friction Test',
            value: 'test/step_defs/zz_man_test/test_axis_y_friction.py',
        },
        {
            label: 'Z Friction Test',
            value: 'test/step_defs/zz_man_test/test_axis_z_friction.py',
        },
        {
            label: 'G Friction Test',
            value: 'test/step_defs/zz_man_test/test_axis_g_friction.py',
        },
        {
            label: 'X Homing Repeatability Test',
            value: 'test/step_defs/zz_man_test/test_axis_homming_repeatability.py::test_x_axis_homing_repeatability',
        },
        {
            label: 'Y Homing Repeatability Test',
            value: 'test/step_defs/zz_man_test/test_axis_homming_repeatability.py::test_y_axis_homing_repeatability',
        },
        {
            label: 'Z Homing Repeatability Test',
            value: 'test/step_defs/zz_man_test/test_axis_homming_repeatability.py::test_z_axis_homing_repeatability',
        },
        {
            label: 'G Homing Repeatability Test',
            value: 'test/step_defs/zz_man_test/test_axis_homming_repeatability.py::test_g_axis_homing_repeatability',
        },
        {
            label: 'Shaking Repeatability Test',
            value: 'test/step_defs/zz_man_test/test_axis_homming_repeatability.py::test_shaking_repeatability',
        },
        {
            label: 'Reconfigure Cameras',
            value: 'test/step_defs/zz_man_test/test_fix_camera_config.py',
        },
        {
            label: 'Incubator Test',
            value: 'test/step_defs/zz_man_test/test_incubator.py::test_incubator',
        },
        {
            label: 'EC Incubator Test',
            value: 'test/step_defs/zz_man_test/test_incubator.py::test_ec_incubator_station_test',
        },
        {
            label: 'Dance over a5 b5 a1',
            value: 'test/step_defs/intergration_tests/test_dance.py::test_dance_a5_b5_a1',
        },
        {
            label: 'Dance hive a1',
            value: 'test/step_defs/intergration_tests/test_dance.py::test_dance_hive_a1',
        },
        {
            label: 'Dance hive a2',
            value: 'test/step_defs/intergration_tests/test_dance.py::test_dance_hive_a2',
        },
        {
            label: 'Dance hive a3',
            value: 'test/step_defs/intergration_tests/test_dance.py::test_dance_hive_a3',
        },
        {
            label: 'Dance hive a4',
            value: 'test/step_defs/intergration_tests/test_dance.py::test_dance_hive_a4',
        },
        {
            label: 'Dance hive a5',
            value: 'test/step_defs/intergration_tests/test_dance.py::test_dance_hive_a5',
        },
        {
            label: 'Dance hive b1',
            value: 'test/step_defs/intergration_tests/test_dance.py::test_dance_hive_b1',
        },
        {
            label: 'Dance hive b2',
            value: 'test/step_defs/intergration_tests/test_dance.py::test_dance_hive_b2',
        },
        {
            label: 'Dance hive b3',
            value: 'test/step_defs/intergration_tests/test_dance.py::test_dance_hive_b3',
        },
        {
            label: 'Dance hive b4',
            value: 'test/step_defs/intergration_tests/test_dance.py::test_dance_hive_b4',
        },
        {
            label: 'Dance hive b5',
            value: 'test/step_defs/intergration_tests/test_dance.py::test_dance_hive_b5',
        },
        {
            label: 'Dance hive from a1 to a5',
            value: 'test/step_defs/intergration_tests/test_dance.py::test_dance_side_A_from_1_to_5',
        },
        {
            label: 'Dance hive from b1 to b5',
            value: 'test/step_defs/intergration_tests/test_dance.py::test_dance_side_B_from_1_to_5',
        },
        {
            label: 'FL Test',
            value: 'test/step_defs/zz_man_test/test_fl.py::test_fl_with_end_module',
        },
        {
            label: 'IR Check test',
            value: 'test/step_defs/zz_man_test/test_end_module_station.py::test_check_ir',
        },
        {
            label: 'Calibrate load cell',
            value: CALIBRATE_LOAD_CELL_TEST,
        },
    ];
};

export const jenkinsColumnDefs = [
    {
        headerName: 'ID',
        field: 'id',
        maxWidth: 130,
    },
    {
        headerName: 'Build Name',
        field: 'build_name',
    },
    {
        headerName: 'Build ID',
        field: 'build_id',
        maxWidth: 115,
    },
    {
        headerName: 'Build URL',
        field: 'build_url',
    },
    {
        headerName: 'Build Status',
        field: 'build_status',
        maxWidth: 140,
    },
    {
        headerName: 'Test Cases',
        field: 'test_cases',
        valueFormatter: params => params.data.test_cases.join(', '),
    },
    {
        headerName: 'S3 Data',
        field: 'data',
        cellRenderer: S3LinkRenderer,
    },
    {
        headerName: 'Cancel',
        field: 'id',
        cellRenderer: CancelRenderer,
        maxWidth: 125,
    },
    {
        headerName: 'Created At',
        field: 'created_at',
        valueFormatter: params => new Date(params.data.created_at).toLocaleString(),
        comparator: (valueA, valueB) => dayjs(valueA).unix() - dayjs(valueB).unix(),
        maxWidth: 220,
    },
];

export const getStationType = bhomeId => {
    if (inRange(bhomeId, 1000, 1010)) {
        return 'endModule';
    } else if (inRange(bhomeId, 1010, 1020)) {
        return 'gripper';
    } else if (inRange(bhomeId, 1020, 1030)) {
        return 'frameLoader';
    } else if (inRange(bhomeId, 1030, 1040)) {
        return 'electricalCabinet';
    }
    return 'fat';
};

export const getJenkinsResultRenderers = testS3Data =>
    Object.keys(testS3Data).reduce(
        (acc, key) => {
            if (key.includes('.csv') || key.includes('.txt')) {
                acc.tabList.push(key);
                acc.tabContents.push(<CsvRenderer key={key} data={testS3Data[key]} fileName={key} />);
            } else if (key.includes('.jpeg') || key.includes('.jpg')) {
                acc.tabList.push(key);
                acc.tabContents.push(<ImageRenderer fileName={key} data={testS3Data[key]} />);
            }
            // TODO: add other renderers
            return acc;
        },
        {
            tabList: [],
            tabContents: [],
        }
    );
