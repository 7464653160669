import { matrixHelpers } from './matrix';

export { default as matrix, COSTS, matrixHelpers } from './matrix';

/* eslint-disable */
const moveFramesCalculator = (state, moves, hiveSize = 30) => {
  const FRAME_TYPES = {
    PARTITION: 'partition',
    FEEDER: 'feeder',
    QUEEN_EXCLUDER: 'queen_excluder',
    COMB_FRAME: 'comb_frame'
  };
  const HIVE_LIMITED_FRAMES = [FRAME_TYPES.FEEDER, FRAME_TYPES.QUEEN_EXCLUDER];
  const FRAMES_NOT_MOVEABLE_BY_USER = [];
  const HIVE_SIZE = hiveSize;

  let _moves = moves;
  let _state = state;
  let _movesPerformed = []; //the actual performed moves, in the actual order. As they will sent and be perfotmed by the machine
  let _queuedMoves = [];
  let _extractedPartitionObj = {};
  let _frameIndexToPlaceBack = null;
  const _placedFrames = []; //cache the frames that already placed in their final destination

  const _isFrameMoveableByUser = (frameObj) =>
    !frameObj || !FRAMES_NOT_MOVEABLE_BY_USER.includes(frameObj.type);

  const _getHiveNumber = (index) => matrixHelpers.getHiveNumber(index, HIVE_SIZE);

  const _isMoveWithinTheSameHive = (moveObj) =>
    _getHiveNumber(moveObj.fromIndex) === _getHiveNumber(moveObj.toIndex);

  const _getHive = (index) => {
    const hiveNumber = _getHiveNumber(index);
    return _state
      .slice(hiveNumber * HIVE_SIZE, hiveNumber * HIVE_SIZE + HIVE_SIZE)
      .map((item, innerIndex) => ({
        ...item,
        absIndex: hiveNumber * HIVE_SIZE + innerIndex
      }));
  };

  const _isEmptySlot = (slot) => slot.rfid === null;

  const _isDisabledSlot = (slot) => slot.status === 'disabled' || slot.status === 'error';

  const _getEmptySlotsIndices = (slots = null) => {
    slots = slots || _state;
    let emptySlotIndices = [];
    for (let i = 0; i < slots.length; i++) {
      if (_isEmptySlot(slots[i]) && !_isDisabledSlot(slots[i])) {
        emptySlotIndices.push(i);
      }
    }
    return emptySlotIndices.length ? emptySlotIndices : false;
  };

  const _getCrossHivesMoveableFramesIndices = (slots = null) => {
    slots = slots || _state;
    let crossHivesMoveableFrames = [];
    for (let i = 0; i < slots.length; i++) {
      if (
        !HIVE_LIMITED_FRAMES.includes(slots[i].type) &&
        !_isWithinPartitions(_state.findIndex((item) => item && item.rfid === slots[i].rfid))
      ) {
        crossHivesMoveableFrames.push(i);
      }
    }
    return crossHivesMoveableFrames.length ? crossHivesMoveableFrames : false;
  };

  const _isLegalMove = (moveObj) => {
    if (
      !_isMoveWithinTheSameHive(moveObj) &&
      HIVE_LIMITED_FRAMES.includes(_state[moveObj.fromIndex].type)
    )
      throw {
        error: 'TRYING_TO_MOVE_A_LIMITED_FRAME_TO_OTHER_HIVE',
        msg: `Frame [${moveObj.rfid}] from type [${
          _state[moveObj.fromIndex].type
        }] cannot be moved to other hive.`
      };
    if (!_isFrameMoveableByUser(moveObj))
      throw {
        error: 'FRAME_TYPE_CANNOT_BE_MOVED',
        msg: `Frame [${moveObj.rfid}] cannot be moved by a user.`
      };
    if (_isEmptySlot(moveObj))
      throw {
        error: 'TRYING_TO_MOVE_AN_EMPTY_FRAME',
        msg: `Trying to move an empty slot [Index: ${moveObj.fromIndex}].`
      };
    if (!_isMoveWithinTheSameHive(moveObj) && _isDestinationHiveFull(moveObj))
      throw {
        error: 'HIVE_IS_FULL',
        msg: `Trying to move a frame [${moveObj.rfid}] into a full hive [index: ${moveObj.toIndex}].`
      };
    if (moveObj.toIndex > _state.length)
      throw {
        error: 'OUT_OF_BHOME',
        msg: `Destination index [${moveObj.toIndex}] is out of Bhome scope`
      };
    if (_isDisabledSlot(_state[moveObj.toIndex]))
      throw {
        error: 'MOVING_TO_A_DISABLED_SLOT',
        msg: `Tried to move frame [${moveObj.rfid}] to a disabled slot [${moveObj.toIndex}]`
      };
    if (_isDisabledSlot(_state[moveObj.fromIndex]))
      throw {
        error: 'MOVING_A_DISABLED_FRAME',
        msg: `Tried to move a disabled frame [${moveObj.rfid}]`
      };
    if (!_getEmptySlotsIndices()) throw { error: 'NO_EMPTY_SLOTS', msg: 'No empty slots' };
    return true;
  };

  const _isDestinationHiveFull = (moveObj) => {
    const hiveSlots = _getHive(moveObj.toIndex);
    const partitionsAmount = hiveSlots.filter((item) => item.type === FRAME_TYPES.PARTITION).length;
    if (partitionsAmount < 2) {
      return false;
    }
    // check if there is empty (null) or comb_frames outside of populated area
    return hiveSlots.reduce((acc, item) => {
      if (!acc) {
        return acc;
      }
      if (_isEmptySlot(item)) {
        return false;
      }
      const currentAbsoluteIndex = _state.findIndex(
        (stateItem) => stateItem && item && stateItem.rfid === item.rfid
      );
      if (
        !_isWithinPartitions(currentAbsoluteIndex) &&
        item.type === FRAME_TYPES.COMB_FRAME &&
        !_isDisabledSlot(item)
      ) {
        acc = false;
      }

      return acc;
    }, true);
  };

  const getMoveToEmptySlotCost = (index, emptyIndex) =>
    matrixHelpers.getMoveToEmptySlotCost(state, HIVE_SIZE, index, emptyIndex);

  const _getTheClosestEmptySlotIndex = (index) => {
    let emptySlotsIndices = _getEmptySlotsIndices(); //sorted

    if (emptySlotsIndices.length === 1) {
      return emptySlotsIndices[0];
    }

    let emptySlotsWithDistances = [];

    for (let i = 0; i < emptySlotsIndices.length; i++) {
      const cost = getMoveToEmptySlotCost(index, emptySlotsIndices[i]);
      emptySlotsWithDistances.push({ slotIndex: emptySlotsIndices[i], cost });
    }

    return emptySlotsWithDistances.sort((a, b) => a.cost - b.cost)[0].slotIndex;
  };

  const _isWithinPartitions = (index) => {
    if (_state[index]) {
      if (_state[index].type === FRAME_TYPES.PARTITION) return true;
      if (_extractedPartitionObj.originalIndex === index) return true;
    }

    const hive = _getHive(index);
    let partitions = 0;
    const relativeIndex = _getRelativeIndex(index);
    const hiveAbsoluteStartIndex = _getHiveNumber(index) * HIVE_SIZE;
    const partitionsAmount = hive.filter((item) => item.type === FRAME_TYPES.PARTITION).length;
    for (let i = 0; i < hive.length; i++) {
      if (
        hive[i] &&
        hive[i].type === FRAME_TYPES.PARTITION &&
        hiveAbsoluteStartIndex + i !== _extractedPartitionObj.currentIndex
      )
        partitions++;
      else if (hiveAbsoluteStartIndex + i === _extractedPartitionObj.originalIndex) partitions++;
      if (
        (partitions === 1 ||
          (partitionsAmount > 2 && partitions >= 1 && partitions < partitionsAmount)) &&
        i === relativeIndex
      )
        return true;
      if (i === relativeIndex) return false;
    }
  };

  const _deleteMove = (index) => {
    for (let i in _moves) {
      if (_moves[i].index === index) {
        _moves.splice(i, 1);
        break;
      }
    }
  };

  const _signOffMove = (move) => {
    _extractedPartitionObj = {};
    _frameIndexToPlaceBack = null;

    delete _queuedMoves[move.rfid];

    _deleteMove(move.index);
    delete move.index;

    _placedFrames.push(move.rfid);
  };

  //there can be no more than one move per frame
  const _getFrameFutureMove = (move) => {
    for (let i in _moves) {
      if (
        _state[move.toIndex] &&
        !_queuedMoves[_moves[i].index] &&
        _state[move.toIndex].rfid === _moves[i].rfid
      ) {
        return moves[i];
      }
    }
    return false;
  };

  const _getRelativeIndex = (absoluteIndex) =>
    matrixHelpers.getRelativeIndex(absoluteIndex, HIVE_SIZE);

  const _getPartitionsIndices = (index) => {
    const hive = _getHive(index);
    const hiveAbsoluteStartIndex = _getHiveNumber(index) * HIVE_SIZE;
    let partition1Index,
      partition2Index = null;
    for (let i = 0; i < hive.length; i++) {
      if (
        hive[i] &&
        hive[i].type === FRAME_TYPES.PARTITION &&
        hiveAbsoluteStartIndex + i !== _extractedPartitionObj.currentIndex
      ) {
        if (!Number.isInteger(partition1Index)) {
          partition1Index = i;
        } else {
          partition2Index = i;
        }
      }
    }

    if (!partition2Index) partition2Index = _getRelativeIndex(_extractedPartitionObj.originalIndex);

    const hiveStartingPosition = _getHiveNumber(index) * HIVE_SIZE;
    return [hiveStartingPosition + partition1Index, hiveStartingPosition + partition2Index];
  };

  const _isPartitionExpandable = (partitionIndex) => {
    const hive = _getHive(partitionIndex);
    const relativePartitionIndex = _getRelativeIndex(partitionIndex);
    const expandDirection = _isLeftPartition(relativePartitionIndex, hive) ? -1 : 1;

    if (relativePartitionIndex + expandDirection === HIVE_SIZE || relativePartitionIndex === 0)
      return false; //partition is at the edge

    const expandToArea = hive.splice(
      expandDirection === 1 ? relativePartitionIndex : 0,
      expandDirection === 1 ? HIVE_SIZE - relativePartitionIndex : relativePartitionIndex
    );
    for (let i = 0; i < expandToArea.length; i++) {
      if (
        _isEmptySlot(expandToArea[i]) ||
        ![null, ...HIVE_LIMITED_FRAMES].includes(
          _isEmptySlot(expandToArea[i]) || _isDisabledSlot(expandToArea[i])
            ? null
            : expandToArea[i].type
        )
      )
        return true;
    }

    return false;
  };

  const _isPartitionSideExpandable = (
    partitionIndex,
    fromIndex,
    toIndex,
    isMoveInsidePopulatedAreaWithinSameHive
  ) => {
    const hive = _getHive(partitionIndex);
    const relativePartitionIndex = _getRelativeIndex(partitionIndex);
    const shiftDirection = _isLeftPartition(relativePartitionIndex, hive) ? -1 : 1;

    // check if we have slots in unpopulated area in possible shift direction
    // if partition is not on the left/right edge of hive
    if (
      relativePartitionIndex + shiftDirection >= 0 &&
      relativePartitionIndex + shiftDirection < hive.length
    ) {
      let isShiftingPossible = true;
      let isSomeFrameEmpty = false;
      let emptyFrameIndex = null;
      let isSomeFrameExpandable = false;
      let expandableFrameIndex = null;
      let isEmptyIndexCloserToPartition = false;

      // shifting is not possible in case fromIndex is in the shifting area (and closest partition is the one we'd like to shift)
      // while moving inside populated area of same hive
      // since it will shift dropped frame before it will be moved to its target location (toIndex)
      const closestPartitionIndex = _getClosestPartitionIndex(fromIndex);
      if (
        (fromIndex > toIndex && shiftDirection === 1 && isMoveInsidePopulatedAreaWithinSameHive) ||
        (fromIndex < toIndex && shiftDirection === -1 && isMoveInsidePopulatedAreaWithinSameHive) ||
        (fromIndex < toIndex &&
          partitionIndex >= toIndex &&
          shiftDirection === 1 &&
          isMoveInsidePopulatedAreaWithinSameHive &&
          closestPartitionIndex === partitionIndex) ||
        (fromIndex > toIndex &&
          partitionIndex <= toIndex &&
          shiftDirection === -1 &&
          isMoveInsidePopulatedAreaWithinSameHive &&
          closestPartitionIndex === partitionIndex)
      ) {
        return {
          isShiftingPossible: false
        };
      }

      for (
        let i = relativePartitionIndex + shiftDirection;
        shiftDirection === 1 ? i < hive.length : i >= 0;
        i += shiftDirection
      ) {
        // check if dragged frame is not in possible shifting area
        if (
          !_isEmptySlot(hive[i]) &&
          !isSomeFrameExpandable &&
          !isSomeFrameEmpty &&
          isShiftingPossible
        ) {
          const expandableIndex = _state.findIndex((item) => item && item.rfid === hive[i].rfid);
          if (fromIndex === expandableIndex) {
            isShiftingPossible = false;
            break;
          }
        }

        // check if we have empty slot in possible shifting area
        if (_isEmptySlot(hive[i]) && !isSomeFrameEmpty && isShiftingPossible) {
          isSomeFrameEmpty = true;
          emptyFrameIndex = _getHiveNumber(partitionIndex) * HIVE_SIZE + i;
          isEmptyIndexCloserToPartition =
            Math.abs(partitionIndex - fromIndex) > Math.abs(partitionIndex - emptyFrameIndex);
          break;
        }

        // get index of frame we will be able to move outside to proceed with shifting
        if (
          hive[i].type === FRAME_TYPES.COMB_FRAME &&
          !isSomeFrameExpandable &&
          isShiftingPossible
        ) {
          const expandableIndex = _state.findIndex((item) => item && item.rfid === hive[i].rfid);
          if (expandableIndex !== fromIndex) {
            isSomeFrameExpandable = true;
            expandableFrameIndex = expandableIndex;
          }
        }
      }

      return {
        shiftDirection,
        isSomeFrameExpandable,
        expandableFrameIndex,
        isSomeFrameEmpty,
        emptyFrameIndex,
        isShiftingPossible,
        isEmptyIndexCloserToPartition
      };
    }
  };

  const _isLeftPartition = (partitionIndex, hive) => {
    let isLeft = false;

    hive.some((frame, index) => {
      if (frame.type === FRAME_TYPES.PARTITION || index === partitionIndex) {
        isLeft = index === partitionIndex;
        return true;
      }
    });

    return isLeft;
  };

  const _getFramesToShift = (startIndex, sideToShift, shiftingDirection) => {
    //getting the frames that needs to be shifted
    const hive = _getHive(startIndex);
    const relativeStartIndex = _getRelativeIndex(startIndex);

    const sliceFrom = sideToShift === 1 ? relativeStartIndex : 0;
    const sliceTo = sideToShift === 1 ? HIVE_SIZE - 1 : relativeStartIndex;

    let framesToShift = hive.slice(sliceFrom, sliceTo + 1);

    //checking for empty slots within the frames to shift
    const emptySlotsIndices = _getEmptySlotsIndices(framesToShift);
    const emptySlotIndex = emptySlotsIndices
      ? shiftingDirection === 1
        ? emptySlotsIndices[0]
        : emptySlotsIndices[emptySlotsIndices.length - 1]
      : false;

    if (sideToShift === shiftingDirection) {
      //arranging a place for the shift
      if (Number.isInteger(emptySlotIndex)) {
        return sideToShift === 1
          ? framesToShift.slice(0, emptySlotIndex)
          : framesToShift.slice(emptySlotIndex + 1);
      } else {
        //no empty slot, making a place for the shift by extracting the nearest comb frame
        const _crossHivesMoveableFramesIndices = _getCrossHivesMoveableFramesIndices(framesToShift);
        const closedCrossHivesMoveableFrameIndex =
          shiftingDirection === 1
            ? _crossHivesMoveableFramesIndices[0]
            : _crossHivesMoveableFramesIndices[_crossHivesMoveableFramesIndices.length - 1];
        const absoluteClosedCrossHivesMoveableFrameIndex = _state.findIndex(
          (item) => item && item.rfid === framesToShift[closedCrossHivesMoveableFrameIndex].rfid
        );
        const closestEmptySlotIndex = _getTheClosestEmptySlotIndex(
          closedCrossHivesMoveableFrameIndex
        );
        // in case closed cross hives frame is our placing back frame - we should save its new position to place it back later
        if (absoluteClosedCrossHivesMoveableFrameIndex === _frameIndexToPlaceBack) {
          _frameIndexToPlaceBack = closestEmptySlotIndex;
        }
        _executeMove(absoluteClosedCrossHivesMoveableFrameIndex, closestEmptySlotIndex);

        return sideToShift === 1
          ? framesToShift.slice(0, closedCrossHivesMoveableFrameIndex)
          : framesToShift.slice(closedCrossHivesMoveableFrameIndex + 1);
      }
    }

    //gap closing, there is a place to shift
    return framesToShift;
  };

  const _shift = (startIndex, sideToShift, shiftingDirection, isGap = false) => {
    let framesToShift = _getFramesToShift(startIndex, sideToShift, shiftingDirection);

    if (!framesToShift.length) return;

    const absoluteIndexOfFirstFrameToShift = framesToShift[0].absIndex;

    let currentIterationIndex, currentAbsoluteIterationIndex;

    const lastPartitionToShift = framesToShift.filter(
      (item) => item.type === FRAME_TYPES.PARTITION
    );

    for (let i = 0; i < framesToShift.length; i++) {
      currentIterationIndex = shiftingDirection === 1 ? framesToShift.length - 1 - i : i;
      currentAbsoluteIterationIndex = absoluteIndexOfFirstFrameToShift + currentIterationIndex;

      if (currentAbsoluteIterationIndex === _frameIndexToPlaceBack) {
        _frameIndexToPlaceBack = currentAbsoluteIterationIndex + shiftingDirection;
      }

      _executeMove(
        currentAbsoluteIterationIndex,
        currentAbsoluteIterationIndex + shiftingDirection,
        false,
        'shift'
      );

      //if shifted a partition, the rest is not important
      if (
        shiftingDirection !== sideToShift &&
        _state[currentAbsoluteIterationIndex + shiftingDirection] &&
        _state[currentAbsoluteIterationIndex + shiftingDirection].type ===
          lastPartitionToShift[shiftingDirection > 0 ? lastPartitionToShift.length - 1 : 0].type
      ) {
        break;
      }
    }
  };

  const _getClosestPartitionIndex = (pivotIndex) => {
    let partition1Index, partition2Index;
    [partition1Index, partition2Index] = _getPartitionsIndices(pivotIndex);
    return Math.abs(pivotIndex - partition1Index) < Math.abs(pivotIndex - partition2Index)
      ? partition1Index
      : partition2Index;
  };

  const _getClosestUnpopulatedCombFrameInsideSameHive = (toIndex, fromIndex) =>
    _getHive(toIndex).reduce((acc, item) => {
      if (item.type === FRAME_TYPES.COMB_FRAME) {
        const currentAbsoluteIndex = _state.findIndex(
          (stateItem) => stateItem && item && stateItem.rfid === item.rfid
        );
        if (currentAbsoluteIndex !== fromIndex && !_isWithinPartitions(currentAbsoluteIndex)) {
          return !acc
            ? currentAbsoluteIndex
            : Math.abs(toIndex - acc) > Math.abs(toIndex - currentAbsoluteIndex)
              ? currentAbsoluteIndex
              : acc;
        }
      }
      return acc;
    }, null);

  //get the shifting side by the closest moveable partition ( return -1 for left, and 1 for right )
  const _getShiftingSide = (pivotIndex, isMoveInsidePopulatedAreaWithinSameHive, toIndex) => {
    const closerPartitionIndex = _getClosestPartitionIndex(pivotIndex);
    let partition1Index, partition2Index;
    [partition1Index, partition2Index] = _getPartitionsIndices(pivotIndex);
    const isExpandable = isMoveInsidePopulatedAreaWithinSameHive
      ? true
      : _isPartitionExpandable(closerPartitionIndex);
    const partitionToIndexShift = isExpandable
      ? closerPartitionIndex
      : closerPartitionIndex === partition1Index
        ? partition2Index
        : partition1Index;

    //if the partition to shift is the pivot, will check if its the right or the left partition
    if (partitionToIndexShift === pivotIndex) {
      const partitionToCompareWith =
        partitionToIndexShift === partition1Index ? partition2Index : partition1Index;
      return partitionToIndexShift > partitionToCompareWith ? 1 : -1;
    }

    let shiftingSide = partitionToIndexShift - pivotIndex > 0 ? 1 : -1;

    if (isMoveInsidePopulatedAreaWithinSameHive) {
      // in case move will appear inside populated area of same hive
      // AND fromIndex will be in left half + toIndex will be to the left of fromIndex
      // OR fromIndex will be in right half + toIndex will be to the right of fromIndex
      // OR fromIndex is in the middle of populated area and toIndex is on the right of fromIndex
      // we should switch shifting direction and prevent hive expanding
      if (
        (pivotIndex > (partition1Index + partition2Index) / 2 && pivotIndex - toIndex < 0) ||
        (pivotIndex < (partition1Index + partition2Index) / 2 && pivotIndex - toIndex > 0) ||
        (Math.abs(pivotIndex - partition1Index) === Math.abs(pivotIndex - partition2Index) &&
          pivotIndex - toIndex < 0)
      ) {
        shiftingSide = shiftingSide * -1;
      }
    }

    return shiftingSide;
  };

  const _closeGap = (
    gapIndex,
    isInsideSameHiveWithUnlimitedPartitions = false,
    shouldRevertShiftingIndexInUnlimitedPartitionsHive = 1
  ) => {
    const shiftingSide =
      _getShiftingSide(gapIndex, isInsideSameHiveWithUnlimitedPartitions) *
      shouldRevertShiftingIndexInUnlimitedPartitionsHive;
    const shiftingDirection = shiftingSide * -1;
    _shift(gapIndex + shiftingSide, shiftingSide, shiftingDirection);
  };

  const _placingBackProcedure = (
    fromIndex,
    toIndex,
    isMoveInsidePopulatedAreaWithinSameHive = false,
    originalFromIndex
  ) => {
    const shiftingSide = _getShiftingSide(
      isMoveInsidePopulatedAreaWithinSameHive ? originalFromIndex : toIndex,
      isMoveInsidePopulatedAreaWithinSameHive,
      toIndex
    );

    const shiftingDirection = shiftingSide; //they are not equal in case of gap (See _closeGap)

    _shift(toIndex + shiftingSide, shiftingSide, shiftingDirection);

    _executeMove(
      Number.isInteger(_frameIndexToPlaceBack) ? _frameIndexToPlaceBack : fromIndex,
      toIndex + shiftingSide
    );
  };

  const _executeMove = (from, to, isUserMove = false, type = 'move') => {
    _state[to] = _state[from];

    if (type === 'move' && _isMoveWithinTheSameHive({ fromIndex: from, toIndex: to })) {
      type = Math.abs(from - to) === 1 ? 'shift' : 'move';
    }

    _movesPerformed[_movesPerformed.length - 1].push({
      fromIndex: from,
      toIndex: to,
      type,
      rfid: _state[from].rfid,
      frameType: _state[from].type,
      isUserMove
    });
    //updating the new location of the frame, in case it is involved in a future move
    _state[from] = {
      ..._state[from],
      rfid: null
    };
    delete _state[from].type;
  };

  //executing moves recursively. making sure that a frame that involved in a move will be moved once
  const _launchMove = (move) => {
    if (_isLegalMove(move)) {
      //just for readability. _isLegalMove will throw an exception if move is illegal.

      //if the frame that occupying the destination slot is part of a future move: perform this move first, so the machine will not move the same frame twice
      const frameFutureMove = _getFrameFutureMove(move);
      if (frameFutureMove) {
        //queuing the current move and launch the future move
        _queuedMoves[move.index] = move;
        _launchMove(frameFutureMove);
      }

      // create new sub-array for current movement
      _movesPerformed.push([]);

      //performing a move

      //---SIMPLE MOVES (no shifts) START
      const emptySlotIndex = _getTheClosestEmptySlotIndex(move.toIndex);
      // move from unpopulated area to empty slot
      if (!_isWithinPartitions(move.fromIndex) && _isEmptySlot(_state[move.toIndex])) {
        _executeMove(move.fromIndex, move.toIndex, true);
      }
      // move from unpopulated to unpopulated
      else if (!_isWithinPartitions(move.toIndex) && !_isWithinPartitions(move.fromIndex)) {
        // check if toIndex is not queen/feeder OR empty slot is within same hive
        if (
          (_state[move.toIndex].type !== FRAME_TYPES.QUEEN_EXCLUDER &&
            _state[move.toIndex].type !== FRAME_TYPES.FEEDER) ||
          _getHiveNumber(move.toIndex) === _getHiveNumber(emptySlotIndex)
        ) {
          _executeMove(move.toIndex, emptySlotIndex);
          _executeMove(move.fromIndex, move.toIndex, true);
          _executeMove(emptySlotIndex, move.fromIndex);
        } else {
          const closestCombIndex = _getClosestUnpopulatedCombFrameInsideSameHive(
            move.toIndex,
            move.fromIndex
          );
          if (closestCombIndex) {
            _executeMove(closestCombIndex, emptySlotIndex);
            _executeMove(move.toIndex, closestCombIndex);
            _executeMove(move.fromIndex, move.toIndex, true);
            _executeMove(emptySlotIndex, move.fromIndex);
          }
        }
      }
      //---SIMPLE MOVES (no shifts) END

      //moving from OR to populated area
      else {
        let shouldPlaceBackQueenOrFeederFrame = false;
        let isPartitionMoveHappened = false;
        const partitionsInFromHive = _getHive(move.fromIndex).filter(
          (frame) => frame.type === FRAME_TYPES.PARTITION
        );
        const partitionsInToHive = _getHive(move.toIndex).filter(
          (frame) => frame.type === FRAME_TYPES.PARTITION
        );
        let continuePerformMove = true;

        if (
          _isMoveWithinTheSameHive(move) &&
          _state[move.fromIndex].type === FRAME_TYPES.PARTITION &&
          partitionsInFromHive.length > 2 &&
          (move.fromIndex === partitionsInFromHive[0].absIndex ||
            move.fromIndex === partitionsInFromHive.slice(-1)[0].absIndex)
        ) {
          if (
            (move.fromIndex === partitionsInFromHive[0].absIndex &&
              move.toIndex < partitionsInFromHive[1].absIndex) ||
            (move.fromIndex === partitionsInFromHive.slice(-1)[0].absIndex &&
              move.toIndex > partitionsInFromHive[partitionsInFromHive.length - 2].absIndex)
          ) {
            let placeBack =
              (_state[move.toIndex].type === FRAME_TYPES.FEEDER ||
                _state[move.toIndex].type === FRAME_TYPES.QUEEN_EXCLUDER) &&
              !_getEmptySlotsIndices(_getHive(move.fromIndex));
            _executeMove(move.toIndex, emptySlotIndex);
            _executeMove(move.fromIndex, move.toIndex, true);
            if (placeBack) {
              _placingBackProcedure(emptySlotIndex, move.toIndex, false, move.toIndex);
            }
            continuePerformMove = false;
          }
        }

        if (continuePerformMove) {
          const isExtractingFromAPartitionedArea = _isWithinPartitions(move.fromIndex);
          const isExtractingToAPartitionedArea =
            _getHive(move.toIndex).filter((item) => item.type === FRAME_TYPES.PARTITION).length >
              1 && _isWithinPartitions(move.toIndex);
          const isMoveToSameHive = _isMoveWithinTheSameHive(move);
          const isMoveInsidePopulatedAreaWithinSameHive =
            isExtractingToAPartitionedArea && isExtractingFromAPartitionedArea && isMoveToSameHive;

          //moving the occupying frame to the empty slot
          if (move.toIndex !== emptySlotIndex) {
            if (_state[move.toIndex].type === FRAME_TYPES.PARTITION) {
              // check if move happens not inside populated area and fromIndex/toIndex are not direct adjacent
              if (
                !isMoveInsidePopulatedAreaWithinSameHive &&
                !(move.fromIndex - move.toIndex === 1 || move.toIndex - move.fromIndex === 1)
              ) {
                const expandableInfo = _isPartitionSideExpandable(
                  move.toIndex,
                  move.fromIndex,
                  move.toIndex,
                  isMoveInsidePopulatedAreaWithinSameHive
                );
                // check if adjacent of target partition is empty slot and execute moves based on that
                if (
                  expandableInfo &&
                  expandableInfo.isSomeFrameEmpty &&
                  expandableInfo.isShiftingPossible &&
                  expandableInfo.isEmptyIndexCloserToPartition
                ) {
                  _shift(
                    move.toIndex,
                    expandableInfo.shiftDirection,
                    expandableInfo.shiftDirection
                  );
                  _executeMove(move.fromIndex, move.toIndex, true);
                  isPartitionMoveHappened = true;
                } else if (
                  expandableInfo &&
                  expandableInfo.isSomeFrameExpandable &&
                  (!expandableInfo.isSomeFrameEmpty ||
                    !expandableInfo.isEmptyIndexCloserToPartition) &&
                  expandableInfo.isShiftingPossible
                ) {
                  _executeMove(expandableInfo.expandableFrameIndex, emptySlotIndex);
                  _shift(
                    move.toIndex,
                    expandableInfo.shiftDirection,
                    expandableInfo.shiftDirection
                  );
                  _executeMove(move.fromIndex, move.toIndex, true);
                  isPartitionMoveHappened = true;
                } else {
                  _extractedPartitionObj.originalIndex = move.toIndex;
                  _extractedPartitionObj.currentIndex = emptySlotIndex;
                }
              } else {
                _extractedPartitionObj.originalIndex = move.toIndex;
                _extractedPartitionObj.currentIndex = emptySlotIndex;
              }
            }
            // check if toIndex is queen/feeder AND is not within partitions AND empty slot is not within same hive
            if (
              (_state[move.toIndex].type === FRAME_TYPES.QUEEN_EXCLUDER ||
                _state[move.toIndex].type === FRAME_TYPES.FEEDER) &&
              !_isWithinPartitions(move.toIndex) &&
              _getHiveNumber(move.toIndex) !== _getHiveNumber(emptySlotIndex)
            ) {
              const closestCombIndex = _getClosestUnpopulatedCombFrameInsideSameHive(
                move.toIndex,
                move.fromIndex
              );
              // move closest unpopulated comb_frame instead if exists
              if (closestCombIndex) {
                _executeMove(closestCombIndex, emptySlotIndex);
                _executeMove(move.toIndex, closestCombIndex);
              } else {
                // move feeder/queen_excluder out and make sure it will be placed back after
                shouldPlaceBackQueenOrFeederFrame = true;
                _executeMove(move.toIndex, emptySlotIndex);
              }
            } else if (!isPartitionMoveHappened) {
              const closestPartition = _getClosestPartitionIndex(move.toIndex);
              const expandableInfo = _isPartitionSideExpandable(
                closestPartition,
                move.fromIndex,
                move.toIndex,
                isMoveInsidePopulatedAreaWithinSameHive
              );
              if (
                _isWithinPartitions(move.toIndex) &&
                expandableInfo &&
                expandableInfo.isSomeFrameEmpty &&
                expandableInfo.isShiftingPossible &&
                expandableInfo.isEmptyIndexCloserToPartition &&
                !(move.fromIndex - move.toIndex === 1 || move.toIndex - move.fromIndex === 1)
              ) {
                _shift(move.toIndex, expandableInfo.shiftDirection, expandableInfo.shiftDirection);
                _executeMove(move.fromIndex, move.toIndex, true);
                isPartitionMoveHappened = true;
              } else if (
                _isWithinPartitions(move.toIndex) &&
                expandableInfo &&
                expandableInfo.isSomeFrameExpandable &&
                expandableInfo.isShiftingPossible &&
                (!expandableInfo.isSomeFrameEmpty ||
                  !expandableInfo.isEmptyIndexCloserToPartition) &&
                !(move.fromIndex - move.toIndex === 1 || move.toIndex - move.fromIndex === 1)
              ) {
                _executeMove(expandableInfo.expandableFrameIndex, emptySlotIndex);
                _shift(move.toIndex, expandableInfo.shiftDirection, expandableInfo.shiftDirection);
                _executeMove(move.fromIndex, move.toIndex, true);
                isPartitionMoveHappened = true;
              } else {
                _executeMove(move.toIndex, emptySlotIndex);
              }
            }
            // keep frame index that we should use in case of placing back procedure
            _frameIndexToPlaceBack = emptySlotIndex;
          }
          //moving the frame to its destination in case it wasn't partition sibling move
          if (!isPartitionMoveHappened) {
            _executeMove(move.fromIndex, move.toIndex, true);
          }

          // if sibling move inside populated area of same hive just place toIndex frame to fromIndex position
          if (
            (move.fromIndex - move.toIndex === 1 || move.toIndex - move.fromIndex === 1) &&
            isMoveInsidePopulatedAreaWithinSameHive
          ) {
            _executeMove(emptySlotIndex, move.fromIndex);
          } else {
            // both closeGap and placingBack will be performed only in case of populated -> populated movements
            // in such case closeGap should be first because in other case placingBack will shift unpopulated frame to empty slot
            // (which is a gap in previous populated area)

            // if the frame moved from a partitioned area and not inside populated area of same hive, will close the gap
            const partitions = _getHive(move.fromIndex).filter(
              (frame) => frame.type === FRAME_TYPES.PARTITION
            );
            const isSameUnlimitedPartitionsHiveMove =
              partitions.length > 2 && _isMoveWithinTheSameHive(move);
            let shouldRevertShiftingIndexInUnlimitedPartitionsHive = 1;
            if (isSameUnlimitedPartitionsHiveMove) {
              if (
                Math.abs(move.fromIndex - partitionsInFromHive[0].absIndex) >
                  Math.abs(move.fromIndex - partitionsInFromHive.slice(-1)[0].absIndex) &&
                Math.abs(move.toIndex - partitionsInFromHive[0].absIndex) >
                  Math.abs(move.toIndex - partitionsInFromHive.slice(-1)[0].absIndex)
              ) {
                shouldRevertShiftingIndexInUnlimitedPartitionsHive = -1;
              }
              if (
                Math.abs(move.fromIndex - partitionsInFromHive[0].absIndex) <
                  Math.abs(move.fromIndex - partitionsInFromHive.slice(-1)[0].absIndex) &&
                Math.abs(move.toIndex - partitionsInFromHive[0].absIndex) <
                  Math.abs(move.toIndex - partitionsInFromHive.slice(-1)[0].absIndex)
              ) {
                shouldRevertShiftingIndexInUnlimitedPartitionsHive = -1;
              }
            }
            if (
              isExtractingFromAPartitionedArea &&
              (!isMoveInsidePopulatedAreaWithinSameHive || isPartitionMoveHappened) &&
              partitions.length > 1 &&
              partitions[0].absIndex < move.fromIndex &&
              partitions.slice(-1)[0].absIndex > move.fromIndex
            ) {
              _closeGap(
                move.fromIndex,
                isSameUnlimitedPartitionsHiveMove,
                shouldRevertShiftingIndexInUnlimitedPartitionsHive
              );
            }
            // if the frame moved to a partitioned area, will bring the frame that occupied that slot from the empty slot, back to the hive
            if (
              (isExtractingToAPartitionedArea || shouldPlaceBackQueenOrFeederFrame) &&
              !isPartitionMoveHappened
            ) {
              _placingBackProcedure(
                emptySlotIndex,
                move.toIndex,
                isMoveInsidePopulatedAreaWithinSameHive,
                move.fromIndex
              );
            }
          }
        }
      }

      _signOffMove(move);
    }
  };

  const _attachIndicesToMoves = (moves) => {
    for (let i in moves) {
      moves[i].index = i;
    }
    return moves;
  };

  const process = () => {
    try {
      let nextMoveToPerform;

      _moves = _attachIndicesToMoves(_moves); //helper. will be detached at the end of the process

      //will execute moves by empty slot area, to save machine travel
      while (_moves.length) {
        // nextMoveToPerform = _getIndexOfTheClosestMoveToAnEmptySlot();
        // _launchMove( nextMoveToPerform );
        _launchMove(_moves[0]);
      }
    } catch (errorObj) {
      return errorObj;
    }

    return {
      moves: _movesPerformed,
      layout: _state
    };
  };

  return process();
};

export default moveFramesCalculator;
