import {
    ON_GET_AVAILABLE_TESTS,
    SET_CURRENT_BHOME,
    ON_GET_TEST_STATUS,
    ON_UPDATE_JENKINS_TEST_RESULTS,
} from 'actionTypes';
import {
    FETCH_GET_MANUFACTURING_TESTS,
    FETCH_GET_TESTS_S3_DATA,
    FETCH_RUN_JENKINS_BUILD,
    REMOVE_TEST_S3_DATA,
} from '../actionTypes';

const initialState = {
    scripts: null,
    logInfo: null,
    log: '',
    manufacturingTests: [],
    testData: {},
};

const scriptExecutor = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GET_MANUFACTURING_TESTS.pending: {
            return {
                ...state,
                manufacturingTests: [],
            };
        }
        case FETCH_GET_MANUFACTURING_TESTS.success: {
            return {
                ...state,
                manufacturingTests: action.data.tests,
            };
        }
        case FETCH_GET_TESTS_S3_DATA.success: {
            return {
                ...state,
                testData: action.data.test,
            };
        }
        case FETCH_RUN_JENKINS_BUILD.success: {
            return {
                ...state,
                manufacturingTests: [action.data.build, ...state.manufacturingTests],
            };
        }
        case ON_UPDATE_JENKINS_TEST_RESULTS: {
            return {
                ...state,
                manufacturingTests: state.manufacturingTests.map(test => {
                    if (test.id === action?.payload?.updatedTest?.id) {
                        return action.payload.updatedTest;
                    }
                    return test;
                }),
            };
        }
        case REMOVE_TEST_S3_DATA: {
            return {
                ...state,
                testData: initialState.testData,
            };
        }
        case SET_CURRENT_BHOME:
            return {
                ...state,
                ...initialState,
            };
        case ON_GET_AVAILABLE_TESTS: {
            return {
                ...state,
                scripts: action.payload,
            };
        }
        case ON_GET_TEST_STATUS: {
            // eslint-disable-next-line camelcase
            const { test_output = '', ...rest } = action.payload;
            return {
                ...state,
                logInfo: rest,
                log: state.log ? `${state.log}\n${JSON.stringify(test_output)}` : JSON.stringify(test_output),
            };
        }
        default:
            return state;
    }
};

export default scriptExecutor;
