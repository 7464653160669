import { FETCH_FRAMES_LOST_QRID, FETCH_RFID_SCAN_HISTORY } from '../actionTypes';

export const fetchRfidScanHistory = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_RFID_SCAN_HISTORY.default,
        url: `/bhomes/${bhomeId}/get-rfid-scan-history`,
    },
});
export const fetchFramesLostQrid = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_FRAMES_LOST_QRID.default,
        url: `/bhomes/${bhomeId}/get-bhome-frame-lost-qrid`,
    },
});
