import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import TextField from '@beewise/text-field';
import { getCurrentBhome } from 'selectors';
import { get } from 'lodash-es';
import constants from 'appConstants';
import { loading } from '@beewise/react-utils';
import analytics from 'utils/analytics';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import { fetchBhomeExhibitionDance } from './actions';
import TechButton from '../../reusables/TechButton';
import BeehomeLogs from './components/BeehomeLogs';
import MovementCalibration from './components/MovementCalibration';
import { fetchFrames } from '../ActionsAndMonitoring/actions';
import { fetchBhomeSettings } from '../Config/actions/config.actions';
import { FETCH_BHOME_EXHIBITION_DANCE, FETCH_READ_RFID, FETCH_FILL_FEEDERS } from './actionTypes';
import { FETCH_SEND_TECHNICIAN_ACTION } from '../ActionsAndMonitoring/actionTypes';
import EntranceActionsCenter from './components/EntranceActionsCenter';
import TestActions from '../ActionsAndMonitoring/components/TestActions';

const SpecialFeatures = () => {
    const [amount, setAmount] = useState(3);
    const dispatch = useDispatch();
    const bhome = useSelector(getCurrentBhome, shallowEqual);

    useEffect(() => {
        if (bhome?.id) {
            dispatch(fetchBhomeSettings(bhome.id));
            dispatch(fetchFrames(bhome.id));
        }
    }, [bhome?.id, dispatch]);

    const areAllButtonsDisabled = useMemo(() => get(bhome, 'status', '') === constants.BHOME_STATUSES.ERROR, [bhome]);

    const handleStartExhibitionClick = useCallback(() => {
        if (amount > 0) {
            analytics.fireGeneralRobotEvent({
                action: 'bhomeExhibitionDance',
                label: `Beehome ${bhome.id}`,
            });
            dispatch(fetchBhomeExhibitionDance(bhome.id, amount));
        }
    }, [amount, bhome.id, dispatch]);

    const handleTechnicianActionClick = useCallback(
        (type, params = {}) =>
            () => {
                analytics.fireGeneralRobotEvent({
                    action: type,
                    label: `Beehome ${bhome.id}`,
                });

                dispatch(fetchSendTechnicianAction(bhome.id, { type, params }));
            },
        [dispatch, bhome]
    );

    return (
        <div className="special-features special-features-v4">
            <div className="special-features-item">
                <TextField label="Number of repeats" type="number" value={amount} onChange={setAmount} size="small" />
                <TechButton enabledInReceptiveAndMaintenance onClick={handleStartExhibitionClick}>
                    Start Exhibition Dance
                </TechButton>
            </div>
            <div className="special-features-content">
                <BeehomeLogs bhome={bhome} areAllButtonsDisabled={areAllButtonsDisabled} />
                <MovementCalibration handleTechnicianActionClick={handleTechnicianActionClick} />
                <EntranceActionsCenter bhome={bhome} />
                <TestActions bhome={bhome} />
            </div>
        </div>
    );
};

export default loading([
    FETCH_SEND_TECHNICIAN_ACTION.default,
    FETCH_READ_RFID.default,
    FETCH_FILL_FEEDERS.default,
    FETCH_BHOME_EXHIBITION_DANCE.default,
])(SpecialFeatures);
