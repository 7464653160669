import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { shallowEqual, useSelector } from 'react-redux';
import { inRange, noop } from 'lodash-es';
import BhomeLayout from '../BhomeLayout';
import { getMarkedHives } from '../../../selectors';

const SlotsMultipickerV4 = ({ type, onlyInsideMarkedHive = false, onChange = noop }) => {
    const [pickedSlots, setPickedSlots] = useState([]);
    const markedHives = useSelector(getMarkedHives, shallowEqual);

    const handleSlotChange = useCallback(
        newSlots => {
            setPickedSlots(newSlots);
            onChange(newSlots);
        },
        [onChange]
    );

    const onFrameClick = useCallback(
        frame => {
            const isPickedFrame = pickedSlots.some(item => item.frameId === frame.frameId);
            if (
                (!type || frame.type === type) &&
                !isPickedFrame &&
                (!onlyInsideMarkedHive ||
                    markedHives.some(
                        hive =>
                            frame?.place?.station === hive.station &&
                            inRange(frame?.place?.position?.x, hive.from, hive.to)
                    ))
            ) {
                handleSlotChange([...pickedSlots, frame]);
            } else if (isPickedFrame) {
                handleSlotChange(pickedSlots.filter(item => item.frameId !== frame.frameId));
            }
        },
        [handleSlotChange, markedHives, onlyInsideMarkedHive, pickedSlots, type]
    );

    const handleSlotRemove = useCallback(
        slot => () => {
            handleSlotChange(pickedSlots.filter(item => item.frameId !== slot.frameId));
        },
        [handleSlotChange, pickedSlots]
    );

    return (
        <div className="slots-multipicker-v4">
            <BhomeLayout onFrameClick={onFrameClick} closeOnClick={false} pickedFrames={pickedSlots} />
            <div className="slots-multipicker-v4-list">
                {pickedSlots.map(slot => (
                    <div className="slots-multipicker-v4-list-item" key={slot.frameId}>
                        <span>
                            Station: {slot?.place?.station?.toUpperCase()} (x: {slot?.place?.position?.x})
                        </span>
                        <Button onClick={handleSlotRemove(slot)}>-</Button>
                    </div>
                ))}
            </div>
        </div>
    );
};

SlotsMultipickerV4.propTypes = {
    type: PropTypes.string,
    onlyInsideMarkedHive: PropTypes.bool,
    onChange: PropTypes.func,
};

export default SlotsMultipickerV4;
