import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Grid from 'components/reusables/Grid';
import Modal from '@beewise/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-light-svg-icons';
import { isEmpty } from 'lodash-es';
import { fetchGetManufacturingTests, removeTestS3Data } from '../../actions';
import { jenkinsColumnDefs } from '../../utils';
import ResultTabs from './ResultTabs';

const JenkinsResults = ({ bhome }) => {
    const dispatch = useDispatch();
    const tests = useSelector(state => state.scriptExecutor.manufacturingTests, arrayOfObjectsShallowEqual);
    const testS3Data = useSelector(state => state.scriptExecutor.testData, shallowEqual);

    const handleFetchTests = useCallback(() => {
        if (bhome?.id) {
            dispatch(fetchGetManufacturingTests(bhome?.id));
        }
    }, [bhome?.id, dispatch]);

    useEffect(() => {
        handleFetchTests();
    }, [handleFetchTests]);

    const getRowId = useCallback(({ data }) => `${data.id}-${data.build_status}`, []);

    const handleModalClose = useCallback(() => {
        dispatch(removeTestS3Data());
    }, [dispatch]);

    const getRowClass = useCallback(params => {
        switch (params?.node?.data?.build_status?.toLowerCase()) {
            case 'failure':
                return 'row-failure';
            case 'success':
                return 'row-success';
            default:
                return '';
        }
    }, []);

    return (
        <div className="jenkins-results">
            <FontAwesomeIcon className="icon-sync" icon={faSync} onClick={handleFetchTests} />
            <Grid
                getRowClass={getRowClass}
                rowData={tests}
                getRowId={getRowId}
                columnDefs={jenkinsColumnDefs}
                hideDownload
            />
            <Modal
                className="jenkins-s3-modal"
                header="Test Log"
                isOpen={!isEmpty(testS3Data)}
                onClose={handleModalClose}
            >
                <ResultTabs testS3Data={testS3Data} />
            </Modal>
        </div>
    );
};

JenkinsResults.propTypes = {
    bhome: PropTypes.shape(),
};

export default JenkinsResults;
