import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import Grid from 'components/reusables/Grid';
import { loading } from '@beewise/react-utils';
import { faSync } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchAllBhomesErrors } from './actions';
import { getFilteredErrors } from './selectors';
import { FETCH_ALL_BHOMES_ERRORS, FETCH_UPDATE_BHOME_ERROR } from './actionTypes';

const Errors = () => {
    const [dateRange, setDateRange] = useState([
        dayjs(new Date()).startOf('day').valueOf(),
        dayjs(new Date()).endOf('day').valueOf(),
    ]);
    const [startDate, endDate] = dateRange;

    const dispatch = useDispatch();
    const errors = useSelector(getFilteredErrors);

    const handleDateRangeChange = useCallback(range => {
        setDateRange([dayjs(range[0]).valueOf(), !dayjs(range[1]) ? null : dayjs(range[1]).endOf('day').valueOf()]);
    }, []);

    const handleFetchErrorsData = useCallback(() => {
        if (startDate && endDate) {
            dispatch(fetchAllBhomesErrors(startDate / 1000, endDate / 1000));
        }
    }, [dispatch, endDate, startDate]);

    useEffect(() => {
        handleFetchErrorsData();
    }, [handleFetchErrorsData]);

    const columnDefs = useMemo(
        () => [
            {
                headerName: 'ID',
                field: 'message_id',
                maxWidth: 200,
            },
            {
                headerName: 'Error',
                field: 'error',
                wrapText: true,
                autoHeight: true,
            },
            {
                headerName: 'Alert ID',
                field: 'alert_id',
                wrapText: true,
                autoHeight: true,
            },
            {
                headerName: 'Alert Name',
                field: 'alert_name',
                wrapText: true,
                autoHeight: true,
            },
            {
                headerName: 'Alert Type',
                field: 'alert_type',
                wrapText: true,
                autoHeight: true,
            },
            {
                headerName: 'Created At',
                field: 'created_at',
                valueFormatter: params => new Date(params.data.created_at).toLocaleString(),
                comparator: (valueA, valueB) => dayjs(valueA).unix() - dayjs(valueB).unix(),
                maxWidth: 200,
            },
            {
                headerName: 'BH ID',
                field: 'bhome_id',
                maxWidth: 110,
            },
            {
                headerName: 'Bee Home State',
                field: 'bhome_status',
                wrapText: true,
                autoHeight: true,
            },
            {
                headerName: 'Combined Posencoders',
                field: 'combinedposencoders',
                wrapText: true,
                autoHeight: true,
            },
        ],
        []
    );

    const getRowId = useCallback(({ data }) => `${data.id}`, []);

    return (
        <div className="errors">
            <FontAwesomeIcon className="icon-sync" icon={faSync} onClick={handleFetchErrorsData} />
            <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateRangeChange}
                withPortal
                maxDate={new Date()}
            />
            <Grid rowData={errors} getRowId={getRowId} columnDefs={columnDefs} filePrefix="errors-" />
        </div>
    );
};

export default loading([FETCH_ALL_BHOMES_ERRORS.default, FETCH_UPDATE_BHOME_ERROR.default])(Errors);
