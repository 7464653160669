import constants from '@beewise/constants';
import { getContractHiveActionWithLayout } from './contractHive';
import { getExpandHiveActionWithLayout } from './expandHive';
import { getShiftHiveActionWithLayout } from './shiftHive';
import { isFramesDataValid, isExpandPlanner, isContractPlanner, isShiftPlanner } from './utils';

/**
 * Handles the movement of frames based on provided data.
 * @param {Object} param0 - Object containing movedFrames, stations, and settings.
 * @param {Array} param0.selectedFrames - Array of frames that been selected as hive.
 * @param {Array} param0.movedFrames - Array of frames that have been moved within param0.selectedFrames.
 * @param {Object} param0.stations - Stations involved in the hive operation.
 * @param {Object} param0.settings - Settings for the hive operation.
 * @returns {Object} An object representing the hive action sequences and new frames layout, or throws an error if data is invalid.
 */
const moveFramesCalculatorV4 = ({ movedFrames, selectedFrames, stations, settings }) => {
  if (!isFramesDataValid({ movedFrames, selectedFrames })) {
    throw new Error('Provided frames data is not valid');
  }

  const preparedMovedFrames = structuredClone(movedFrames);

  const currentPartitionIndex = preparedMovedFrames.findIndex(
    ({ type }) => type === constants.FRAME_TYPES.PARTITION
  );
  const initialPartitionIndex = preparedMovedFrames[currentPartitionIndex].initialFrameIndex;

  const isFirstMove = movedFrames.filter((frame) => frame.isMoved).length === 1;

  const aggregatedActions = [];
  let framesLayout = [];

  if (
    isExpandPlanner({
      currentPartitionIndex,
      initialPartitionIndex,
      movedFrames: preparedMovedFrames
    })
  ) {
    const { actions, layout } = getExpandHiveActionWithLayout({
      movedFrames,
      settings,
      stations,
      currentPartitionIndex,
      initialPartitionIndex,
      isFirstMove
    });

    aggregatedActions.push(...actions);
    framesLayout = layout;
  } else if (
    isContractPlanner({
      currentPartitionIndex,
      movedFrames: preparedMovedFrames,
      initialPartitionIndex
    })
  ) {
    const { actions, layout } = getContractHiveActionWithLayout({
      movedFrames,
      settings,
      stations,
      currentPartitionIndex,
      isFirstMove
    });

    aggregatedActions.push(...actions);
    framesLayout = layout;
  } else if (
    isShiftPlanner({
      currentPartitionIndex,
      movedFrames: preparedMovedFrames,
      initialPartitionIndex
    })
  ) {
    const { actions, layout } = getShiftHiveActionWithLayout({
      movedFrames,
      settings,
      stations,
      currentPartitionIndex,
      isFirstMove
    });

    aggregatedActions.push(...actions);
    framesLayout = layout;
  }

  const newFrames = framesLayout.map(({ finalPositionX, initialFrameIndex, ...item }) => ({
    ...item
  }));

  return {
    actions: aggregatedActions,
    frames: newFrames
  };
};

export default moveFramesCalculatorV4;
