import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const PreloaderCompact = forwardRef(({ color = '#FEBA12', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    textRendering="geometricPrecision"
    shapeRendering="geometricPrecision"
    {...rest}
    className="preloader-compact"
  >
    <g id="eyxht8rseqm2">
      <path
        id="eyxht8rseqm3"
        d="M16.307000,4.855200L20.498700,12.115400L16.307000,19.375600L7.923720,19.375600L3.732050,12.115400L7.923720,4.855200L16.307000,4.855200Z"
        strokeWidth="3"
      />
      <path
        id="eyxht8rseqm4"
        d="M15.793700,12.115400L13.954500,15.300900L10.276200,15.300900L8.437060,12.115400L10.276200,8.929870L13.954500,8.929870L15.793700,12.115400Z"
        fill="#FEBA12"
        strokeWidth="1"
      />
    </g>
  </svg>
));

PreloaderCompact.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

PreloaderCompact.displayName = 'PreloaderCompact';

export default PreloaderCompact;
