import React, { forwardRef } from 'react';

const Battery = forwardRef((props, ref) => (
  <svg
    ref={ref}
    width="12"
    height="18"
    viewBox="0 0 12 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M2.25 2.7L4.5 2.7L4.5 1.8L6.75 1.8L6.75 2.7L9 2.7L9 16.2L2.25 16.2L2.25 2.7ZM-6.88454e-07 2.25L-5.90103e-08 16.65C-2.64194e-08 17.3956 0.755508 18 1.6875 18L9.5625 18C10.4945 18 11.25 17.3956 11.25 16.65L11.25 2.25C11.25 1.50441 10.4945 0.899999 9.5625 0.899999L9 0.899999L9 0.674999C9 0.302202 8.62225 -3.76891e-07 8.15625 -3.56521e-07L3.09375 -1.35232e-07C2.62775 -1.14863e-07 2.25 0.302202 2.25 0.674999L2.25 0.9L1.6875 0.9C0.755507 0.9 -7.21045e-07 1.50441 -6.88454e-07 2.25Z"
        fill="#828282"
      />
      <path d="M3.375 3.75L3.375 15.375L7.875 15.375L7.875 3.75L3.375 3.75Z" fill="#65BD60" />
    </g>
  </svg>
));

Battery.displayName = 'Battery';

export default Battery;
