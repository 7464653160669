/**
 * compose decoration function
 * @param {Function} Component - Component to decorate
 * @param {Function} decorators - comm-separated decorators
 * @returns {Class} - decorated Component
 */
const compose = (Component, ...decorators) =>
  // Reduce to a single Class
  decorators.reduce((prev, decorator) => decorator(prev), Component);

export default compose;
