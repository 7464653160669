import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SelectField } from '@beewise/select-field';
import Checkbox from '@beewise/checkbox-field';
import Card from 'components/reusables/Card';
import analytics from 'utils/analytics';
import TechButton from 'components/reusables/TechButton';
import constants from 'appConstants';
import { useToggle } from '@beewise/react-utils';
import { isNumber } from 'lodash-es';
import { HIVES } from 'utils';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import { entranceActionsOptions, entrancesOptions } from '../../utils';
import { fetchChangeEntrances, fetchCheckEntrances } from '../../../ActionsAndMonitoring/actions';

const EntranceActionsCenter = ({ bhome }) => {
    const dispatch = useDispatch();
    const [action, setAction] = useState(null);
    const [pickedHive, setPickedHive] = useState(null);
    const [pickedEntrances, setPickedEntrances] = useState([]);
    const [pickedEntrance, setPickedEntrance] = useState(null);
    const [all, toggleAll] = useToggle();

    useEffect(() => {
        setAction(null);
        setPickedHive(null);
        setPickedEntrances([]);
        setPickedEntrance(null);
    }, [bhome.id]);

    const handleActionsChange = useCallback(
        value => {
            setAction(value);
            setPickedHive(null);
            setPickedEntrances([]);
            setPickedEntrance(null);
        },
        [setAction]
    );

    const handleEntranceChange = useCallback(
        value => {
            if (action === constants.COMMANDS.BE_ALIGN) {
                setPickedEntrance(value);
            }
            setPickedEntrances(value);
        },
        [action]
    );

    const cleanUp = useCallback(
        () => () => {
            setAction(null);
            setPickedHive(null);
            setPickedEntrances([]);
            setPickedEntrance(null);
        },
        []
    );

    const handleSendClick = useCallback(() => {
        let actionToSend = null;
        let state;
        if (action === 'close' || action === 'open') {
            actionToSend = constants.COMMANDS.BE_CHANGE_STATE;
            state = action;
        } else {
            actionToSend = action;
        }
        analytics.fireFrameActionEvent({
            action,
            label: `Beehome ${bhome.id}`,
        });
        let entrances = [];

        if (all && actionToSend !== constants.COMMANDS.BE_ALIGN) {
            const amount = HIVES.length * constants.ENTRANCES_PER_HIVE;
            entrances = new Array(amount).fill(undefined).map((item, index) => ({
                hive_id: Math.floor(index / constants.ENTRANCES_PER_HIVE) + 1,
                be_id: (index % constants.ENTRANCES_PER_HIVE) + 1,
            }));
        }

        if (isNumber(pickedHive) && !all) {
            if (!pickedEntrances?.length && !isNumber(pickedEntrance)) {
                entrances = new Array(constants.ENTRANCES_PER_HIVE).fill(undefined).map((item, index) => ({
                    hive_id: pickedHive + 1,
                    be_id: (index % constants.ENTRANCES_PER_HIVE) + 1,
                }));
            } else {
                if (actionToSend === constants.COMMANDS.BE_ALIGN) {
                    entrances = [
                        {
                            hive_id: pickedHive + 1,
                            be_id: pickedEntrance + 1,
                        },
                    ];
                } else {
                    entrances = pickedEntrances.map(entr => ({
                        hive_id: pickedHive + 1,
                        be_id: entr + 1,
                    }));
                }
            }
        }

        if (state) {
            entrances = entrances.map(item => ({
                ...item,
                be_state: state,
            }));
        }

        if (actionToSend === constants.COMMANDS.BE_CHANGE_STATE) {
            dispatch(fetchChangeEntrances(bhome.id, entrances, cleanUp));
        } else if (actionToSend === constants.COMMANDS.BE_ALIGN) {
            dispatch(
                fetchSendTechnicianAction(bhome.id, { type: actionToSend, params: { hive_be_ids: entrances } }, cleanUp)
            );
        } else {
            dispatch(fetchCheckEntrances(bhome.id, entrances, cleanUp));
        }
    }, [action, all, bhome.id, cleanUp, dispatch, pickedEntrance, pickedEntrances, pickedHive]);

    return (
        <div className="entrances-actions-center">
            <Card className="entrance-actions" title="Entrances Actions">
                <SelectField
                    value={action}
                    options={entranceActionsOptions}
                    onChange={handleActionsChange}
                    placeholder="Select action"
                    className="nowrap"
                    size="small"
                />
                <SelectField
                    value={pickedHive}
                    options={HIVES}
                    onChange={setPickedHive}
                    disabled={all}
                    placeholder="Choose hive"
                    size="small"
                />
                <SelectField
                    value={action === constants.COMMANDS.BE_ALIGN ? pickedEntrance : pickedEntrances}
                    options={entrancesOptions}
                    onChange={handleEntranceChange}
                    disabled={all}
                    isMulti={action !== constants.COMMANDS.BE_ALIGN}
                    placeholder="Choose entrance"
                    size="small"
                />
                <Checkbox
                    label="All"
                    checked={all}
                    onChange={toggleAll}
                    disabled={action === constants.COMMANDS.BE_ALIGN}
                />
                <TechButton
                    disabled={!action || (action === constants.COMMANDS.BE_ALIGN && !isNumber(pickedEntrance))}
                    className="btn-primary"
                    onClick={handleSendClick}
                    enabledInReceptiveAndMaintenance
                >
                    Send
                </TechButton>
            </Card>
        </div>
    );
};

EntranceActionsCenter.propTypes = {
    bhome: PropTypes.shape(),
};

export default EntranceActionsCenter;
