import { FETCH_FRAMES_LOST_QRID, FETCH_RFID_SCAN_HISTORY } from '../actionTypes';

const initialState = {
    history: null,
    frames: [],
};

const powerManagement = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RFID_SCAN_HISTORY.success:
            return {
                ...state,
                history: action.data.history,
            };
        case FETCH_FRAMES_LOST_QRID.success:
            return {
                ...state,
                frames: action.data.frames,
            };
        default:
            return state;
    }
};

export default powerManagement;
