import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Pesticides = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <ellipse cx="12" cy="12" rx="6" ry="8" />
    <line x1="19" y1="12" x2="23" y2="12" />
    <line x1="17" y1="6.82843" x2="19.8284" y2="4" />
    <line x1="4" y1="19.8284" x2="6.82843" y2="17" />
    <line x1="4.41421" y1="4" x2="7.24264" y2="6.82843" />
    <line x1="17.4142" y1="17" x2="20.2426" y2="19.8284" />
    <line x1="1" y1="12" x2="5" y2="12" stroke="#0075FF" />
    <line x1="7" y1="10" x2="17" y2="10" stroke="#0075FF" />
  </svg>
));

Pesticides.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Pesticides.displayName = 'Pesticides';

export default Pesticides;
