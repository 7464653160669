import bhome24 from './bhome24';

export const COSTS = {
  SIDE_MOVE: 1,
  SIDE_SWITCH: 1,
  LEVEL_CROSSING: 1
};

const matrix = {
  24: bhome24
};

const getHiveNumber = (index, hiveSize) => Math.floor(index / hiveSize);

const getSideAndFloor = (slotIndex, matrix, hiveSize) => {
  const hiveIndex = getHiveNumber(slotIndex, hiveSize);

  let side = null;
  let floor = null;
  let floorIndex = null;

  matrix.left.forEach((matrixFloor, index) => {
    if (side === 'left') {
      return;
    }

    const indexInFloor = matrixFloor.findIndex((hive) => hive === hiveIndex);

    if (indexInFloor >= 0) {
      floor = index;
      floorIndex = indexInFloor;
      side = 'left';
    } else {
      side = 'right';
    }
  });

  if (floor === null) {
    matrix.right.forEach((matrixFloor, index) => {
      if (floor !== null) {
        return;
      }

      const indexInFloor = matrixFloor.findIndex((hive) => hive === hiveIndex);
      if (indexInFloor >= 0) {
        floor = index;
        floorIndex = indexInFloor;
      }
    });
  }

  return {
    side,
    floor,
    floorIndex
  };
};

const getRelativeIndex = (absoluteIndex, hiveSize) => absoluteIndex % hiveSize;

const getMoveToEmptySlotCost = (layout, hiveSize, index, emptyIndex) => {
  const hivesAmount = layout.length / hiveSize;

  if (matrix[hivesAmount]) {
    let cost = 0;

    const toIndexData = getSideAndFloor(index, matrix[hivesAmount], hiveSize);
    const emptyIndexData = getSideAndFloor(emptyIndex, matrix[hivesAmount], hiveSize);

    if (toIndexData.side !== emptyIndexData.side) {
      cost += COSTS.SIDE_SWITCH;
    }

    if (toIndexData.floor !== emptyIndexData.floor) {
      cost += Math.abs(toIndexData.floor - emptyIndexData.floor) * COSTS.LEVEL_CROSSING;
    }

    const absoluteIndexDistance = Math.abs(
      getRelativeIndex(index, hiveSize) - getRelativeIndex(emptyIndex, hiveSize)
    );
    const hiveIndexDistance =
      Math.abs(toIndexData.floorIndex - emptyIndexData.floorIndex) * hiveSize;

    cost += (absoluteIndexDistance + hiveIndexDistance) * COSTS.SIDE_MOVE;

    return cost;
  }

  return Math.abs(index - emptyIndex);
};

export const matrixHelpers = {
  getMoveToEmptySlotCost,
  getHiveNumber,
  getSideAndFloor,
  getRelativeIndex
};

export default matrix;
