import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import constants from 'appConstants';
import Tooltip from '@beewise/tooltip';
import getFrameTooltip from '../../utils/getFrameTooltip';
import { getCapacity, getIsFoundationFrame } from '../../utils';
import Comb from '../Frame/components/Comb';
import FullFrame from '../Frame/components/FullFrame';

const StationFrame = ({ frame, onFrameClick, stationStart, stationEnd, hives, isSelected }) => {
    const capacity = useMemo(() => getCapacity(frame), [frame]);
    const isFoundationFrame = useMemo(() => getIsFoundationFrame(frame), [frame]);
    const isStaticPartition = useMemo(() => frame.type === constants.FRAME_TYPES.STATIC_PARTITION, [frame.type]);
    const tooltipText = useMemo(() => getFrameTooltip({ ...frame, frameIdx: frame.slotId }), [frame]);
    const isInsideMarkedHive =
        frame?.frameId &&
        hives.some(
            hive =>
                frame?.place?.station === hive?.station &&
                frame?.place?.position?.x >= hive?.from &&
                frame?.place?.position?.x < hive?.to
        );

    const style = useMemo(
        () => ({
            left: `${((frame.place.position.x - stationStart) / (stationEnd - stationStart)) * 100}%`,
            ...(isStaticPartition && { transform: 'translateX(4px)' }),
        }),
        [frame.place.position.x, isStaticPartition, stationEnd, stationStart]
    );

    return (
        <div
            className={cx('station-frame-wrapper', {
                'station-frame-wrapper-outside': !isInsideMarkedHive,
                'station-frame-wrapper-static-partition': isStaticPartition,
            })}
            style={style}
        >
            <Tooltip
                text={tooltipText}
                position="top center"
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                hideOnScroll
                disabled={!tooltipText}
            >
                <div
                    className={cx('station-frame', {
                        'station-frame-empty': !frame?.type,
                        'station-frame-selected': isSelected,
                    })}
                    role="presentation"
                    onClick={onFrameClick}
                >
                    {frame.type && frame.type === constants.FRAME_TYPES.COMB_FRAME && !isFoundationFrame ? (
                        <Comb frame={frame} capacity={capacity} />
                    ) : (
                        <FullFrame
                            type={frame.type}
                            isFoundationFrame={isFoundationFrame}
                            isEmptySlot={!frame.frameId}
                        />
                    )}
                </div>
            </Tooltip>
        </div>
    );
};

StationFrame.propTypes = {
    frame: PropTypes.shape().isRequired,
    onFrameClick: PropTypes.func.isRequired,
    stationStart: PropTypes.number.isRequired,
    stationEnd: PropTypes.number.isRequired,
    hives: PropTypes.arrayOf(PropTypes.shape()),
    isSelected: PropTypes.bool,
};

export default StationFrame;
