import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import cx from 'classnames';
import { getCurrentBhome } from 'selectors';
import { loading, usePermission } from '@beewise/react-utils';
import { get } from 'lodash-es';
import constants from 'appConstants';
import analytics from 'utils/analytics';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import BhomeLayoutContent from 'components/reusables/BhomeLayout/components/BhomeLayoutContent';
import { FETCH_SAVE_BHOME_SETTINGS } from 'components/views/Config/actionTypes';
import GeneralFunctions from './components/GeneralFunctions';
import FrameActionsCenter from './components/FrameActionsCenter';
import Devices from './components/Devices';
import GeoReadings from './components/GeoReadings';
import { getCurrentBhomeLiveStream, isNavigationCalibrationInProgress } from './selectors';
import { fetchBhomeSettings } from '../Config/actions/config.actions';
import {
    FETCH_SEND_TECHNICIAN_ACTION,
    FETCH_ACTIVITIES,
    FETCH_SET_CAMERA_LIGHT,
    FETCH_SET_LIGHTING_SETTINGS,
    FETCH_CAMERA_DEVICES,
    FETCH_MOVE_FRAMES,
    FETCH_BE_CHECK_STATE,
    FETCH_BE_CHANGE_STATE,
} from './actionTypes';
import RobotVersions from './components/RobotVersions';
import AxisController from './components/AxisController';
import AxisSensor from './components/AxisSensor';
import CommandSender from './components/CommandSender';
import LiveVideo from '../../reusables/LiveVideo';
import Calibrations from './components/Calibrations';
import LightsV4 from './components/LightsV4';
import VentsCenter from './components/VentsCenter';
import ShakerCenter from './components/ShakerCenter';
import SoftwareUpdateCenter from './components/SoftwareUpdateCenter';
import PowerDataCenter from './components/PowerDataCenter';
import IncubatorCenter from './components/IncubatorCenter';
import FrameScaleCenter from './components/FrameScaleCenter';
import { FETCH_TOGGLE_LOCK_HIVE } from '../Hives/actionTypes';
import ActivityManagerCenter from './components/ActivityManagerCenter';

const ActionsAndMonitoring = () => {
    const bhome = useSelector(getCurrentBhome, shallowEqual);
    const robotStatus = useSelector(state => state.actionsMonitoring.status);
    const systemStatuses = useSelector(state => state.actionsMonitoring.systemStatuses, shallowEqual);
    const isNavCalibrationInProgress = useSelector(isNavigationCalibrationInProgress);
    const currentLiveStream = useSelector(getCurrentBhomeLiveStream);

    const isManufacturer = usePermission('isManufacturer', 'technician');
    const isQc = usePermission('isQc', 'technician');

    const isEMTester = usePermission('isEMTester', 'technician');
    const isGripperTester = usePermission('isGripperTester', 'technician');
    const isFLTester = usePermission('isFLTester', 'technician');
    const isECTester = usePermission('isECTester', 'technician');

    const areAllButtonsDisabled = useMemo(() => get(bhome, 'status', '') === constants.BHOME_STATUSES.ERROR, [bhome]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (bhome?.id) {
            dispatch(fetchBhomeSettings(bhome.id));
        }
    }, [bhome?.id, dispatch]);

    const handleTechnicianActionClick = useCallback(
        (type, params = {}) =>
            () => {
                analytics.fireGeneralRobotEvent({
                    action: type,
                    label: `Beehome ${bhome.id}`,
                });

                dispatch(fetchSendTechnicianAction(bhome.id, { type, params }));
            },
        [dispatch, bhome]
    );

    return (
        <div
            className={cx('actions-monitoring actions-monitoring-v-4', {
                'actions-monitoring-manufacturer': isManufacturer,
                'actions-monitoring-em-tester': isEMTester,
                'actions-monitoring-gripper-tester': isGripperTester,
                'actions-monitoring-fl-tester': isFLTester,
                'actions-monitoring-ec-tester': isECTester,
            })}
        >
            <div className="actions-monitoring-content">
                {!isManufacturer && <BhomeLayoutContent bhome={bhome} />}
                <GeneralFunctions
                    bhome={bhome}
                    robotStatus={robotStatus}
                    systemStatuses={systemStatuses}
                    currentLiveStream={currentLiveStream}
                    handleTechnicianActionClick={handleTechnicianActionClick}
                    isQc={isQc}
                    isNavCalibrationInProgress={isNavCalibrationInProgress}
                    isFLTester={isFLTester}
                />
                {!isManufacturer && <ActivityManagerCenter bhome={bhome} />}
                {!isManufacturer && <LiveVideo bhome={bhome} streamNum="1" />}
                <FrameActionsCenter bhome={bhome} isEMTester={isEMTester} isFLTester={isFLTester} />
                {!isManufacturer && <LiveVideo bhome={bhome} streamNum="2" />}
                <AxisController
                    bhome={bhome}
                    isEMTester={isEMTester}
                    isGripperTester={isGripperTester}
                    isFLTester={isFLTester}
                />
                <AxisSensor robotStatus={robotStatus} handleTechnicianActionClick={handleTechnicianActionClick} />
                <RobotVersions
                    handleTechnicianActionClick={handleTechnicianActionClick}
                    areAllButtonsDisabled={areAllButtonsDisabled}
                    bhome={bhome}
                />
                <GeoReadings bhome={bhome} isNavCalibrationInProgress={isNavCalibrationInProgress} />
                <Devices bhome={bhome} isNavCalibrationInProgress={isNavCalibrationInProgress} />
                <Calibrations bhome={bhome} />
                <LightsV4
                    bhome={bhome}
                    isEMTester={isEMTester}
                    isFLTester={isFLTester}
                    isNavCalibrationInProgress={isNavCalibrationInProgress}
                />
                <VentsCenter bhome={bhome} isECTester={isECTester} />
                <ShakerCenter bhome={bhome} />
                <SoftwareUpdateCenter bhome={bhome} />
                <PowerDataCenter bhome={bhome} />
                <IncubatorCenter bhome={bhome} robotStatus={robotStatus} />
                <FrameScaleCenter bhome={bhome} />
            </div>
            {!isManufacturer && (
                <div className="actions-monitoring-bh4">
                    <CommandSender bhome={bhome} />
                </div>
            )}
        </div>
    );
};

export default loading([
    FETCH_SEND_TECHNICIAN_ACTION.default,
    FETCH_ACTIVITIES.default,
    FETCH_SET_CAMERA_LIGHT.default,
    FETCH_SET_LIGHTING_SETTINGS.default,
    FETCH_CAMERA_DEVICES.default,
    FETCH_MOVE_FRAMES.default,
    FETCH_BE_CHECK_STATE.default,
    FETCH_BE_CHANGE_STATE.default,
    FETCH_TOGGLE_LOCK_HIVE.default,
    FETCH_SAVE_BHOME_SETTINGS.default,
])(ActionsAndMonitoring);
