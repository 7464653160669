import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import { groupBy } from 'lodash-es';
import appConstants from 'appConstants';

const getStatistics = state => state.statistics.data;

const getSelectedBhomes = state => state.statistics.selectedBhomes;

const getShowAutoScans = state => state.statistics.showAutoScans;

export const getFilteredStatistics = createSelector(
    getStatistics,
    getSelectedBhomes,
    getShowAutoScans,
    (statistics, selectedBhomes, showAutoScans) => {
        const valueKey = showAutoScans ? 'auto' : 'value';
        const groupedData = groupBy(statistics, item => dayjs(item.date).utcOffset(0, true).startOf('day').format());
        return Object.keys(groupedData).reduce((acc, item) => {
            acc.push({
                name: item,
                date: dayjs.utc(item).valueOf(),
                ...groupedData[item].reduce(
                    (acc, subItem) => {
                        if (!selectedBhomes.length || selectedBhomes.includes(subItem.bhome_id)) {
                            if (subItem.status === appConstants.MESSAGE_STATUS.SUCCESS) {
                                acc.success += Number(subItem[valueKey]);
                            } else if (subItem.status === appConstants.MESSAGE_STATUS.FAILED) {
                                acc.failed += Number(subItem[valueKey]);
                            } else if (subItem.status === appConstants.MESSAGE_STATUS.CANCELED) {
                                acc.canceled += Number(subItem[valueKey]);
                            } else {
                                acc.pending += Number(subItem[valueKey]);
                            }
                            acc.total += Number(subItem[valueKey]);
                        }

                        return acc;
                    },
                    {
                        pending: 0,
                        success: 0,
                        failed: 0,
                        canceled: 0,
                        total: 0,
                    }
                ),
            });
            return acc;
        }, []);
    }
);
