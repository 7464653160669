import { FETCH_ALL_BHOMES_MESSAGES, TOGGLE_KPI_VIEW, MARK_MESSAGES_AS_FAILED } from '../actionTypes';

export const fetchAllBhomesMessages = (from, to, command) => ({
    type: 'FETCH',
    params: {
        type: FETCH_ALL_BHOMES_MESSAGES.default,
        url: `/messages?from=${from}&to=${to}${command ? `&command=${command}` : ''}`,
    },
});

export const toggleKpiView = () => ({
    type: TOGGLE_KPI_VIEW,
});

export const markMessagesAsFailed = (bhomeId, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: MARK_MESSAGES_AS_FAILED.default,
        url: `/messages/bhomes/${bhomeId}/mark-failed`,
        resolver,
    },
});
