import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '@beewise/icon';
import Tooltip from '@beewise/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/pro-regular-svg-icons';
import getVoltageLevel from '../getVoltageLevel';
import SolarData from './SolarData';

/*  eslint-disable camelcase */
const BeehomeStatusIcons = ({
  isFetched,
  isBhomeOnline,
  isPowerOn,
  isUnderMaintenance,
  isRfidScanInProcess,
  voltage,
  bhome,
  showSolarData,
  solarDataTooltipProps = {},
  solarKeysMap = null
}) => {
  const voltageHeight = getVoltageLevel(voltage);
  const { solar_data } = bhome;

  return (
    <div className="beehome-status">
      <Tooltip
        className="beehome-status-solar-tooltip"
        content={
          <SolarData
            solarData={solar_data}
            showSolarData={showSolarData}
            solarKeysMap={solarKeysMap}
          />
        }
        disabled={!showSolarData || !solar_data}
        position="top center"
        {...solarDataTooltipProps}
      >
        <div className="voltage">
          <div
            className={cx('voltage-level', {
              warn: voltageHeight <= 20 && voltageHeight > 15,
              alert: voltageHeight <= 10
            })}
            style={{ height: `${voltageHeight}%` }}
          />
        </div>
      </Tooltip>
      <Icon
        type="signal"
        className={cx('reception status-icon', {
          'reception-online': isFetched && isBhomeOnline,
          'reception-offline': isFetched && !isBhomeOnline
        })}
      />
      <Icon
        type="power"
        className={cx('power status-icon', {
          'power-on': isFetched && isPowerOn,
          'power-off': isFetched && !isPowerOn
        })}
      />
      {(isUnderMaintenance || isRfidScanInProcess) && (
        <Tooltip
          content="The Beehome is under maintenance, and activities may be delayed."
          disabled={!isUnderMaintenance && !isRfidScanInProcess}
          position="top center"
        >
          <FontAwesomeIcon icon={faTools} className="tools status-icon" />
        </Tooltip>
      )}
    </div>
  );
};

BeehomeStatusIcons.propTypes = {
  isFetched: PropTypes.bool,
  isBhomeOnline: PropTypes.bool,
  isPowerOn: PropTypes.bool,
  isUnderMaintenance: PropTypes.bool,
  isRfidScanInProcess: PropTypes.bool,
  voltage: PropTypes.number,
  bhome: PropTypes.shape(),
  solarDataTooltipProps: PropTypes.shape(),
  solarKeysMap: PropTypes.shape(),
  showSolarData: PropTypes.bool
};

export default BeehomeStatusIcons;
