import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/reusables/Card';
import Stream from './components/Stream';

/* eslint-disable react/no-danger */
const LiveVideo = ({ bhome, isCameraView, streamNum }) => (
    <div className={`live-video live-video-${streamNum}`}>
        <Card className={`live-stream live-stream-${streamNum}`} title={`Live Video ${streamNum}`}>
            {isCameraView && <div className="live-video-bhome-id">Beehome: {bhome.id}</div>}
            <div className="live-feed">
                <Stream bhomeId={bhome?.id} isCameraView={isCameraView} streamNum={streamNum} />
            </div>
        </Card>
    </div>
);

LiveVideo.propTypes = {
    bhome: PropTypes.shape(),
    isCameraView: PropTypes.bool,
    streamNum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LiveVideo;
