import {
    FETCH_BHOME_EXHIBITION_DANCE,
    CLEAR_LOGS,
    FINISH_LOG_STREAM,
    CLEAR_LOGS_TO_DOWNLOAD,
    FETCH_READ_RFID,
    FETCH_FILL_FEEDERS,
} from '../actionTypes';

export const fetchBhomeExhibitionDance = (id, amount) => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOME_EXHIBITION_DANCE.default,
        method: 'POST',
        url: `/bhomes/${id}/start-exhibition-dance`,
        body: {
            amount,
        },
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const readRfid = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_READ_RFID.default,
        url: `/bhomes/${bhomeId}/read-rfid`,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchFillFeeders = (body, beehomeId, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_FILL_FEEDERS.default,
        url: `/bhomes/${beehomeId}/feed`,
        method: 'POST',
        body,
        payloadType: null,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
        resolver,
    },
});

export const finishLogStream = () => ({
    type: FINISH_LOG_STREAM,
});

export const clearLogs = () => ({
    type: CLEAR_LOGS,
});

export const clearLogsToDownload = () => ({
    type: CLEAR_LOGS_TO_DOWNLOAD,
});
