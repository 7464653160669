import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@beewise/select-field';
import { untuckOptions } from '../../../utils';

const FillSyrupParams = ({ setParams, params }) => {
    const handleUntuckChange = useCallback(
        value => {
            setParams(params => ({
                ...params,
                untuck_direction: value,
            }));
        },
        [setParams]
    );

    return (
        <div className="scan-frame-params">
            <SelectField
                value={params?.untuck_direction ?? null}
                options={untuckOptions}
                onChange={handleUntuckChange}
                placeholder="Untuck Direction"
                className="nowrap frame-type-select"
                size="small"
            />
        </div>
    );
};

FillSyrupParams.propTypes = {
    params: PropTypes.shape(),
    setParams: PropTypes.func.isRequired,
};

export default FillSyrupParams;
