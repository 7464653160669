import React, { forwardRef } from 'react';

const MarkerNew = forwardRef((props, ref) => (
  <svg ref={ref} width="38" height="52" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m21 2.536 13 7.505a4 4 0 0 1 2 3.465v15.01a4 4 0 0 1-2 3.465l-2.555 1.475-.708 1.065-1.398 2.06-5.853 8.485L22.16 47l-1.413 2.072a2.109 2.109 0 0 1-3.494 0l-2.087-3.056-2.77-4.024-3.072-4.445-1.288-1.882L6.9 33.978l-.345-.523L4 31.981a4 4 0 0 1-2-3.464V13.506a4 4 0 0 1 2-3.465l13-7.505a4 4 0 0 1 4 0z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </svg>
));

MarkerNew.displayName = 'MarkerNew';

export default MarkerNew;
