import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import constants from 'appConstants';

const FullFrame = ({ type, isFoundationFrame, isEmptySlot }) => (
    <div
        className={cx('full', {
            partition: type === constants.FRAME_TYPES.PARTITION,
            'static-partition': type === constants.FRAME_TYPES.STATIC_PARTITION,
            'queen-excluder': type === constants.FRAME_TYPES.QUEEN_EXCLUDER,
            empty: isEmptySlot,
            feeder: type === constants.FRAME_TYPES.FEEDER,
            foundation: isFoundationFrame,
        })}
        style={{
            height: '100%',
        }}
    />
);

FullFrame.propTypes = {
    type: PropTypes.string,
    isFoundationFrame: PropTypes.bool,
    isEmptySlot: PropTypes.bool,
};

export default FullFrame;
