import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Icon from '@beewise/icon';

import './ImagePlaceholder.scss';

const ImagePlaceholder = ({ text, iconName = 'camera', className }) => (
    <div className={cx('image-placeholder', { [className]: !!className })}>
        <Icon type={iconName} className="image-placeholder-icon" />
        <div className="image-placeholder-text">{text}</div>
    </div>
);

ImagePlaceholder.propTypes = {
    text: PropTypes.string.isRequired,
    iconName: PropTypes.string,
    className: PropTypes.string,
};

export default ImagePlaceholder;
