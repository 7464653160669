import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@beewise/select-field';
import { untuckOptions } from 'components/views/ActionsAndMonitoring/utils';

const CloseHiveParams = ({ params, setParams }) => {
    const handleUntuckChange = useCallback(
        value => {
            setParams(params => ({
                ...params,
                tuck: {
                    ...(params?.tuck ?? {}),
                    direction: value,
                },
            }));
        },
        [setParams]
    );

    return (
        <div className="action-hive-params">
            <SelectField
                value={params?.tuck?.direction ?? null}
                options={untuckOptions}
                onChange={handleUntuckChange}
                placeholder="Untuck Direction"
                className="nowrap frame-type-select"
                size="small"
            />
        </div>
    );
};

CloseHiveParams.propTypes = {
    params: PropTypes.shape(),
    setParams: PropTypes.func.isRequired,
};

export default CloseHiveParams;
