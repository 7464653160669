import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const ArrowDownWithLineOnTop = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...rest}
  >
    <path
      d="M8.45312 15.7625C8.59375 15.9125 8.79375 16 9 16C9.20625 16 9.40312 15.9156 9.54688 15.7625L13.5469 11.5125C13.8313 11.2094 13.8156 10.7375 13.5156 10.4531C13.2156 10.1687 12.7406 10.1844 12.4563 10.4844L9.75 13.3594V10.75V6.75C9.75 6.33437 9.41563 6 9 6C8.58437 6 8.25 6.33437 8.25 6.75V10.75V13.3594L5.54688 10.4875C5.2625 10.1844 4.7875 10.1719 4.4875 10.4563C4.1875 10.7406 4.17188 11.2156 4.45625 11.5156L8.45625 15.7656L8.45312 15.7625ZM14.25 3.5C14.6656 3.5 15 3.16562 15 2.75C15 2.33437 14.6656 2 14.25 2H3.75C3.33437 2 3 2.33437 3 2.75C3 3.16562 3.33437 3.5 3.75 3.5H14.25Z"
      fill={color}
    />
  </svg>
));

ArrowDownWithLineOnTop.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ArrowDownWithLineOnTop.displayName = 'ArrowDownWithLineOnTop';

export default ArrowDownWithLineOnTop;
