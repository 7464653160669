import React from 'react';
import PropTypes from 'prop-types';

const SolarData = ({ showSolarData, solarData, solarKeysMap = null }) => {
  if (!showSolarData || !solarData) {
    return null;
  }

  return (
    <div>
      {Object.keys(solarData).map((key) => {
        let keyText = key;
        if (solarKeysMap && solarKeysMap[key]) {
          keyText = solarKeysMap[key];
        }

        return (
          <div>
            {keyText}: {solarData[key]}
          </div>
        );
      })}
    </div>
  );
};

SolarData.propTypes = {
  showSolarData: PropTypes.bool,
  solarData: PropTypes.shape(),
  solarKeysMap: PropTypes.shape()
};

export default SolarData;
