const REQUEST_STATUSES = {
  failed: 'FAILED',
  pending: 'PENDING',
  success: 'SUCCESS'
};

const failedAction = (action) => `${action}_${REQUEST_STATUSES.failed}`;

const pendingAction = (action) => `${action}_${REQUEST_STATUSES.pending}`;

const successAction = (action) => `${action}_${REQUEST_STATUSES.success}`;

export const createAsyncAction = (action) => ({
  default: action,
  failed: failedAction(action),
  pending: pendingAction(action),
  success: successAction(action)
});
