import React from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash-es';
import TextField from '@beewise/text-field';
import { SelectField } from '@beewise/select-field';

const EditFrameContent = ({
    currentFrame,
    editRfid,
    handleEditRfidChange,
    frameType,
    frameOptions,
    handleTypeChange,
}) => {
    const slotText = `Station: ${currentFrame?.place?.station?.toUpperCase()}, X: ${currentFrame?.place?.position?.x}`;

    return (
        <>
            {isNumber(currentFrame.slotIndex) && <div className="frames-slot-index-data">{slotText}</div>}
            <TextField className="frameid-input" label="Frame ID" value={currentFrame.id || ''} disabled size="small" />
            <TextField label="Rfid" value={editRfid} onChange={handleEditRfidChange} size="small" />
            <SelectField
                value={frameType}
                options={frameOptions}
                onChange={handleTypeChange}
                placeholder="Choose type"
                size="small"
            />
        </>
    );
};

EditFrameContent.propTypes = {
    currentFrame: PropTypes.shape(),
    editRfid: PropTypes.string,
    frameType: PropTypes.string,
    handleEditRfidChange: PropTypes.func,
    handleTypeChange: PropTypes.func,
    frameOptions: PropTypes.arrayOf(PropTypes.shape()),
};

export default EditFrameContent;
