import { isEmpty } from 'lodash-es';
import React from 'react';

export const getTextsAndValues = robotStatus => {
    if (!isEmpty(robotStatus?.env_int)) {
        return [
            {
                text: 'Internal Temperature',
                value: <>{robotStatus?.env_int?.temperature?.toFixed?.(2)} &#8451;</>,
            },
            {
                text: 'External Temperature',
                value: <>{robotStatus?.env_ext?.temperature?.toFixed?.(2)} &#8451;</>,
            },
            {
                text: 'EC Temperature',
                value: <>{robotStatus?.env_ec?.temperature?.toFixed?.(2)} &#8451;</>,
            },
            {
                text: 'Internal Humidity',
                value: <>{robotStatus?.env_int?.humidity?.toFixed?.(2)} %</>,
            },
            {
                text: 'External Humidity',
                value: <>{robotStatus?.env_ext?.humidity?.toFixed?.(2)} %</>,
            },
            {
                text: 'EC Humidity',
                value: <>{robotStatus?.env_ec?.humidity?.toFixed?.(2)} %</>,
            },
        ];
    }
    if (!isEmpty(robotStatus?.internal_temperature1)) {
        return [
            {
                text: 'Internal Temperature 1',
                value: <>{robotStatus?.internal_temperature1?.toFixed?.(2)} &#8451;</>,
            },
            {
                text: 'Internal Temperature 2',
                value: <>{robotStatus?.internal_temperature2?.toFixed?.(2)} &#8451;</>,
            },
            {
                text: 'External Temperature',
                value: <>{robotStatus?.external_temperature1?.toFixed?.(2)} &#8451;</>,
            },
            {
                text: 'Internal Humidity 1',
                value: <>{robotStatus?.internal_humidity1?.toFixed?.(2)} %</>,
            },
            {
                text: 'Internal Humidity 2',
                value: <>{robotStatus?.internal_humidity2?.toFixed?.(2)} %</>,
            },
            {
                text: 'External Humidity',
                value: <>{robotStatus?.external_humidity1?.toFixed?.(2)} %</>,
            },
        ];
    }
    return null;
};
