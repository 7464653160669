import constants from 'appConstants';

export const entrancesOptions = new Array(constants.ENTRANCES_PER_HIVE).fill(undefined).map((item, index) => ({
    label: index + 1,
    value: index,
}));

export const entranceActionsOptions = [
    {
        label: 'Close Entrance',
        value: 'close',
    },
    {
        label: 'Open Entrance',
        value: 'open',
    },
    {
        label: 'Check Entrance State',
        value: constants.COMMANDS.BE_CHECK_STATE,
    },
    {
        label: 'Align To Entrance',
        value: constants.COMMANDS.BE_ALIGN,
    },
];
