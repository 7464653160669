import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import SlotsMultipickerV4 from 'components/reusables/SlotsMultipicker/SlotsMultipickerV4';
import constants from 'appConstants';

const FeedBundleParams = ({ setParams }) => {
    const handleOnChange = useCallback(
        slots =>
            setParams({
                slots,
            }),
        [setParams]
    );

    return <SlotsMultipickerV4 type={constants.FRAME_TYPES.FEEDER} onlyInsideMarkedHive onChange={handleOnChange} />;
};

FeedBundleParams.propTypes = {
    setParams: PropTypes.func.isRequired,
};

export default FeedBundleParams;
