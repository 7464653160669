import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Queen = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <path d="M4 9V20H20V9L15.5 13.5L12 4L8.5 13.5L4 9Z" />
  </svg>
));

Queen.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Queen.displayName = 'Queen';

export default Queen;
