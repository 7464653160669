import { useCallback, useReducer, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import getDirtyFormFields from './getDirtyFormFields';

const reducer = (state, action) => {
  switch (action.type) {
    case 'change':
      return {
        ...state,
        [action.key]: action.value
      };
    case 'increase':
      return {
        ...state,
        [action.key]: state[action.key] + action.value
      };
    case 'decrease':
      return {
        ...state,
        [action.key]: state[action.key] - action.value
      };
    case 'add_to_array':
      return {
        ...state,
        [action.key]: [...state[action.key], action.value]
      };
    case 'remove_from_array':
      return {
        ...state,
        [action.key]: state[action.key].filter((item) => item.id !== action.value)
      };
    case 'rerender_state':
      return {
        ...state,
        ...action.value
      };
    default:
      return state;
  }
};

const useForm = ({ initialFields, preSubmit, onSubmit, callback, isEditMode, resetForm }) => {
  const dispatch = useDispatch();

  const [state, localDispatch] = useReducer(reducer, initialFields);

  const handleLocalReducerChange = useCallback(
    (key, value, type = 'change') => localDispatch({ type, key, value }),
    [localDispatch]
  );

  useEffect(() => {
    handleLocalReducerChange(null, initialFields, 'rerender_state');
  }, [initialFields, handleLocalReducerChange]);

  const handleSubmit = useCallback(() => {
    let enhancedData = state;
    if (preSubmit) {
      enhancedData = preSubmit(state);
    }
    if (!isEditMode) {
      Object.keys(enhancedData).forEach((key) => {
        if (!enhancedData[key]) {
          delete enhancedData[key];
        } else if (typeof enhancedData[key] === 'string') {
          enhancedData[key] = enhancedData[key].trim();
        }
      });
      dispatch(onSubmit(enhancedData, callback));
      if (resetForm) {
        handleLocalReducerChange(null, initialFields, 'rerender_state');
      }
    } else {
      const dirtyFields = getDirtyFormFields(initialFields, enhancedData);
      dispatch(onSubmit(dirtyFields, callback));
    }
  }, [state, dispatch, isEditMode, initialFields, onSubmit, callback, preSubmit]);

  return {
    state,
    handleLocalReducerChange,
    handleSubmit
  };
};

export default useForm;
