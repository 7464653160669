import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import constants from 'appConstants';
import TechButton from 'components/reusables/TechButton';
import { fetchCancel, fetchSendTechnicianAction } from 'actions/app.actions';

import './PendingTasks.scss';

const PendingTasks = ({ bhome }) => {
    const dispatch = useDispatch();

    const handleButtonClick = type => () => {
        dispatch(
            fetchSendTechnicianAction(bhome.id, {
                type,
            })
        );
    };

    const handleCancelPendingTasks = () => {
        dispatch(fetchCancel({ bhomeId: bhome.id }));
    };

    return (
        <div className="pending-tasks">
            <TechButton
                className="green"
                onClick={handleButtonClick(constants.COMMANDS.RESUME_PENDING_TASKS)}
                enabledInNonError
            >
                Resume
            </TechButton>
            <TechButton className="red" onClick={handleCancelPendingTasks} alwaysEnabled>
                Cancel
            </TechButton>
        </div>
    );
};

PendingTasks.propTypes = {
    bhome: PropTypes.shape(),
};

export default PendingTasks;
