import {
    FETCH_BHOME_SETTINGS,
    FETCH_SAVE_BHOME_SETTINGS,
    FETCH_GLOBAL_BHOME_SETTINGS,
    TOGGLE_SETTINGS_ERROR,
    FETCH_UPDATE_GLOBAL_SETTINGS,
    CLEAN_GLOBAL_SETTINGS_CONFLICTS,
} from '../actionTypes';

export const fetchBhomeSettings = (id, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOME_SETTINGS.default,
        url: `/bhomes/${id}/settings`,
        resolver,
    },
});

export const fetchGlobalBhomeSettings = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_GLOBAL_BHOME_SETTINGS.default,
        url: '/settings/get?type=bhome',
    },
});

export const fetchUpdateGlobalSettings = (
    id,
    data,
    ignoreConflicts = false,
    checkedConflicts = [],
    isAllChecked = true,
    resolver
) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: FETCH_UPDATE_GLOBAL_SETTINGS.default,
        url: `/settings/${id}/update-bhome-setting`,
        body: {
            data,
            ignoreConflicts,
            checkedConflicts,
            isAllChecked,
        },
        resolver,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchSaveSettings = (
    id,
    settings,
    previousSettingsData,
    disableHashCheck = false,
    enforceImmediateAction = false
) => ({
    type: 'FETCH',
    params: {
        type: FETCH_SAVE_BHOME_SETTINGS.default,
        method: 'PUT',
        url: `/bhomes/${id}/settings`,
        body: {
            updateBody: settings,
            previousSettingsData,
            disableHashCheck,
            enforceImmediateAction,
        },
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const toggleSettingsError = error => ({
    type: TOGGLE_SETTINGS_ERROR,
    error,
});

export const cleanGlobalSettingsConflicts = () => ({
    type: CLEAN_GLOBAL_SETTINGS_CONFLICTS,
});
