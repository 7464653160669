import { ON_UPDATE_CONFIG } from 'actionTypes';
import {
    FETCH_BHOME_SETTINGS,
    FETCH_SAVE_BHOME_SETTINGS,
    FETCH_GLOBAL_BHOME_SETTINGS,
    TOGGLE_SETTINGS_ERROR,
    FETCH_UPDATE_GLOBAL_SETTINGS,
    CLEAN_GLOBAL_SETTINGS_CONFLICTS,
} from '../actionTypes';

const config = (
    state = {
        currentBhomeSettings: null,
        globalSettings: null,
        error: null,
        conflicts: null,
    },
    action
) => {
    switch (action.type) {
        case FETCH_BHOME_SETTINGS.success:
        case FETCH_SAVE_BHOME_SETTINGS.success:
            return {
                ...state,
                currentBhomeSettings: action.data.settings,
                error: null,
            };
        case ON_UPDATE_CONFIG:
            return {
                ...state,
                currentBhomeSettings: {
                    ...action.payload.settings,
                },
                error: null,
            };
        case FETCH_SAVE_BHOME_SETTINGS.failed:
            return {
                ...state,
                error: action.error,
            };
        case FETCH_GLOBAL_BHOME_SETTINGS.success:
            return {
                ...state,
                globalSettings: action.data.settings,
            };
        case FETCH_UPDATE_GLOBAL_SETTINGS.success:
            if (action.data.conflicts) {
                return {
                    ...state,
                    conflicts: action.data.conflicts,
                };
            }

            return {
                ...state,
                globalSettings: action.data.settings,
                conflicts: null,
            };
        case TOGGLE_SETTINGS_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case CLEAN_GLOBAL_SETTINGS_CONFLICTS:
            return {
                ...state,
                conflicts: null,
            };
        default:
            return state;
    }
};

export default config;
