/* eslint-disable import/no-unresolved */
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import { delay, put, takeEvery } from 'redux-saga/effects';
import { noop } from 'lodash-es';
import { showToast } from '@beewise/toast';
import http from '../services/http';
import memento, { generateId, proceedIfAllowed } from './utils';
import { FETCH, FETCH_FAIL, LOADING_END, LOADING_START } from './actionTypes';

export const TOAST_TYPE = {
  SUCCESS: 'toast-success',
  ERROR: 'toast-error'
};

class FetchError extends Error {
  constructor(message, status = 500) {
    super(message);
    this.status = status;
  }
}

let isErrorLogged = false;

const getUIErrorMessager = async (error) => {
  const { status = 200 } = error;
  if (status >= 500) {
    return 'Something went wrong';
  }
  return error.message;
};

const getRequestConfig = (url, options) => {
  const { body: params, method, headers = {}, responseType = 'json' } = options;
  return {
    method,
    headers,
    [method === 'GET' ? 'params' : 'data']: params,
    url,
    responseType
  };
};

export const promisifyAction = (action, errHandler, cb = () => {}) => {
  const defaultErrorHandler = (err) => {
    throw new Error({ message: err });
  };
  const errorHandler = errHandler || defaultErrorHandler;

  return (payload) =>
    new Promise((resolve, reject) => {
      action(payload, resolve, reject);
    })
      .then(cb)
      .catch(errorHandler);
};

// main execution function
export function* init(appName, API_URL, SIGN_OUT_ACTION, isDevMode, action) {
  // development path prefix to mocks
  const mockPrefix = '/__mocks__/';

  // fetch abort controller
  const controller = AbortController && new AbortController();
  const signal = controller && controller.signal;

  // define all options
  const {
    type,
    isSubmit,
    successAction,
    failAction,
    successKey = 'data',
    fakeResponse,
    handler,
    resolver,
    rejector,
    mock,
    url: fetchUrl,
    enableMemento = true,
    toastText = null,
    failureToastText = null,
    toastOptions = { toastType: TOAST_TYPE.SUCCESS },
    failureToastOptions = { toastType: TOAST_TYPE.ERROR }
  } = action.params;

  // create url for request
  // eslint-disable-next-line no-constant-condition
  const url =
    isDevMode && mock ? `//${window.location.host}${mockPrefix}${mock}` : `${API_URL}${fetchUrl}`;
  const currentFetchId = generateId();

  // memento helpers to register/unregister fetch calls
  const isTypeIdEqualCurrentConditional = proceedIfAllowed(
    memento.isTypeIdEqualCurrent.bind(memento),
    enableMemento,
    () => true
  );
  const registerTypeConditional = proceedIfAllowed(
    memento.registerType.bind(memento),
    enableMemento,
    noop
  );
  const unregisterTypeConditional = proceedIfAllowed(
    memento.unregisterType.bind(memento),
    enableMemento,
    noop
  );

  function* successSectionGenerator(data) {
    isErrorLogged = false;
    // custom handler to handle response
    const transformedData = !isDevMode && handler ? handler(data) : data;

    // success action dispatching
    yield put({ type: `${type}_SUCCESS`, [successKey]: transformedData });

    if (isTypeIdEqualCurrentConditional(type, currentFetchId)) {
      // stop loading indicator
      yield delay(700);
      yield put({ type: LOADING_END, key: type });
    }

    unregisterTypeConditional(type);

    if (resolver) resolver(transformedData);
    if (toastText) showToast(toastText, toastOptions);

    // custom success action
    if (successAction) {
      const { type } = successAction;
      const successData = successAction.data ? successAction.data : transformedData;

      yield put({ type, [successKey]: successData });
    }
  }

  function* errorSectionGenerator(error) {
    isErrorLogged = false;
    if (!isTypeIdEqualCurrentConditional(type, currentFetchId)) return;

    const transformedError = error instanceof FetchError ? error.message : error;

    yield put({
      type: FETCH_FAIL,
      error: transformedError,
      isSubmit,
      key: type
    });
    yield put({ type: `${type}_FAILED`, error: transformedError });
    if (error.status === 401) {
      yield put({ type: SIGN_OUT_ACTION });
    }

    // stop loading indicator
    yield delay(700);
    yield put({ type: LOADING_END, key: type });

    unregisterTypeConditional(type);

    if (failAction) {
      yield put({ type: failAction.type });
    }

    if (rejector) rejector(transformedError);
    if (failureToastText) showToast(failureToastText, failureToastOptions);
  }

  try {
    registerTypeConditional(type, currentFetchId, controller);

    // start loading indicator
    yield put({ type: LOADING_START, key: type });
    yield put({ type: `${type}_PENDING` });

    let data = {};

    // real fetch process
    if (!fakeResponse) {
      data = yield http(API_URL).request(getRequestConfig(url, action.params, signal));
    } else {
      // if fake data defined as response
      data = yield fakeResponse;
    }
    if (!isTypeIdEqualCurrentConditional(type, currentFetchId)) return;
    yield* successSectionGenerator(data);
  } catch (error) {
    if (!isErrorLogged) {
      // eslint-disable-next-line no-console
      console.error(`${url} failed. Please check the network stability.`);
    }
    const errorMessage = yield getUIErrorMessager(error);
    yield* errorSectionGenerator(new FetchError(errorMessage, error.status));
  }
}

// error handler (all errors come through one point to be handled)
function errorHandler(action) {
  isErrorLogged = false;
  // eslint-disable-next-line no-console
  console.error('FETCH process failed. See logs output', action.error);
}

// root saga
export function* appSaga(appName, API_URL, SIGN_OUT_ACTION, isDevMode) {
  yield takeEvery(FETCH, init.bind(null, appName, API_URL, SIGN_OUT_ACTION, isDevMode));
  yield takeEvery(FETCH_FAIL, errorHandler);
}

export { default as loading } from './Loading';
export { default as SuspenseLoading } from './SuspenseLoading';
