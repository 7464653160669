import { useRef, useEffect } from 'react';
import { isEqual } from 'lodash-es';

const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    if (!isEqual(ref.current, value)) {
      ref.current = value;
    }
  }, [value]);

  return ref.current;
};

export const useCompareEffect = (create, input) => {
  useEffect(create, [usePrevious(input)]);
};

export default usePrevious;
