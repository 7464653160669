import { FETCH_ALL_LOCKED_HIVES, FETCH_TOGGLE_LOCK_HIVE } from '../actionTypes';
import { getFilteredSortedLockedHives } from '../utils';

const initialState = {
    lockedHives: [],
};

const hives = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_LOCKED_HIVES.success:
            return {
                ...state,
                lockedHives: action.data ?? [],
            };
        case FETCH_TOGGLE_LOCK_HIVE.success:
            return {
                ...state,
                lockedHives: getFilteredSortedLockedHives(action.data, state.lockedHives),
            };
        default:
            return state;
    }
};

export default hives;
