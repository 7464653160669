import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@beewise/text-field';
import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { fetchUpdateBhomeNote } from '../actions';

const NoteRenderer = ({ value, data }) => {
    const [note, setNote] = useState(value || '');

    const dispatch = useDispatch();

    const handleSave = useCallback(() => {
        if ((note || (value && !note)) && note !== value && data.id) {
            dispatch(fetchUpdateBhomeNote(data.id, note));
        }
    }, [data?.id, note, value, dispatch]);

    return (
        <>
            <TextField value={note} onChange={setNote} size="small" />
            <FontAwesomeIcon className="floppy-icon" icon={faFloppyDisk} onClick={handleSave} />
            {data.note_updated_at && (
                <span className="note-renderer-date">{new Date(data.note_updated_at).toLocaleString()}</span>
            )}
        </>
    );
};

NoteRenderer.propTypes = {
    value: PropTypes.string,
    data: PropTypes.shape({
        id: PropTypes.number,
        note_updated_at: PropTypes.string,
    }),
};

export default NoteRenderer;
