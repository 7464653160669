import React, { forwardRef } from 'react';

const HiveWorkspace = forwardRef((props, ref) => (
  <svg
    ref={ref}
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.6875 7.76562L15.3125 7.76563"
      stroke="black"
      strokeWidth="0.8"
      strokeLinecap="round"
    />
    <path d="M8.22916 13.5208H11.7708" stroke="black" strokeWidth="0.8" strokeLinecap="round" />
    <path
      d="M2.91666 6.88021L9.99999 4.66667L17.0833 6.88021"
      stroke="black"
      strokeWidth="0.8"
      strokeLinecap="round"
    />
    <path
      d="M4.6875 6.4375L4.6875 16.1771H15.3125V6.4375"
      stroke="black"
      strokeWidth="0.8"
      strokeLinejoin="round"
    />
    <circle
      cx="9.99998"
      cy="11.3073"
      r="0.885417"
      stroke="black"
      strokeWidth="0.8"
      strokeLinecap="round"
    />
  </svg>
));

HiveWorkspace.displayName = 'HiveWorkspace';

export default HiveWorkspace;
