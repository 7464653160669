import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Signal = forwardRef(({ size = '18', color = '#65BD60', ...rest }, ref) => (
  <svg
    ref={ref}
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect x="15.25" width="2.25" height="18" rx="1" fill={color} />
    <rect x="11.5" y="3" width="2.25" height="15" rx="1" fill={color} />
    <rect x="7.75" y="6.75" width="2.25" height="11.25" rx="1" fill={color} />
    <rect x="4" y="10.5" width="2.25" height="7.5" rx="1" fill={color} />
    <rect x="0.25" y="13.5" width="2.25" height="4.5" rx="1" fill={color} />
  </svg>
));

Signal.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Signal.displayName = 'Signal';

export default Signal;
