import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { noop } from 'lodash-es';
import constants from 'appConstants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCurrentBhomeFrames, getLayoutTimestamp, getMarkedHives, getStations } from 'selectors';
import Button from '@beewise/button';
import { fetchFrames } from 'components/views/ActionsAndMonitoring/actions';
import { getFilteredMessages } from 'components/views/Messages/selectors';
import { fetchAllBhomesMessages } from 'components/views/Messages/actions';
import Station from '../Station';
import { getIsLayoutInMovement } from '../../utils';

const BhomeLayoutContent = ({ handleOnFrameClick = noop, bhome, pickedFrames = [] }) => {
    const dispatch = useDispatch();
    const frames = useSelector(getCurrentBhomeFrames, shallowEqual);
    const stations = useSelector(getStations, shallowEqual);
    const markedHives = useSelector(getMarkedHives, shallowEqual);
    const layoutTimestamp = useSelector(getLayoutTimestamp);
    const messages = useSelector(getFilteredMessages);
    const isLayoutInMovement = getIsLayoutInMovement({ messages, bhomeId: bhome?.id });

    const getLatestMessages = useCallback(
        () => dispatch(fetchAllBhomesMessages(dayjs().subtract(40, 'minute').unix(), dayjs().unix())),
        [dispatch]
    );

    const handleRefreshClick = useCallback(() => {
        isLayoutInMovement ? getLatestMessages() : dispatch(fetchFrames(bhome?.id));
    }, [bhome?.id, dispatch, getLatestMessages, isLayoutInMovement]);

    useEffect(() => {
        getLatestMessages();
    }, [getLatestMessages]);

    return (
        <div className="bhome-layout-content">
            <div className="bhome-layout-update">
                {isLayoutInMovement ? (
                    <p className="text-warning">Layout changes are in progress</p>
                ) : (
                    <p>Layout updated: {layoutTimestamp ? new Date(layoutTimestamp).toLocaleString() : 'unknown'}</p>
                )}
                <Button className="btn-primary" onClick={handleRefreshClick}>
                    Refresh
                </Button>
            </div>
            <div className="hive-stations-content">
                <div className="hive-stations-lines">
                    <div className="hive-stations-line">
                        <div className="hive-stations-name">A</div>
                        <div className="hive-stations-frames">
                            <Station
                                stationName={constants.STATIONS.A}
                                layout={frames}
                                handleOnFrameClick={handleOnFrameClick}
                                stations={stations}
                                hives={markedHives}
                                bhomeId={bhome?.id}
                                settings={bhome?.settings}
                                pickedFrames={pickedFrames}
                            />
                        </div>
                    </div>
                    <div className="hive-stations-line">
                        <div className="hive-stations-name">B</div>
                        <div className="hive-stations-frames">
                            <Station
                                stationName={constants.STATIONS.B}
                                layout={frames}
                                handleOnFrameClick={handleOnFrameClick}
                                stations={stations}
                                hives={markedHives}
                                bhomeId={bhome?.id}
                                settings={bhome?.settings}
                                pickedFrames={pickedFrames}
                            />
                        </div>
                    </div>
                </div>
                {bhome && (
                    <div className="hive-syrup-container">
                        <span className="hive-syrup-text">{bhome?.sensors?.syrupLevel ?? 0}% Syrup</span>
                        <div
                            className="hive-syrup-level"
                            style={{
                                height: `${bhome?.sensors?.syrupLevel ?? 0}%`,
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

BhomeLayoutContent.propTypes = {
    handleOnFrameClick: PropTypes.func,
    bhome: PropTypes.shape(),
    pickedFrames: PropTypes.arrayOf(PropTypes.shape()),
};

export default BhomeLayoutContent;
