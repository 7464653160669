import React from 'react';
import PropTypes from 'prop-types';
import { framesUtils } from '@beewise/utils';

const Comb = ({ frame, capacity, showFramesHistory }) => (
    <>
        <div
            className="comb brood brood-egg"
            style={{
                height:
                    capacity === 0
                        ? 0
                        : `${(framesUtils.getElementSum(frame, 'larva_0_to_1', showFramesHistory) / capacity) * 100}%`,
            }}
        />
        <div
            className="comb brood brood-open"
            style={{
                height:
                    capacity === 0
                        ? 0
                        : `${
                              ((framesUtils.getElementSum(frame, 'larva_2_to_3') +
                                  framesUtils.getElementSum(frame, 'larva_4_to_5', showFramesHistory) +
                                  framesUtils.getElementSum(frame, 'larva_6_to_7')) /
                                  capacity) *
                              100
                          }%`,
            }}
        />
        <div
            className="comb brood brood-capped"
            style={{
                height:
                    capacity === 0
                        ? 0
                        : `${(framesUtils.getElementSum(frame, 'larva_8_to_21', showFramesHistory) / capacity) * 100}%`,
            }}
        />
        <div
            className="comb pollen"
            style={{
                height:
                    capacity === 0
                        ? 0
                        : `${(framesUtils.getElementSum(frame, 'pollen', showFramesHistory) / capacity) * 100}%`,
            }}
        />
        <div
            className="comb opened-honey"
            style={{
                height:
                    capacity === 0
                        ? 0
                        : `${(framesUtils.getElementSum(frame, 'worker-honey', showFramesHistory) / capacity) * 100}%`,
            }}
        />
        <div
            className="comb capped-honey"
            style={{
                height:
                    capacity === 0
                        ? 0
                        : `${(framesUtils.getElementSum(frame, 'caped_honey', showFramesHistory) / capacity) * 100}%`,
            }}
        />
    </>
);

Comb.propTypes = {
    frame: PropTypes.shape(),
    capacity: PropTypes.number,
    showFramesHistory: PropTypes.bool,
};

export default Comb;
