import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Marker = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.1553 6.89845L29.145 6.89241L29.1347 6.88653L22.7259 3.25402C21.6553 2.64722 20.3447 2.64722 19.2741 3.25402L12.8653 6.88653L12.855 6.89241L12.8447 6.89845L6.49446 10.6324C5.43367 11.2561 4.77839 12.3911 4.7686 13.6216L4.71005 20.9881L4.70995 21L4.71005 21.0119L4.7686 28.3784C4.77839 29.6089 5.43367 30.7439 6.49446 31.3676L12.8447 35.1016L12.855 35.1076L12.8653 35.1135L19.2741 38.746C20.3447 39.3528 21.6553 39.3528 22.7258 38.746L29.1347 35.1135L29.145 35.1076L29.1553 35.1016L35.5055 31.3676C36.5663 30.7439 37.2216 29.6089 37.2314 28.3784L37.29 21.0119L37.29 21L37.29 20.9881L37.2314 13.6216C37.2216 12.3911 36.5663 11.2561 35.5055 10.6324L29.1553 6.89845Z"
      fill="white"
      stroke="#222222"
      strokeWidth="3"
    />
    <path
      d="M20.0138 0.558978C20.6256 0.212238 21.3745 0.212238 21.9862 0.558978L30.135 5.17772L38.2093 9.92541C38.8155 10.2818 39.19 10.9304 39.1955 11.6336L39.27 21L39.1955 30.3664C39.19 31.0696 38.8155 31.7182 38.2093 32.0746L30.135 36.8223L21.9862 41.441C21.3744 41.7878 20.6256 41.7878 20.0138 41.441L11.865 36.8223L3.79066 32.0746C3.1845 31.7182 2.81005 31.0696 2.80446 30.3664L2.73 21L2.80446 11.6336C2.81005 10.9304 3.1845 10.2818 3.79066 9.92541L11.865 5.17772L20.0138 0.558978Z"
      fill="white"
    />
    <path
      d="M21.2465 4.86394L28.08 8.73715L34.851 12.7184C35.0025 12.8076 35.0961 12.9697 35.0975 13.1455L35.16 21L35.0975 28.8545C35.0961 29.0303 35.0025 29.1924 34.851 29.2815L28.08 33.2629L21.2466 37.1361C21.0936 37.2227 20.9064 37.2227 20.7535 37.1361L13.92 33.2629L7.14904 29.2816C6.99749 29.1924 6.90388 29.0303 6.90248 28.8545L6.84005 21L6.90248 13.1455C6.90388 12.9697 6.9975 12.8076 7.14903 12.7184L13.92 8.73712L20.7534 4.86394C20.9064 4.77725 21.0936 4.77725 21.2465 4.86394Z"
      fill="white"
      stroke="#222222"
      strokeWidth="3"
    />
  </svg>
));

Marker.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Marker.displayName = 'Marker';

export default Marker;
