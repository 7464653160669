import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useToggle } from '@beewise/react-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

import './Card.scss';

const Card = ({ title, className, children, expandable, defaultOpen = true }) => {
    const [isOpened, toggle] = useToggle(expandable ? defaultOpen : true);

    const handleClick = () => {
        if (expandable) {
            toggle();
        }
    };

    return (
        <div className={cx('card', { [className]: !!className })}>
            {title && (
                <div
                    className={cx('card-header', { 'card-header-expandable': expandable })}
                    role="presentation"
                    onClick={handleClick}
                >
                    {title}
                </div>
            )}
            {expandable && (
                <FontAwesomeIcon
                    className="toggle-button"
                    icon={isOpened ? faChevronUp : faChevronDown}
                    onClick={handleClick}
                />
            )}
            {isOpened && <div className="card-content">{children}</div>}
        </div>
    );
};

Card.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    defaultOpen: PropTypes.bool,
    expandable: PropTypes.bool,
};

export default Card;
