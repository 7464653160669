import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Power = forwardRef((props, ref) => (
  <svg
    ref={ref}
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M14.5625 1.90195C16.7773 3.48398 18.2188 6.07148 18.2188 9C18.2188 13.8094 14.3234 17.7082 9.51758 17.7188C4.71876 17.7293 0.788288 13.8164 0.781256 9.01406C0.777741 6.08555 2.21915 3.49102 4.43048 1.90547C4.8418 1.61367 5.41485 1.73672 5.66094 2.17617L6.21641 3.16406C6.42383 3.5332 6.3254 4.00078 5.98438 4.25391C4.5254 5.33672 3.59376 7.05234 3.59376 8.99648C3.59024 12.2414 6.2129 14.9062 9.50001 14.9062C12.7203 14.9062 15.4274 12.2977 15.4063 8.96133C15.3957 7.14023 14.5379 5.38242 13.0121 4.25039C12.6711 3.99727 12.5762 3.52969 12.7836 3.16406L13.3391 2.17617C13.5852 1.74023 14.1547 1.61016 14.5625 1.90195ZM10.9063 9.28125V0.84375C10.9063 0.376172 10.5301 0 10.0625 0H8.93751C8.46993 0 8.09376 0.376172 8.09376 0.84375V9.28125C8.09376 9.74883 8.46993 10.125 8.93751 10.125H10.0625C10.5301 10.125 10.9063 9.74883 10.9063 9.28125Z"
        fill="#828282"
      />
      <path
        d="M10.8125 9.28125V0.84375C10.8125 0.376172 10.4363 0 9.96875 0H8.84375C8.37617 0 8 0.376172 8 0.84375V9.28125C8 9.74883 8.37617 10.125 8.84375 10.125H9.96875C10.4363 10.125 10.8125 9.74883 10.8125 9.28125Z"
        fill="#65BD60"
      />
    </g>
  </svg>
));

Power.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Power.displayName = 'Power';

export default Power;
