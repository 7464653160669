import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/reusables/Card';
import { useDispatch } from 'react-redux';
import TechButton from 'components/reusables/TechButton';
import constants from 'appConstants';
import { fetchSendTechnicianAction } from 'actions/app.actions';

const ShakerCenter = ({ bhome }) => {
    const dispatch = useDispatch();

    const handleSendShakerCommand = useCallback(
        command => () => {
            dispatch(
                fetchSendTechnicianAction(bhome?.id, {
                    type: command,
                })
            );
        },
        [bhome?.id, dispatch]
    );

    return (
        <div className="shaker-center">
            <Card className="shaker-control" title="Shaker Control">
                <TechButton
                    enabledInNonError
                    onClick={handleSendShakerCommand(constants.COMMANDS.SHAKER_SHAKE)}
                    showToManufacturer
                >
                    Start shaker
                </TechButton>
                <TechButton
                    enabledInNonError
                    onClick={handleSendShakerCommand(constants.COMMANDS.SHAKER_STOP)}
                    showToManufacturer
                >
                    Stop shaker
                </TechButton>
                <TechButton
                    enabledInNonError
                    onClick={handleSendShakerCommand(constants.COMMANDS.SHAKER_LOCK)}
                    showToManufacturer
                >
                    Lock shaker
                </TechButton>
                <TechButton
                    enabledInNonError
                    onClick={handleSendShakerCommand(constants.COMMANDS.SHAKER_UNLOCK)}
                    showToManufacturer
                >
                    Unlock shaker
                </TechButton>
            </Card>
        </div>
    );
};

ShakerCenter.propTypes = {
    bhome: PropTypes.shape(),
};

export default ShakerCenter;
