import { createSelector } from 'reselect';
import constants from 'appConstants';
import { reverse, inRange } from 'lodash-es';
import { isLimitedTestUser, LIMITED_USERS_RANGE } from 'utils';
import { getUser } from '../../../reusables/AppHeader/selectors/bhome.ids.selector';

const getCurrentBhome = state => state.app.currentBhome;

const getMessages = state => state.messages.messages;

const getIsKpiView = state => state.messages.isKpiView;

const filterByKpi = messages => {
    const kpi = {};
    return reverse(
        reverse(messages).reduce((acc, message) => {
            if (message.command === constants.COMMANDS.SCAN) {
                if (message.status === constants.MESSAGE_STATUS.FAILED) {
                    acc.push({
                        ...message,
                        kpi: kpi[message.bhome_id] || 0,
                    });

                    kpi[message.bhome_id] = 0;
                } else {
                    if (kpi[message.bhome_id]) {
                        kpi[message.bhome_id] += 1;
                    } else {
                        kpi[message.bhome_id] = 1;
                    }
                }
            }
            return acc;
        }, [])
    );
};

export const getFilteredMessages = createSelector(
    getCurrentBhome,
    getMessages,
    getUser,
    getIsKpiView,
    (bhome, messages, user, isKpiView) => {
        let messagesToProcess = messages;

        if (isKpiView) {
            messagesToProcess = filterByKpi(messages);
        }

        if (bhome && bhome.id) {
            return messagesToProcess.filter(message => message.bhome_id === bhome.id);
        }

        if (isLimitedTestUser(user)) {
            return messagesToProcess.filter(message =>
                inRange(message.bhome_id, LIMITED_USERS_RANGE.FROM, LIMITED_USERS_RANGE.TO)
            );
        }
        return messagesToProcess;
    }
);
