import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { camelCase, startCase } from 'lodash-es';
import cx from 'classnames';
import * as Icon from './icons';

const getComponentByIconName = (iconName) => {
  const nameInPascalCase = startCase(camelCase(iconName)).replace(/ /g, '');
  return Icon[nameInPascalCase];
};

const IconComponent = forwardRef(({ type, className, ...rest }, ref) => {
  const SelectedIcon = getComponentByIconName(type);
  return SelectedIcon ? (
    <div
      className={cx('icon', {
        [className]: !!className
      })}
      ref={ref}
      {...rest}
    >
      <SelectedIcon {...rest} />
    </div>
  ) : null;
});

IconComponent.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string
};

export default IconComponent;
