import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const BeeSimple = forwardRef(({ color = 'currentColor', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <g clipPath="url(#clip0_286_4821)">
      <path
        d="M6.84231 10.4733C8.53897 8.77662 9.16415 6.65098 8.23871 5.72554C7.31326 4.80011 5.18762 5.42531 3.49097 7.12199C1.79431 8.81866 1.16913 10.9443 2.09457 11.8697C3.02002 12.7952 5.14566 12.17 6.84231 10.4733Z"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66663 6.90039C8.66663 9.22906 11.734 12.298 14.0642 12.298C14.7143 12.298 15.6667 11.9897 15.6667 10.6955C15.6667 8.36684 12.5993 5.29792 10.2691 5.29792C9.99505 5.2912 9.72295 5.34549 9.47247 5.45685"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.95398 11.8687C5.11345 12.8751 5.54296 13.8195 6.19674 14.6012C6.85051 15.3828 7.70408 15.9725 8.66651 16.3074C9.62743 15.973 10.4798 15.3846 11.1332 14.6048C11.7866 13.8249 12.2166 12.8826 12.3775 11.8779"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.68183 5.52953C5.5597 5.33442 5.49398 5.10931 5.49194 4.87914C5.49194 3.82716 6.91327 2.97437 8.66656 2.97437C10.4198 2.97437 11.8412 3.82716 11.8412 4.87914C11.8394 5.10188 11.7777 5.32004 11.6625 5.51071"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1664 12.1409C12.1664 12.1409 11.6447 11.657 10.9142 11.4469C10.1837 11.2367 9.42657 11.1338 8.66648 11.1412C7.90535 11.1338 7.14718 11.237 6.41578 11.4478C5.68437 11.6585 5.16638 12.1409 5.16638 12.1409"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9142 13.9464C10.1837 13.7363 9.42656 13.6333 8.66647 13.6408C7.90534 13.6333 7.14717 13.7365 6.41577 13.9473"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66626 1.30737C4.66626 1.30737 6.66627 1.64071 7.99961 2.97405"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6671 1.30737C12.6671 1.30737 10.6671 1.64071 9.33374 2.97405"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_286_4821">
        <rect width="16" height="16" fill="white" transform="translate(0.666626 0.707275)" />
      </clipPath>
    </defs>
  </svg>
));

BeeSimple.propTypes = {
  color: PropTypes.string
};

BeeSimple.displayName = 'BeeSimple';

export default BeeSimple;
