import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useAsyncPoll } from '@beewise/react-utils';
import constants from '@beewise/constants';
import BeehomeStatusIcons from './components/BeehomeStatusIcons';

const BeehomeStatus = ({
  bhome,
  fetchIsBhomeOnline,
  onChangeOnlineStatus,
  fetchIsRfidScanInProcess,
  voltage = null,
  showSolarData = false,
  solarDataTooltipProps = {},
  solarKeysMap = null,
  showAllGreen = false
}) => {
  const [isOnline, setIsOnline] = useState(true);
  const [isFetched, setIsFetched] = useState(showAllGreen);
  const [isRfidScanInProcess, setIsRfidScanInProcess] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (bhome?.id) {
      dispatch(
        fetchIsBhomeOnline(bhome.id, ({ isOnline }) => {
          setIsFetched(true);
          setIsOnline(isOnline);
          onChangeOnlineStatus(isOnline);
        })
      );
      if (fetchIsRfidScanInProcess) {
        dispatch(
          fetchIsRfidScanInProcess(bhome.id, (message) => {
            if (message?.id) {
              setIsRfidScanInProcess(true);
            }
          })
        );
      }
    }
  }, [dispatch, bhome?.id, onChangeOnlineStatus, showAllGreen]);

  useAsyncPoll(() => {
    if (bhome?.id) {
      setIsFetched(true);
      dispatch(
        fetchIsBhomeOnline(bhome.id, ({ isOnline }) => {
          setIsOnline(isOnline);
          onChangeOnlineStatus(isOnline);
        })
      );
      if (fetchIsRfidScanInProcess) {
        dispatch(
          fetchIsRfidScanInProcess(bhome.id, (message) => {
            if (message?.id) {
              setIsRfidScanInProcess(true);
            }
          })
        );
      }
    }
  });

  const isBhomeOnline =
    showAllGreen || (isFetched && isOnline && bhome.status !== constants.BHOME_STATUSES.POWER_OUT);
  const isBhomeUnderMaintenance =
    !showAllGreen && bhome.status !== constants.BHOME_STATUSES.RECEPTIVE;
  const isPowerOn = showAllGreen || bhome.status !== constants.BHOME_STATUSES.POWER_OUT;

  return (
    <BeehomeStatusIcons
      bhome={bhome}
      voltage={voltage}
      isBhomeOnline={isBhomeOnline}
      isFetched={isFetched}
      isPowerOn={isPowerOn}
      isUnderMaintenance={isBhomeUnderMaintenance}
      isRfidScanInProcess={isRfidScanInProcess}
      showSolarData={showSolarData}
      solarDataTooltipProps={solarDataTooltipProps}
      solarKeysMap={solarKeysMap}
    />
  );
};

BeehomeStatus.propTypes = {
  bhome: PropTypes.shape().isRequired,
  fetchIsBhomeOnline: PropTypes.func.isRequired,
  onChangeOnlineStatus: PropTypes.func,
  fetchIsRfidScanInProcess: PropTypes.func,
  voltage: PropTypes.number,
  showSolarData: PropTypes.bool,
  showAllGreen: PropTypes.bool,
  solarDataTooltipProps: PropTypes.shape(),
  solarKeysMap: PropTypes.shape()
};

export default BeehomeStatus;
