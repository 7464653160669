import React from 'react';
import useAsync from './useAsync';
import { AsyncProps } from './props';
import SelectField from './SelectField';

const AsyncSelect = React.forwardRef(
  ({ loadOptions, defaultOptions, onInputChange, ...props }, ref) => {
    const { options, ...restSelectProps } = useAsync({
      loadOptions,
      defaultOptions,
      cacheOptions: true,
      onInputChange
    });
    return <SelectField {...props} {...restSelectProps} options={options || []} ref={ref} />;
  }
);

AsyncSelect.propTypes = AsyncProps;

export default AsyncSelect;
