import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/reusables/Card';
import TechButton from 'components/reusables/TechButton';
import constants from 'appConstants';

const MovementCalibration = ({ handleTechnicianActionClick }) => (
    <div className="movement-calibration-center">
        <Card className="calibration-movement" title="Calibration & Robot Movement">
            <div className="content-one-column">
                <TechButton
                    className="fluid btn-primary"
                    onClick={handleTechnicianActionClick(constants.COMMANDS.CALIBRATE_ROBOT_CENTER)}
                >
                    Calibrate Center
                </TechButton>
                <TechButton
                    className="fluid btn-primary"
                    onClick={handleTechnicianActionClick(constants.COMMANDS.AUTO_CALIBRATE_ROBOT)}
                >
                    Auto Calibrate Robot
                </TechButton>
                <TechButton
                    className="fluid btn-primary"
                    onClick={handleTechnicianActionClick(constants.COMMANDS.ROBOT_GO)}
                >
                    Allow Robot Movement (Go)
                </TechButton>
            </div>
        </Card>
    </div>
);

MovementCalibration.propTypes = {
    handleTechnicianActionClick: PropTypes.func.isRequired,
};

export default MovementCalibration;
