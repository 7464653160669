import React, { forwardRef } from 'react';

const Collapse = forwardRef((props, ref) => (
  <svg
    ref={ref}
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.80934 7.43164L7.79818 11.7332L6.40209 13.1922L0 6.43164L6.40209 0L7.79818 1.45895L3.80934 5.43164H18V7.43164H3.80934ZM18 0.43152V2.48638H9.7926V0.43152H18ZM18 10.4316V12.4316H9.7926V10.4316H18Z"
      fill="#BDBDBD"
    />
  </svg>
));

Collapse.displayName = 'Collapse';

export default Collapse;
