import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import TechButton from 'components/reusables/TechButton';
import { fetchStopBhome } from 'actions/app.actions';
import analytics from 'utils/analytics';
import PropTypes from 'prop-types';

const StopButton = ({ bhome }) => {
    const dispatch = useDispatch();

    const handleStopPress = useCallback(() => {
        analytics.fireStopEvent({
            action: 'Stop',
            label: `Beehome ${bhome.id}`,
        });

        dispatch(fetchStopBhome(bhome.id));
    }, [bhome, dispatch]);

    return (
        <TechButton className="stop red" onClick={handleStopPress} alwaysEnabled showToManufacturer>
            Stop
        </TechButton>
    );
};

StopButton.propTypes = {
    bhome: PropTypes.shape(),
};

export default StopButton;
