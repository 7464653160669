import { FETCH_ALL_BHOMES_MESSAGES, TOGGLE_KPI_VIEW } from '../actionTypes';

const initialState = {
    messages: [],
    isKpiView: false,
};

const messages = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_BHOMES_MESSAGES.success:
            return {
                ...state,
                messages: action.data.messages,
            };
        case TOGGLE_KPI_VIEW:
            return {
                ...state,
                isKpiView: !state.isKpiView,
            };
        default:
            return state;
    }
};

export default messages;
