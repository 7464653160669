import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SelectField } from '@beewise/select-field';
import constants from 'appConstants';
import TechButton from 'components/reusables/TechButton';
import { fetchSendTechnicianAction } from 'actions/app.actions';

const stationsOptions = [
    {
        label: constants.STATIONS.A.toUpperCase(),
        value: constants.STATIONS.A,
    },
    {
        label: constants.STATIONS.B.toUpperCase(),
        value: constants.STATIONS.B,
    },
];

const MapBeehome = ({ bhome }) => {
    const dispatch = useDispatch();
    const [pickedStation, setPickedStation] = useState(null);

    const handleMapBeehome = useCallback(() => {
        if (pickedStation) {
            dispatch(
                fetchSendTechnicianAction(bhome?.id, {
                    type: constants.COMMANDS.MAP_BEEHOME,
                    params: {
                        stations: [pickedStation],
                    },
                })
            );
        }
    }, [bhome?.id, dispatch, pickedStation]);

    return (
        <>
            <SelectField
                value={pickedStation}
                options={stationsOptions}
                onChange={setPickedStation}
                placeholder="Choose stations"
                className="map-beehome-select"
                size="small"
            />
            <TechButton
                onClick={handleMapBeehome}
                disabled={!pickedStation}
                enabledInReceptiveAndMaintenance
                showToManufacturer
            >
                Map Beehome
            </TechButton>
        </>
    );
};

MapBeehome.propTypes = {
    bhome: PropTypes.shape(),
};

export default MapBeehome;
