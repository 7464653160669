import React, { useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import dayjs from 'dayjs';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { getCurrentBhome, getCurrentBhomeActivities } from 'selectors';
import { fetchTechnicianActivities } from 'actions/app.actions';
import Button from '@beewise/button';

import './Activities.scss';

const Activities = ({ onClose }) => {
    const dispatch = useDispatch();

    const bhome = useSelector(getCurrentBhome, shallowEqual);
    const bhomeActivities = useSelector(getCurrentBhomeActivities);

    const [activeTab, setActiveTab] = useState('active');

    useEffect(() => {
        if (bhome) {
            dispatch(fetchTechnicianActivities(bhome.id));
        }
    }, [bhome, dispatch]);

    const handleTabClick = useCallback(
        tab => () => {
            setActiveTab(tab);
        },
        []
    );

    return (
        <div className="activities">
            <div className="activities-header">
                <div className="activities-title">Activities</div>
                <Button iconType="x" onClick={onClose} />
            </div>
            <div className="activities-menu">
                <div
                    className={cx('activities-menu-item', {
                        'menu-item-active': activeTab === 'active',
                    })}
                    onClick={handleTabClick('active')}
                    role="presentation"
                >
                    Active
                </div>
                <div
                    className={cx('activities-menu-item', {
                        'menu-item-active': activeTab === 'history',
                    })}
                    onClick={handleTabClick('history')}
                    role="presentation"
                >
                    History
                </div>
            </div>
            <div className="activities-body">
                <div className="activities-count">{bhomeActivities[activeTab]?.length} Activities</div>
                <Scrollbars className="scrollbars">
                    {bhomeActivities[activeTab]?.map(activity => (
                        <div className="activity-card" key={activity.id}>
                            <div className="activity-card-command">Command: {activity.command}</div>
                            <div className="activity-card-details">
                                Status: {activity.status}, Initiator: {activity.user}, Started:{' '}
                                {dayjs(activity.sent_at).format('MMM DD, HH:mm')}
                            </div>
                        </div>
                    ))}
                </Scrollbars>
            </div>
        </div>
    );
};

Activities.propTypes = {
    onClose: PropTypes.func,
};

export default Activities;
