import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/reusables/Card';
import constants from 'appConstants';
import TechButton from 'components/reusables/TechButton';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import { getTextsAndValues } from './utils';

const GeoReadings = ({ bhome, isNavCalibrationInProgress }) => {
    const dispatch = useDispatch();
    const robotStatus = useSelector(state => state.actionsMonitoring.status);

    const handleGetGps = useCallback(() => {
        dispatch(
            fetchSendTechnicianAction(bhome?.id, {
                type: constants.COMMANDS.REPORT_GPS_DATA,
            })
        );
    }, [bhome?.id, dispatch]);

    const temperatureValuesAndTexts = useMemo(() => getTextsAndValues(robotStatus), [robotStatus]);

    return (
        <div className="geo-readings-center">
            <Card className="geo-readings" title="Geo & Readings">
                <TechButton
                    className="geo-reading-get-gps btn-primary"
                    onClick={handleGetGps}
                    showToManufacturer
                    disabled={isNavCalibrationInProgress}
                    enabledInAnyState
                >
                    Get GPS
                </TechButton>

                <div className="geo-readings-content">
                    {bhome?.gps && (
                        <>
                            <div className="geo-readings-item">
                                <span className="geo-readings-item-title">Geolocation:</span>
                                <a
                                    className="geo-readings-item-content"
                                    href={`https://maps.google.com/?q=${bhome?.gps?.latitude},${bhome?.gps?.longitude}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {bhome?.gps?.latitude} {bhome?.gps?.longitude}
                                </a>
                            </div>
                            {bhome?.gps?.timestamp && (
                                <div className="geo-readings-item">
                                    <span className="geo-readings-item-title">GPS Last Updated:</span>
                                    <span className="geo-readings-item-content">
                                        {new Date(bhome?.gps?.timestamp).toLocaleString()}
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                    {!!temperatureValuesAndTexts?.length && (
                        <>
                            {temperatureValuesAndTexts.map(item => (
                                <div className="geo-readings-item">
                                    <span className="geo-readings-item-title">{item.text}:</span>
                                    <span className="geo-readings-item-content">{item.value}</span>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </Card>
        </div>
    );
};

GeoReadings.propTypes = {
    bhome: PropTypes.shape(),
    isNavCalibrationInProgress: PropTypes.bool,
};

export default GeoReadings;
