import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Hive = forwardRef(({ size = 28, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    {...rest}
  >
    <path
      d="M4.39509 12.5738L5.6594 8.21417H22.4093L23.6736 12.5738H4.39509ZM15.1199 9.9406V9.07738H12.9488V9.9406H15.1199ZM4.39509 17.9362L5.6594 13.5853H22.4093L23.6736 17.9362H4.39509ZM15.1199 15.303V14.4398H12.9488V15.303H15.1199ZM6.41798 3H21.6507L25.0687 7.21144H3L6.41798 3ZM15.1199 20.6741V19.8109H12.9488V20.6741H15.1199ZM18.0147 24.406V23.3074H10.0452V24.406C10.0452 24.8594 9.67902 25.2256 9.22561 25.2256H8.44959C7.99618 25.2256 7.62125 24.8594 7.62125 24.406V23.3074H4.39509L5.6594 18.9477H22.4093L23.6736 23.3074H20.4474V24.406C20.4474 24.8594 20.0812 25.2256 19.6191 25.2256H18.8431C18.3896 25.2343 18.0147 24.8594 18.0147 24.406Z"
      fill="#202020"
    />
  </svg>
));

Hive.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Hive.displayName = 'Hive';

export default Hive;
