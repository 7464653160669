import storage from 'local-storage-fallback';
import jwtDecode from 'jwt-decode';

let KEYS = {
  USER: '',
  JWT_TOKEN: '',
  SOCKET_JWT_TOKEN: ''
};

export const init = (AUTH_KEYS) => {
  KEYS = AUTH_KEYS;
};

export const getJwtToken = () => storage.getItem(KEYS.JWT_TOKEN);
export const getSocketJwtToken = () => storage.getItem(KEYS.SOCKET_JWT_TOKEN);

export const getUser = () => {
  const user = storage.getItem(KEYS.USER);
  if (user) {
    return JSON.parse(user);
  }
  return user;
};

const setUser = (token) => {
  storage.setItem(KEYS.USER, JSON.stringify(jwtDecode(token)));
};

export const setJwtToken = (token) => {
  storage.setItem(KEYS.JWT_TOKEN, token);
  setUser(token);
};

export const setSocketJwtToken = (token) => {
  storage.setItem(KEYS.SOCKET_JWT_TOKEN, token);
};

export const clearUser = () => {
  storage.removeItem(KEYS.USER);
  storage.removeItem(KEYS.JWT_TOKEN);
  storage.removeItem(KEYS.SOCKET_JWT_TOKEN);
};
