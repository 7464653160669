import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOpenSshTunnel, setOpenSshTunnel, setCloseSshTunnel } from 'components/views/ActionsAndMonitoring/actions';
import TextField from '@beewise/text-field';
import TechButton from 'components/reusables/TechButton';
import { showToast } from '@beewise/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons';

const Ssh = ({ bhomeId }) => {
    const [port, setPort] = useState('');
    const dispatch = useDispatch();
    const ssh = useSelector(state => state.actionsMonitoring.ssh);

    const handleOpenSsh = useCallback(() => {
        setPort('');
        dispatch(setOpenSshTunnel(bhomeId, port, true));
    }, [dispatch, bhomeId, port]);

    const handleCloseSsh = useCallback(() => {
        dispatch(setCloseSshTunnel(bhomeId, ssh?.port, ssh?.id));
    }, [dispatch, bhomeId, ssh?.port, ssh?.id]);

    useEffect(() => {
        dispatch(fetchOpenSshTunnel(bhomeId));
    }, [bhomeId, dispatch]);

    const sshText = `ssh -p ${ssh?.port} beewise@localhost`;

    const handleCopy = useCallback(
        () => navigator.clipboard.writeText(sshText).then(() => showToast('Text copied')),
        [sshText]
    );

    const sshOptions = useMemo(
        () =>
            ssh?.port
                ? {
                      name: 'Close ssh',
                      handler: handleCloseSsh,
                      component: (
                          <div
                              className="ssh-info"
                              onClick={handleCopy}
                              role="button"
                              tabIndex={0}
                              onKeyDown={handleCopy}
                          >
                              <b>{sshText}</b>
                              <FontAwesomeIcon className="icon-copy" icon={faCopy} />
                          </div>
                      ),
                  }
                : {
                      name: 'Open ssh',
                      handler: handleOpenSsh,
                      component: <TextField value={port} onChange={setPort} size="small" />,
                  },
        [ssh?.port, handleCloseSsh, handleOpenSsh, port, sshText, handleCopy]
    );

    return (
        <div className="ssh">
            <TechButton onClick={sshOptions.handler} alwaysEnabled showToManufacturer>
                {sshOptions.name}
            </TechButton>
            {sshOptions.component}
        </div>
    );
};

Ssh.propTypes = {
    bhomeId: PropTypes.number,
};

export default Ssh;
