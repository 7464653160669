import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash-es';

const StatusRenderer = ({ value, reverse }) => {
    const isBad = useMemo(() => (reverse ? value || isString(value) : !value || isString(value)), [reverse, value]);
    const convertedValue = useMemo(() => {
        if (isString(value)) {
            return value;
        }
        return value ? 'Yes' : 'No';
    }, [value]);

    return (
        <div className="status-renderer">
            {!isBad && <div className="good" />}
            {isBad && <div className="bad" />}
            {convertedValue}
        </div>
    );
};

StatusRenderer.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    reverse: PropTypes.bool,
};

export default StatusRenderer;
