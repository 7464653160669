import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@beewise/icon';
import { ToastContainer, toast } from 'react-toastify';

import './Toast.scss';

const CloseButton = ({ closeToast, onButtonClick, buttonText }) => (
  <div className="beewise-toast-actions">
    {buttonText && (
      <div className="beewise-toast-interact" onClick={onButtonClick} role="presentation">
        {buttonText}
      </div>
    )}
    <Icon type="x" className="beewise-toast-close" onClick={closeToast} />
  </div>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func,
  buttonText: PropTypes.string
};

export const BeewiseToastContainer = ({ ...props }) => (
  <ToastContainer
    autoClose={6000}
    className="beewise-toast-container"
    position="top-center"
    draggable
    hideProgressBar
    pauseOnHover={false}
    bodyClassName="beewise-toast-body"
    closeButton={CloseButton}
    {...props}
  />
);

export const showToast = (text, options = {}) =>
  toast(text, {
    closeButton: ({ closeToast }) => (
      <CloseButton
        closeToast={closeToast}
        onButtonClick={options.onButtonClick}
        buttonText={options.buttonText}
      />
    ),
    className: `beewise-toast ${options.toastType || 'toast-message'}`,
    ...options
  });
