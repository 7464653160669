export const FETCH_PERMISSION = {
  default: 'FETCH_PERMISSION',
  success: 'FETCH_PERMISSION_SUCCESS',
  failed: 'FETCH_PERMISSION_FAILED'
};

export const FETCH_REFRESH_USER_TOKEN = {
  default: 'FETCH_REFRESH_USER_TOKEN',
  success: 'FETCH_REFRESH_USER_TOKEN_SUCCESS',
  failed: 'FETCH_REFRESH_USER_TOKEN_FAILED'
};
