import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchChangeBhomeLockedHives } from 'components/views/Hives/actions';
import { useDispatch } from 'react-redux';
import TechButton from 'components/reusables/TechButton';
import TextField from '@beewise/text-field';
import Modal from '@beewise/modal';
import { noop } from 'lodash-es';

const LockModal = ({ hiveToLock, setHiveToLock, bhomeId, resolver = noop }) => {
    const [reason, setReason] = useState('');
    const dispatch = useDispatch();

    const handleOnClose = () => {
        setReason('');
        setHiveToLock(null);
    };

    const handleChangeStatusClick = () => {
        dispatch(
            fetchChangeBhomeLockedHives(
                bhomeId,
                {
                    hiveIds: [hiveToLock?.hiveId],
                    status: !hiveToLock?.isLocked,
                    reason,
                },
                resolver
            )
        );
        handleOnClose();
    };

    return (
        <Modal isOpen={hiveToLock} header={`Hive ${hiveToLock?.isLocked ? 'release' : 'lock'}`} onClose={handleOnClose}>
            <TextField label="Reason" value={reason} onChange={setReason} size="small" />
            <TechButton enabledInAnyState disabled={!reason} onClick={handleChangeStatusClick}>
                Send
            </TechButton>
        </Modal>
    );
};

LockModal.propTypes = {
    hiveToLock: PropTypes.shape(),
    setHiveToLock: PropTypes.func.isRequired,
    bhomeId: PropTypes.number,
    resolver: PropTypes.func,
};

export default LockModal;
