import React, { useCallback, useRef, useEffect } from 'react';
import Button from '@beewise/button';
import PropTypes from 'prop-types';
import Hls from 'hls.js';

const config = {
    enableWorker: true,
    maxBufferLength: 0,
    liveBackBufferLength: 0,
    liveSyncDuration: 1,
    liveMaxLatencyDuration: 5,
    liveDurationInfinity: true,
    highBufferWatchdogPeriod: 1,
};

/* eslint-disable jsx-a11y/media-has-caption */
const Video = ({ url, className }) => {
    const playerRef = useRef();
    const hlsRef = useRef();

    useEffect(
        () => () => {
            playerRef.current.pause();
            hlsRef.current.stopLoad();
        },
        []
    );

    const controlRef = useCallback(
        ref => {
            if (ref) {
                playerRef.current = ref;
                if (Hls.isSupported()) {
                    const hls = new Hls(config);
                    hlsRef.current = hls;
                    hls.loadSource(url);
                    hls.attachMedia(ref);
                }
            }
        },
        [url]
    );

    const reload = useCallback(() => {
        playerRef.current.currentTime = 0;
    }, []);

    return (
        <div className={className}>
            <Button onClick={reload} className="button-reload">
                Reload
            </Button>
            <video preload="none" ref={controlRef} autoPlay controls />
        </div>
    );
};

Video.propTypes = {
    url: PropTypes.string,
    className: PropTypes.string,
};

export default Video;
