import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const HoneyDrop = forwardRef(({ width = 4, height = 6, color = '#DC7433', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 4 6"
    fill="none"
    {...rest}
  >
    <path d="M4 4C4 5.1 3.1 6 2 6C0.9 6 0 5.1 0 4C0 2.88 2 0 2 0C2 0 4 2.88 4 4Z" fill={color} />
  </svg>
));

HoneyDrop.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

export default HoneyDrop;
