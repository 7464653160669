import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { loading, useToggle } from '@beewise/react-utils';
import dayjs from 'dayjs';
import { SelectField } from '@beewise/select-field';
import { CSVLink } from 'react-csv';
import cx from 'classnames';
import Checkbox from '@beewise/checkbox-field';
import constants from 'appConstants';
import DatePicker from 'react-datepicker';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toggle from '@beewise/toggle';
import { generateTicks, getDataToDownload, getCsvHeaders } from './utils';
import ChartFramesPlot from './components/Chart';
import { fetchActivityStatistics, selectStatisticsBhomes, toggleShowAutoScans } from './actions';
import { FETCH_ACTIVITY_STATISTICS } from './actionTypes';
import { getFilteredStatistics } from './selectors';

const DATE_FORMAT = 'DD-MM-YYYY';

const Statistics = () => {
    const [isHarvesting, toggleHarvesting] = useToggle();
    const [showPending, toggleShowPending] = useToggle();
    const [showCanceled, toggleShowCanceled] = useToggle();
    const [maxDate, setMaxDate] = useState(dayjs.utc().toDate());
    const [from, setFrom] = useState(dayjs.utc().subtract(7, 'days').toDate());
    const [to, setTo] = useState(dayjs.utc().toDate());

    const dispatch = useDispatch();
    const statistics = useSelector(getFilteredStatistics);
    const selectedBhomes = useSelector(state => state.statistics.selectedBhomes, shallowEqual);
    const scanData = useSelector(state => state.statistics.data, shallowEqual);
    const showAutoScans = useSelector(state => state.statistics.showAutoScans);
    const bhomesOptions = useSelector(
        state =>
            state.app.bhomes
                .map(item => ({ value: item.id, label: item.id }))
                .sort((a, b) => Number(a.value) - Number(b.value)),
        shallowEqual
    );

    useEffect(() => {
        if (from && to) {
            dispatch(
                fetchActivityStatistics(
                    dayjs.utc(from).unix(),
                    dayjs.utc(to).add(1, 'day').unix(),
                    isHarvesting ? constants.COMMANDS.EXTRACT_HONEY : constants.COMMANDS.SCAN
                )
            );
        }
    }, [dispatch, from, to, isHarvesting]);

    const handleDateChange = useCallback((date, name) => {
        if (name === 'from') {
            setFrom(date);
            setMaxDate(dayjs.utc().toDate());
        } else {
            setTo(date);
        }
    }, []);

    const ticksAmount = useMemo(() => {
        if (statistics.length) {
            return statistics.length > 1
                ? dayjs(statistics[0].name).diff(dayjs(statistics[statistics.length - 1].name), 'days')
                : 1;
        }
        return 0;
    }, [statistics]);

    const handleToggleAutoScans = useCallback(() => dispatch(toggleShowAutoScans()), [dispatch]);

    const handleToggleSwitch = useCallback(
        checked => {
            if (showAutoScans && checked) {
                handleToggleAutoScans();
            }
            toggleHarvesting();
        },
        [handleToggleAutoScans, showAutoScans, toggleHarvesting]
    );

    const handleSelectChange = useCallback(value => dispatch(selectStatisticsBhomes(value)), [dispatch]);

    const dataToDownload = useMemo(
        () => getDataToDownload(scanData, selectedBhomes, isHarvesting),
        [scanData, selectedBhomes, isHarvesting]
    );

    const headers = useMemo(() => getCsvHeaders(isHarvesting), [isHarvesting]);

    return (
        <div className="statistics">
            <div className="statistics-content">
                <div className="statistics-range">
                    <div className="statistics-action">
                        <div className="statistics-sublabel">Scan</div>
                        <Toggle
                            name="statistics-command"
                            value={isHarvesting}
                            onChange={handleToggleSwitch}
                            className="statistics-switch"
                        />
                        <div className="statistics-sublabel">Harvest</div>
                    </div>
                    <CSVLink
                        className="csv-link"
                        data={dataToDownload}
                        headers={headers}
                        filename={`${isHarvesting ? 'harvest' : 'scan'}-data-${from}-${to}`}
                    >
                        Download as CSV
                    </CSVLink>
                    <Checkbox
                        className={cx('statistics-checkbox', {
                            hidden: isHarvesting,
                        })}
                        onChange={handleToggleAutoScans}
                        checked={showAutoScans}
                        label="Auto Scan(s)"
                    />
                    <Checkbox
                        className="statistics-checkbox"
                        onChange={toggleShowCanceled}
                        checked={showCanceled}
                        label="Show Canceled"
                    />
                    <Checkbox
                        className="statistics-checkbox"
                        onChange={toggleShowPending}
                        checked={showPending}
                        label="Show Pending"
                    />
                    <DatePicker
                        selected={from}
                        onChange={date => handleDateChange(date, 'from')}
                        maxDate={to || maxDate}
                        className="datetime"
                        name="from"
                        showIcon
                        icon={<FontAwesomeIcon icon={faCalendar} />}
                        selectsStart
                        startDate={from}
                        endDate={to}
                    />
                    <DatePicker
                        selected={to}
                        onChange={date => handleDateChange(date, 'to')}
                        maxDate={maxDate}
                        className="datetime"
                        name="to"
                        showIcon
                        icon={<FontAwesomeIcon icon={faCalendar} />}
                        selectsEnd
                        startDate={from}
                        endDate={to}
                        minDate={from}
                    />
                    <SelectField
                        value={selectedBhomes}
                        options={bhomesOptions}
                        onChange={handleSelectChange}
                        placeholder="Choose bhome(s)"
                        isMulti
                        search
                        size="small"
                    />
                </div>
                <ChartFramesPlot
                    data={statistics}
                    format={DATE_FORMAT}
                    xAxisOptions={{
                        domain: ['dataMin', 'dataMax'],
                        ticks: generateTicks(ticksAmount, 'day'),
                        minTickGap: 5,
                    }}
                    showPending={showPending}
                    showCanceled={showCanceled}
                />
            </div>
        </div>
    );
};

export default loading([FETCH_ACTIVITY_STATISTICS.default])(Statistics);
