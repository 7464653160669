export default settings => {
    if (settings?.data?.settings?.machine_specific) {
        return ['data.settings.machine_specific'];
    }

    return [
        'data.settings.robot_settings.X_CORRECTION',
        'data.settings.robot_settings.T_HOMING_CORRECTION',
        'data.settings.robot_settings.T_AXIS_POSITIONS.B',
        'data.settings.robot_settings.Y_DISTANCE_FROM_SLOT_CENTER_TO_EXTRACT_POSITION',
        'data.settings.centrifuge_settings.CENTRIFUGE_X_OFFSET_FROM_UNCAPPER',
        'data.settings.centrifuge_settings.CENTRIFUGE_Y_OFFSET_FROM_UNCAPPER',
        'data.settings.centrifuge_settings.SLOT_OFFSET_FROM_HOME',
        'data.settings.centrifuge_settings.UNCAPPER_X_OFFSET_FROM_NEAREST_SLOT',
        'data.settings.centrifuge_settings.UNCAPPER_Y_OFFSET_FROM_NEAREST_SLOT',
        'data.settings.centrifuge_settings.Z_POSITION_FOR_FRAME_GRIPPING_IN_CENTRIFUGE',
        'data.settings.centrifuge_settings.CENTRIFUGE_ENTRANCE_TO_FRAME_TOP_Y_OFFSET',
        'data.settings.centrifuge_settings.UNCAPPER_ENTRANCE_TO_FRAME_TOP_Y_OFFSET',
        'data.settings.hardware_features',
    ];
};
