import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@beewise/select-field';
import Button from '@beewise/button';
import { useDispatch } from 'react-redux';
import Checkbox from '@beewise/checkbox-field';
import { useToggle } from '@beewise/react-utils';
import { HIVES } from 'utils';
import { fetchCreatePlaceholderHives } from '../actions';

const grades = [
    {
        label: 'Strong',
        value: 'strong',
    },
    {
        label: 'Medium',
        value: 'medium',
    },
    {
        label: 'Weak',
        value: 'weak',
    },
];

const CreatePlaceholderHive = ({ bhomes }) => {
    const [selectedBhomes, setBhomes] = useState([]);
    const [selectedHives, setHives] = useState([]);
    const [selectedGrade, setGrade] = useState(null);
    const [allHives, toggleAllHives, , removeAllHives] = useToggle();
    const dispatch = useDispatch();

    const bhomesOptions = useMemo(
        () =>
            bhomes.map(bhome => ({
                label: bhome.id,
                value: bhome.id,
            })),
        [bhomes]
    );

    const handleCreateClick = useCallback(() => {
        if ((selectedHives?.length || allHives) && selectedGrade && selectedBhomes?.length) {
            const hivesToMap = allHives ? HIVES.map(item => item.value) : selectedHives;
            const bodyToSend = {
                hives: hivesToMap.map(hive => ({
                    index: hive,
                    grade: selectedGrade,
                })),
                bhomes: selectedBhomes,
            };
            dispatch(
                fetchCreatePlaceholderHives(bodyToSend, () => {
                    setBhomes([]);
                    setHives([]);
                    setGrade(null);
                    removeAllHives();
                })
            );
        }
    }, [allHives, dispatch, removeAllHives, selectedBhomes, selectedGrade, selectedHives]);

    return (
        <div className="create-placeholder-hive">
            <SelectField
                value={selectedBhomes}
                options={bhomesOptions}
                onChange={setBhomes}
                placeholder="Choose beehomes"
                isMulti
                search
                className="beehome-select"
                size="small"
            />
            <SelectField
                value={selectedHives}
                options={HIVES}
                onChange={setHives}
                placeholder="Choose hive"
                isMulti
                search
                disabled={allHives}
                size="small"
            />
            <Checkbox label="All hives" checked={allHives} onChange={toggleAllHives} />
            <SelectField
                value={selectedGrade}
                options={grades}
                onChange={setGrade}
                placeholder="Choose grade"
                size="small"
            />
            <Button
                className="btn-primary"
                onClick={handleCreateClick}
                disabled={(!selectedHives?.length && !allHives) || !selectedGrade || !selectedBhomes.length}
            >
                Create placeholder hives
            </Button>
        </div>
    );
};

CreatePlaceholderHive.propTypes = {
    bhomes: PropTypes.arrayOf(PropTypes.shape()),
};

export default CreatePlaceholderHive;
