import constants from 'appConstants';

export const stationOptions = [
    {
        label: 'A',
        value: 'a',
    },
    {
        label: 'B',
        value: 'b',
    },
];

export const calibrationsPack = [
    {
        type: constants.COMMANDS.MOVE_MOTOR,
        params: {
            axis: 'Z',
            pos: 1,
            skipEncoder: false,
            maxIrun: false,
            allowUnsafeTMove: false,
            moveType: 'move_motor_abs',
        },
    },
    {
        type: constants.COMMANDS.MOVE_MOTOR,
        params: {
            axis: 'X',
            pos: 150,
            skipEncoder: false,
            maxIrun: false,
            allowUnsafeTMove: false,
            moveType: 'move_motor_abs',
        },
    },
    {
        type: constants.COMMANDS.CALIBRATE_NAVIGATION,
        params: { station: constants.STATIONS.A },
    },
    {
        type: constants.COMMANDS.CALIBRATE_NAVIGATION,
        params: { station: constants.STATIONS.B },
    },
    {
        type: constants.COMMANDS.MAP_BEEHOME,
        params: {
            stations: [constants.STATIONS.A],
        },
    },
    {
        type: constants.COMMANDS.MAP_BEEHOME,
        params: {
            stations: [constants.STATIONS.B],
        },
    },
    {
        type: constants.COMMANDS.CALIBRATE_FHTR,
    },
    {
        type: constants.COMMANDS.CALIBRATE_SCAN,
        params: {
            frame: {
                frame_type: constants.FRAME_TYPES.COMB_FRAME,
                place: {
                    station: constants.STATIONS.A,
                    position: {
                        x: 350,
                    },
                },
            },
        },
    },
];
