import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@beewise/select-field';
import Checkbox from '@beewise/checkbox-field';
import { untuckOptions } from '../../../utils';

const ScanFrameParams = ({ setParams, params }) => {
    const toggleProp = useCallback(
        prop => () => {
            setParams(params => ({
                ...params,
                [prop]: !params?.[prop],
            }));
        },
        [setParams]
    );

    const handleUntuckChange = useCallback(
        value => {
            setParams(params => ({
                ...params,
                untuck_direction: value,
            }));
        },
        [setParams]
    );

    return (
        <div className="scan-frame-params">
            <Checkbox label="Shake" checked={params?.shake ?? false} onChange={toggleProp('shake')} />
            <Checkbox label="Count Bees" checked={params?.count_bees ?? false} onChange={toggleProp('count_bees')} />
            <SelectField
                value={params?.untuck_direction ?? null}
                options={untuckOptions}
                onChange={handleUntuckChange}
                placeholder="Untuck Direction"
                className="nowrap frame-type-select"
                size="small"
            />
        </div>
    );
};

ScanFrameParams.propTypes = {
    params: PropTypes.shape(),
    setParams: PropTypes.func.isRequired,
};

export default ScanFrameParams;
