import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Closed = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <g clipPath="url(#clip0)">
      <path d="M5.92896 7.75735L10.1716 12L5.92896 16.2426" />
      <path d="M18.071 16.2426L13.8284 12L18.071 7.75735" />
      <path d="M13.8285 12H22.7279" />
      <path d="M1.27205 12H10.1715" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0H24V24H0V0Z" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

Closed.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Closed.displayName = 'Closed';

export default Closed;
