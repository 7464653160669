import { FETCH_FRAME_BY_RFID, FETCH_FRAME_UPDATE, SWITCH_FRAME_TO_EMPTY_SLOT } from '../actionTypes';

const initialState = {
    frame: {},
};

const frames = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FRAME_BY_RFID.success:
        case FETCH_FRAME_UPDATE.success:
            return {
                ...state,
                frame: action.data.frame,
            };
        case SWITCH_FRAME_TO_EMPTY_SLOT:
            return {
                ...state,
                frame: {
                    slot: {
                        ...state?.frame?.slot,
                    },
                },
            };
        default:
            return state;
    }
};

export default frames;
