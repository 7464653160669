import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TechButton from 'components/reusables/TechButton';
import { useDispatch } from 'react-redux';
import constants from 'appConstants';
import { fetchSendTechnicianAction } from 'actions/app.actions';

const FeederPumpControl = ({ bhome }) => {
    const dispatch = useDispatch();

    const handlePumpControlClick = useCallback(
        direction => () => {
            dispatch(
                fetchSendTechnicianAction(bhome?.id, {
                    type: constants.COMMANDS.SYRUP_PUMP_CONTROL,
                    params: {
                        direction,
                    },
                })
            );
        },
        [bhome?.id, dispatch]
    );

    return (
        <>
            <TechButton className="syrup-pump-activate" showToManufacturer onClick={handlePumpControlClick(1)}>
                Activate Syrup Pump
            </TechButton>
            <TechButton showToManufacturer onClick={handlePumpControlClick(-1)}>
                Retract Syrup
            </TechButton>
        </>
    );
};

FeederPumpControl.propTypes = {
    bhome: PropTypes.shape(),
};

export default FeederPumpControl;
