import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { useDispatch } from 'react-redux';
import { fetchTestS3Data } from '../../actions';

const S3LinkRenderer = ({ value, data }) => {
    const dispatch = useDispatch();

    const handleGetS3Files = useCallback(() => {
        dispatch(fetchTestS3Data(data.bhome_id, data.id));
    }, [data?.bhome_id, data?.id, dispatch]);

    if (!value?.s3Files) {
        return 'No Data';
    }
    return (
        <Button className="s3-link-renderer" onClick={handleGetS3Files}>
            Get S3 Log
        </Button>
    );
};

S3LinkRenderer.propTypes = {
    value: PropTypes.shape({
        s3Files: PropTypes.string,
    }),
    data: PropTypes.shape(),
};

export default S3LinkRenderer;
