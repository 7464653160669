import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import Icon from '@beewise/icon';

const Actions = ({ actions }) => {
  if (isEmpty(actions)) {
    return null;
  }

  return (
    <section>
      <span>Please accept the following actions:</span>
      <div className="modal-actions-container">
        {actions.map((action, index) => (
          <div key={`${action.name}-${index}`} className="modal-action-container">
            <div className="modal-action-icon">
              <Icon type="new" />
            </div>
            <div className="modal-action-text-container">
              <div className="modal-action-name">
                <span>{action.name}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

Actions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  )
};

export default memo(Actions);
