import PropTypes from 'prop-types';
import { SIZES } from './utils';

export const SelectProps = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
  info: PropTypes.string,
  components: PropTypes.shape(),
  addNewOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }),
  disabled: PropTypes.bool,
  children: PropTypes.node
};
export const AsyncProps = {
  ...SelectProps,
  loadOptions: PropTypes.func.isRequired
};
