export const SIZES = {
  NORMAL: 'normal',
  SMALL: 'small',
  XSMALL: 'xsmall'
};

export const SIZE_VALUES = {
  [SIZES.NORMAL]: 54,
  [SIZES.SMALL]: 40,
  [SIZES.XSMALL]: 30
};
