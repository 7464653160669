import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getMaxHiveCapacity } from '@beewise/utils';
import { JsonEditor as Editor } from 'components/reusables/JsonEditor';
import dayjs from 'dayjs';
import { arrayOfObjectsShallowEqual, loading } from '@beewise/react-utils';
import { faSync } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchFramesLostQrid, fetchRfidScanHistory } from './actions';
import Grid from '../../reusables/Grid';
import { getHiveNumber } from '../Messages/utils';
import { getCurrentBhome } from '../../../selectors';
import { FETCH_FRAMES_LOST_QRID, FETCH_RFID_SCAN_HISTORY } from './actionTypes';

const disabledHivesOptions = {
    columnDefs: [
        {
            headerName: 'Hive',
            field: 'hiveIndex',
            valueFormatter: params => params.data.hiveIndex + 1,
        },
        {
            headerName: 'User Name',
            field: 'username',
        },
        {
            headerName: 'Sent At',
            field: 'sent_at',
            valueFormatter: params => new Date(params.data.sent_at).toLocaleString(),
            comparator: (valueA, valueB) => dayjs(valueA).unix() - dayjs(valueB).unix(),
        },
        {
            headerName: 'Reasons',
            field: 'reasons',
        },
    ],
};

const RfidScanHistory = () => {
    const dispatch = useDispatch();
    const bhome = useSelector(getCurrentBhome, shallowEqual);
    const history = useSelector(state => state.rfidScanHistory.history, shallowEqual);
    const framesLostQrid = useSelector(state => state.rfidScanHistory.frames, arrayOfObjectsShallowEqual);
    const jsonEditor = useRef();

    useEffect(() => {
        if (bhome.id) {
            dispatch(fetchRfidScanHistory(bhome.id));
            dispatch(fetchFramesLostQrid(bhome.id));
        }
    }, [dispatch, bhome?.id]);

    const columnDefs = useMemo(() => {
        const hiveCapacity = getMaxHiveCapacity(bhome);

        return [
            {
                headerName: 'RFID',
                field: 'rfid',
            },
            {
                headerName: 'Slot Index',
                field: 'slotIndex',
            },
            {
                headerName: 'Hive/Slot',
                field: 'slotIndex',
                valueGetter: params =>
                    `H:${getHiveNumber(params.data.slotIndex, hiveCapacity)} S:${
                        (params.data.slotIndex % hiveCapacity) + 1
                    }`,
            },
            {
                headerName: 'Reason',
                field: 'reason',
            },
        ];
    }, [bhome]);

    const lostRfidColumnDefs = useMemo(() => {
        const hiveCapacity = getMaxHiveCapacity(bhome);

        return [
            {
                headerName: 'Last Scan Date',
                field: 'updated_at',
                valueGetter: params => new Date(params.data.updated_at).toLocaleString(),
                minWidth: 180,
            },
            {
                headerName: 'Slot Index',
                field: 'slot',
            },
            {
                headerName: 'Hive/Slot',
                field: 'slot',
                valueGetter: params =>
                    `H:${getHiveNumber(params.data.slot, hiveCapacity)} S:${(params.data.slot % hiveCapacity) + 1}`,
            },
            {
                headerName: 'Repeats',
                field: 'repeats',
            },
            {
                headerName: 'Reason',
                field: 'reason',
                valueGetter: params => params?.data?.reason ?? 'Lost QRID',
            },
        ];
    }, [bhome]);

    const handleUpdate = useCallback(() => {
        dispatch(fetchRfidScanHistory(bhome.id));
    }, [bhome?.id, dispatch]);

    useEffect(() => {
        if (jsonEditor) {
            jsonEditor?.current?.jsonEditor?.set(history?.layout);
        }
    }, [history?.layout]);

    return (
        <div className="rfid-history">
            <FontAwesomeIcon className="icon-sync" icon={faSync} onClick={handleUpdate} />
            {!history && <div>No data</div>}
            {history?.created_at && (
                <div className="rfid-history-date">
                    Scan date:{' '}
                    <span className="rfid-history-bold">{new Date(history.created_at).toLocaleString()}</span>
                </div>
            )}
            {!!history?.disabled_hives?.length && (
                <>
                    <div className="rfid-history-disabled-hives-header">
                        Hive{history.disabled_hives.length > 1 ? 's' : ''} disabled by user:
                    </div>
                    <Grid
                        className="rfid-history-disabled-hives"
                        rowData={history.disabled_hives}
                        gridOptions={disabledHivesOptions}
                        hideSearch
                        hideDownload
                    />
                </>
            )}
            {!!history?.errors?.length && (
                <>
                    <div className="rfid-history-errors">
                        Frame{history.errors.length > 1 ? 's' : ''} moved to status error after latest RFID scan:
                    </div>
                    <Grid
                        className="rfid-history-errors"
                        rowData={history.errors}
                        columnDefs={columnDefs}
                        hideSearch
                        filePrefix="rfid-errors-"
                    />
                </>
            )}
            <div className="rfid-history-layout">
                {!!history?.layout?.length && (
                    <div className="rfid-history-layout-list">
                        <div className="rfid-history-layout">Data from machine:</div>
                        <Editor ref={jsonEditor} mode="text" value={history.layout} />
                    </div>
                )}
                {!!framesLostQrid?.length && (
                    <div className="rfid-history-layout-lost-qrid">
                        <div className="rfid-history-layout">Failed ID read:</div>
                        <Grid
                            className="rfid-history-lost-qrid"
                            rowData={framesLostQrid}
                            columnDefs={lostRfidColumnDefs}
                            hideSearch
                            hideDownload
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default loading([FETCH_RFID_SCAN_HISTORY.default, FETCH_FRAMES_LOST_QRID.default])(RfidScanHistory);
