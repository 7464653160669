import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ info, error }) =>
  (info || error) && (
    <p
      className={cn('select-field-info', {
        'select-field--error select-field--error-message': error
      })}
    >
      {error ||
        (Array.isArray(info)
          ? info.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))
          : info)}
    </p>
  );

Error.propTypes = {
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  error: PropTypes.string
};

export default Error;
