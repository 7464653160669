import dayjs from 'dayjs';
import DecodedRenderer from '../DecodedRenderer';

export const sequenceColDefs = [
    {
        headerName: 'Sequence ID',
        field: 'sequenceId',
        maxWidth: 300,
    },
    {
        headerName: 'Sequence Name',
        field: 'command',
        maxWidth: 170,
    },
    {
        headerName: 'Sequence Status',
        field: 'sequenceStatus',
        maxWidth: 170,
    },
    {
        headerName: 'Updated At',
        field: 'updatedAt',
        valueFormatter: params => (params.data.updatedAt ? new Date(params.data.updatedAt).toLocaleString() : '-'),
        comparator: (valueA, valueB) => dayjs(valueA).unix() - dayjs(valueB).unix(),
        maxWidth: 200,
    },
    {
        headerName: 'Sequence params',
        field: 'params',
        valueGetter: params => JSON.stringify(params.data.params),
        cellRenderer: DecodedRenderer,
        cellClass: 'no-border',
    },
];
