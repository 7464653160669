import {
    FETCH_FRAME_BY_RFID,
    FETCH_FRAME_UPDATE,
    FETCH_CREATE_FRAME,
    FETCH_LAYOUT_UPDATE,
    SWITCH_FRAME_TO_EMPTY_SLOT,
} from '../actionTypes';

export const fetchFrameByRfid = (id, rfid, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_FRAME_BY_RFID.default,
        url: `/bhomes/${id}/get-frame/${rfid}`,
        resolver,
    },
});

export const fetchUpdateFrame = (id, frameId, isEditingRfid, updateBody) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: FETCH_FRAME_UPDATE.default,
        url: `/bhomes/${id}/update-frame/${frameId}`,
        body: {
            updateBody,
            isEditingRfid,
        },
        toastText: 'Frame has been successfully updated',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
        failureToastText: 'Frame with this rfid already exists',
        failureToastOptions: {
            toastType: 'toast-error',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchUpdateLayout = ({ bhomeId, frameId, updateBody, resolver }) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: FETCH_LAYOUT_UPDATE.default,
        url: `/bhomes/${bhomeId}/update-layout/${frameId}`,
        body: {
            updateBody,
        },
        toastText: 'Layout has been successfully updated',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
        resolver,
    },
});

export const fetchCreateFrame = (id, fields, slotIndex, positionX, station, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: FETCH_CREATE_FRAME.default,
        url: `/bhomes/${id}/create-frame`,
        body: {
            fields,
            station,
            positionX,
            slotIndex,
        },
        resolver,
        toastText: `Frame has been successfully created and assigned to Beehome ${id}`,
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
        failureToastText: 'Frame with this rfid already exists',
        failureToastOptions: {
            toastType: 'toast-error',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const clearFrame = () => ({
    type: SWITCH_FRAME_TO_EMPTY_SLOT,
});
