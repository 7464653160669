import { isUndefined } from 'lodash-es';
import React from 'react';
import constants from 'appConstants';

export const secondsToHms = d => {
    d = Number(d);

    if (Number.isNaN(d)) {
        return 'Unknown';
    }

    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
    const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
    const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
    return hDisplay + mDisplay + sDisplay;
};

export const COMMANDS_LIST = [
    {
        command: constants.COMMANDS.INCUBATOR_OPEN,
        text: 'Open Incubator',
    },
    {
        command: constants.COMMANDS.INCUBATOR_CLOSE,
        text: 'Close Incubator',
    },
    {
        command: constants.COMMANDS.INCUBATOR_INSERT,
        text: 'Incubator Insert',
    },
    {
        command: constants.COMMANDS.INCUBATOR_EXTRACT,
        text: 'Incubator Extract',
    },
    {
        command: constants.COMMANDS.HEAT_TREATMENT_START_PROTOCOL,
        text: 'Start Heat Treatment Protocol',
        hasInput: true,
    },
    {
        command: constants.COMMANDS.HEAT_TREATMENT_STOP_PROTOCOL,
        text: 'Stop Heat Treatment Protocol',
    },
    {
        command: constants.COMMANDS.INCUBATOR_CHANGE_HEATER_STATE,
        text: 'Turn Heater On',
        params: {
            state: 'on',
        },
    },
    {
        command: constants.COMMANDS.INCUBATOR_CHANGE_HEATER_STATE,
        text: 'Turn Heater Off',
        params: {
            state: 'off',
        },
    },
];

export const getIncubatorStatus = robotStatus => {
    if (isUndefined(robotStatus?.incubator_heater)) {
        return null;
    }

    return [
        {
            name: 'Phase',
            value: robotStatus?.heat_treatment?.phase,
        },
        {
            name: 'Current Temperature',
            value: <>{robotStatus?.heat_treatment?.current_temperature}&#8451;</>,
        },
        {
            name: 'Target Temperature',
            value: <>{robotStatus?.heat_treatment?.target_temperature}&#8451;</>,
        },
        {
            name: 'Elapsed Time',
            value: secondsToHms(robotStatus?.heat_treatment?.elapsed_time),
        },
        {
            name: 'Fans State',
            value: JSON.stringify(robotStatus?.incubator_fan_ctrl),
        },
        {
            name: 'Heater State',
            value: robotStatus?.incubator_heater ? 'On' : 'Off',
        },
    ];
};
