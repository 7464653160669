import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import Icon from '@beewise/icon';
import { useToggle, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { getCurrentBhomeUsers } from 'selectors';

import './CurrentUsers.scss';

const CurrentUsers = () => {
    const currentUsers = useSelector(getCurrentBhomeUsers, arrayOfObjectsShallowEqual);
    const [isOpen, toggleOpen] = useToggle(false);

    const renderUsersList = useCallback(
        () =>
            currentUsers.map(user => (
                <div className="current-user" key={user.id}>
                    <div className="current-user-username">{user.username}</div>
                </div>
            )),
        [currentUsers]
    );

    return (
        <div className={cx('current-users', { open: isOpen })} onClick={toggleOpen} role="presentation">
            <div className="current-users-header">
                <div className="current-users-header-title">Current users ({currentUsers?.length})</div>
                <Icon type="chevron-down" />
            </div>
            <div className="current-users-body">{renderUsersList()}</div>
        </div>
    );
};

export default CurrentUsers;
