import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Crosshair = forwardRef(({ size = 22, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    {...rest}
  >
    <path
      d="M19.94 10C19.48 5.83 16.17 2.52 12 2.06V0H10V2.06C5.83 2.52 2.52 5.83 2.06 10H0V12H2.06C2.52 16.17 5.83 19.48 10 19.94V22H12V19.94C16.17 19.48 19.48 16.17 19.94 12H22V10H19.94ZM11 18C7.13 18 4 14.87 4 11C4 7.13 7.13 4 11 4C14.87 4 18 7.13 18 11C18 14.87 14.87 18 11 18Z"
      fill="#222222"
    />
  </svg>
));

Crosshair.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Crosshair.displayName = 'Crosshair';

export default Crosshair;
