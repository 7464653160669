import { FETCH_FORGOT_PASSWORD, FETCH_SIGNIN, SET_FORGOT_PASSWORD_ERROR } from '../actionTypes';

const signin = (
    state = {
        error: null,
        forgotPasswordError: null,
        forgotPasswordSent: false,
    },
    action
) => {
    switch (action.type) {
        case FETCH_SIGNIN.success:
            return {
                ...state,
                error: null,
                forgotPasswordError: null,
            };
        case FETCH_FORGOT_PASSWORD.success:
            return {
                ...state,
                forgotPasswordSent: true,
                forgotPasswordError: null,
            };
        case FETCH_SIGNIN.failed:
            return {
                ...state,
                error: action.error,
                forgotPasswordError: null,
            };
        case SET_FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                forgotPasswordError: action.error,
            };
        default:
            return state;
    }
};

export default signin;
