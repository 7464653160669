import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@beewise/text-field';
import { SelectField } from '@beewise/select-field';
import Button from '@beewise/button';

const CreateFrame = ({ currentBhome, handleFrameSave, slotStatusOptions, hives, frameOptions }) => {
    const [rfid, setRfid] = useState('');
    const [positionX, setPositionX] = useState('');
    const [status, setStatus] = useState('');
    const [frameType, setType] = useState(null);
    const [pickedHive, setPickedHive] = useState(null);
    const [pickedSlot, setPickedSlot] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setPositionX('');
    }, [currentBhome?.id]);

    const saveResolver = useCallback(data => {
        if (data.result) {
            setRfid('');
            setStatus('');
            setType(null);
            setPickedHive(null);
            setPickedSlot(null);
            setError(null);
            setPositionX(null);
        } else {
            setError('Frame with such rfid already exists');
        }
    }, []);

    const handleSetPositionX = useCallback(value => setPositionX(Number(value)), []);

    const isCreationDisabled = !rfid || !frameType || !status || !positionX || !pickedHive;

    return (
        <div className="create-frame">
            {error && <div className="create-error">{error}</div>}
            <TextField label="Rfid" className="rfid-input" value={rfid} onChange={setRfid} size="small" />
            <SelectField
                value={frameType}
                options={frameOptions}
                onChange={setType}
                placeholder="Choose type"
                size="small"
            />
            <SelectField
                value={status}
                options={slotStatusOptions}
                onChange={setStatus}
                placeholder="Choose status"
                size="small"
            />
            <SelectField
                value={pickedHive}
                options={hives}
                onChange={setPickedHive}
                placeholder="Choose Station"
                size="small"
            />
            <TextField
                label="Position X"
                className="position-input"
                value={positionX}
                onChange={handleSetPositionX}
                size="small"
            />
            <Button
                className="btn-primary"
                onClick={handleFrameSave({ rfid, frameType, status }, pickedHive, pickedSlot, positionX, saveResolver)}
                disabled={isCreationDisabled}
            >
                Create Frame
            </Button>
        </div>
    );
};

CreateFrame.propTypes = {
    currentBhome: PropTypes.shape(),
    slotStatusOptions: PropTypes.arrayOf(PropTypes.shape()),
    hives: PropTypes.arrayOf(PropTypes.shape()),
    frameOptions: PropTypes.arrayOf(PropTypes.shape()),
    handleFrameSave: PropTypes.func,
};

export default CreateFrame;
