import React from 'react';
import PropTypes from 'prop-types';

const ImageRenderer = ({ data, fileName }) => <img className="jenkins-image-result" src={data?.link} alt={fileName} />;

ImageRenderer.propTypes = {
    data: PropTypes.shape({
        link: PropTypes.string,
    }),
    fileName: PropTypes.string,
};

export default ImageRenderer;
