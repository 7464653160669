import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TechButton from 'components/reusables/TechButton';
import { useDispatch } from 'react-redux';
import Card from 'components/reusables/Card';
import constants from 'appConstants';
import { fetchSendTechnicianAction } from 'actions/app.actions';

const TestActions = ({ bhome }) => {
    const dispatch = useDispatch();

    const handleTestButtonClick = useCallback(
        type => () => {
            dispatch(
                fetchSendTechnicianAction(bhome.id, {
                    type,
                })
            );
        },
        [dispatch, bhome]
    );

    return (
        <div className="test-actions-center">
            <Card className="test-actions" title="Test Actions">
                <div className="test-actions-content">
                    <TechButton alwaysEnabled onClick={handleTestButtonClick(constants.COMMANDS.START_AIR)}>
                        Start Air
                    </TechButton>
                    <TechButton alwaysEnabled onClick={handleTestButtonClick(constants.COMMANDS.STOP_AIR)}>
                        Stop Air
                    </TechButton>
                </div>
            </Card>
        </div>
    );
};

TestActions.propTypes = {
    bhome: PropTypes.shape(),
};

export default TestActions;
