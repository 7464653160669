import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';

const ImageSlider = ({ images = [] }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div className="image-slider">
            <img src={images[activeIndex]} alt="Calibration" />
            <div className="slider-buttons">
                <Button
                    className="btn-primary"
                    disabled={activeIndex === 0}
                    onClick={() => setActiveIndex(activeIndex - 1)}
                >
                    Prev
                </Button>
                <Button
                    className="btn-primary"
                    disabled={activeIndex === images.length - 1}
                    onClick={() => setActiveIndex(activeIndex + 1)}
                >
                    Next
                </Button>
            </div>
        </div>
    );
};

export default ImageSlider;

ImageSlider.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
};
