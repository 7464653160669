import { createAsyncAction } from '@beewise/utils';

export const FETCH_SEND_TECHNICIAN_ACTION = createAsyncAction('FETCH_SEND_TECHNICIAN_ACTION');
export const MODIFY_MODE = createAsyncAction('MODIFY_MODE');
export const FETCH_UPDATE_BHOME_SOFTWARE = createAsyncAction('FETCH_UPDATE_BHOME_SOFTWARE');
export const FETCH_MOVE_FRAMES = createAsyncAction('FETCH_MOVE_FRAMES');
export const FETCH_CREATE_PLACEHOLDER_LAYOUT = createAsyncAction('FETCH_CREATE_PLACEHOLDER_LAYOUT');
export const FETCH_GET_AUTO_SCAN = createAsyncAction('FETCH_GET_AUTO_SCAN');
export const FETCH_SCAN = createAsyncAction('FETCH_SCAN');
export const FETCH_COUNT_BEES = createAsyncAction('FETCH_COUNT_BEES');
export const FETCH_INCUBATE = createAsyncAction('FETCH_INCUBATE');
export const FETCH_FEED = createAsyncAction('FETCH_FEED');
export const FETCH_CALIBRATE_CENTRIFUGE = createAsyncAction('FETCH_CALIBRATE_CENTRIFUGE');
export const FETCH_ROTATE_CENTRIFUGE_TO_SLOT = createAsyncAction('FETCH_ROTATE_CENTRIFUGE_TO_SLOT');
export const FETCH_EXTRACT_HONEY = createAsyncAction('FETCH_EXTRACT_HONEY');
export const FETCH_BE_CHANGE_STATE = createAsyncAction('FETCH_BE_CHANGE_STATE');
export const FETCH_BE_CHECK_STATE = createAsyncAction('FETCH_BE_CHECK_STATE');
export const FETCH_UPDATE_LIVE_STREAM = createAsyncAction('FETCH_UPDATE_LIVE_STREAM');
export const FETCH_SET_CAMERA_LIGHT = createAsyncAction('FETCH_SET_CAMERA_LIGHT');
export const FETCH_SET_LIGHTING_SETTINGS = createAsyncAction('FETCH_SET_LIGHTING_SETTINGS');
export const FETCH_SET_FAN_STATUS = createAsyncAction('FETCH_SET_FAN_STATUS');
export const FETCH_OPEN_SSH_TUNNEL = createAsyncAction('FETCH_OPEN_SSH_TUNNEL');
export const FETCH_REBOOT_DEVICE = createAsyncAction('FETCH_REBOOT_DEVICE');
export const FETCH_ACTIVITIES = createAsyncAction('FETCH_ACTIVITIES');
export const FETCH_FRAMES = createAsyncAction('FETCH_FRAMES');
export const FETCH_SET_OPEN_SSH_TUNNEL = createAsyncAction('FETCH_SET_OPEN_SSH_TUNNEL');
export const CLEAR_ROBOT_VERSIONS = 'CLEAR_ROBOT_VERSIONS';
export const CLEAR_DEVICES = 'CLEAR_DEVICES';
export const SET_SYSTEM_STATUSES = 'SET_SYSTEM_STATUSES';
export const TOGGLE_LIVE_STREAM = createAsyncAction('TOGGLE_LIVE_STREAM');
export const FETCH_SOFTWARE_BUNDLES = createAsyncAction('FETCH_SOFTWARE_BUNDLES');
export const FETCH_AUTO_FEATURES_STATE = createAsyncAction('FETCH_AUTO_FEATURES_STATE');
export const FETCH_DOWNLOAD_APPLY_SCALE_CALIBRATION = createAsyncAction('FETCH_DOWNLOAD_APPLY_SCALE_CALIBRATION');
export const FETCH_CAMERA_DEVICES = createAsyncAction('FETCH_CAMERA_DEVICES');
