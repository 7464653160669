import { FETCH_BHOME_CONNECTIVITY_MAP, FETCH_LATEST_BHOMES_ERRORS, FETCH_CONNECTIVITY_MAP_DATA } from '../actionTypes';

const monitoring = (
    state = {
        connectivity: [],
        errors: [],
        data: {},
    },
    action
) => {
    switch (action.type) {
        case FETCH_BHOME_CONNECTIVITY_MAP.success:
            return {
                ...state,
                connectivity: action.data.connectivity,
            };
        case FETCH_LATEST_BHOMES_ERRORS.success:
            return {
                ...state,
                errors: action.data.errors,
            };
        case FETCH_CONNECTIVITY_MAP_DATA.success:
            return {
                ...state,
                data: action.data.data,
            };
        default:
            return state;
    }
};

export default monitoring;
