import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@beewise/select-field';
import TextField from '@beewise/text-field';
import { stationOptions } from '../../Calibrations/utils';

const PartialFrameLayoutParams = ({ params, setParams }) => {
    const handleStationChange = useCallback(
        value => {
            setParams(params => ({
                ...params,
                station: value,
            }));
        },
        [setParams]
    );

    const handleXChange = useCallback(
        name => value => {
            setParams(params => ({
                ...params,
                [name]: Number(value),
            }));
        },
        [setParams]
    );

    return (
        <div className="partial-frame-layout-params">
            <SelectField
                value={params?.station ?? null}
                options={stationOptions}
                onChange={handleStationChange}
                placeholder="Choose station"
                size="small"
            />
            <TextField
                type="number"
                label="X Start"
                value={params.x_start}
                onChange={handleXChange('x_start')}
                size="small"
            />
            <TextField
                type="number"
                label="X End"
                value={params.x_end}
                onChange={handleXChange('x_end')}
                size="small"
            />
        </div>
    );
};

PartialFrameLayoutParams.propTypes = {
    params: PropTypes.shape(),
    setParams: PropTypes.func.isRequired,
};

export default PartialFrameLayoutParams;
