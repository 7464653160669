import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchPermission, fetchRefreshUserToken } from './actions';

const PermissionsProvider = ({
  app = '',
  children,
  refreshUserToken = false,
  showChildrenBeforeRefresh = true
}) => {
  const [showChildren, setShowChildren] = useState(showChildrenBeforeRefresh);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user, shallowEqual);

  useEffect(() => {
    if (user?.id) {
      dispatch(fetchPermission(app));
    }
  }, [user?.id]);

  useEffect(() => {
    if (user?.id && refreshUserToken) {
      dispatch(
        fetchRefreshUserToken(() => {
          setShowChildren(true);
        })
      );
    }
  }, [user?.id, refreshUserToken]);

  if (user?.id && !showChildren) {
    return null;
  }

  return children;
};

PermissionsProvider.propTypes = {
  app: PropTypes.string,
  children: PropTypes.node,
  refreshUserToken: PropTypes.bool,
  showChildrenBeforeRefresh: PropTypes.bool
};

export default PermissionsProvider;
