import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TechButton from 'components/reusables/TechButton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Card from 'components/reusables/Card';
import constants from 'appConstants';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import dayjs from 'dayjs';

const PowerDataCenter = ({ bhome }) => {
    const dispatch = useDispatch();
    const powerDataRow = useSelector(state => state.actionsMonitoring.powerData, shallowEqual);

    const handleGetPowerDataClick = useCallback(() => {
        dispatch(
            fetchSendTechnicianAction(bhome?.id, {
                type: constants.COMMANDS.GET_POWER_DATA,
            })
        );
    }, [dispatch, bhome?.id]);

    return (
        <div className="power-data-center">
            <Card className="power-data" title="Power Data">
                <div className="power-data-content">
                    <TechButton
                        className="power-data-get-button"
                        enabledInAnyState
                        showToManufacturer
                        onClick={handleGetPowerDataClick}
                    >
                        Get Power Data
                    </TechButton>
                    {powerDataRow && (
                        <div className="power-data-list">
                            {Object.keys(powerDataRow).map(key => {
                                let value;
                                if (key === 'timestamp' && powerDataRow[key]) {
                                    value = new Date(dayjs.unix(powerDataRow[key]).valueOf())?.toLocaleString?.();
                                } else if (key === 'charging_state' && powerDataRow[key]) {
                                    value = powerDataRow[key];
                                } else {
                                    value = powerDataRow[key]?.toFixed?.(1) ?? powerDataRow[key];
                                }
                                return (
                                    <div key={`power-data-row-${key}`} className="power-data-row">
                                        <span className="power-data-bold">{key}:</span> {value}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </Card>
        </div>
    );
};

PowerDataCenter.propTypes = {
    bhome: PropTypes.shape(),
};

export default PowerDataCenter;
