import constants from '@beewise/constants';
import {
  createMoveFrameCommand,
  createCloseHiveCommand,
  createHomeXCommand,
  getFrameData,
  calculateLayout
} from './utils';

/**
 * Generates a sequence of actions to contract the hive and hive layout with new coordinates.
 * @param {Object} param0 - Object containing movedFrames, settings, stations, and currentPartitionIndex.
 * @param {Array} param0.movedFrames - Array of frames that have been moved.
 * @param {Object} param0.settings - Settings for the hive operation.
 * @param {Object} param0.stations - Stations involved in the hive operation.
 * @param {number} param0.currentPartitionIndex - Index of the partition frame.
 * @param {boolean} param0.isFirstMove - Is first move of the frame.
 * @returns {Object} An object representing the contract hive command with its sequences and hive layout with new coordinates.
 */
export const getContractHiveActionWithLayout = ({
  movedFrames,
  settings,
  stations,
  currentPartitionIndex,
  isFirstMove
}) => {
  const contractHiveSequences = [];
  const partitionFrame = movedFrames[currentPartitionIndex];
  const defaultFrameWidth = constants.DEFAULT_FRAME_WIDTH.COMB_FRAME ?? 0;
  const defaultPartitionWidth = constants.DEFAULT_FRAME_WIDTH.PARTITION ?? 0;
  const untuckDistanceX = settings?.sequences?.untuck?.x_untuck_distance ?? 0;
  const untuckDirection = -1;
  const combWidth = settings?.physical_measurements?.comb_width ?? defaultFrameWidth;

  const preparedMovedFrames = movedFrames
    .toSpliced(currentPartitionIndex, movedFrames.length + 1)
    .sort(({ place: a }, { place: b }) => a.position.x - b.position.x);

  const inputFrameIndex = movedFrames.findIndex((frame) => frame.finalPositionX);

  preparedMovedFrames.forEach((frame, index) => {
    if (frame.initialFrameIndex === index) {
      return;
    }

    const initialPartitionPositionFrameX = partitionFrame.place.position.x;

    const homeXCommand = createHomeXCommand();

    const moveOutCommand = createMoveFrameCommand({
      frame: getFrameData({ frame, positionX: frame.place.position.x }),
      place: {
        station: constants.STATIONS.SYSTEM_SLOT,
        position: {
          x: stations?.system_slot?.x
        }
      },
      shake: true,
      extra_shake: true,
      untuckDirection
    });

    const partitionFrameNewPositionX =
      initialPartitionPositionFrameX + untuckDirection * untuckDistanceX;
    const closeHiveCommand = createCloseHiveCommand({
      frame: getFrameData({
        frame: partitionFrame,
        positionX: partitionFrameNewPositionX
      })
    });

    const movedFrameNewPosition =
      initialPartitionPositionFrameX - (defaultFrameWidth + defaultPartitionWidth) / 2;
    const moveBackCommand = createMoveFrameCommand({
      frame: getFrameData({
        frame,
        station: constants.STATIONS.SYSTEM_SLOT,
        positionX: stations?.system_slot?.x
      }),
      place: {
        station: frame.place.station,
        position: { x: movedFrameNewPosition }
      },
      shake: false,
      untuckDirection: 0
    });

    const sequences = isFirstMove ? [homeXCommand] : [];

    contractHiveSequences.push({
      command: constants.COMMANDS.CONTRACT_HIVE,
      params: {
        sequences: [...sequences, moveOutCommand, closeHiveCommand, homeXCommand, moveBackCommand]
      }
    });
  });

  return {
    actions: contractHiveSequences,
    layout: calculateLayout({
      movedFrames,
      combWidth,
      inputFrame: movedFrames[inputFrameIndex],
      inputFrameIndex
    })
  };
};
