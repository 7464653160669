import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import Card from 'components/reusables/Card';
import TechButton from 'components/reusables/TechButton';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import { SelectField } from '@beewise/select-field';
import TextField from '@beewise/text-field';
import { noop } from 'lodash-es';
import MapBeehome from './components/MapBeehome';
import CalibrationPackRunner from './components/CalibrationPackRunner';
import { fetchDownloadAndApplyScaleCalibration } from '../../actions';
import { stationOptions } from './utils';

import './Calibrations.scss';

const Calibrations = ({ bhome }) => {
    const [pickedStation, setPickedStation] = useState(null);
    const [pickedStation2, setPickedStation2] = useState(null);
    const [x, setX] = useState('350');
    const [emSerial, setEmSerial] = useState('EM-BW');
    const dispatch = useDispatch();

    const handleSendAction = useCallback(
        (body, resolver = noop) =>
            () => {
                dispatch(fetchSendTechnicianAction(bhome?.id, body, resolver));
            },
        [dispatch, bhome?.id]
    );

    const handleDownloadScaleCalibrationClick = () => {
        if (!emSerial) {
            return;
        }

        dispatch(fetchDownloadAndApplyScaleCalibration({ id: bhome?.id, serial: emSerial }));
    };

    return (
        <div className="calibrations-center">
            <Card className="calibrations" title="Calibrations">
                <div className="calibrations-item">
                    <h3>Frame Scale Calibrations:</h3>
                    <div className="calibrations-scale-download">
                        <TextField label="EM Serial" value={emSerial} onChange={setEmSerial} size="small" />
                        <TechButton
                            disabled={!emSerial}
                            onClick={handleDownloadScaleCalibrationClick}
                            showToManufacturer
                            enabledInReceptiveAndMaintenance
                        >
                            Download
                        </TechButton>
                    </div>
                </div>
                <div className="calibrations-item">
                    <h3>Vision Calibrations:</h3>
                    <div className="calibrations-line navigation-calibration">
                        <span className="calibrations-line-number">1.</span>
                        <SelectField
                            className="cameras-view-select"
                            value={pickedStation}
                            options={stationOptions}
                            onChange={setPickedStation}
                            placeholder="Choose stations"
                            size="small"
                        />
                        <TechButton
                            disabled={!pickedStation}
                            showToManufacturer
                            enabledInReceptiveAndMaintenance
                            onClick={handleSendAction({
                                type: constants.COMMANDS.CALIBRATE_NAVIGATION,
                                params: { station: pickedStation },
                            })}
                        >
                            Calibrate Navigation
                        </TechButton>
                    </div>
                    <div className="calibrations-line map-beehome">
                        <span className="calibrations-line-number">2.</span>
                        <MapBeehome bhome={bhome} />
                    </div>
                    <div className="calibrations-line fhtr-calibration">
                        <span className="calibrations-line-number">3.</span>
                        <TechButton
                            showToManufacturer
                            enabledInReceptiveAndMaintenance
                            onClick={handleSendAction({
                                type: constants.COMMANDS.CALIBRATE_FHTR,
                            })}
                        >
                            Calibrate Vision for Frame Hit The Rail
                        </TechButton>
                    </div>
                    <div className="calibrations-line scan-calibration">
                        <span className="calibrations-line-number">4.</span>
                        <div className="frame-info">
                            <SelectField
                                className="cameras-view-select"
                                value={pickedStation2}
                                options={stationOptions}
                                onChange={setPickedStation2}
                                placeholder="Choose station"
                                size="small"
                            />
                            <TextField label="X" value={x} onChange={setX} size="small" />
                            <TechButton
                                disabled={!x || !pickedStation2}
                                showToManufacturer
                                enabledInReceptiveAndMaintenance
                                onClick={handleSendAction({
                                    type: constants.COMMANDS.CALIBRATE_SCAN,
                                    params: {
                                        frame: {
                                            frame_type: constants.FRAME_TYPES.COMB_FRAME,
                                            place: {
                                                station: pickedStation2,
                                                position: {
                                                    x: Number(x),
                                                },
                                            },
                                        },
                                    },
                                })}
                            >
                                Calibrate Scan
                            </TechButton>
                        </div>
                    </div>
                    <CalibrationPackRunner bhome={bhome} />
                </div>
            </Card>
        </div>
    );
};

Calibrations.propTypes = {
    bhome: PropTypes.shape(),
};

export default Calibrations;
