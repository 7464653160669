import React from 'react';
import constants from 'appConstants';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import cx from 'classnames';
import Tooltip from '@beewise/tooltip';

const StationHive = ({ hive, stationEnd, stationStart, settings, setHiveToLock }) => {
    const adjustedXFrom =
        hive.from -
        stationStart +
        (settings?.physical_measurements?.partition_width ?? constants.DEFAULT_FRAME_WIDTH.PARTITION);
    const adjustedXTo =
        hive.to -
        stationStart -
        (settings?.physical_measurements?.static_partition_width ?? constants.DEFAULT_FRAME_WIDTH.STATIC_PARTITION);
    const left = `${(adjustedXFrom / (stationEnd - stationStart)) * 100}%`;
    const width = `${((adjustedXTo - adjustedXFrom) / (stationEnd - stationStart)) * 100}%`;

    const tooltipText = `Click to ${hive.isLocked ? 'release' : 'lock'} hive`;

    const handleClick = () => setHiveToLock(hive);

    return (
        <Tooltip text={tooltipText} position="bottom center" mouseEnterDelay={0} mouseLeaveDelay={0} hideOnScroll>
            <Button
                onClick={handleClick}
                className={cx('station-hive', { 'station-hive-locked': hive.isLocked })}
                style={{ left, width }}
            />
        </Tooltip>
    );
};

StationHive.propTypes = {
    hive: PropTypes.shape({
        from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        id: PropTypes.string,
        isLocked: PropTypes.bool,
    }).isRequired,
    stationEnd: PropTypes.number.isRequired,
    stationStart: PropTypes.number.isRequired,
    settings: PropTypes.shape(),
    setHiveToLock: PropTypes.func.isRequired,
};

export default StationHive;
