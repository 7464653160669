export const getTypeOptions = ({ isEMTester, isFLTester }) => {
    if (isEMTester) {
        return [
            {
                label: 'Tech',
                value: 'tech',
            },
        ];
    }

    if (isFLTester) {
        return [
            {
                label: 'Nav',
                value: 'nav',
            },
            {
                label: 'Tech',
                value: 'tech',
            },
            {
                label: 'Scan',
                value: 'scan',
            },
        ];
    }

    return [
        {
            label: 'Nav',
            value: 'nav',
        },
        {
            label: 'Tech',
            value: 'tech',
        },
        {
            label: 'Beehome',
            value: 'beehome',
        },
        {
            label: 'Scan',
            value: 'scan',
        },
    ];
};

export const colorOptions = type => {
    if (type === 'scan' || type === 'beehome') {
        return [
            {
                label: 'White',
                value: 'white',
            },
        ];
    }

    return [
        {
            label: 'Red',
            value: 'red',
        },
        {
            label: 'White',
            value: 'white',
        },
    ];
};

export const sideOptions = [
    {
        label: 'A',
        value: 'a',
    },
    {
        label: 'B',
        value: 'b',
    },
];

export const stateOptions = [
    {
        label: 'On',
        value: 1,
    },
    {
        label: 'Off',
        value: 0,
    },
];
