import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@beewise/select-field';
import { moveToVisitPositionOptions } from 'components/views/ActionsAndMonitoring/utils';

const MoveToVisitPositionParams = ({ params, setParams }) => {
    const updateParams = updateFn => {
        setParams(prevParams => ({
            ...prevParams,
            ...updateFn(prevParams || {}),
        }));
    };

    const handleAxisChange = value => {
        updateParams(() => ({
            position_to_move_to: value,
        }));
    };

    return (
        <SelectField
            value={params?.position_to_move_to ?? null}
            options={moveToVisitPositionOptions}
            onChange={handleAxisChange}
            className="nowrap"
            size="small"
        />
    );
};

MoveToVisitPositionParams.propTypes = {
    params: PropTypes.shape(),
    setParams: PropTypes.func.isRequired,
};

export default MoveToVisitPositionParams;
