import React from 'react';
import Icon from '@beewise/icon';

const EmptyChart = () => (
  <div className="empty-chart">
    <Icon type="chart-bar" />
    <div className="empty-chart-label">No data, yet</div>
  </div>
);

export default EmptyChart;
