import { createSelector } from 'reselect';
import { getUser } from 'components/reusables/AppHeader/selectors/bhome.ids.selector';
import { inRange } from 'lodash-es';
import { isLimitedTestUser, LIMITED_USERS_RANGE } from 'utils';

const getCurrentBhome = state => state.app.currentBhome;

const getErrors = state => state.errors.errors;

export const getFilteredErrors = createSelector(getCurrentBhome, getErrors, getUser, (bhome, errors, user) => {
    if (bhome?.id) {
        return errors.filter(error => error.bhome_id === bhome.id);
    }

    if (isLimitedTestUser(user)) {
        return errors.filter(error => inRange(error.bhome_id, LIMITED_USERS_RANGE.FROM, LIMITED_USERS_RANGE.TO));
    }

    return errors;
});
