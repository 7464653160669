import { GET_CURRENT_BHOME_RECEPTION } from '../actionTypes';

export const fetchIsBhomeOnline = (bhomeId, resolver) => ({
    type: 'FETCH',
    params: {
        type: GET_CURRENT_BHOME_RECEPTION.default,
        url: `/bhomes/${bhomeId}/is-online`,
        resolver,
        enableMemento: false,
    },
});
