import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@beewise/modal';
import Button from '@beewise/button';
import BhomeLayoutContent from './BhomeLayoutContent';

import './BhomeLayoutModal.scss';

const BhomeLayoutModal = ({ bhome, isOpen, handleOnClose, handleOnFrameClick, pickedFrames = [] }) => (
    <Modal
        className="bhome-layout-modal"
        header={`Beehome ${bhome?.id} Layout`}
        isOpen={isOpen}
        onClose={handleOnClose}
    >
        <BhomeLayoutContent handleOnFrameClick={handleOnFrameClick} bhome={bhome} pickedFrames={pickedFrames} />
        <Button className="bhome-layout-modal-done btn-primary" primary onClick={handleOnClose}>
            Done
        </Button>
    </Modal>
);

BhomeLayoutModal.propTypes = {
    bhome: PropTypes.shape(),
    isOpen: PropTypes.bool,
    handleOnClose: PropTypes.func,
    handleOnFrameClick: PropTypes.func,
    pickedFrames: PropTypes.arrayOf(PropTypes.shape()),
};

export default BhomeLayoutModal;
