import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const HexagonSmall = forwardRef(({ color = 'none', size, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="31"
    viewBox="0 0 36 31"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <path
      d="M35.3846 15.6923L26.7308 30.6812L9.42308 30.6812L0.769233 15.6923L9.42308 0.703403L26.7308 0.703404L35.3846 15.6923Z"
      fill="#FEBA12"
    />
  </svg>
));

HexagonSmall.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

HexagonSmall.displayName = 'HexagonSmall';

export default HexagonSmall;
