import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import Card from 'components/reusables/Card';
import constants from 'appConstants';
import TechButton from 'components/reusables/TechButton';
import { useToggle } from '@beewise/react-utils';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchableGrid from '@beewise/searchable-grid';
import { getAxisOptions, getSensorOptions, getSequenceOptions } from '../../utils';

const AxisSensor = ({ robotStatus, handleTechnicianActionClick }) => {
    const [isShown, toggleSensors] = useToggle();

    const hasLimitColumn = get(robotStatus, 'X1 motor.minSense', get(robotStatus, 'X motor.minSense', null));
    const columnsRunning = [
        {
            field: 'running',
            headerName: 'Running',
            headerClassName: 'header-disabled',
            cellClassName: 'left-column',
            width: 100,
            sortable: false,
        },
        {
            field: 'name',
            renderHeader: () => <span style={{ fontWeight: 'bold' }}>Name</span>,
            headerClassName: 'header-background',
            width: 300,
            sortable: false,
        },
    ];

    const dataRunning = getSequenceOptions(robotStatus).map((option, index) => ({
        id: `${option.status}-${option.value}-${index}`,
        running: option.value,
        name: option.status,
    }));

    const columnsAxis = [
        {
            field: 'axis',
            headerName: 'Axis',
            headerClassName: 'header-disabled',
            cellClassName: 'left-column',
            flex: 1,
            sortable: false,
        },
        {
            field: 'position',
            renderHeader: () => <span style={{ fontWeight: 'bold' }}>Pos mm</span>,
            headerClassName: 'header-background',
            flex: 1.25,
            sortable: false,
        },
        {
            field: 'encoder',
            renderHeader: () => <span style={{ fontWeight: 'bold' }}>Enc mm</span>,
            headerClassName: 'header-background',
            flex: 1.25,
            sortable: false,
        },
        {
            field: hasLimitColumn ? 'limit' : 'speed',
            renderHeader: () => (
                <span style={{ fontWeight: 'bold' }}>{hasLimitColumn ? 'Limit (on/off)' : 'Speed'}</span>
            ),
            headerClassName: 'header-background',
            flex: 1.25,
            sortable: false,
        },
        {
            field: 'status',
            renderHeader: () => <span style={{ fontWeight: 'bold' }}>Status</span>,
            headerClassName: 'header-background',
            flex: 1.25,
            sortable: false,
        },
    ];

    const dataAxis = getAxisOptions(robotStatus).map((option, index) => ({
        id: `${option.position}-${option.value}-${index}`,
        axis: option.value,
        position: option.position,
        encoder: option.encoder,
        limit: option[hasLimitColumn ? 'limit' : 'speed'],
        speed: option[hasLimitColumn ? 'limit' : 'speed'],
        status: option.status,
    }));

    const columnsSensor = [
        {
            field: 'sensor',
            headerName: 'Sensor',
            headerClassName: 'header-disabled',
            cellClassName: 'left-column',
            width: 150,
            sortable: false,
        },
        {
            field: 'status',
            renderHeader: () => <span style={{ fontWeight: 'bold' }}>State (on/off)</span>,
            headerClassName: 'header-background',
            width: 300,
            sortable: false,
        },
    ];

    const dataSensor = getSensorOptions(robotStatus).map((option, index) => ({
        id: `${option.status}-${option.value}-${index}`,
        sensor: option.value,
        status: option.status,
    }));

    return (
        <div className="axis-sensors-center">
            <Card className="axes" title="Axes & Sensors">
                <TechButton
                    className="axis-get-button btn-primary"
                    onClick={handleTechnicianActionClick(constants.COMMANDS.GET_ROBOT_STATUS)}
                    showToManufacturer
                    alwaysEnabled
                >
                    Get Status
                </TechButton>
                <SearchableGrid
                    rows={dataRunning}
                    columns={columnsRunning}
                    autoHeight
                    disableColumnMenu
                    sx={{
                        '& .MuiDataGrid-main': { fontFamily: 'Poppins', fontSize: '12px', lineHeight: '12px' },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontFamily: 'Poppins',
                            fontSize: '12px',
                            lineHeight: '12px',
                        },
                    }}
                    rowHeight={35}
                    columnHeaderHeight={40}
                />
                <SearchableGrid
                    rows={dataAxis}
                    columns={columnsAxis}
                    autoHeight
                    disableColumnMenu
                    sx={{
                        '& .MuiDataGrid-main': { fontFamily: 'Poppins', fontSize: '12px', lineHeight: '12px' },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontFamily: 'Poppins',
                            fontSize: '12px',
                            lineHeight: '12px',
                        },
                    }}
                    rowHeight={35}
                    columnHeaderHeight={40}
                />
                <div className="sensors-toggler" role="presentation" onClick={toggleSensors}>
                    <span className="sensors-toggler-text">Sensors</span>
                    <FontAwesomeIcon icon={isShown ? faChevronUp : faChevronDown} />
                </div>
                {isShown && (
                    <SearchableGrid
                        rows={dataSensor}
                        columns={columnsSensor}
                        autoHeight
                        disableColumnMenu
                        sx={{
                            '& .MuiDataGrid-main': { fontFamily: 'Poppins', fontSize: '12px', lineHeight: '12px' },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                lineHeight: '12px',
                            },
                        }}
                        rowHeight={35}
                        columnHeaderHeight={40}
                    />
                )}
            </Card>
        </div>
    );
};

AxisSensor.propTypes = {
    robotStatus: PropTypes.shape(),
    handleTechnicianActionClick: PropTypes.func,
};

export default AxisSensor;
