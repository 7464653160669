import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { get } from 'lodash-es';
import constants from 'appConstants';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentBhome } from 'selectors';
import Button from '@beewise/button';
import { usePermission } from '@beewise/react-utils';

const TechButton = ({
    className,
    alwaysEnabled = false,
    enabledInAnyState = false,
    enabledOnlyInMaintenance = false,
    enabledOnlyInReceptive = false,
    children,
    enabledInReceptiveAndMaintenance = false,
    enabledInNonReceptive = false,
    enabledInNonError = false,
    disabled,
    showToManufacturer = false,
    ...rest
}) => {
    const bhome = useSelector(getCurrentBhome, shallowEqual);
    const isManufacturer = usePermission('isManufacturer', 'technician');
    const areAllButtonsDisabled = useMemo(() => {
        if (enabledInAnyState) {
            return false;
        }
        if (enabledOnlyInReceptive) {
            return get(bhome, 'status', '') !== constants.BHOME_STATUSES.RECEPTIVE;
        }
        if (enabledOnlyInMaintenance) {
            return get(bhome, 'status', '') !== constants.BHOME_STATUSES.MAINTENANCE;
        }
        if (enabledInReceptiveAndMaintenance) {
            return (
                get(bhome, 'status', '') !== constants.BHOME_STATUSES.RECEPTIVE &&
                get(bhome, 'status', '') !== constants.BHOME_STATUSES.MAINTENANCE
            );
        }
        if (enabledInNonReceptive) {
            return get(bhome, 'status', '') === constants.BHOME_STATUSES.RECEPTIVE;
        }
        if (enabledInNonError) {
            return get(bhome, 'status', '') === constants.BHOME_STATUSES.ERROR;
        }

        return (
            get(bhome, 'status', '') === constants.BHOME_STATUSES.ERROR ||
            get(bhome, 'status', '') !== constants.BHOME_STATUSES.MAINTENANCE
        );
    }, [
        enabledInAnyState,
        enabledOnlyInReceptive,
        enabledOnlyInMaintenance,
        enabledInReceptiveAndMaintenance,
        enabledInNonReceptive,
        enabledInNonError,
        bhome,
    ]);

    if (isManufacturer && !showToManufacturer) {
        return null;
    }

    return (
        <Button
            className={cx('tech-button btn-primary', {
                [className]: !!className,
            })}
            {...rest}
            disabled={(areAllButtonsDisabled || disabled) && !alwaysEnabled}
        >
            {children}
        </Button>
    );
};

TechButton.propTypes = {
    alwaysEnabled: PropTypes.bool,
    enabledOnlyInReceptive: PropTypes.bool,
    enabledOnlyInMaintenance: PropTypes.bool,
    enabledInAnyState: PropTypes.bool,
    enabledInReceptiveAndMaintenance: PropTypes.bool,
    enabledInNonReceptive: PropTypes.bool,
    enabledInNonError: PropTypes.bool,
    disabled: PropTypes.bool,
    showToManufacturer: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default TechButton;
