import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import constants from 'appConstants';
import TechButton from 'components/reusables/TechButton';
import { fetchSendTechnicianAction } from 'actions/app.actions';

const ShipmentPosition = ({ bhome }) => {
    const dispatch = useDispatch();

    const handleTransportationPositionClick = useCallback(() => {
        dispatch(
            fetchSendTechnicianAction(bhome?.id, {
                type: constants.COMMANDS.MOVE_TO_TRANSPORTATION_POSITION,
            })
        );
    }, [bhome?.id, dispatch]);

    return (
        <TechButton showToManufacturer onClick={handleTransportationPositionClick}>
            Go To Transportation Position
        </TechButton>
    );
};

ShipmentPosition.propTypes = {
    bhome: PropTypes.shape(),
};

export default ShipmentPosition;
