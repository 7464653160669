import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Loading = forwardRef(({ size = 160, fill = '#FDBA12', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 160 160"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="preloader-big"
    {...rest}
  >
    <g id="logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M79.1626555,42.6194068 L33.5788232,42.6194068 C32.58375,42.6194068 31.6623859,43.1503448 31.1657267,44.0107099 L8.37381059,83.4262925 C7.87539647,84.2866575 7.87539647,85.3485337 8.37381059,86.210651 L31.1657267,125.624481 C31.6623859,126.486599 32.58375,127.017537 33.5788232,127.017537 L79.1626555,127.017537 C80.1594837,127.017537 81.0790929,126.486599 81.577507,125.624481 L104.369423,86.210651 C104.866082,85.3485337 104.866082,84.2866575 104.369423,83.4262925 L81.577507,44.0107099 C81.0790929,43.1503448 80.1594837,42.6194068 79.1626555,42.6194068 Z M74.884016,52.8036055 L93.3955376,84.8175956 L74.884016,116.831586 L37.8592178,116.831586 L19.3459412,84.8175956 L37.8592178,52.8036055 L74.884016,52.8036055 L74.884016,52.8036055 Z"
        id="big-big"
        fill={fill}
      />
      <path
        d="M66.8058463,63.9515532 L45.9373875,63.9515532 C44.9265194,63.9515532 43.9946254,64.4895004 43.4891913,65.3621313 L33.0558394,83.4070175 C32.5504054,84.2796484 32.5504054,85.3555428 33.0558394,86.229926 L43.4891913,104.27306 C43.9946254,105.145691 44.9265194,105.683638 45.9373875,105.683638 L66.8058463,105.683638 C67.8149594,105.683638 68.7486084,105.145691 69.2522874,104.27306 L79.6873943,86.229926 C80.1928284,85.3555428 80.1928284,84.2796484 79.6873943,83.4070175 L69.2522874,65.3621313 C68.7486084,64.4895004 67.8149594,63.9515532 66.8058463,63.9515532 Z M64.0996682,71.4530251 L71.826842,84.8175956 L64.0996682,98.1821661 L48.6435655,98.1821661 L40.9146367,84.8175956 L48.6435655,71.4530251 L64.0996682,71.4530251 L64.0996682,71.4530251 Z"
        id="big-small"
        fill={fill}
      />
      <path
        d="M136.954124,18 L111.319141,18 C110.520626,18 109.785289,18.4240496 109.386909,19.112692 L96.5702951,41.2789195 C96.1719148,41.967562 96.1719148,42.8174134 96.5702951,43.5043036 L109.386909,65.6722833 C109.785289,66.3609258 110.520626,66.7832231 111.319141,66.7832231 L136.954124,66.7832231 C137.749129,66.7832231 138.486221,66.3609258 138.884601,65.6722833 L151.701215,43.5043036 C152.099595,42.8174134 152.099595,41.967562 151.701215,41.2789195 L138.884601,19.112692 C138.486221,18.4240496 137.749129,18 136.954124,18 Z M133.359926,26.4389369 L142.584097,42.3916115 L133.359926,58.3442862 L114.911584,58.3442862 L105.687413,42.3916115 L114.911584,26.4389369 L133.359926,26.4389369 L133.359926,26.4389369 Z"
        id="medium-big"
        fill={fill}
      />
      <path
        d="M119.721982,50.795503 L128.549528,50.795503 C128.82506,50.795503 129.079532,50.6483122 129.218175,50.4100034 L133.631948,42.7771111 C133.768836,42.5388023 133.768836,42.2444208 133.631948,42.0061119 L129.218175,34.3732197 C129.079532,34.1349108 128.82506,33.9894724 128.549528,33.9894724 L119.721982,33.9894724 C119.44645,33.9894724 119.190223,34.1349108 119.053335,34.3732197 L114.639562,42.0061119 C114.502674,42.2444208 114.502674,42.5388023 114.639562,42.7771111 L119.053335,50.4100034 C119.190223,50.6483122 119.44645,50.795503 119.721982,50.795503"
        id="medium-small"
        fill={fill}
      />
      <path
        d="M106.394669,143 L122.426405,143 C123.184556,143 123.886548,142.596978 124.265623,141.939876 L132.280614,128.077661 C132.661444,127.420559 132.661444,126.61101 132.280614,125.957413 L124.265623,112.093445 C123.886548,111.438096 123.184556,111.033321 122.426405,111.033321 L106.394669,111.033321 C105.636518,111.033321 104.934526,111.438096 104.555451,112.093445 L96.5404605,125.957413 C96.15963,126.61101 96.15963,127.420559 96.5404605,128.077661 L104.555451,141.939876 C104.934526,142.596978 105.636518,143 106.394669,143"
        id="small"
        fill={fill}
      />
    </g>
  </svg>
));

Loading.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Loading.displayName = 'Loading';

export default Loading;
