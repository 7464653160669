import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@beewise/select-field';
import { gripperOptions } from 'components/views/ActionsAndMonitoring/utils';

const GripperActionParams = ({ params, setParams }) => {
    const handleGripperChange = useCallback(
        value => {
            setParams(params => ({
                ...params,
                action: value,
            }));
        },
        [setParams]
    );

    return (
        <SelectField
            value={params?.action ?? null}
            options={gripperOptions}
            onChange={handleGripperChange}
            placeholder="Select action"
            className="nowrap frame-type-select"
            size="small"
        />
    );
};

GripperActionParams.propTypes = {
    params: PropTypes.shape(),
    setParams: PropTypes.func.isRequired,
};

export default GripperActionParams;
