import constants from 'appConstants';
import {
    FETCH_SEND_TECHNICIAN_ACTION,
    FETCH_UPDATE_LIVE_STREAM,
    FETCH_ACTIVITIES,
    FETCH_SET_LIGHTING_SETTINGS,
    FETCH_SET_CAMERA_LIGHT,
    FETCH_CALIBRATE_CENTRIFUGE,
    FETCH_ROTATE_CENTRIFUGE_TO_SLOT,
    FETCH_EXTRACT_HONEY,
    FETCH_SET_FAN_STATUS,
    FETCH_OPEN_SSH_TUNNEL,
    FETCH_REBOOT_DEVICE,
    FETCH_SET_OPEN_SSH_TUNNEL,
    FETCH_FRAMES,
    FETCH_CAMERA_DEVICES,
    SET_SYSTEM_STATUSES,
    FETCH_CREATE_PLACEHOLDER_LAYOUT,
    FETCH_GET_AUTO_SCAN,
    FETCH_SCAN,
    CLEAR_ROBOT_VERSIONS,
    CLEAR_DEVICES,
    FETCH_MOVE_FRAMES,
    FETCH_BE_CHANGE_STATE,
    FETCH_BE_CHECK_STATE,
    TOGGLE_LIVE_STREAM,
    FETCH_UPDATE_BHOME_SOFTWARE,
    FETCH_FEED,
    FETCH_SOFTWARE_BUNDLES,
    FETCH_COUNT_BEES,
    FETCH_INCUBATE,
    FETCH_DOWNLOAD_APPLY_SCALE_CALIBRATION,
    FETCH_AUTO_FEATURES_STATE,
} from '../actionTypes';

export const fetchMoveBeehomeFrames = (body, beehomeId, options) => ({
    type: 'FETCH',
    params: {
        type: FETCH_MOVE_FRAMES.default,
        url: `/bhomes/${beehomeId}/move`,
        method: 'POST',
        body,
        payloadType: null,
        ...options,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchCreatePlaceholderLayout = (id, isTwoFamilies) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_PLACEHOLDER_LAYOUT.default,
        method: 'POST',
        url: `/bhomes/${id}/create-placeholder-layout?isTwoFamilies=${isTwoFamilies}`,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchGetAutoScan = (id, status) => ({
    type: 'FETCH',
    params: {
        type: FETCH_GET_AUTO_SCAN.default,
        method: 'POST',
        body: {
            status,
        },
        url: `/get-auto-scan/${id}`,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchScan = (beehomeId, slots, resolver, rejector) => ({
    type: 'FETCH',
    params: {
        type: FETCH_SCAN.default,
        url: `/bhomes/${beehomeId}/scan`,
        method: 'POST',
        payloadType: null,
        body: {
            slots,
            from: constants.MQ_MESSAGE_CREATORS.TECHNICIAN,
        },
        resolver,
        rejector,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchCountBees = (beehomeId, slots, resolver, rejector) => ({
    type: 'FETCH',
    params: {
        type: FETCH_COUNT_BEES.default,
        url: `/bhomes/v4/${beehomeId}/count-bees`,
        method: 'POST',
        payloadType: null,
        body: {
            slots,
            from: constants.MQ_MESSAGE_CREATORS.TECHNICIAN,
        },
        resolver,
        rejector,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchFeed = (beehomeId, feeders, resolver, rejector) => ({
    type: 'FETCH',
    params: {
        type: FETCH_FEED.default,
        url: `/bhomes/${beehomeId}/feed`,
        method: 'POST',
        payloadType: null,
        body: {
            feeders,
            from: constants.MQ_MESSAGE_CREATORS.TECHNICIAN,
        },
        resolver,
        rejector,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchIncubate = (beehomeId, slots, resolver, rejector) => ({
    type: 'FETCH',
    params: {
        type: FETCH_INCUBATE.default,
        url: `/bhomes/v4/${beehomeId}/pest-treatment`,
        method: 'POST',
        payloadType: null,
        body: {
            slots,
            from: constants.MQ_MESSAGE_CREATORS.BCLOUD,
        },
        resolver,
        rejector,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchUpdateLiveStream = (id, stream, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_LIVE_STREAM.default,
        method: 'PUT',
        url: `/bhomes/${id}/update-live-stream`,
        body: {
            stream,
        },
        resolver,
        toastText: 'Success',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchSendFullRfidScan = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_SEND_TECHNICIAN_ACTION.default,
        method: 'POST',
        url: `/bhomes/${id}/full-rfid-scan`,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchCalibrateCentrifuge = (id, quick) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CALIBRATE_CENTRIFUGE.default,
        method: 'POST',
        url: `/bhomes/${id}/calibrate-centrifuge`,
        body: {
            quick,
        },
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchHomeCentrifuge = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_CALIBRATE_CENTRIFUGE.default,
        method: 'POST',
        url: `/bhomes/${id}/home-centrifuge`,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchRotateCentrifugeToSlot = (id, slotIndex, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_ROTATE_CENTRIFUGE_TO_SLOT.default,
        method: 'POST',
        body: {
            params: {
                slotIndex,
            },
        },
        url: `/bhomes/${id}/rotate-centrifuge-to-slot`,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
        resolver,
    },
});

export const fetchExtractHoney = (id, slots, emptySlot, noBgate = false, noUncapping = false, resolver, rejector) => ({
    type: 'FETCH',
    params: {
        type: FETCH_EXTRACT_HONEY.default,
        method: 'POST',
        body: {
            slots,
            emptySlot,
            noBgate,
            noUncapping,
            isTechnician: true,
        },
        url: `/bhomes/${id}/harvest`,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
        rejector,
        resolver,
    },
});

export const fetchChangeEntrances = (id, entrances, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_BE_CHANGE_STATE.default,
        method: 'PUT',
        body: {
            entrances,
            from: constants.MQ_MESSAGE_CREATORS.TECHNICIAN,
        },
        url: `/bhomes/${id}/entrances`,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
        resolver,
    },
});

export const fetchCheckEntrances = (id, entrances, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_BE_CHECK_STATE.default,
        method: 'POST',
        body: {
            entrances,
            from: constants.MQ_MESSAGE_CREATORS.TECHNICIAN,
        },
        url: `/bhomes/${id}/entrances/check-state`,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
        resolver,
    },
});

export const fetchActivities = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_ACTIVITIES.default,
        url: `/messages/${bhomeId}`,
    },
});

export const setCameraLight = (bhomeId, camera, status) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: FETCH_SET_CAMERA_LIGHT.default,
        url: `/bhomes/${bhomeId}/set-camera-light`,
        body: {
            camera,
            status,
        },
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const setLightingSettings = (bhomeId, status) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: FETCH_SET_LIGHTING_SETTINGS.default,
        url: `/bhomes/${bhomeId}/switch-light`,
        body: {
            status,
        },
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const setFanStatus = (bhomeId, fan, status) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: FETCH_SET_FAN_STATUS.default,
        url: `/bhomes/${bhomeId}/set-fan-status`,
        body: {
            fan,
            status,
        },
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const toggleLiveVideo = (id, body, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: TOGGLE_LIVE_STREAM.default,
        url: `/stream/live-video/${id}`,
        body,
        resolver,
        toastText:
            body?.status?.toLowerCase() !== 'off'
                ? `Trying to switch on ${body?.cameraType} ${body?.cameraId} video for Beehome ${id}`
                : `Switching off ${body?.cameraType} ${body?.cameraId} stream`,
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchUpdateBhomeSoftware = (body, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_BHOME_SOFTWARE.default,
        method: 'PUT',
        url: '/bhomes/update-software',
        body,
        toastText: 'Success!',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
        resolver,
    },
});

export const setOpenSshTunnel = (bhomeId, port) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: FETCH_SET_OPEN_SSH_TUNNEL.default,
        url: `/bhomes/${bhomeId}/open-ssh`,
        body: {
            port,
        },
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const setCloseSshTunnel = (bhomeId, port, id) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: FETCH_SET_OPEN_SSH_TUNNEL.default,
        url: `/bhomes/${bhomeId}/close-ssh`,
        body: {
            port,
            id,
        },
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchRebootDevice = (bhomeId, type) => ({
    type: 'FETCH',
    params: {
        type: FETCH_REBOOT_DEVICE.default,
        url: `/bhomes/${bhomeId}/reboot`,
        method: 'POST',
        body: {
            type,
        },
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchOpenSshTunnel = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_OPEN_SSH_TUNNEL.default,
        url: `/bhomes/${bhomeId}/ssh`,
    },
});

export const fetchFrames = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_FRAMES.default,
        url: `/bhomes/${bhomeId}/frames?photos=false`,
    },
});

export const fetchCameraDevices = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_CAMERA_DEVICES.default,
        url: `/bhomes/${bhomeId}/get-all-camera-devices`,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchSoftwareBundles = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_SOFTWARE_BUNDLES.default,
        url: '/bhomes/software-bundles',
    },
});

export const fetchAutoFeaturesState = ({ id }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_AUTO_FEATURES_STATE.default,
        url: `/messages/v4/${id}/auto-features`,
    },
});

export const fetchDownloadAndApplyScaleCalibration = ({ id, serial }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_DOWNLOAD_APPLY_SCALE_CALIBRATION.default,
        url: `/bhomes/v4/${id}/calibrations/frame-scale/apply`,
        method: 'POST',
        body: {
            serial,
        },
        failureToastText: 'Failed to download scale calibration',
        failureToastOptions: {
            toastType: 'toast-error',
            position: 'bottom-center',
            buttonText: null,
        },
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const setSystemStatuses = statuses => ({
    type: SET_SYSTEM_STATUSES,
    statuses,
});

export const clearRobotVersions = () => ({
    type: CLEAR_ROBOT_VERSIONS,
});

export const clearDevices = () => ({
    type: CLEAR_DEVICES,
});
