import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import Card from 'components/reusables/Card';
import { SelectField } from '@beewise/select-field';
import TechButton from 'components/reusables/TechButton';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import { getVentsOptions } from './utils';

import './VentsCenter.scss';

const VentsCenter = ({ bhome, isECTester }) => {
    const [pickedGroups, setPickedGroups] = useState([]);
    const dispatch = useDispatch();

    const handleSendVentsClick = useCallback(
        state => () => {
            dispatch(
                fetchSendTechnicianAction(
                    bhome?.id,
                    {
                        type: constants.COMMANDS.CHANGE_FANS_STATE,
                        params: {
                            state,
                            groups: pickedGroups,
                        },
                    },
                    () => {
                        setPickedGroups([]);
                    }
                )
            );
        },
        [dispatch, bhome?.id, pickedGroups]
    );

    const ventGroupsOptions = getVentsOptions(isECTester);

    return (
        <div className="vents-center">
            <Card className="vents" title="Vents">
                <SelectField
                    className="vents-center"
                    value={pickedGroups}
                    options={ventGroupsOptions}
                    onChange={setPickedGroups}
                    isMulti
                    placeholder="Choose vents"
                    size="small"
                />
                <TechButton
                    disabled={!pickedGroups?.length}
                    showToManufacturer
                    enabledOnlyInMaintenance
                    onClick={handleSendVentsClick('on')}
                >
                    Start Fans
                </TechButton>
                <TechButton
                    disabled={!pickedGroups?.length}
                    showToManufacturer
                    enabledOnlyInMaintenance
                    onClick={handleSendVentsClick('off')}
                >
                    Stop Fans
                </TechButton>
            </Card>
        </div>
    );
};

VentsCenter.propTypes = {
    bhome: PropTypes.shape(),
    isECTester: PropTypes.bool,
};

export default VentsCenter;
