import React, { useState } from 'react';
import { UI_ENV } from 'config';
import { useDispatch } from 'react-redux';
import { usePermission } from '@beewise/react-utils';
import Button from '@beewise/button';
import { fetchDownloadFatImg } from 'actions/app.actions';

const MasterImageDownloader = () => {
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const isQcUser = usePermission('isQc', 'technician');

    const handleDownloadClick = () => {
        dispatch(
            fetchDownloadFatImg(data => {
                const { link } = data;
                if (!link) {
                    setError('Failed to download FAT image');
                    return;
                }
                setError(null);
                const a = document.createElement('a');
                a.href = link;
                a.click();
            })
        );
    };

    if (UI_ENV !== 'stage' || !isQcUser) {
        return null;
    }

    return (
        <div className="fat-image-downloader">
            <Button className="btn-primary" onClick={handleDownloadClick}>
                Download FAT image
            </Button>
            {error && <div className="error">{error}</div>}
        </div>
    );
};

export default MasterImageDownloader;
