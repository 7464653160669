import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TechButton from 'components/reusables/TechButton';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/reusables/Card';
import Checkbox from '@beewise/checkbox-field';
import { arrayOfObjectsShallowEqual, useToggle } from '@beewise/react-utils';
import { SelectField } from '@beewise/select-field';
import { fetchSoftwareBundles, fetchUpdateBhomeSoftware } from '../../actions';

const SoftwareUpdateCenter = ({ bhome }) => {
    const [version, setVersion] = useState(null);
    const [rebootAfterUpdate, toggleRebootAfterUpdate] = useToggle(false);
    const [enforceImmediateAction, toggleEnforceImmediateAction] = useToggle(false);
    const dispatch = useDispatch();
    const bundles = useSelector(state => state.actionsMonitoring.softwareBundles, arrayOfObjectsShallowEqual);

    useEffect(() => {
        dispatch(fetchSoftwareBundles());
    }, [dispatch, bhome?.id]);

    const bundleOptions = bundles.map(bundleRecord => ({
        value: bundleRecord.id,
        label: bundleRecord?.bundle?.versionNumber,
    }));

    const handleSendUpdateClick = useCallback(() => {
        dispatch(
            fetchUpdateBhomeSoftware({
                ids: [bhome?.id],
                bundleId: version,
                reboot_after_update: rebootAfterUpdate,
                enforceImmediateAction,
            })
        );
    }, [dispatch, bhome?.id, version, rebootAfterUpdate, enforceImmediateAction]);

    return (
        <div className="software-update-center">
            <Card className="software-update" title="Software Update">
                <div className="software-update-content">
                    <SelectField
                        value={version}
                        options={bundleOptions}
                        onChange={setVersion}
                        placeholder="Software bundle"
                        search
                        size="small"
                    />
                    <TechButton
                        disabled={!version}
                        enabledInReceptiveAndMaintenance
                        showToManufacturer
                        onClick={handleSendUpdateClick}
                    >
                        Update
                    </TechButton>
                    <div className="software-update-checkboxes">
                        <Checkbox
                            className="software-reboot-update"
                            label="Reboot after update"
                            disabled={!version}
                            checked={rebootAfterUpdate}
                            onChange={toggleRebootAfterUpdate}
                        />
                        <Checkbox
                            className="software-immediate-update"
                            label="Enforce Immediate"
                            disabled={!version}
                            checked={enforceImmediateAction}
                            onChange={toggleEnforceImmediateAction}
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};

SoftwareUpdateCenter.propTypes = {
    bhome: PropTypes.shape(),
};

export default SoftwareUpdateCenter;
