import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Card from 'components/reusables/Card';
import Button from '@beewise/button';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import TextField from '@beewise/text-field';
import { COMMANDS_LIST, getIncubatorStatus } from './utils';

const IncubatorCenter = ({ bhome, robotStatus }) => {
    const [frameId, setFrameId] = useState('');
    const dispatch = useDispatch();

    const handleSendIncubatorCommand = useCallback(
        (command, params = {}) =>
            () => {
                dispatch(fetchSendTechnicianAction(bhome.id, { type: command, params }));
            },
        [bhome.id, dispatch]
    );
    return (
        <div className="incubator-center">
            <Card className="incubator" title="Incubator">
                <div className="incubator-center-content">
                    <div className="incubator-center-buttons">
                        {COMMANDS_LIST.map(({ command, text, params, hasInput }) => (
                            <React.Fragment key={`incubator-command-${command}-${text}`}>
                                {hasInput && (
                                    <TextField
                                        className="incubator-center-frame-id-input"
                                        label="Frame Id"
                                        value={frameId}
                                        onChange={setFrameId}
                                        size="small"
                                    />
                                )}
                                <Button
                                    className="btn-primary"
                                    onClick={handleSendIncubatorCommand(command, {
                                        ...params,
                                        ...(hasInput ? { frame_id: frameId } : {}),
                                    })}
                                >
                                    {text}
                                </Button>
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="incubator-center-status-list">
                        {getIncubatorStatus(robotStatus)?.map(({ name, value }) => (
                            <div key={`incubator-status-${name}`} className="incubator-center-status-item">
                                <div className="incubator-center-status-header">{name}:</div>
                                <div className="incubator-center-status-value">{value}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </Card>
        </div>
    );
};

IncubatorCenter.propTypes = {
    bhome: PropTypes.shape(),
    robotStatus: PropTypes.shape(),
};

export default IncubatorCenter;
