import { FETCH_CALIBRATIONS_DATA } from '../actionTypes';

export const fetchCalibrationsData = id => ({
    type: 'FETCH',
    params: {
        method: 'GET',
        type: FETCH_CALIBRATIONS_DATA.default,
        url: `/bhomes/${id}/calibrations`,
    },
});
