import { BHOME_STATUSES } from '@beewise/constants';

const availableTypes = [
    BHOME_STATUSES.RECEPTIVE,
    BHOME_STATUSES.MAINTENANCE,
    BHOME_STATUSES.INITIALIZING,
    BHOME_STATUSES.ERROR,
];

const statusOptions = Object.keys(availableTypes).map(key => ({
    label: availableTypes[key],
    value: availableTypes[key],
}));

export default statusOptions;
