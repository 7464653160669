import {
    FETCH_ALL_BHOMES_ERRORS,
    FETCH_LATEST_BHOME_ERROR,
    CLEAR_LATEST_BHOME_ERROR,
    FETCH_UPDATE_BHOME_ERROR,
    FETCH_LATEST_BHOME_ALERT,
    CLEAR_LATEST_BHOME_ALERT,
} from '../actionTypes';

export const fetchAllBhomesErrors = (from, to) => ({
    type: 'FETCH',
    params: {
        type: FETCH_ALL_BHOMES_ERRORS.default,
        url: `/bhomes/errors?from=${from}&to=${to}`,
    },
});

export const fetchLatestBhomesError = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_LATEST_BHOME_ERROR.default,
        url: `/bhomes/latest-error?id=${bhomeId}`,
    },
});

export const fetchLatestBhomesAlert = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_LATEST_BHOME_ALERT.default,
        url: `/bhomes/latest-alert?id=${bhomeId}`,
    },
});

export const fetchUpdateBhomeError = (id, fixed) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: FETCH_UPDATE_BHOME_ERROR.default,
        url: `/bhomes/error/${id}`,
        body: {
            fixed,
        },
    },
});

export const clearLatestBhomeError = () => ({
    type: CLEAR_LATEST_BHOME_ERROR,
});

export const clearLatestBhomeAlert = () => ({
    type: CLEAR_LATEST_BHOME_ALERT,
});
