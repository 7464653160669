export const getDaysOptions = status =>
    status?.days_of_week
        ? Object.keys(status?.days_of_week)?.map(key => ({
              label: key,
              value: key,
          }))
        : [];

export const getDaysValue = status =>
    status?.days_of_week ? Object.keys(status?.days_of_week)?.filter(item => status?.days_of_week?.[item]) : [];

export const AUTO_SCAN_TYPES = [
    { value: 'beeCount only', label: 'Bee Count Only' },
    { value: 'full', label: 'Full' },
];
