import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Syrup = forwardRef(({ color = 'currentColor', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <g clipPath="url(#clip0_286_4927)">
      <path
        d="M11.8334 9.70736L10.7094 9.53444C10.0201 9.42839 9.31713 9.44893 8.63514 9.59507C7.77868 9.7786 6.89156 9.76357 6.04181 9.55113L5.33337 9.37402"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.05343 3.1897L7.05342 5.88794L2.78743 13.2337C2.64032 13.487 2.56257 13.7746 2.56201 14.0675C2.56146 14.3604 2.63811 14.6483 2.78424 14.9022C2.93038 15.1561 3.14084 15.3669 3.39443 15.5135C3.64801 15.6602 3.93576 15.7374 4.22868 15.7374H12.4382C12.7311 15.7374 13.0189 15.6602 13.2724 15.5135C13.526 15.3669 13.7365 15.1561 13.8826 14.9022C14.0288 14.6483 14.1054 14.3604 14.1048 14.0675C14.1043 13.7746 14.0265 13.487 13.8794 13.2337L9.61343 5.88794L9.61343 3.1897"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33337 3.1897H10.3334"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33337 2.07953V1.04053H9.33337V2.07953"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_286_4927">
        <rect width="16" height="16" fill="white" transform="translate(0.333374 0.707275)" />
      </clipPath>
    </defs>
  </svg>
));

Syrup.propTypes = {
  color: PropTypes.string
};

Syrup.displayName = 'Syrup';

export default Syrup;
