import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Arrows = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 512 512"
    fill={color}
    {...rest}
  >
    <path
      d="M512.1,256c0-5.4-2.5-10.3-6.5-13.4l-84-84c-6.7-6.7-17.5-6.7-24.1,0c-6.6,6.7-6.6,17.5,0,24.1l56.2,56.2H273.1V58.3
	l56.2,56.2c6.7,6.7,17.5,6.7,24.1,0c6.7-6.7,6.7-17.5,0-24.1L268.1,5c-4.7-4.7-11.3-6.1-17.2-4.2c0,0,0,0,0,0
	c-0.5,0.2-1,0.3-1.5,0.5c-0.4,0.1-0.7,0.3-1,0.5c-0.1,0.1-0.2,0.1-0.3,0.2c-1.9,1-3.6,2.4-5.1,4l-84.3,84.3
	c-6.7,6.7-6.7,17.5,0,24.1c6.6,6.7,17.4,6.7,24,0.1l56.2-56.2v180.6H58.3l56.2-56.2c6.7-6.7,6.7-17.5,0-24.1
	c-6.7-6.7-17.5-6.7-24.2,0L5,243.9c-4.7,4.7-6.1,11.3-4.2,17.2c0,0,0,0,0,0c0.2,0.5,0.3,1,0.5,1.5c0.1,0.4,0.3,0.7,0.5,1
	c0.1,0.1,0.1,0.2,0.2,0.3c1,1.9,2.4,3.6,4,5.1l84.3,84.3c6.7,6.7,17.5,6.7,24.1,0c6.7-6.7,6.7-17.5,0-24.1l-56.1-56.1h180.6v180.6
	l-56.2-56.2c-6.7-6.7-17.5-6.7-24.1,0c-6.7,6.7-6.7,17.5,0,24.2l84.5,84.5c3.1,3.6,7.7,5.9,12.9,5.9c5.4,0,10.1-2.4,13.3-6.3
	l84.1-84.1c6.7-6.7,6.7-17.5,0-24.1c-6.7-6.7-17.5-6.7-24.1,0l-56.2,56.1V273.1h180.6l-56.2,56.2c-6.7,6.7-6.7,17.5,0,24.1
	c6.7,6.7,17.5,6.7,24.1,0l84-84C509.5,266.3,512.1,261.5,512.1,256z"
    />
  </svg>
));

Arrows.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Arrows.displayName = 'Arrows';

export default Arrows;
