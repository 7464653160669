import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@beewise/select-field';
import TextField from '@beewise/text-field';
import { frameStationOptions, partialAxisOptions } from 'components/views/ActionsAndMonitoring/utils';

const GoToParams = ({ params, setParams }) => {
    const updateParams = updateFn => {
        setParams(prevParams => ({
            ...prevParams,
            ...updateFn(prevParams || {}),
        }));
    };

    const handleAxisChange = value => {
        updateParams(() => ({
            axis: value,
        }));
    };

    const handleStationChange = value => {
        updateParams(params => ({
            place: {
                ...params.place,
                station: value,
            },
        }));
    };

    const handleChangePosition = axis => e => {
        const currentValue = typeof e === 'object' && e.target ? e.target.value : e;
        updateParams(params => ({
            place: {
                ...params.place,
                position: {
                    ...(params?.place?.position ?? {}),
                    [axis]: currentValue?.endsWith('.') ? currentValue : Number(currentValue),
                },
            },
        }));
    };

    const stationOptions = frameStationOptions({
        partialStationOptions: true,
    });

    return (
        <>
            <SelectField
                value={params?.axis ?? null}
                options={partialAxisOptions}
                onChange={handleAxisChange}
                placeholder="Select axis"
                className="nowrap"
                size="small"
            />
            <SelectField
                value={params?.place?.station ?? null}
                options={stationOptions}
                onChange={handleStationChange}
                placeholder="Select station"
                className="nowrap"
                size="small"
            />
            <TextField
                label="x"
                value={params?.place?.position?.x ?? ''}
                onChange={handleChangePosition('x')}
                size="small"
            />
            <TextField
                label="y"
                value={params?.place?.position?.y ?? ''}
                onChange={handleChangePosition('y')}
                size="small"
            />
        </>
    );
};

GoToParams.propTypes = {
    params: PropTypes.shape(),
    setParams: PropTypes.func.isRequired,
};

export default GoToParams;
