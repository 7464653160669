import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual, shallowEqual, loading } from '@beewise/react-utils';
import cx from 'classnames';
import { getCurrentBhome } from 'selectors';
import { getCurrentBhomeCalibrations } from './selectors';
import { fetchCalibrationsData } from './actions';
import { FETCH_CALIBRATIONS_DATA } from './actionTypes';
import ImageSlider from './components/ImageSlider';
import './CamerasCalibrations.scss';

const CamerasCalibrations = () => {
    const dispatch = useDispatch();
    const bhome = useSelector(getCurrentBhome, shallowEqual);
    const calibrations = useSelector(getCurrentBhomeCalibrations, arrayOfObjectsShallowEqual);

    useEffect(() => {
        if (bhome?.id) {
            dispatch(fetchCalibrationsData(bhome.id));
        }
    }, [bhome, dispatch]);

    return (
        <div className="cameras-calibrations">
            {calibrations.length === 0 && <h3>No calibrations data for the selected bhome</h3>}
            {calibrations.map(({ id, process, data, createdAt }) => (
                <div key={id} className="calibration">
                    <h3>{process}</h3>
                    <p>Time: {createdAt ? new Date(createdAt).toLocaleString() : data?.time}</p>
                    {data?.images && <ImageSlider images={data.images} />}
                    {data?.success && (
                        <p
                            className={cx({
                                'success-text': data?.success,
                                'error-text': !data?.success,
                            })}
                        >
                            Success
                        </p>
                    )}
                    {data?.errorDescription && <p>Error: {data.errorDescription}</p>}
                    {data?.troubleShootLink && (
                        <a href={data.troubleShootLink} target="_blank" rel="noopener noreferrer">
                            Troubleshoot
                        </a>
                    )}
                </div>
            ))}
        </div>
    );
};

export default loading([FETCH_CALIBRATIONS_DATA.default])(CamerasCalibrations);
