import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import constants from 'appConstants';
import TechButton from 'components/reusables/TechButton';
import { SelectField } from '@beewise/select-field';
import { fetchSendTechnicianAction } from 'actions/app.actions';

const sensorOptions = [
    {
        label: 'A',
        value: 'a',
    },
    {
        label: 'B',
        value: 'b',
    },
];

const IrTemperature = ({ bhome, isNavCalibrationInProgress }) => {
    const [sensorId, setSensorId] = useState(null);
    const dispatch = useDispatch();
    const irTemperatures = useSelector(state => state.actionsMonitoring.irTemperatures, shallowEqual);

    const handleFetchIrTemperature = useCallback(() => {
        dispatch(
            fetchSendTechnicianAction(bhome?.id, {
                type: constants.COMMANDS.GET_IR_TEMPERATURE,
                params: {
                    sensor_id: sensorId,
                },
            })
        );
    }, [bhome?.id, dispatch, sensorId]);

    return (
        <>
            <SelectField
                value={sensorId}
                options={sensorOptions}
                onChange={setSensorId}
                placeholder="Choose sensor"
                className="ir-sensors-select"
                size="small"
            />
            <TechButton
                disabled={!sensorId || isNavCalibrationInProgress}
                showToManufacturer
                onClick={handleFetchIrTemperature}
            >
                Get IR Temperature
            </TechButton>
            {irTemperatures?.a && <div className="ir-temperature-value">Temp A: {irTemperatures?.a}</div>}
            {irTemperatures?.b && <div className="ir-temperature-value">Temp B: {irTemperatures?.b}</div>}
        </>
    );
};

IrTemperature.propTypes = {
    bhome: PropTypes.shape(),
    isNavCalibrationInProgress: PropTypes.bool,
};

export default IrTemperature;
