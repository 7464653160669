import 'es6-symbol/implement';
import React from 'react';
import { createRoot } from 'react-dom/client';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { Provider } from 'react-redux';
import { BeewiseToastContainer } from '@beewise/toast';
import store from './store';
import Routes from './routes';

import './App.scss';

dayjs.extend(customParseFormat).extend(utc).extend(isToday).extend(isYesterday);

const App = () => (
    <Provider store={store}>
        <div id="app" className="app">
            <Routes />
            <BeewiseToastContainer />
        </div>
    </Provider>
);

const root = createRoot(document.getElementById('root'));
root.render(<App />);
