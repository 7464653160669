import React from 'react';
import { get } from 'lodash-es';
import dayjs from 'dayjs';

export default frame => {
    if (frame?.frameId) {
        const latestScan =
            get(frame, 'sideA.scanTimestamp', 0) > get(frame, 'sideB.scanTimestamp', 0)
                ? frame?.sideA?.scanTimestamp
                : frame?.sideB?.scanTimestamp;

        if (latestScan) {
            const diff = dayjs(Date.now()).startOf('day').diff(dayjs(latestScan).startOf('day'), 'day');
            if (diff > 0) {
                return (
                    <div>
                        Frame {frame.frameIdx + 1} <span className="padding-line">|</span> {diff} day
                        {diff === 1 ? '' : 's'} ago
                    </div>
                );
            }
            if (diff === 0) {
                return (
                    <div>
                        Frame {frame.frameIdx + 1} <span className="padding-line">|</span>Today
                    </div>
                );
            }
            return null;
        } else {
            return <div>Frame {frame.frameIdx + 1}</div>;
        }
    }
};
