import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import TechButton from 'components/reusables/TechButton';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import { calibrationsPack } from '../utils';

const CalibrationPackRunner = ({ bhome }) => {
    const dispatch = useDispatch();

    const handleSendPack = useCallback(() => {
        // eslint-disable-next-line no-alert
        if (window.confirm('Are you sure you want to run all calibrations?')) {
            calibrationsPack.forEach((action, index) => {
                setTimeout(() => {
                    dispatch(fetchSendTechnicianAction(bhome?.id, action));
                }, index * 2000);
            });
        }
    }, [dispatch, bhome?.id]);

    return (
        <TechButton
            className="calibrate-all"
            showToManufacturer
            enabledInReceptiveAndMaintenance
            onClick={handleSendPack}
        >
            Calibrate All
        </TechButton>
    );
};

CalibrationPackRunner.propTypes = {
    bhome: PropTypes.shape(),
};

export default CalibrationPackRunner;
