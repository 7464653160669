import { get } from 'lodash-es';
import { createSelector } from 'reselect';
import constants from 'appConstants';
import { getCurrentBhomeActivities } from 'selectors';

export const getCurrentBhomeId = state => get(state, 'app.currentBhome.id', null);
export const getCurrentBhomeLiveStream = state => get(state, 'app.currentBhome.live_stream', null);
export const getIsActivitiesFetched = state => get(state, 'actionsMonitoring.activities.isFetched', false);
export const getActivitiesInProgress = state => get(state, 'actionsMonitoring.activities.inProgress', []);
export const getActivitiesDone = state => get(state, 'actionsMonitoring.activities.done', []);
export const getDevices = state => get(state, 'actionsMonitoring.devices');
export const getIpCameraDevice = state => get(state, 'actionsMonitoring.ipCameraDevice');
export const getFrameScaleData = state => state.actionsMonitoring.frameScale;
export const isNavigationCalibrationInProgress = createSelector(getCurrentBhomeActivities, activities =>
    activities?.active?.some(
        activity =>
            activity.command === constants.COMMANDS.CALIBRATE_NAVIGATION &&
            activity.status === constants.MESSAGE_STATUS.PROCESSING
    )
);
