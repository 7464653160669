import { FETCH_ACTION_TYPES } from '@beewise/react-utils';

const initialState = {
    loading: {},
    fetched: {},
};

const ui = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACTION_TYPES.LOADING_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [action.key]: true,
                },
                fetched: {
                    ...state.loading.fetched,
                    [action.key]: false,
                },
            };
        case FETCH_ACTION_TYPES.LOADING_END:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [action.key]: false,
                },
                fetched: {
                    ...state.loading.fetched,
                    [action.key]: true,
                },
            };
        default:
            return state;
    }
};

export default ui;
