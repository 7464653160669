import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { JsonEditor as Editor } from 'components/reusables/JsonEditor';
import { isEqual, get } from 'lodash-es';
import Modal, { Submit } from '@beewise/modal';
import Checkbox from '@beewise/checkbox-field';
import TechButton from 'components/reusables/TechButton';

const GlobalSettings = ({
    settings,
    handleGlobalSettingsSave,
    clearConfigError,
    conflicts,
    cleanGlobalSettingsConflicts,
    fetchSaveConflicts,
    isLoading,
}) => {
    const [tmpGlobalSettings, setTmpGlobalSettings] = useState(null);
    const [isAllChecked, setAllChecked] = useState(true);
    const [checkedArray, setCheckedArray] = useState([]);

    useEffect(() => {
        if (settings) {
            setTmpGlobalSettings(get(settings, 'data.settings', null));
        }

        return () => {
            clearConfigError();
        };
    }, [settings, clearConfigError]);

    const handleOnChange = useCallback(
        data => {
            clearConfigError();
            setTmpGlobalSettings(data);
        },
        [clearConfigError]
    );

    const handleCheck = useCallback(
        checkedItem => () => {
            if (!checkedArray.find(item => isEqual(item, checkedItem))) {
                setAllChecked(false);
                setCheckedArray([...checkedArray, checkedItem]);
            } else {
                setCheckedArray(checkedArray.filter(item => !isEqual(item, checkedItem)));
            }
        },
        [checkedArray]
    );

    const handleAllChecked = useCallback(() => {
        if (!isAllChecked) {
            setCheckedArray([]);
        }
        setAllChecked(!isAllChecked);
    }, [isAllChecked]);

    const handleOnClose = useCallback(() => {
        cleanGlobalSettingsConflicts();
        setAllChecked(true);
        setCheckedArray([]);
    }, [cleanGlobalSettingsConflicts]);

    return (
        <div className="config-global">
            <h2 className="config-global-title">Global Beehome Settings:</h2>
            {tmpGlobalSettings && <Editor value={tmpGlobalSettings} onChange={handleOnChange} />}
            <TechButton
                className="axis-get-button btn-primary"
                onClick={handleGlobalSettingsSave(tmpGlobalSettings)}
                disabled={isEqual(get(settings, 'data.settings', null), tmpGlobalSettings)}
                alwaysEnabled={!isEqual(get(settings, 'data.settings', null), tmpGlobalSettings)}
            >
                Save
            </TechButton>
            <Modal header="Overwrite these Beehomes?" isOpen={conflicts && conflicts.length} onClose={handleOnClose}>
                <div className="conflicts-body">
                    <div className="conflicts-title">
                        Those Beehomes has custom settings, check the ones you would like to overwrite:
                    </div>
                    <div className="conflicts-list">
                        <div className="conflicts-item conflicts-item-bold">
                            <div className="conflicts-item-checkbox">
                                <Checkbox name="all" checked={isAllChecked} onChange={handleAllChecked} />
                            </div>
                            <div className="conflicts-item-id">Beehome id</div>
                            <div className="conflicts-item-name">Setting name</div>
                            <div className="conflicts-item-value">Setting value</div>
                        </div>
                        {conflicts &&
                            conflicts.map(item => (
                                <div className="conflicts-item" key={`${item.bhomeId}_${item.key}`}>
                                    <div>
                                        <Checkbox
                                            name={`${item.bhomeId}_${item.key}`}
                                            checked={isAllChecked || !!checkedArray.find(tmp => isEqual(tmp, item))}
                                            onChange={handleCheck(item)}
                                        />
                                    </div>
                                    <div className="conflicts-item-id">{item.bhomeId}</div>
                                    <div className="conflicts-item-name">{item.key}</div>
                                    <div className="conflicts-item-value">{JSON.stringify(item.value)}</div>
                                </div>
                            ))}
                    </div>
                    <Submit
                        isLoading={isLoading}
                        onSubmit={fetchSaveConflicts(tmpGlobalSettings, checkedArray, isAllChecked)}
                        submitLabel="Submit"
                    />
                </div>
            </Modal>
        </div>
    );
};

GlobalSettings.propTypes = {
    settings: PropTypes.shape(),
    handleGlobalSettingsSave: PropTypes.func,
    clearConfigError: PropTypes.func,
    cleanGlobalSettingsConflicts: PropTypes.func,
    fetchSaveConflicts: PropTypes.func,
    conflicts: PropTypes.arrayOf(PropTypes.shape()),
    isLoading: PropTypes.bool,
};

export default GlobalSettings;
