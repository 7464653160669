import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const ArrowMove = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 12"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M14.2521 1.56797L14.7476 1.04766C14.9574 0.82735 15.2967 0.82735 15.5043 1.04766L19.8436 5.60157C20.0534 5.82188 20.0534 6.17813 19.8436 6.3961L15.5043 10.9523C15.2945 11.1727 14.9552 11.1727 14.7476 10.9523L14.2521 10.432C14.04 10.2094 14.0445 9.8461 14.261 9.62813L16.9507 6.93751H10.5356C10.2387 6.93751 9.99985 6.68672 9.99985 6.37501V5.62501C9.99985 5.31329 10.2387 5.06251 10.5356 5.06251H16.9507L14.261 2.37188C14.0423 2.15391 14.0378 1.79063 14.2521 1.56797Z"
        fill="#BDBDBD"
      />
    </g>
    <line y1="6" x2="8" y2="6" stroke="#BDBDBD" strokeWidth="2" strokeDasharray="2 2" />
    <defs>
      <clipPath id="clip0">
        <rect width="10" height="12" fill="white" transform="translate(20 12) rotate(180)" />
      </clipPath>
    </defs>
  </svg>
));

ArrowMove.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ArrowMove.displayName = 'ArrowMove';

export default ArrowMove;
