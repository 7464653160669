import { FETCH_PERMISSION, FETCH_REFRESH_USER_TOKEN } from '../actionTypes';

export const fetchPermission = (app) => ({
  type: 'FETCH',
  params: {
    type: FETCH_PERMISSION.default,
    url: `/users/permission/${app}`
  }
});
export const fetchRefreshUserToken = (resolver) => ({
  type: 'FETCH',
  params: {
    type: FETCH_REFRESH_USER_TOKEN.default,
    url: '/users/refresh-user-token',
    resolver
  }
});
