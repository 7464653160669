import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from 'components/reusables/Grid';

const CsvRenderer = ({ data, fileName }) => {
    const s3ColumnDefs = useMemo(() => {
        if (!data?.length) {
            return [];
        }

        return Object.keys(data[0]).map(key => ({
            headerName: key,
            field: key,
        }));
    }, [data]);

    return <Grid key={fileName} rowData={data} columnDefs={s3ColumnDefs} hideDownload hideSearch />;
};

CsvRenderer.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape()),
    fileName: PropTypes.string,
};

export default CsvRenderer;
