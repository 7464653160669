import {
    FETCH_LAST_MESSAGES_BY_DATE_AND_COMMAND,
    FETCH_MESSAGES_SINCE_LAST_SUCCESS_BY_BHOME_ID_AND_COMMAND,
} from '../actionTypes';

const initialState = {
    lastMessages: [],
    messagesSinceLastSuccessByBhomeIdAndCommand: [],
};

const swUpdateMonitor = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LAST_MESSAGES_BY_DATE_AND_COMMAND.success:
            return {
                ...state,
                lastMessages: action.data.messages,
            };
        case FETCH_MESSAGES_SINCE_LAST_SUCCESS_BY_BHOME_ID_AND_COMMAND.success:
            return {
                ...state,
                messagesSinceLastSuccessByBhomeIdAndCommand: action.data.messages,
            };
        default:
            return state;
    }
};

export default swUpdateMonitor;
