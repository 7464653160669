import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { UI_ENV } from 'config';
import Card from 'components/reusables/Card';
import { isNumber } from 'lodash-es';
import { SelectField } from '@beewise/select-field';
import TextField from '@beewise/text-field';
import TechButton from 'components/reusables/TechButton';
import constants from 'appConstants';
import Checkbox from '@beewise/checkbox-field';
import { useToggle } from '@beewise/react-utils';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import { axisOptions } from '../../utils';
import ShipmentPosition from './components/ShipmentPosition';

const AxisController = ({ bhome, isEMTester, isGripperTester, isFLTester }) => {
    const dispatch = useDispatch();
    const [distance, setDistance] = useState(null);
    const [skipEncoder, toggleSkipEncoder] = useToggle(false);
    const [maxIrun, toggleMaxIrun] = useToggle(false);
    const [allowUnsafeTMove, toggleAllowUnsafeTMove, allowUnsafeMove, disallowUnsafeMove] = useToggle(false);
    const [axis, setAxis] = useState('');

    useEffect(() => {
        setDistance(null);
        setAxis('');
    }, [bhome.id]);

    useEffect(() => {
        if (bhome?.id) {
            if (UI_ENV === 'dev') {
                allowUnsafeMove();
            } else {
                disallowUnsafeMove();
            }
        }
    }, [allowUnsafeMove, disallowUnsafeMove, bhome?.id]);

    const handleDistanceChange = useCallback(value => {
        if (value === '') {
            setDistance(null);
        } else if (value.length > 1 && value[0] === '0') {
            setDistance(+value.substring(1));
        } else {
            setDistance(+value);
        }
    }, []);

    const handleDistanceButton = useCallback(value => () => setDistance(distance + value), [distance]);

    const handleAxisChange = useCallback(value => {
        setAxis(value);
        setDistance(0);
    }, []);

    const handleSend = useCallback(() => {
        if (axis && isNumber(distance)) {
            const params = {
                axis,
                change: distance,
                skipEncoder,
                maxIrun,
                allowUnsafeTMove,
            };
            dispatch(
                fetchSendTechnicianAction(bhome.id, {
                    type: constants.COMMANDS.MOVE_MOTOR,
                    params,
                })
            );
        }
    }, [axis, distance, skipEncoder, maxIrun, allowUnsafeTMove, dispatch, bhome.id]);

    const handleSafeForTravelClick = useCallback(() => {
        dispatch(
            fetchSendTechnicianAction(bhome.id, {
                type: constants.COMMANDS.HOME_FRAME_LOADER,
            })
        );
    }, [dispatch, bhome.id]);

    const axisOpts = useMemo(
        () => axisOptions({ isEMTester, isGripperTester, isFLTester }),
        [isEMTester, isGripperTester, isFLTester]
    );

    const isTester = isEMTester || isFLTester;

    return (
        <div className="axis-controller-center">
            <Card className="axis" title="Choose axis and distance (in mm).">
                <div className="axis-controls">
                    <SelectField
                        options={axisOpts}
                        placeholder="Select axis"
                        onChange={handleAxisChange}
                        value={axis}
                        size="small"
                    />
                    <div className="axis-distance">
                        <button
                            className={cx('axis-plus', {
                                highlighted: distance && distance < 0,
                            })}
                            onClick={handleDistanceButton(1)}
                        >
                            &#8593;
                        </button>
                        <TextField
                            placeholder="Distance (in mm)"
                            className="distance-input"
                            value={isNumber(distance) ? distance : ''}
                            onChange={handleDistanceChange}
                            type="number"
                            size="small"
                        />
                        <button
                            className={cx('axis-minus', {
                                highlighted: distance && distance > 0,
                            })}
                            onClick={handleDistanceButton(-1)}
                        >
                            &#8595;
                        </button>
                    </div>
                    <TechButton
                        className="send btn-primary"
                        onClick={handleSend}
                        disabled={!distance || !axis}
                        showToManufacturer
                    >
                        Send
                    </TechButton>
                    <TechButton
                        className="send btn-primary"
                        onClick={handleSend}
                        disabled={distance !== 0 || !axis}
                        showToManufacturer
                    >
                        Home
                    </TechButton>
                    {axis && (
                        <div className="axis-checkboxes">
                            <Checkbox label="Skip Encoder" checked={skipEncoder} onChange={toggleSkipEncoder} />
                            <Checkbox label="Max Irun" checked={maxIrun} onChange={toggleMaxIrun} />
                            <Checkbox
                                label="Allow unsafe robot move"
                                checked={allowUnsafeTMove}
                                onChange={toggleAllowUnsafeTMove}
                            />
                        </div>
                    )}
                </div>
                {!isTester && !isGripperTester && (
                    <>
                        <TechButton
                            className="send btn-primary safe-for-travel"
                            onClick={handleSafeForTravelClick}
                            showToManufacturer
                        >
                            Robot safe for travel
                        </TechButton>
                        <ShipmentPosition bhome={bhome} />
                    </>
                )}
            </Card>
        </div>
    );
};

AxisController.propTypes = {
    bhome: PropTypes.shape(),
    isEMTester: PropTypes.bool,
    isGripperTester: PropTypes.bool,
    isFLTester: PropTypes.bool,
};

export default AxisController;
