import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Graph = forwardRef(({ size = 14, fill = '#4E4540', ...rest }, ref) => (
  <svg
    ref={ref}
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1.66683 11.6666V12.3333H13.6668V13.6666H0.333496V0.333252H1.66683V10.3333C3.66683 10.3333 5.66683 8.99992 7.06683 6.59992C9.06683 3.26659 11.2668 1.66659 13.6668 1.66659V2.99992C11.8002 2.99992 10.0002 4.39992 8.26683 7.33325C6.5335 10.0666 4.1335 11.6666 1.66683 11.6666Z"
      fill={fill}
    />
  </svg>
));

Graph.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Graph.displayName = 'Graph';

export default Graph;
