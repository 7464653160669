import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const Chip = ({ title, handleDeleteView, isActiveView, setActiveView }) => {
    const handleDeleteViewClick = e => {
        e.stopPropagation();
        handleDeleteView();
    };

    return (
        <div
            className={cx('chip', {
                active: isActiveView,
            })}
            onClick={setActiveView}
            role="presentation"
        >
            <span className="checkmark">✓</span>
            {title}
            <button onClick={handleDeleteViewClick} className="delete-button">
                X
            </button>
        </div>
    );
};

Chip.propTypes = {
    title: PropTypes.string,
    handleDeleteView: PropTypes.func,
    isActiveView: PropTypes.bool,
    setActiveView: PropTypes.func,
};

export default Chip;
