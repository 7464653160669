import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const RedoAction = forwardRef(({ size = 12, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <path
      d="M6.94778 5.34126H11.7145C11.8722 5.34126 12 5.21333 12 5.05555V0.285713C12 0.127927 11.8722 0 11.7145 0H10.5724C10.4147 0 10.2868 0.127927 10.2868 0.285713V2.14552C9.19773 0.935216 7.61575 0.177856 5.85703 0.190644C2.59951 0.214311 -0.00691128 2.84833 1.43051e-05 6.10807C0.00693893 9.36328 2.64617 12 5.90086 12C7.42578 12 8.81551 11.4211 9.86277 10.4711C9.98409 10.361 9.98969 10.1722 9.87388 10.0563L9.06568 9.24757C8.95922 9.14105 8.78819 9.13526 8.67543 9.23507C7.93697 9.88895 6.9657 10.2857 5.90086 10.2857C3.58648 10.2857 1.71314 8.41152 1.71314 6.09524C1.71314 3.77936 3.5861 1.90477 5.90086 1.90477C7.2927 1.90477 8.52484 2.58277 9.2861 3.62698H6.94779C6.79011 3.62698 6.66226 3.75491 6.66226 3.91269V5.05555C6.66226 5.21334 6.79011 5.34126 6.94779 5.34126L6.94778 5.34126Z"
      fill="#4F4F4F"
    />
  </svg>
));

RedoAction.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

RedoAction.displayName = 'UndoAction';

export default RedoAction;
