import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TechButton from 'components/reusables/TechButton';
import { useDispatch } from 'react-redux';
import constants from 'appConstants';
import { fetchCurrentBhome, modifyMode } from 'actions/app.actions';

export const ACTIONS = {
    START: 'start',
    END: 'end',
};

const ModeControl = ({ bhome }) => {
    const dispatch = useDispatch();

    const isActiveMode = bhome?.mode?.visit || bhome?.mode?.transportation || bhome?.mode?.super;

    const handleMode = useCallback(
        (mode, action) => () => {
            dispatch(modifyMode(bhome?.id, mode, action, () => dispatch(fetchCurrentBhome(bhome.id))));
        },
        [bhome?.id, dispatch]
    );

    return (
        <>
            <TechButton
                onClick={handleMode(constants.MODE.VISIT, ACTIONS.START)}
                enabledOnlyInReceptive
                disabled={isActiveMode}
            >
                Visit
            </TechButton>
            <TechButton
                onClick={handleMode(constants.MODE.VISIT, ACTIONS.END)}
                enabledOnlyInReceptive
                disabled={!bhome?.mode?.visit}
            >
                End Visit
            </TechButton>
            <TechButton
                onClick={handleMode(constants.MODE.TRANSPORTATION, ACTIONS.START)}
                disabled={isActiveMode}
                enabledOnlyInReceptive
            >
                Transport
            </TechButton>
            <TechButton
                onClick={handleMode(constants.MODE.TRANSPORTATION, ACTIONS.END)}
                disabled={!bhome?.mode?.transportation}
                enabledOnlyInReceptive
            >
                End Transport
            </TechButton>
            <TechButton
                onClick={handleMode(constants.MODE.SUPER, ACTIONS.START)}
                disabled={isActiveMode}
                enabledOnlyInReceptive
            >
                Super
            </TechButton>
            <TechButton
                onClick={handleMode(constants.MODE.SUPER, ACTIONS.END)}
                disabled={!bhome?.mode?.super}
                enabledOnlyInReceptive
            >
                End Super
            </TechButton>
        </>
    );
};

ModeControl.propTypes = {
    bhome: PropTypes.shape(),
};

export default ModeControl;
