import { isObject, isEqual, differenceBy, difference, keyBy, isEmpty, omit } from 'lodash-es';

const getChangedArrayValues = ({
  key,
  initialArray,
  newArray,
  fieldsToStringify,
  getDirtyFormFields,
  dirtyFields
}) => {
  const changedAddedRemovedFields = {};

  const isAllValuesRemoved = !newArray.length && initialArray.length;
  if (isAllValuesRemoved) {
    changedAddedRemovedFields[`${key}Remove`] = initialArray.map((item) =>
      isObject(item) ? item.id : item
    );
    return changedAddedRemovedFields;
  }

  const isAllValuesAdded = newArray.length && !initialArray.length;
  if (isAllValuesAdded) {
    changedAddedRemovedFields[`${key}Add`] = newArray.map((item) =>
      isObject(item) ? omit(item, 'id') : item
    );
    return changedAddedRemovedFields;
  }

  const isArrayOfObjects = isObject(newArray[0]);

  const initialArrayOfObjectsById =
    initialArray.length && isArrayOfObjects ? keyBy(initialArray, 'id') : {};

  const { changedItems, newItems } = isArrayOfObjects
    ? newArray.reduce(
        (acc, item, index) => {
          let newItem;

          const itemFromOldArray = initialArrayOfObjectsById[item.id];

          const isDirtyFieldsPerObject = isObject(dirtyFields?.[index]);

          if (isDirtyFieldsPerObject) {
            const areSomeFieldsDirty = Object.values(dirtyFields?.[index]).some((item) => item);
            if (areSomeFieldsDirty) {
              newItem = getDirtyFormFields({
                dirtyFields: dirtyFields?.[index],
                initialFields: itemFromOldArray,
                newFields: item,
                fieldsToStringify
              });
            }
          } else if (itemFromOldArray && !isDirtyFieldsPerObject) {
            newItem = getDirtyFormFields({
              initialFields: itemFromOldArray,
              newFields: item,
              fieldsToStringify
            });
          }
          if (itemFromOldArray && newItem && !isEmpty(newItem)) {
            // push only changed fields
            acc.changedItems.push({ ...newItem, id: item.id });
          } else if (!itemFromOldArray) {
            const { id, ...rest } = item;
            acc.newItems.push(rest);
          }
          return acc;
        },
        { changedItems: [], newItems: [] }
      )
    : { changedItems: [], newItems: [] };

  const itemsToRemove = isArrayOfObjects
    ? differenceBy(initialArray, newArray, 'id').map(({ id }) => id)
    : difference(initialArray, newArray);

  const itemsToAdd = isArrayOfObjects ? newItems : difference(newArray, initialArray);
  if (itemsToRemove.length > 0) {
    changedAddedRemovedFields[`${key}Remove`] = itemsToRemove;
  }
  if (itemsToAdd?.length > 0) {
    changedAddedRemovedFields[`${key}Add`] = itemsToAdd;
  }
  if (changedItems?.length) {
    changedAddedRemovedFields[`${key}Change`] = changedItems;
  }
  return changedAddedRemovedFields;
};

const getDirtyFormFields = ({ dirtyFields, initialFields, newFields, fieldsToStringify }) => {
  const dirtyFieldsArrayItemKeys = dirtyFields
    ? Object.keys(dirtyFields).filter((key) => dirtyFields[key])
    : Object.keys(newFields);

  // value  - either boolean value or objects with dirty fields or array with booleans
  return dirtyFieldsArrayItemKeys.reduce((changedAddedRemovedFieldsObjectAcc, key) => {
    if (!dirtyFields) {
      if (!isObject(newFields[key])) {
        if (initialFields?.[key] !== newFields[key]) {
          changedAddedRemovedFieldsObjectAcc[key] = newFields[key];
        }
        return changedAddedRemovedFieldsObjectAcc;
      }
    }

    const hasToBeStringified = fieldsToStringify?.includes(key);
    if (hasToBeStringified) {
      const areValuesEqual = isEqual(initialFields?.[key], newFields[key]);
      if (!areValuesEqual) {
        changedAddedRemovedFieldsObjectAcc[key] = newFields[key];
      }
      return changedAddedRemovedFieldsObjectAcc;
    }

    if (Array.isArray(newFields[key])) {
      const changedArrayData = getChangedArrayValues({
        key,
        initialArray: initialFields[key],
        newArray: newFields[key],
        fieldsToStringify,
        getDirtyFormFields,
        dirtyFields: dirtyFields?.[key]
      });
      changedAddedRemovedFieldsObjectAcc = {
        ...changedAddedRemovedFieldsObjectAcc,
        ...changedArrayData
      };
    } else if (isObject(dirtyFields[key])) {
      if (!isEqual(initialFields?.[key], newFields?.[key])) {
        changedAddedRemovedFieldsObjectAcc[key] = getDirtyFormFields({
          dirtyFields: dirtyFields[key],
          initialFields: initialFields[key],
          newFields: newFields[key],
          fieldsToStringify
        });
      } else {
        return changedAddedRemovedFieldsObjectAcc;
      }
    } else {
      changedAddedRemovedFieldsObjectAcc[key] = newFields[key];
    }
    return changedAddedRemovedFieldsObjectAcc;
  }, {});
};

export default getDirtyFormFields;
