import React, { forwardRef } from 'react';

const Diary = forwardRef((props, ref) => (
  <svg
    ref={ref}
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.812498 14.5H7.08334V11.0156C7.08334 10.2873 7.74312 9.8125 8.39583 9.8125H12.5V0.703123C12.5 0.670881 12.4864 0.627672 12.4354 0.583528C12.3832 0.538306 12.2975 0.5 12.1875 0.5H0.812498C0.70253 0.5 0.616846 0.538306 0.56459 0.583528C0.513578 0.627673 0.5 0.670881 0.5 0.703123V14.2969C0.5 14.3291 0.513578 14.3723 0.56459 14.4165C0.616847 14.4617 0.70253 14.5 0.812498 14.5Z"
      fill="#4F4F4F"
      stroke="#4F4F4F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 11.4287V11.25H8.66669V15H8.8732C9.08986 15 9.29637 14.9268 9.44871 14.7949L12.763 11.9238C12.9154 11.792 13 11.6133 13 11.4287L13 11.4287Z"
      fill="#4F4F4F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.28572 7.30769V6.85897C9.28572 6.75321 9.12903 6.66667 8.93751 6.66667H4.06251C3.87099 6.66667 3.71429 6.75321 3.71429 6.85897V7.30769C3.71429 7.41346 3.87099 7.5 4.06251 7.5H8.93751C9.12903 7.5 9.28572 7.41346 9.28572 7.30769Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.93751 5H4.06251C3.87099 5 3.71429 4.91346 3.71429 4.80769V4.35897C3.71429 4.25321 3.87099 4.16667 4.06251 4.16667H8.93751C9.12903 4.16667 9.28572 4.25321 9.28572 4.35897V4.80769C9.28572 4.91346 9.12903 5 8.93751 5Z"
      fill="white"
    />
  </svg>
));

Diary.displayName = 'Diary';

export default Diary;
