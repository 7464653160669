import { intersection, isEqual, isNull, isUndefined } from 'lodash-es';
import { toggleSettingsError } from '../actions/config.actions';

export default (tmpSettings, globalSettings, dispatch) => {
    const { settings } = globalSettings.data;
    let previousKeys = Object.keys(settings);
    const tmpKeys = Object.keys(tmpSettings);

    if (!isEqual(previousKeys, intersection(previousKeys, tmpKeys))) {
        dispatch(toggleSettingsError('You are not able to change settings keys.'));
        return;
    }

    return tmpKeys.reduce(
        (acc, key) => {
            if (
                previousKeys.find(item => item === key) &&
                tmpSettings[key] !== settings[key] &&
                !isNull(tmpSettings[key]) &&
                !isUndefined(tmpSettings[key])
            ) {
                acc.change = {
                    ...acc.change,
                    [key]: tmpSettings[key],
                };

                previousKeys = previousKeys.filter(item => item !== key);
            } else if (
                !previousKeys.find(item => item === key) &&
                !isNull(tmpSettings[key]) &&
                !isUndefined(tmpSettings[key])
            ) {
                acc.add = {
                    ...acc.add,
                    [key]: tmpSettings[key],
                };
            }
            return acc;
        },
        {
            change: {},
        }
    );
};
