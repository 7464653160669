(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("checkbox-field", [], factory);
	else if(typeof exports === 'object')
		exports["checkbox-field"] = factory();
	else
		root["checkbox-field"] = factory();
})(self, () => {
return 