import { FETCH_ALL_LOCKED_HIVES, FETCH_TOGGLE_LOCK_HIVE } from '../actionTypes';

export const fetchAllLockedHives = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_ALL_LOCKED_HIVES.default,
        url: '/bhomes/v4/hives/locked',
    },
});

export const fetchChangeBhomeLockedHives = (id, body, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: FETCH_TOGGLE_LOCK_HIVE.default,
        url: `/bhomes/v4/${id}/hives/lock`,
        body,
        toastText: 'Done',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
        resolver,
    },
});
