import axios from 'axios';
import * as authService from './auth';

let axiosClient = null;

const createAxiosClient = (API_URL) => {
  const client = axios.create({
    baseURL: API_URL
  });

  client.interceptors.request.use(
    (request) => {
      const token = authService.getJwtToken();
      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }
      if (request.headers['Content-Type'] === 'multipart/form-data') {
        delete request.headers['Content-Type'];
      } else {
        request.headers['Content-Type'] = 'application/json';
      }
      return request;
    },
    (error) => Promise.reject(error)
  );

  client.interceptors.response.use(
    (response) => response.data,
    (error) => {
      const { data, status } = error.response;
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: data.error, status });
    }
  );

  axiosClient = client;

  return axiosClient;
};

export default (API_URL) => {
  if (axiosClient) {
    return axiosClient;
  }

  return createAxiosClient(API_URL);
};
