import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const MarkerAlert = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
      fill="white"
    />
    <circle cx="12" cy="12" r="10" fill="#F22A2A" />
    <path
      d="M11.9956 13.806C11.7436 13.806 11.5276 13.722 11.3476 13.554C11.1796 13.386 11.0716 13.146 11.0236 12.834L10.0876 7.23597C10.0756 7.16397 10.0696 7.06797 10.0696 6.94797C10.0696 6.40797 10.2496 5.98797 10.6096 5.68797C10.9816 5.37597 11.4436 5.21997 11.9956 5.21997C12.5596 5.21997 13.0216 5.37597 13.3816 5.68797C13.7416 5.99997 13.9216 6.42597 13.9216 6.96597C13.9216 7.08597 13.9156 7.18197 13.9036 7.25397L12.9496 12.834C12.9136 13.146 12.8056 13.386 12.6256 13.554C12.4576 13.722 12.2476 13.806 11.9956 13.806ZM11.9956 18.108C11.4916 18.108 11.0716 17.94 10.7356 17.604C10.4116 17.268 10.2496 16.854 10.2496 16.362C10.2496 15.87 10.4116 15.456 10.7356 15.12C11.0716 14.784 11.4916 14.616 11.9956 14.616C12.4876 14.616 12.8956 14.784 13.2196 15.12C13.5556 15.456 13.7236 15.87 13.7236 16.362C13.7236 16.854 13.5556 17.268 13.2196 17.604C12.8956 17.94 12.4876 18.108 11.9956 18.108Z"
      fill="white"
    />
  </svg>
));

MarkerAlert.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

MarkerAlert.displayName = 'MarkerAlert';

export default MarkerAlert;
