import constants from 'appConstants';
import { FETCH_ACTIVITY_STATISTICS, SELECT_STATISTICS_BHOMES, TOGGLE_SHOW_AUTO_SCANS } from '../actionTypes';

export const fetchActivityStatistics = (from, to, command = constants.COMMANDS.EXTRACT_HONEY) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: FETCH_ACTIVITY_STATISTICS.default,
        url: '/messages/get-activity-statistics',
        body: {
            from,
            to,
            command,
        },
    },
});

export const selectStatisticsBhomes = bhomes => ({
    type: SELECT_STATISTICS_BHOMES,
    bhomes,
});

export const toggleShowAutoScans = () => ({
    type: TOGGLE_SHOW_AUTO_SCANS,
});
