import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from '@beewise/button';
import Icon from '@beewise/icon';

const Submit = ({ cancelLabel, submitLabel, tip, isLoading, onCancel, onSubmit }) => {
  const handleClick = !isLoading ? onSubmit : null;
  return (
    <div className="modal-submit-container">
      {tip && (
        <div className="modal-tip">
          <span>{tip}</span>
        </div>
      )}
      {onCancel && (
        <Button className="modal-cancel-button" onClick={onCancel}>
          {cancelLabel}
        </Button>
      )}
      <Button className={cx('modal-submit-button', { loading: isLoading })} onClick={handleClick}>
        {!isLoading ? submitLabel : <Icon type="preloader-compact" />}
      </Button>
    </div>
  );
};

Submit.propTypes = {
  submitLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string,
  onCancel: PropTypes.func,
  tip: PropTypes.string,
  isLoading: PropTypes.bool
};

Submit.defaultProps = {
  tip: null,
  onCancel: null,
  cancelLabel: ''
};

export default memo(Submit);
