import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const HiveWorkspaceEmpty = forwardRef(
  ({ color = '#F84D4D', width = 19, height = 16, ...rest }, ref) => (
    <svg
      ref={ref}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.6875 3.76562L13.3125 3.76563"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
      />
      <path
        d="M0.916687 2.88029L8.00002 0.666748L15.0834 2.88029"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
      />
      <path
        d="M2.6875 2.4375L2.6875 12.1771H13.3125V2.4375"
        stroke={color}
        strokeWidth="0.8"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 6.5L6.5 9.5"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 6.5L9.5 9.5"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
);

HiveWorkspaceEmpty.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

HiveWorkspaceEmpty.displayName = 'HiveWorkspaceEmpty';

export default HiveWorkspaceEmpty;
