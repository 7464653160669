import getFrameCapacity from '../getFrameCapacity';

export default (frame, broodFrameMinLimit = 0.3) => {
  const capacity = getFrameCapacity(frame);

  const larvaAmount = Object.keys(frame).reduce((acc, key) => {
    if (key.includes('side') && frame[key]) {
      const side = Object.keys(frame[key]).reduce((sideAcc, sideKey) => {
        if (sideKey.includes('larva')) {
          return sideAcc + frame[key][sideKey];
        }
        return sideAcc;
      }, 0);
      return acc + side;
    }
    return acc;
  }, 0);

  return larvaAmount / capacity >= broodFrameMinLimit;
};
