import React from 'react';
import { useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual, loading, usePermission } from '@beewise/react-utils';
import { getBhomes } from 'components/reusables/AppHeader/selectors/bhome.ids.selector';
import CreatePlaceholderHive from './components/CreatePlaceholderHive';
import { FETCH_CREATE_PLACEHOLDER_HIVES } from './actionTypes';

const LayoutActions = () => {
    const bhomes = useSelector(getBhomes, arrayOfObjectsShallowEqual);
    const canAddPlaceholderHives = usePermission('createPlaceholderHives', 'technician');

    return <div className="layout-actions">{canAddPlaceholderHives && <CreatePlaceholderHive bhomes={bhomes} />}</div>;
};
export default loading([FETCH_CREATE_PLACEHOLDER_HIVES.default])(LayoutActions);
