import React from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';

const SequenceCellRenderer = ({ data, colDef }) => {
    if (!data?.payload?.sequences?.length) {
        return null;
    }

    const handleCellClick = () => {
        colDef?.rendererParams?.onCellClicked?.(data);
    };

    return (
        <Button className="sequence-cell-renderer-body" onClick={handleCellClick}>
            Show Sequences
        </Button>
    );
};

SequenceCellRenderer.propTypes = {
    data: PropTypes.shape(),
    colDef: PropTypes.shape(),
};

export default SequenceCellRenderer;
