import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const SwitchOn = forwardRef(({ color, size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height={size}
    viewBox="0 0 40 24"
    {...rest}
  >
    <rect width="40" height="24" rx="12" fill="#0075FF" />
    <rect x="16.5" y="0.5" width="23" height="23" rx="11.5" fill="white" stroke="#0075FF" />
  </svg>
));

SwitchOn.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SwitchOn.displayName = 'SwitchOn';

export default SwitchOn;
