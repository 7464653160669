import { useCallback, useEffect, useRef, useState } from 'react';

const DEFAULT_DURATION = 60 * 1000;

function useAsyncPoll(fn, msDuration = DEFAULT_DURATION) {
  const [isPolling, setIsPolling] = useState(false);

  const interval = useRef(null);

  const startPolling = useCallback(() => {
    clearInterval(interval.current);
    interval.current = setInterval(fn, msDuration);
    setIsPolling(true);
  }, [fn, msDuration]);

  const stopPolling = useCallback(() => {
    clearInterval(interval.current);
    setIsPolling(false);
  }, [setIsPolling]);

  useEffect(() => {
    startPolling();

    return () => {
      stopPolling();
    };
  }, [fn, msDuration, startPolling, stopPolling]);

  return {
    startPolling,
    stopPolling,
    isPolling
  };
}

export default useAsyncPoll;
