import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Feed = forwardRef(({ color = 'currentColor', ...rest }, ref) => (
  <svg
    ref={ref}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke={color}
    {...rest}
  >
    <path
      d="M3.00019 2.40798L3 6.65645C3.00019 7.07753 3.34428 7.422 3.76536 7.42181L4.67754 7.42174L4.55041 14.4253C4.55041 14.7357 4.69626 15 5.50012 15C6.50012 15 6.50012 14.7356 6.50012 14.4252L6.37031 7.42181L7.12403 7.42174C7.54473 7.42174 7.88902 7.07746 7.88939 6.65637L7.88902 2.40771"
      stroke={color}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.59961 5.76852V2.5"
      stroke={color}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.19922 5.76852L6.1996 2.5"
      stroke={color}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.9998 9.48606L12.996 2.74969C12.9887 2.3222 13.0099 1.89886 12.6615 2.03609C12.6615 2.03609 11.362 2.03614 10.9999 3.50018L9.68359 8.66767L11.4998 9.00012L11.374 14.4152C11.3744 14.7315 11.362 15.0001 11.9998 15.0001C13.003 15.0001 13.0026 14.7307 13.003 14.4148L12.9998 9.48606Z"
      stroke={color}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

Feed.propTypes = {
  color: PropTypes.string
};

Feed.displayName = 'Feed';

export default Feed;
