import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Visit = forwardRef(({ color = 'currentColor', ...rest }, ref) => (
  <svg
    ref={ref}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke={color}
    {...rest}
  >
    <path
      d="M8.00023 6.15389C8.51003 6.15389 8.9233 5.74062 8.9233 5.23082C8.9233 4.72101 8.51003 4.30774 8.00023 4.30774C7.49042 4.30774 7.07715 4.72101 7.07715 5.23082C7.07715 5.74062 7.49042 6.15389 8.00023 6.15389Z"
      fill="#00A3FF"
    />
    <path
      d="M11.2311 5.23077C11.2311 8.46154 8.0003 10.3077 8.0003 10.3077C8.0003 10.3077 4.76953 8.46154 4.76953 5.23077C4.76953 4.37392 5.10992 3.55216 5.7158 2.94627C6.32169 2.34038 7.14345 2 8.0003 2C8.85715 2 9.67891 2.34038 10.2848 2.94627C10.8907 3.55216 11.2311 4.37392 11.2311 5.23077Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1538 9.5658C13.2915 9.9858 14 10.5766 14 11.2308C14 12.5052 11.3138 13.5385 8 13.5385C4.68615 13.5385 2 12.5052 2 11.2308C2 10.5766 2.70846 9.9858 3.84615 9.5658"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

Visit.propTypes = {
  color: PropTypes.string
};

Visit.displayName = 'Visit';

export default Visit;
