import constants from 'appConstants';
import dayjs from 'dayjs';
import CustomTooltip from '../components/CustomTooltip';

export const columnDefs = [
    {
        headerName: 'BH ID',
        field: 'bhome_id',
        maxWidth: 110,
    },
    {
        headerName: 'Status',
        field: 'status',
        maxWidth: 140,
        tooltipComponent: CustomTooltip,
        headerTooltip: 'Header',
    },
    {
        headerName: 'Updated At',
        field: 'updated_at',
        valueFormatter: params => new Date(params.data.updated_at).toLocaleString(),
        comparator: (valueA, valueB) => dayjs(valueA).unix() - dayjs(valueB).unix(),
    },
    {
        headerName: 'Process stuck',
        field: 'process_stuck',
    },
    {
        headerName: 'Online / offline',
        field: 'connected',
    },
    {
        headerName: 'New MW version',
        field: 'new_middleware_version',
    },
    {
        headerName: 'New FW version',
        field: 'new_fw_version',
    },
    {
        headerName: 'New Vision version',
        field: 'new_vision_version',
    },
    {
        headerName: 'User',
        field: 'user',
    },
];

const isProcessStuck = ({ message }) => {
    if (message.status === constants.MESSAGE_STATUS.PROCESSING) {
        const lastUpdated = new Date(message.updated_at);
        const now = new Date();
        const timeDifference = (now - lastUpdated) / (1000 * 60 * 60);
        return timeDifference > 1;
    }
    return false;
};

export const getDataToDisplay = ({ messages, connectivity }) => {
    const itemsToFilter = messages.reduce((acc, message) => {
        const connected = !!connectivity?.find(item => item.id === message.bhome_id)?.connected;
        acc.push({
            bhome_id: message.bhome_id,
            status: message.status,
            updated_at: message.updated_at,
            process_stuck: isProcessStuck({ message }),
            connected,
            new_middleware_version: message.payload?.new_config?.general?.software_updates?.new_middleware_version,
            new_fw_version: message.payload?.new_config?.general?.software_updates?.new_firmware_version,
            new_vision_version: message.payload?.new_config?.general?.software_updates?.new_camera_server_version,
            user: message.user,
        });
        return acc;
    }, []);

    return itemsToFilter;
};

export const columnModalDefs = [
    {
        headerName: 'Status',
        field: 'status',
        maxWidth: 140,
        tooltipComponent: CustomTooltip,
        headerTooltip: 'Header',
    },
    {
        headerName: 'Status change time',
        field: 'updated_at',
        valueFormatter: params => new Date(params.data.updated_at).toLocaleString(),
        comparator: (valueA, valueB) => dayjs(valueA).unix() - dayjs(valueB).unix(),
    },
    {
        headerName: 'User',
        field: 'user',
    },
    {
        headerName: 'MW version',
        field: 'new_middleware_version',
    },
    {
        headerName: 'FW version',
        field: 'new_fw_version',
    },
    {
        headerName: 'Vision version',
        field: 'new_vision_version',
    },
];
