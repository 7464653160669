import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@beewise/text-field';
import './SearchableGrid.scss';

const SearchableGrid = forwardRef(
  (
    { isSearchHidden = false, onSearch = () => undefined, searchClassName, ...DataGridProps },
    ref
  ) => (
    <div className={searchClassName || 'searchable-grid-container'}>
      {!isSearchHidden && (
        <div className="search-wrapper">
          <TextField name="Search" label="Search" onChange={onSearch} />
        </div>
      )}
      <div className="grid-wrapper">
        {/* api https://mui.com/x/react-data-grid */}
        <DataGrid {...DataGridProps} ref={ref} />
      </div>
    </div>
  )
);

export default SearchableGrid;

SearchableGrid.propTypes = {
  isSearchHidden: PropTypes.bool,
  searchClassName: PropTypes.string,
  onSearch: PropTypes.func.isRequired
};
