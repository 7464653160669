import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const New = forwardRef(({ color = 'currentColor', size = 10, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 10 10"
    fill="#0075FF"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <circle cx="5" cy="5" r="5" />
  </svg>
));

New.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

New.displayName = 'New';

export default New;
