export const cameraTypes = [
    {
        label: 'Tech Cameras',
        value: 'tech_cameras',
    },
    {
        label: 'Nav Cameras',
        value: 'nav_cameras',
    },
    {
        label: 'Scan Cameras',
        value: 'scan_cameras',
    },
];

export const cameraIds = [
    {
        label: 'A',
        value: 'a',
    },
    {
        label: 'B',
        value: 'b',
    },
];

export const qualities = [
    {
        label: 'Low',
        value: 'ld',
    },
    {
        label: ' Full HD',
        value: 'fhd',
    },
];
