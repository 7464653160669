import {
    FETCH_LAST_MESSAGES_BY_DATE_AND_COMMAND,
    FETCH_MESSAGES_SINCE_LAST_SUCCESS_BY_BHOME_ID_AND_COMMAND,
} from '../actionTypes';

export const fetchLastMessagesByDateAndCommand = (from, to, command) => ({
    type: 'FETCH',
    params: {
        type: FETCH_LAST_MESSAGES_BY_DATE_AND_COMMAND.default,
        url: `/messages/last-by-command?from=${from}&to=${to}${command ? `&command=${command}` : ''}`,
    },
});

export const fetchMessagesSinceLastSuccessByBhomeIdAndCommand = (bhomeId, command, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_MESSAGES_SINCE_LAST_SUCCESS_BY_BHOME_ID_AND_COMMAND.default,
        url: `/messages/bhomes/${bhomeId}/since-last-success?${command ? `&command=${command}` : ''}`,
        resolver,
    },
});
