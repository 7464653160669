import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { useDispatch } from 'react-redux';
import { fetchCancelJenkinsJob } from '../../actions';

const CancelRenderer = ({ value, data }) => {
    const dispatch = useDispatch();

    const handleCancelJob = useCallback(() => {
        dispatch(fetchCancelJenkinsJob(data.bhome_id, value));
    }, [data.bhome_id, dispatch, value]);

    if (
        !value ||
        data.build_status === 'failure' ||
        data.build_status === 'success' ||
        data.build_status === 'aborted' ||
        data.build_status === 'canceled'
    ) {
        return null;
    }

    return (
        <Button className="cancel-job-renderer" onClick={handleCancelJob}>
            Cancel Job
        </Button>
    );
};

CancelRenderer.propTypes = {
    value: PropTypes.string,
    data: PropTypes.shape(),
};

export default CancelRenderer;
