import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Harvest = forwardRef(({ fill = '#4E4540', ...rest }, ref) => (
  <svg
    ref={ref}
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M3.41162 14.7467C3.41162 15.2271 3.79542 15.6201 4.2645 15.6201C4.73358 15.6201 5.11738 15.2271 5.11738 14.7467C5.11738 14.2576 4.2645 13 4.2645 13C4.2645 13 3.41162 14.2576 3.41162 14.7467Z"
      fill={fill}
    />
    <path
      d="M6.82324 12.5065C6.82324 12.9868 7.20704 13.3798 7.67612 13.3798C8.1452 13.3798 8.529 12.9868 8.529 12.5065C8.529 12.0174 7.67612 10.7598 7.67612 10.7598C7.67612 10.7598 6.82324 12.0174 6.82324 12.5065Z"
      fill={fill}
    />
    <path
      d="M0 11.6334C0 12.1138 0.383795 12.5068 0.852879 12.5068C1.32196 12.5068 1.70576 12.1138 1.70576 11.6334C1.70576 11.1444 0.852879 9.88672 0.852879 9.88672C0.852879 9.88672 0 11.1444 0 11.6334Z"
      fill={fill}
    />
    <rect x="2" y="1" width="1" height="8" rx="0.5" fill={fill} />
    <rect x="4" width="1" height="10" rx="0.5" fill={fill} />
    <rect x="8" y="2" width="1" height="6" rx="0.5" fill={fill} />
    <rect x="6" width="1" height="10" rx="0.5" fill={fill} />
    <rect x="19" y="4" width="2" height="11" rx="1" transform="rotate(90 19 4)" fill={fill} />
    <rect y="2" width="1" height="6" rx="0.5" fill={fill} />
  </svg>
));

Harvest.propTypes = {
  fill: PropTypes.string
};

Harvest.displayName = 'Harvest';

export default Harvest;
