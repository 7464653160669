import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Card from 'components/reusables/Card';
import constants from 'appConstants';
import { SelectField } from '@beewise/select-field';
import TechButton from 'components/reusables/TechButton';
import { isNull } from 'lodash-es';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import { sideOptions, stateOptions, colorOptions, getTypeOptions } from './utils';

const LightsV4 = ({ bhome, isEMTester, isFLTester, isNavCalibrationInProgress }) => {
    const [type, setType] = useState(null);
    const [color, setColor] = useState(null);
    const [side, setSide] = useState(null);
    const [lightState, setLightState] = useState(null);
    const dispatch = useDispatch();

    const typeOptions = getTypeOptions({ isEMTester, isFLTester });

    const handleTypeChange = useCallback(value => {
        setType(value);
        setColor(null);
        setSide(null);
        setLightState(null);
    }, []);

    const getParams = useCallback(() => {
        const params = {
            type,
            color,
            side,
            state: lightState,
        };

        if (type === 'tech' || type === 'beehome') {
            delete params.side;
        }

        return params;
    }, [color, lightState, side, type]);

    const handleSend = useCallback(() => {
        const params = getParams();

        dispatch(
            fetchSendTechnicianAction(bhome?.id, {
                type: constants.COMMANDS.LIGHT_SWITCH,
                params,
            })
        );
    }, [bhome?.id, dispatch, getParams]);

    const handleSendAll = useCallback(
        state => () => {
            dispatch(
                fetchSendTechnicianAction(bhome?.id, {
                    type: constants.COMMANDS.LIGHT_SWITCH,
                    params: {
                        type: 'all',
                        state,
                    },
                })
            );
        },
        [bhome?.id, dispatch]
    );

    const isSendDisabled = useMemo(
        () => Object.values(getParams()).some(value => isNull(value)) || isNavCalibrationInProgress,
        [getParams, isNavCalibrationInProgress]
    );

    return (
        <div className="lights-center">
            <Card className="lights-v4" title="Lights Control">
                <div className="lights-v4-selects">
                    <SelectField
                        options={typeOptions}
                        placeholder="Select Type"
                        onChange={handleTypeChange}
                        value={type}
                        size="small"
                    />
                    <SelectField
                        options={colorOptions(type)}
                        placeholder="Select Color"
                        onChange={setColor}
                        value={color}
                        size="small"
                    />
                    {type !== 'tech' && type !== 'beehome' && (
                        <SelectField
                            options={sideOptions}
                            placeholder="Select Side"
                            onChange={setSide}
                            value={side}
                            size="small"
                        />
                    )}
                    <SelectField
                        options={stateOptions}
                        placeholder="Select State"
                        onChange={setLightState}
                        value={lightState}
                        size="small"
                    />
                </div>
                <TechButton
                    className="send btn-primary"
                    onClick={handleSend}
                    disabled={isSendDisabled}
                    showToManufacturer
                    enabledInNonError
                >
                    Send Lights Command
                </TechButton>
                <TechButton
                    className="send btn-primary"
                    onClick={handleSendAll(1)}
                    showToManufacturer
                    disabled={isNavCalibrationInProgress}
                    enabledInNonError
                >
                    Turn On All Lights
                </TechButton>
                <TechButton
                    className="send btn-primary"
                    onClick={handleSendAll(0)}
                    showToManufacturer
                    disabled={isNavCalibrationInProgress}
                    enabledInNonError
                >
                    Turn Off All Lights
                </TechButton>
            </Card>
        </div>
    );
};

LightsV4.propTypes = {
    bhome: PropTypes.shape(),
    isEMTester: PropTypes.bool,
    isFLTester: PropTypes.bool,
    isNavCalibrationInProgress: PropTypes.bool,
};

export default LightsV4;
