import './CustomTooltip.scss';
import { getTooltip, groupTooltipData } from './utils';
// overriding ag-grid tooltip
// https://www.ag-grid.com/javascript-data-grid/component-tooltip/#header-tooltip-with-custom-tooltip

class CustomTooltip {
    eGui;

    init(params) {
        this.eGui = document.createElement('div');
        const { eGui } = this;
        const isHeader = params.rowIndex === undefined;
        let str;
        const data = groupTooltipData(params);

        eGui.classList.add('ag-custom-tooltip');

        if (isHeader) {
            str = getTooltip(data);
            eGui.innerHTML = str;
        }
    }

    getGui() {
        return this.eGui;
    }
}

export default CustomTooltip;
