/* eslint-disable import/no-unresolved */
import ReactGA from 'react-ga';
import { UI_ENV } from 'config';
import { get } from 'lodash-es';
import { auth } from '@beewise/react-utils';
import constants from 'appConstants';

const analyticsConstants = {
    EVENT_CATEGORIES: {
        STOP: 'stop',
        GENERAL: 'general',
        FRAME_ACTION: 'frameAction',
        GENERAL_ROBOT: 'generalRobot',
        GET_LOGS: 'getLogs',
        UPDATE_FRAME: 'updateFrame',
    },
};

/**
 * @param {Object} event
 * @property {string} event.category
 * @property {string} event.action
 * @property {string} [event.label]
 */
const sendGAEvent = event => {
    if (
        UI_ENV === 'prod' &&
        get(auth.getUser(), 'role', '') !== constants.ROLES.SUPER_ADMIN &&
        get(auth.getUser(), 'role', '') !== constants.ROLES.SUPER_VIEWER
    ) {
        ReactGA.event(event);
    }
};

export default {
    fireStopEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.STOP,
            ...event,
        }),
    fireGeneralEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.GENERAL,
            ...event,
        }),
    fireFrameEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.UPDATE_FRAME,
            ...event,
        }),
    fireFrameActionEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.FRAME_ACTION,
            ...event,
        }),
    fireGetLogsActionEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.GET_LOGS,
            ...event,
        }),
    fireGeneralRobotEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.GENERAL_ROBOT,
            ...event,
        }),
};
