const getMaxHiveCapacity = (bhome) => {
  if (bhome && bhome.settings) {
    if (bhome.settings.ui_settings) {
      return bhome.settings.ui_settings.numOfSlotsPerHive;
    }
    if (
      bhome.settings.defaults &&
      bhome.settings.defaults.ui_settings &&
      bhome.settings.defaults.ui_settings.numOfSlotsPerHive
    ) {
      return bhome.settings.defaults.ui_settings.numOfSlotsPerHive;
    }
    if (
      bhome.settings.machine_specific &&
      bhome.settings.machine_specific.ui_settings &&
      bhome.settings.machine_specific.ui_settings.numOfSlotsPerHive
    ) {
      return bhome.settings.machine_specific.ui_settings.numOfSlotsPerHive;
    }
  }
  return 30;
};

export default getMaxHiveCapacity;
