import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const BeeQueen = forwardRef(({ color = 'currentColor', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <g clipPath="url(#clip0_286_4786)">
      <path
        d="M5.96874 10.5409C7.72572 8.78392 8.48942 6.69899 7.67451 5.8841C6.85961 5.0692 4.77469 5.83292 3.01771 7.58992C1.26074 9.34691 0.497036 11.4318 1.31194 12.2467C2.12684 13.0616 4.21176 12.2979 5.96874 10.5409Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 6.97104C8.18608 9.20857 11.3786 12.4025 13.6176 12.5887C14.2422 12.6406 15.1327 12.4205 15.0293 11.177C14.8432 8.93945 11.6507 5.74555 9.41168 5.55935C9.15726 5.52968 8.89943 5.56019 8.65895 5.6484"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00891 11.532C5.50833 15.1272 8.03984 16.3287 8.03984 16.3287C8.03984 16.3287 10.5545 15.1289 11.0089 11.5414"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.64953 5.71215C5.55244 5.53672 5.50102 5.33969 5.5 5.13918C5.5 4.21245 6.61929 3.46118 8 3.46118C9.38071 3.46118 10.5 4.21245 10.5 5.13918C10.4991 5.33333 10.4508 5.52433 10.3593 5.69556"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 12C5 12 5.5 11.5 6.00159 11.3439C6.50317 11.1878 7.31894 10.9928 8.00059 11.0003C8.68277 10.9932 9.50317 11.1858 10.0016 11.3429C10.5 11.5 11 12 11 12"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0016 13.8501C9.50317 13.693 8.68277 13.5004 8.00059 13.5075C7.31894 13.5 6.50317 13.695 6.00159 13.8511"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66663 2.57227C3.76157 2.78318 4.77744 3.29111 5.60313 4.04052"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2699 2.57227C12.1749 2.78318 11.1591 3.29111 10.3334 4.04052"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33337 2.71386V1.04053L6.66671 2.04053L8.00004 1.04053L9.33337 2.04053L10.6667 1.04053V3.61019"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_286_4786">
        <rect width="16" height="16" fill="white" transform="translate(0 0.707275)" />
      </clipPath>
    </defs>
  </svg>
));

BeeQueen.propTypes = {
  color: PropTypes.string
};

BeeQueen.displayName = 'BeeQueen';

export default BeeQueen;
