export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm';

export const LEVEL_OPTIONS = [
    {
        label: 'Debug',
        value: 'DEBUG',
    },
    {
        label: 'Info',
        value: 'INFO',
    },
    {
        label: 'Warning',
        value: 'WARNING',
    },
    {
        label: 'Warn',
        value: 'WARN',
    },
    {
        label: 'Error',
        value: 'ERROR',
    },
    {
        label: 'Fatal',
        value: 'FATAL',
    },
    {
        label: 'Critical',
        value: 'CRITICAL',
    },
];
