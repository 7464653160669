import constants from '@beewise/constants';
import {
    FETCH_ALL_BHOMES,
    SET_CURRENT_BHOME,
    FETCH_CHANGE_BHOME_STATUS,
    FETCH_STOP_BHOME,
    FETCH_TECHNICIAN_ACTIVITIES,
    FETCH_CURRENT_BHOME,
    SET_LIVE_VIDEO_URL,
    FETCH_ALL_VIEWS,
    FETCH_CREATE_VIEW,
    FETCH_DELETE_VIEW,
    FETCH_DOWNLOAD_FAT_IMG,
} from '../actionTypes';
import { FETCH_SEND_TECHNICIAN_ACTION, MODIFY_MODE } from '../components/views/ActionsAndMonitoring/actionTypes';

export const fetchAllBhomes = (idOnly = false) => ({
    type: 'FETCH',
    params: {
        type: FETCH_ALL_BHOMES.default,
        url: `/bhomes/get-all${idOnly ? '?idOnly=true' : ''}`,
    },
});
export const fetchCurrentBhome = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_CURRENT_BHOME.default,
        url: `/bhomes/${id}`,
    },
});

export const fetchChangeBhomeStatus = (id, status) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: FETCH_CHANGE_BHOME_STATUS.default,
        url: `/bhomes/${id}/change-bhome-status`,
        body: {
            status,
        },
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const setCurrentBhome = bhomeId => ({
    type: SET_CURRENT_BHOME,
    bhomeId,
});

export const fetchStopBhome = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_STOP_BHOME.default,
        url: `/bhomes/${bhomeId}/stop`,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchTechnicianActivities = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_TECHNICIAN_ACTIVITIES.default,
        url: `/messages/technician/${bhomeId}`,
    },
});

export const setLiveVideoUrl = payload => ({
    type: SET_LIVE_VIDEO_URL,
    payload,
});

export const fetchSendTechnicianAction = (id, body, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_SEND_TECHNICIAN_ACTION.default,
        method: 'POST',
        url: `/bhomes/${id}/send-technician-action`,
        body,
        resolver,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const modifyMode = (bhomeId, mode, action, resolver) => ({
    type: 'FETCH',
    params: {
        type: MODIFY_MODE.default,
        method: 'POST',
        url: `/bhomes/v4/${bhomeId}/modes/${mode}/${action}`,
        body: {
            from: constants.MQ_MESSAGE_CREATORS.TECHNICIAN,
        },
        resolver,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchAllViews = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_ALL_VIEWS.default,
        url: '/bhomes/v4/monitoring/views',
    },
});

export const fetchDownloadFatImg = resolver => ({
    type: 'FETCH',
    params: {
        type: FETCH_DOWNLOAD_FAT_IMG.default,
        url: '/bhomes/v4/master-card-fat-img',
        resolver,
    },
});

export const fetchCreateView = ({ body, resolver }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_VIEW.default,
        method: 'POST',
        url: '/bhomes/v4/monitoring/views',
        body,
        resolver,
        toastText: 'View created',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchCancel = ({ bhomeId }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_VIEW.default,
        method: 'POST',
        url: `/bhomes/v4/${bhomeId}/cancel`,
        toastText: 'Command sent',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchDeleteView = (viewId, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_VIEW.default,
        method: 'DELETE',
        url: `/bhomes/v4/monitoring/views/${viewId}`,
        resolver,
        toastText: 'View deleted',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});
