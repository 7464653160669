import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/reusables/Card';
import TechButton from 'components/reusables/TechButton';
import { useToggle } from '@beewise/react-utils';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import constants from 'appConstants';
import { clearLogsToDownload } from '../../actions';
import { getLogsError, getLogsForDownload } from '../../selectors';
import { LEVEL_OPTIONS } from './logsConstants';

const BeehomeLogs = ({ bhome }) => {
    const dispatch = useDispatch();

    const logsError = useSelector(getLogsError);
    const logsToDownload = useSelector(getLogsForDownload);

    const level = LEVEL_OPTIONS[0].value;

    const [downloadStarted, , startDownload, stopDownload] = useToggle(false);

    useEffect(() => {
        if (logsToDownload && downloadStarted) {
            const a = document.createElement('a');
            a.href = logsToDownload;
            a.click();
            stopDownload();
        }

        dispatch(clearLogsToDownload());
    }, [dispatch, downloadStarted, logsToDownload, stopDownload]);

    const handleDownloadLogs = useCallback(() => {
        startDownload();
        dispatch(
            fetchSendTechnicianAction(bhome.id, {
                type: constants.COMMANDS.GET_LOGS,
                params: {
                    type: 'file',
                    level,
                },
            })
        );
    }, [bhome.id, dispatch, level, startDownload]);

    const handleDownloadJetsonLogs = useCallback(() => {
        startDownload();
        dispatch(
            fetchSendTechnicianAction(bhome.id, {
                type: constants.COMMANDS.GET_LOGS,
                params: {
                    type: 'jetson',
                    level,
                },
            })
        );
    }, [bhome.id, dispatch, level, startDownload]);

    const handleDownloadAllPiLogs = useCallback(() => {
        startDownload();
        dispatch(
            fetchSendTechnicianAction(bhome.id, {
                type: constants.COMMANDS.GET_LOGS,
                params: {
                    type: 'all-files',
                    level,
                },
            })
        );
    }, [bhome.id, dispatch, level, startDownload]);

    const handleDownloadAllJetsonLogs = useCallback(() => {
        startDownload();
        dispatch(
            fetchSendTechnicianAction(bhome.id, {
                type: constants.COMMANDS.GET_LOGS,
                params: {
                    type: 'all-jetson',
                    level,
                },
            })
        );
    }, [bhome.id, dispatch, level, startDownload]);

    return (
        <div className="beehome-logs-center">
            <Card className="bhome-logs" title="Beehome Logs">
                <div className="logs-section flex-line flex-line-left">
                    <TechButton className="download-logs btn-primary" onClick={handleDownloadLogs} alwaysEnabled>
                        Download logs
                    </TechButton>
                    <TechButton className="download-logs btn-primary" onClick={handleDownloadJetsonLogs} alwaysEnabled>
                        Download jetson logs
                    </TechButton>
                    <TechButton className="download-logs btn-primary" onClick={handleDownloadAllPiLogs} alwaysEnabled>
                        Download all pi logs
                    </TechButton>
                    <TechButton
                        className="download-logs btn-primary"
                        onClick={handleDownloadAllJetsonLogs}
                        alwaysEnabled
                    >
                        Download all jetson logs
                    </TechButton>
                </div>
                {logsError && <div className="bhome-logs-error">{logsError}</div>}
            </Card>
        </div>
    );
};

BeehomeLogs.propTypes = {
    bhome: PropTypes.shape(),
};

export default BeehomeLogs;
