import cn from 'classnames';
import React from 'react';

const Error = ({ info, error }) => (
  <>
    {(info || error) && (
      <p
        className={cn('text-field-info', {
          'text-field--error text-field--error-message': error
        })}
      >
        {error ||
          (Array.isArray(info)
            ? info.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
            : info)}
      </p>
    )}
  </>
);

export default Error;
