import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/reusables/Card';
import constants from 'appConstants';
import TechButton from 'components/reusables/TechButton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SearchableGrid from '@beewise/searchable-grid';
import { clearRobotVersions } from '../../actions';
import { convertOptionName } from '../../utils';

const RobotVersions = ({ handleTechnicianActionClick, bhome }) => {
    const robotVersions = useSelector(state => state.actionsMonitoring.robotVersions, shallowEqual);

    const dispatch = useDispatch();
    const handleClickRobotVersions = useCallback(() => {
        dispatch(clearRobotVersions());
        handleTechnicianActionClick(constants.COMMANDS.GET_ROBOT_VERSIONS)();
    }, [handleTechnicianActionClick, dispatch]);

    const columns = [
        {
            field: 'robot',
            headerName: 'Robot',
            headerClassName: 'header-disabled',
            cellClassName: 'left-column',
            flex: 1,
            sortable: false,
        },
        {
            field: 'swVersion',
            renderHeader: () => <span style={{ fontWeight: 'bold' }}>SW Version</span>,
            headerClassName: 'header-background',
            flex: 1.2,
            sortable: false,
        },
        {
            field: 'hwVersion',
            renderHeader: () => <span style={{ fontWeight: 'bold' }}>HW Version</span>,
            headerClassName: 'header-background',
            flex: 1.2,
            sortable: false,
        },
    ];
    const gridData = useMemo(() => {
        const gridDataArray = Object.keys(robotVersions).map((option, index) => ({
            id: `robot_version_${index}`,
            robot: convertOptionName(option),
            swVersion: Array.isArray(robotVersions[option])
                ? robotVersions[option]?.[0] || '-'
                : robotVersions[option] || '-',
            hwVersion: Array.isArray(robotVersions[option]) ? robotVersions[option]?.[1] || '-' : '-',
        }));

        if (Object.keys(robotVersions)?.length <= 1) {
            const middlewareVersion = bhome?.configuration?.middleware_version || '-';
            const firmwareVersion =
                `
                ${bhome?.configuration?.fld ? `FLD: ${bhome?.configuration?.fld}, ` : ''}
                ${bhome?.configuration?.fls ? `FLS: ${bhome?.configuration?.fls}, ` : ''}
                ${bhome?.configuration?.mcb ? `MCB: ${bhome?.configuration?.mcb}` : ''}
                ` || '-';

            gridDataArray.unshift({
                id: 'default_versions',
                robot: 'Versions',
                swVersion: middlewareVersion,
                hwVersion: firmwareVersion,
                className: 'robot-versions-left-column',
            });
        }

        return gridDataArray;
    }, [robotVersions, bhome]);

    return (
        <div className="robot-versions-center">
            <Card className="robot-versions" title="Robot Versions">
                <TechButton
                    className="btn-primary robot-versions-get-button"
                    onClick={handleClickRobotVersions}
                    alwaysEnabled
                    showToManufacturer
                >
                    Get Robot Versions
                </TechButton>
                <SearchableGrid
                    rows={gridData}
                    columns={columns}
                    autoHeight
                    disableColumnMenu
                    isSearchHidden
                    sx={{
                        '& .MuiDataGrid-main': { fontFamily: 'Poppins', fontSize: '12px', lineHeight: '12px' },
                    }}
                    rowHeight={35}
                    columnHeaderHeight={40}
                />
            </Card>
        </div>
    );
};

RobotVersions.propTypes = {
    bhome: PropTypes.shape(),
    handleTechnicianActionClick: PropTypes.func,
};

export default RobotVersions;
