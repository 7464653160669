import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from '@beewise/modal';
import Grid from 'components/reusables/Grid';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { useSelector } from 'react-redux';
import './BhUpdateHistoryModal.scss';
import { columnModalDefs, getDataToDisplay } from '../../utils';
import { getMessagesSinceLastSuccessByBhomeIdAndCommand } from '../../selectors';

const BhUpdateHistoryModal = ({ isOpen, handleOnClose }) => {
    const messages = useSelector(getMessagesSinceLastSuccessByBhomeIdAndCommand, arrayOfObjectsShallowEqual);
    const dataToDisplay = useMemo(
        () =>
            getDataToDisplay({
                messages,
            }),
        [messages]
    );
    return (
        <Modal header="BH Update History" isOpen={isOpen} onClose={handleOnClose} className="history-modal">
            <Grid rowData={dataToDisplay} columnDefs={columnModalDefs} hideDownload hideSearch />
        </Modal>
    );
};

BhUpdateHistoryModal.propTypes = {
    isOpen: PropTypes.bool,
    handleOnClose: PropTypes.func,
};

export default BhUpdateHistoryModal;
