import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@beewise/text-field';
import { isEqual, get, set } from 'lodash-es';
import { JsonEditor as Editor } from 'components/reusables/JsonEditor';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import Checkbox from '@beewise/checkbox-field';
import TechButton from 'components/reusables/TechButton';
import editableFields from '../../utils/editableFields';

const BhomeSettings = ({
    currentSettings,
    handleSettingsChange,
    fooKey,
    handleSaveSettings,
    isSubmitDisabled,
    toggleHashCheck,
    disableHashCheck,
    isTextMode,
    toggleMode,
    isViewMode,
    toggleViewMode,
    toggleEnforceImmediateAction,
    enforceImmediateAction,
}) => {
    const areSettingsSame = useMemo(
        () => isEqual(currentSettings.data.settings, currentSettings.machine_data.settings),
        [currentSettings]
    );
    const oldValue = useMemo(() => JSON.stringify(currentSettings.machine_data.settings, null, 4), [currentSettings]);
    const newValue = useMemo(() => JSON.stringify(currentSettings.data.settings, null, 4), [currentSettings]);

    const settingsToChange = useMemo(() => {
        if (isViewMode) {
            return currentSettings;
        }

        const res = {};
        editableFields(currentSettings).forEach(field => {
            set(res, field, get(currentSettings, field, null));
        });
        return res;
    }, [currentSettings, isViewMode]);

    const handleEditorChange = useCallback(
        settings => !isViewMode && handleSettingsChange({ settings }),
        [isViewMode, handleSettingsChange]
    );

    const mode = useMemo(() => {
        if (isViewMode) {
            return 'view';
        }
        return isTextMode ? 'text' : 'tree';
    }, [isTextMode, isViewMode]);

    return (
        <>
            <div className="config-content">
                <div className="config-blog">
                    <TextField label="Bhome ID" value={currentSettings.bhome_id} disabled size="small" />
                    <TextField
                        className="config-comment"
                        label="Comment"
                        value={currentSettings.comment || ''}
                        onChange={value => handleSettingsChange({ comment: value }, true)}
                        size="small"
                    />
                </div>
                <div key={`${fooKey}-editor`}>
                    <div className="config-blog">
                        <Checkbox
                            disabled={isViewMode}
                            className="config-mode-switch"
                            label="Text Mode"
                            checked={!isViewMode && isTextMode}
                            onChange={toggleMode}
                        />
                        <Checkbox
                            className="config-mode-switch"
                            label="View All Mode"
                            checked={isViewMode}
                            onChange={toggleViewMode}
                        />
                    </div>
                    <Editor mode={mode} value={settingsToChange.data.settings} onChange={handleEditorChange} copy />
                </div>
                <Checkbox
                    className="config-hash-checkbox"
                    onChange={toggleHashCheck}
                    checked={disableHashCheck}
                    label="Disable Hash Check"
                />
                <Checkbox
                    className="config-immediate-checkbox"
                    onChange={toggleEnforceImmediateAction}
                    checked={enforceImmediateAction}
                    label="Enforce Immediate"
                />
                <TechButton
                    className="axis-get-button btn-primary"
                    onClick={handleSaveSettings}
                    disabled={isSubmitDisabled}
                    alwaysEnabled={!isSubmitDisabled}
                >
                    Save
                </TechButton>
            </div>
            {!areSettingsSame && (
                <div className="config-content">
                    <ReactDiffViewer
                        leftTitle="Machine Config"
                        rightTitle="Cloud config"
                        compareMethod={DiffMethod.LINES}
                        splitView
                        oldValue={oldValue}
                        newValue={newValue}
                    />
                </div>
            )}
        </>
    );
};

BhomeSettings.propTypes = {
    currentSettings: PropTypes.shape(),
    handleSettingsChange: PropTypes.func,
    toggleHashCheck: PropTypes.func,
    handleSaveSettings: PropTypes.func,
    toggleMode: PropTypes.func,
    toggleViewMode: PropTypes.func,
    fooKey: PropTypes.number,
    isSubmitDisabled: PropTypes.bool,
    disableHashCheck: PropTypes.bool,
    isTextMode: PropTypes.bool,
    isViewMode: PropTypes.bool,
    toggleEnforceImmediateAction: PropTypes.func,
    enforceImmediateAction: PropTypes.bool,
};

export default BhomeSettings;
