export default (voltage) => {
  if (typeof voltage !== 'number') {
    return 100;
  }
  if (voltage >= 25) {
    return 100;
  } else if (voltage >= 24.8) {
    return 90;
  } else if (voltage >= 24.6) {
    return 80;
  } else if (voltage >= 24.4) {
    return 70;
  } else if (voltage >= 24.2) {
    return 60;
  } else if (voltage >= 24) {
    return 50;
  } else if (voltage >= 23.8) {
    return 40;
  } else if (voltage >= 23.5) {
    return 30;
  } else if (voltage >= 23.2) {
    return 20;
  } else if (voltage >= 22.5) {
    return 10;
  }
  return 0;
};
