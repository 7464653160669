import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from 'components/reusables/Grid';
import Button from '@beewise/button';
import constants from 'appConstants';
import TextField from '@beewise/text-field';
import DatePicker from 'react-datepicker';
import { loading } from '@beewise/react-utils';
import dayjs from 'dayjs';
import { FETCH_SEND_TECHNICIAN_ACTION } from 'components/views/ActionsAndMonitoring/actionTypes';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import { getCurrentBhomeId } from '../ActionsAndMonitoring/selectors';
import getGridOptions from './utils/getGridOptions';
import { clearHistoryData, clearPowerManagementView, fetchSolarHistoryData } from './actions';
import { FETCH_SOLAR_HISTORY_DATA } from './actionTypes';

const PowerManagement = () => {
    const gridRef = useRef();
    const [interval, setInterval] = useState(15);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const dispatch = useDispatch();
    const currentBhomeId = useSelector(getCurrentBhomeId);
    const powerDataRow = useSelector(state => state.powerManagement.newRow);
    const historyData = useSelector(state => state.powerManagement.historyData);

    const gridOptions = useMemo(() => getGridOptions(), []);

    useEffect(() => {
        if (gridRef?.current?.api && powerDataRow && !historyData) {
            gridRef.current.api.applyTransaction({
                addIndex: 0,
                add: [powerDataRow],
            });
        }
        if (historyData && !historyData.length && gridRef?.current?.api) {
            gridRef.current.api.setRowData([]);
        }
    }, [historyData, powerDataRow]);

    useEffect(() => () => dispatch(clearPowerManagementView()), [dispatch]);

    useEffect(() => {
        gridRef?.current?.api?.setRowData([]);
        dispatch(clearPowerManagementView());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBhomeId, dispatch]);

    const handleRunClick = useCallback(
        isStop => () => {
            if (interval > 0 || isStop) {
                dispatch(
                    fetchSendTechnicianAction(currentBhomeId, {
                        type: constants.COMMANDS.REPORT_POWER_DATA,
                        params: {
                            interval: isStop ? 0 : interval,
                        },
                    })
                );
            }
        },
        [currentBhomeId, dispatch, interval]
    );

    const handleChangeInterval = useCallback(value => setInterval(Number(value)), []);

    const handleGetHistoryData = useCallback(() => {
        if (dateRange && dateRange[0] && dateRange[1]) {
            dispatch(
                fetchSolarHistoryData(
                    currentBhomeId,
                    dayjs(dateRange[0]).startOf('day').valueOf(),
                    dayjs(dateRange[1]).endOf('day').valueOf()
                )
            );
        }
    }, [currentBhomeId, dateRange, dispatch]);

    const handleClearHistoryData = useCallback(() => {
        dispatch(clearHistoryData());
        setDateRange([null, null]);
    }, [dispatch]);

    const gridRowData = useMemo(() => historyData || [], [historyData]);

    return (
        <div className="power-management">
            <Grid
                key={`${currentBhomeId}-bhome-4`}
                ref={gridRef}
                rowData={gridRowData}
                gridOptions={gridOptions}
                filePrefix="power-management-"
                headerChildren={
                    <>
                        <TextField
                            min={0}
                            type="number"
                            onChange={handleChangeInterval}
                            value={interval}
                            label="Interval (seconds)"
                            size="small"
                        />
                        <Button className="btn-primary" onClick={handleRunClick()}>
                            Start
                        </Button>
                        <Button className="btn-primary" onClick={handleRunClick(true)}>
                            Stop
                        </Button>
                        <Button onClick={handleGetHistoryData}>Get History Data</Button>
                        <DatePicker
                            selectsRange
                            startDate={startDate}
                            endDate={endDate}
                            onChange={setDateRange}
                            withPortal
                        />
                        <Button onClick={handleClearHistoryData}>Clear History</Button>
                    </>
                }
            />
        </div>
    );
};

export default loading([FETCH_SEND_TECHNICIAN_ACTION.default, FETCH_SOLAR_HISTORY_DATA.default])(PowerManagement);
