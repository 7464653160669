import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const History = forwardRef(({ size = 21, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 21 21"
    fill="none"
    {...rest}
  >
    <path
      d="M21 10.4801C21.0107 16.2653 16.2928 20.9959 10.5076 20.9999C8.009 21.0017 5.7139 20.1307 3.91032 18.6751C3.44133 18.2966 3.40627 17.5937 3.83245 17.1675L4.30948 16.6905C4.67398 16.326 5.25588 16.2861 5.65971 16.6065C6.98851 17.6609 8.67016 18.2903 10.5 18.2903C14.806 18.2903 18.2903 14.8054 18.2903 10.5C18.2903 6.19394 14.8054 2.70968 10.5 2.70968C8.43327 2.70968 6.55619 3.5128 5.16244 4.82373L7.3113 6.97259C7.73807 7.39936 7.43581 8.12903 6.83232 8.12903H0.677419C0.303272 8.12903 0 7.82575 0 7.45161V1.29671C0 0.693211 0.729665 0.390955 1.15644 0.817687L3.24678 2.90803C5.13183 1.10656 7.68667 0 10.5 0C16.2923 0 20.9893 4.69028 21 10.4801ZM13.3404 13.8157L13.7563 13.281C14.1009 12.838 14.021 12.1996 13.5781 11.8551L11.8548 10.5148V6.09677C11.8548 5.53557 11.3999 5.08064 10.8387 5.08064H10.1613C9.60008 5.08064 9.14515 5.53557 9.14515 6.09677V11.8401L11.9145 13.994C12.3575 14.3385 12.9959 14.2587 13.3404 13.8157Z"
      fill="#4F4F4F"
    />
  </svg>
));

History.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

History.displayName = 'History';

export default History;
