import { FETCH_TRIGGER_MARK_HIVES } from '../actionTypes';

export const fetchTriggerMarkHives = bhomeId => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: FETCH_TRIGGER_MARK_HIVES.default,
        url: `/bhomes/v4/${bhomeId}/hives/mark`,
        toastText: 'Success',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});
