import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Bar, BarChart } from '@beewise/chart';
import { tickFormatter } from '../utils';

const CustomTooltip = ({ active, payload, label, name }) =>
    active &&
    payload &&
    payload[0] && (
        <div className="custom-tooltip">
            <div className="content">
                {payload[0].value} {name} / {dayjs.utc(label).format('MMM DD')}
            </div>
        </div>
    );

CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(PropTypes.shape()),
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
};

const ChartFramesPlot = ({ data, xAxisOptions, format, showPending, showCanceled }) => (
    <ResponsiveContainer width="95%" height={500}>
        <BarChart data={data}>
            <CartesianGrid />
            <XAxis
                dataKey="date"
                type="number"
                axisLine={false}
                tickLine={false}
                tickFormatter={tickFormatter}
                minTickGap={-999}
                allowDataOverflow
                {...xAxisOptions}
            />
            <YAxis
                type="number"
                domain={[0, 'auto']}
                axisLine={false}
                tickLine={false}
                allowDecimals={false}
                allowDataOverflow={false}
            />
            <Tooltip labelFormatter={label => dayjs(label).format(format)} allowEscapeViewBox={{ x: true, y: true }} />
            <Bar name="Success" fillOpacity={0.5} dataKey="success" stroke="#67C561" fill="#67C561" stackId="stack" />
            <Bar name="Failed" fillOpacity={0.5} dataKey="failed" stroke="#F22A2A" fill="#F22A2A" stackId="stack" />
            <Bar
                name="Pending"
                fillOpacity={0.5}
                dataKey={showPending ? 'pending' : ''}
                stroke={showPending ? '#4f2d21' : 'transparent'}
                fill={showPending ? '#4f2d21' : 'transparent'}
                stackId="stack"
            />
            <Bar
                name="Canceled"
                fillOpacity={0.5}
                dataKey={showCanceled ? 'canceled' : ''}
                stroke={showCanceled ? '#FEC22C' : 'transparent'}
                fill={showCanceled ? '#FEC22C' : 'transparent'}
                stackId="stack"
            />
        </BarChart>
    </ResponsiveContainer>
);

ChartFramesPlot.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape()),
    xAxisOptions: PropTypes.shape(),
    format: PropTypes.string,
    showPending: PropTypes.bool,
    showCanceled: PropTypes.bool,
};

export default ChartFramesPlot;
