import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { noop } from 'lodash-es';
import { useToggle } from '@beewise/react-utils';
import Button from '@beewise/button';

const SideMenu = ({ className, onToggle = noop, items = [], children }) => {
  const [isShown, toggle] = useToggle(true);
  const [isHovered, , setHoverTrue, setHoverFalse] = useToggle(false);

  const handleArrowClick = useCallback(() => {
    if (isShown) {
      setHoverFalse();
    }
    onToggle(!isShown);

    toggle();
  }, [isShown, setHoverFalse, onToggle, toggle]);

  const handleMenuHover = useCallback(() => {
    if (!isShown) {
      setHoverTrue();
      onToggle(true);
    }
  }, [setHoverTrue, onToggle, isShown]);

  const handleMenuLeave = useCallback(() => {
    if (!isShown && isHovered) {
      setHoverFalse();
      onToggle(false);
    }
  }, [setHoverFalse, onToggle, isShown, isHovered]);

  return (
    <nav
      className={cx({
        'side-menu': true,
        'side-menu_opened': isShown,
        'side-menu_closed': !isShown,
        'side-menu_hovering': isHovered,
        [className]: className
      })}
      onMouseOver={handleMenuHover}
      onFocus={handleMenuHover}
      onMouseLeave={handleMenuLeave}
    >
      <div className="side-menu__wrapper">
        <div className="side-menu__button-wrapper">
          <Button
            className={cx({
              'side-menu__button': true,
              'side-menu__button-right': !isShown,
              'side-menu__button-left': isShown
            })}
            iconType="chevron-right"
            onClick={handleArrowClick}
          />
        </div>
        <ul className="side-menu__list">
          {items.map((item) => (
            <li className="side-menu__list-item" key={item.link}>
              <NavLink
                className={({ isActive }) =>
                  `side-menu__list-item-link ${
                    isActive || item.isActive ? 'side-menu__list-item-link_active' : ''
                  } ${item.isDisabled ? 'side-menu__list-item-link_disabled' : ''}`
                }
                to={item.link}
              >
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
        {children}
      </div>
    </nav>
  );
};

SideMenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape()),
  onToggle: PropTypes.func,
  children: PropTypes.node
};

export default SideMenu;
