const singleton = Symbol('singleton');
const singletonEnforcer = Symbol('singleton-enforcer');

let instance;

class FetchIdMemento {
  static generateId() {
    return Date.now();
  }

  static get memento() {
    if (!this[singleton]) {
      this[singleton] = new FetchIdMemento(singletonEnforcer);
    }

    return this[singleton];
  }

  static proceedIfAllowed(func, isAllowed, alternativeFunc) {
    return isAllowed ? func : alternativeFunc;
  }

  get instance() {
    return instance;
  }

  set instance(value) {
    if (!instance) {
      instance = value;
    }
  }

  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct memento singleton');
    }

    this.instance = {};
    this.controllers = {};
  }

  registerType(type, id, controller) {
    if (this.instance[type]) {
      // abort not relevant request
      this.controllers[type] && this.controllers[type].abort();
    }

    this.instance[type] = id;
    this.controllers[type] = controller;
  }

  unregisterType(type) {
    delete this.instance[type];
  }

  getId(type) {
    return this.instance[type] || null;
  }

  isTypeIdEqualCurrent(type, currentId) {
    return this.getId(type) === currentId;
  }
}

export const { generateId, proceedIfAllowed } = FetchIdMemento;

export default FetchIdMemento.memento;
