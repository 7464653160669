import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Honey = forwardRef(({ color = 'currentColor', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <g clipPath="url(#clip0_286_4880)">
      <path
        d="M12.2235 5.72998L12.9251 6.51026C13.1511 6.76154 13.2761 7.08753 13.2761 7.42546V14.8526C13.2761 15.1569 13.1552 15.4487 12.94 15.6639C12.7248 15.8791 12.433 15.9999 12.1287 15.9999H4.14738C3.84307 15.9999 3.55123 15.8791 3.33606 15.6639C3.12088 15.4487 3 15.1569 3 14.8526V7.40974C3 7.08129 3.1181 6.76378 3.33276 6.51518L4.01073 5.72998"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.74829 5.72998H12.2235"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.35298 2H11.8814C12.0629 2 12.237 2.07211 12.3653 2.20046C12.4937 2.32881 12.5658 2.50289 12.5658 2.6844V4.0532H3.66858V2.6844C3.66858 2.50289 3.74069 2.32881 3.86904 2.20046C3.99739 2.07211 4.17147 2 4.35298 2Z"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.17622 10.427L6.66541 11.2989V13.0705L8.17622 13.9428L9.68703 13.0705V11.2989"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1976 9.52774V8.65547L9.68708 7.7832L8.17627 8.65547V10.427L9.68708 11.299L11.1976 10.427V9.52774Z"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6655 11.299L5.15503 10.427V8.65547L6.6655 7.7832L8.17632 8.65547"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_286_4880">
        <rect width="16" height="16" fill="white" transform="translate(0 0.707275)" />
      </clipPath>
    </defs>
  </svg>
));

Honey.propTypes = {
  color: PropTypes.string
};

Honey.displayName = 'Honey';

export default Honey;
