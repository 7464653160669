import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const UndoAction = forwardRef(({ size = 12, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <path
      d="M5.05222 5.34126H0.285525C0.127843 5.34126 0 5.21333 0 5.05555V0.285713C0 0.127927 0.127843 0 0.285525 0H1.42763C1.58531 0 1.71316 0.127927 1.71316 0.285713V2.14552C2.80227 0.935216 4.38425 0.177856 6.14297 0.190644C9.40049 0.214311 12.0069 2.84833 12 6.10807C11.9931 9.36328 9.35383 12 6.09914 12C4.57422 12 3.18449 11.4211 2.13723 10.4711C2.01591 10.361 2.01031 10.1722 2.12612 10.0563L2.93432 9.24757C3.04078 9.14105 3.21181 9.13526 3.32457 9.23507C4.06303 9.88895 5.0343 10.2857 6.09914 10.2857C8.41352 10.2857 10.2869 8.41152 10.2869 6.09524C10.2869 3.77936 8.4139 1.90477 6.09914 1.90477C4.7073 1.90477 3.47516 2.58277 2.7139 3.62698H5.05221C5.20989 3.62698 5.33774 3.75491 5.33774 3.91269V5.05555C5.33774 5.21334 5.20989 5.34126 5.05221 5.34126L5.05222 5.34126Z"
      fill="#4F4F4F"
    />
  </svg>
));

UndoAction.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

UndoAction.displayName = 'UndoAction';

export default UndoAction;
