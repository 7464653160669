import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual, loading } from '@beewise/react-utils';
import Grid from 'components/reusables/Grid';
import LockModal from 'components/reusables/BhomeLayout/components/Station/LockModal';
import { getAllLockedHives } from './selectors';
import { fetchAllLockedHives } from './actions';
import { getHivesColumnDefs } from './utils';
import { FETCH_ALL_LOCKED_HIVES, FETCH_TOGGLE_LOCK_HIVE } from './actionTypes';

const Hives = () => {
    const [hiveToLock, setHiveToLock] = useState(null);
    const [bhomeId, setBhomeId] = useState(null);
    const dispatch = useDispatch();
    const lockedHives = useSelector(getAllLockedHives, arrayOfObjectsShallowEqual);

    const columnDefs = useMemo(() => getHivesColumnDefs(setHiveToLock, setBhomeId), [setHiveToLock, setBhomeId]);

    const handleUpdate = useCallback(() => {
        dispatch(fetchAllLockedHives());
    }, [dispatch]);

    useEffect(() => {
        handleUpdate();
    }, [handleUpdate]);

    const getRowId = useCallback(({ data }) => `${data.id}-${data.hive.hiveId}`, []);

    return (
        <div className="hives-view">
            <Grid rowData={lockedHives} getRowId={getRowId} columnDefs={columnDefs} handleUpdate={handleUpdate} />
            <LockModal hiveToLock={hiveToLock} setHiveToLock={setHiveToLock} bhomeId={bhomeId} />
        </div>
    );
};

export default loading([FETCH_ALL_LOCKED_HIVES.default, FETCH_TOGGLE_LOCK_HIVE.default])(Hives);
