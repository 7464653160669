import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@beewise/checkbox-field';
import { useToggle } from '@beewise/react-utils';

const CheckboxFilters = ({ columnApi, filters, toggleFilter }) => {
    const [isFreezed, toggleIsFreezed] = useToggle();

    const handleFreezeToggle = () => {
        const columnState = columnApi.getColumnState().map((item, index) => {
            // pin # column and next 2 columns
            if (index <= 2) {
                return {
                    ...item,
                    pinned: !isFreezed,
                };
            }
            return item;
        });

        columnApi.applyColumnState({
            state: columnState,
        });

        toggleIsFreezed();
    };

    return (
        <div className="connectivity-map-view-checkboxes">
            <div className="connectivity-map-view-checkboxes-wrapper">
                <Checkbox label="Freeze (pin)" checked={isFreezed} onChange={handleFreezeToggle} />
                <Checkbox
                    label="Show online"
                    checked={filters.some(item => item === 'onlineShown')}
                    onChange={toggleFilter('onlineShown')}
                />
                <Checkbox
                    label="Show marked only"
                    checked={filters.some(item => item === 'markedOnly')}
                    onChange={toggleFilter('markedOnly')}
                />
            </div>
        </div>
    );
};

CheckboxFilters.propTypes = {
    columnApi: PropTypes.shape(),
    filters: PropTypes.arrayOf(PropTypes.shape()),
    toggleFilter: PropTypes.func,
};

export default CheckboxFilters;
