import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@beewise/text-field';

const FrameScaleCalibrateFactorParams = ({ params, setParams }) => {
    const handleWeightChange = value => {
        setParams(params => ({
            ...params,
            weight_on_scale: Number(value),
        }));
    };

    return (
        <div className="partial-frame-layout-params">
            <TextField
                type="number"
                label="Weight on scale"
                value={params.weight_on_scale}
                onChange={handleWeightChange}
                size="small"
            />
        </div>
    );
};

FrameScaleCalibrateFactorParams.propTypes = {
    params: PropTypes.shape(),
    setParams: PropTypes.func.isRequired,
};

export default FrameScaleCalibrateFactorParams;
