import { FETCH_FORGOT_PASSWORD, FETCH_SIGNIN, SET_FORGOT_PASSWORD_ERROR, SIGN_OUT } from '../actionTypes';

export const fetchSignIn = (email, password) => ({
    type: 'FETCH',
    params: {
        type: FETCH_SIGNIN.default,
        method: 'POST',
        url: '/users/sign-in',
        body: {
            email,
            password,
        },
    },
});

export const fetchForgotPassword = email => ({
    type: 'FETCH',
    params: {
        type: FETCH_FORGOT_PASSWORD.default,
        method: 'POST',
        url: '/users/forgot-password',
        body: {
            email,
        },
    },
});

export const signOut = () => ({
    type: SIGN_OUT,
});

export const setForgotPasswordError = error => ({
    type: SET_FORGOT_PASSWORD_ERROR,
    error,
});
