import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useToggle } from '@beewise/react-utils';
import { shallowEqual, useSelector } from 'react-redux';
import { noop } from 'lodash-es';
import constants from 'appConstants';
import { getCurrentBhome } from 'selectors';
import BhomeLayoutModal from './components/BhomeLayoutModal';
import FullFrame from './components/Frame/components/FullFrame';

import './BhomeLayout.scss';

const BhomeLayout = ({ onFrameClick = noop, closeOnClick = true, pickedFrames = [] }) => {
    const [isOpen, toggleModal] = useToggle();
    const bhome = useSelector(getCurrentBhome, shallowEqual);

    const handleOnClose = useCallback(() => {
        toggleModal();
    }, [toggleModal]);

    const handleOnFrameClick = useCallback(
        frame => {
            onFrameClick(frame);
            if (closeOnClick) {
                handleOnClose();
            }
        },
        [closeOnClick, handleOnClose, onFrameClick]
    );

    return (
        <div className="bhome-layout-wrapper">
            <div className="bhome-layout-toggler" role="presentation" onClick={toggleModal}>
                <FullFrame type={constants.FRAME_TYPES.PARTITION} />
                <FullFrame type={constants.FRAME_TYPES.FEEDER} />
                <FullFrame isFoundationFrame />
                <FullFrame isFoundationFrame />
                <FullFrame type={constants.FRAME_TYPES.QUEEN_EXCLUDER} />
                <FullFrame type={constants.FRAME_TYPES.STATIC_PARTITION} />
            </div>
            <BhomeLayoutModal
                isOpen={isOpen}
                bhome={bhome}
                handleOnClose={handleOnClose}
                handleOnFrameClick={handleOnFrameClick}
                pickedFrames={pickedFrames}
            />
        </div>
    );
};

BhomeLayout.propTypes = {
    onFrameClick: PropTypes.func,
    closeOnClick: PropTypes.bool,
    pickedFrames: PropTypes.arrayOf(PropTypes.shape()),
};

export default BhomeLayout;
