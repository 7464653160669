import { FETCH_ACTIVITY_STATISTICS, SELECT_STATISTICS_BHOMES, TOGGLE_SHOW_AUTO_SCANS } from '../actionTypes';

const initialState = {
    data: [],
    selectedBhomes: [],
    showAutoScans: false,
};

const statistics = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACTIVITY_STATISTICS.success:
            return {
                ...state,
                data: action.data.statistics,
            };
        case SELECT_STATISTICS_BHOMES:
            return {
                ...state,
                selectedBhomes: action.bhomes,
            };
        case TOGGLE_SHOW_AUTO_SCANS:
            return {
                ...state,
                showAutoScans: !state.showAutoScans,
            };
        default:
            return state;
    }
};

export default statistics;
