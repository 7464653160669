import dayjs from 'dayjs';
import { groupBy } from 'lodash-es';

export const tickFormatter = tickItem => dayjs.utc(tickItem).format('D MMM');

export const generateTicks = (amount, unit) =>
    new Array(amount)
        .fill(undefined)
        .map((_, idx) => +dayjs(dayjs().utc().subtract(idx, unit).startOf(unit).format()).utc());

const statuses = {
    success: 0,
    failed: 0,
    canceled: 0,
    acknowledged: 0,
    sent: 0,
};

export const getDataToDownload = (data, bhomes, isHarvesting) =>
    Object.values(groupBy(data, 'date')).reduce((acc, item) => {
        if (item) {
            const res = item.reduce((subAcc, subItem) => {
                if (
                    (!subAcc.length || !subAcc.find(item => item.bhome_id === subItem.bhome_id)) &&
                    (!bhomes.length || bhomes.includes(subItem.bhome_id))
                ) {
                    subAcc.push({
                        date: dayjs(subItem.date).format('DD-MM-YYYY'),
                        bhome_id: subItem.bhome_id,
                        ...statuses,
                        [subItem.status]: Number(subItem.value),
                        ...(isHarvesting ? {} : { auto: Number(subItem.auto) }),
                        total: Number(subItem.value),
                    });
                } else {
                    const itemIndex = subAcc.findIndex(
                        item =>
                            item.bhome_id === subItem.bhome_id && item.date === dayjs(subItem.date).format('DD-MM-YYYY')
                    );
                    if (itemIndex >= 0) {
                        subAcc[itemIndex] = {
                            ...subAcc[itemIndex],
                            [subItem.status]: (subAcc[itemIndex][subItem.status] += Number(subItem.value)),
                            ...(isHarvesting
                                ? {}
                                : {
                                      auto: (subAcc[itemIndex].auto += Number(subItem.auto)),
                                  }),
                            total: (subAcc[itemIndex].total += Number(subItem.value)),
                        };
                    }
                }
                return subAcc;
            }, []);

            acc.push(...res);
        }
        return acc;
    }, []);

const defaultColumns = [
    { label: 'Date', key: 'date' },
    { label: 'Bhome ID', key: 'bhome_id' },
    { label: 'Success', key: 'success' },
    { label: 'Failed', key: 'failed' },
    { label: 'Canceled', key: 'canceled' },
    { label: 'Acknowledged', key: 'acknowledged' },
    { label: 'Sent', key: 'sent' },
];

export const getCsvHeaders = isHarvesting => {
    if (isHarvesting) {
        return [...defaultColumns, { label: 'Total', key: 'total' }];
    }

    return [...defaultColumns, { label: 'Auto Scans', key: 'auto' }, { label: 'Total', key: 'total' }];
};
