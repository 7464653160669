export const getCurrentBhome = state => state.app.currentBhome;

export const getCurrentBhomeUsers = state => state.app.currentBhomeUsers;

export const getCurrentBhomeFrames = state => state.actionsMonitoring.frames;
export const getLayoutTimestamp = state => state.actionsMonitoring.layoutTimestamp;

export const getStations = state => state.actionsMonitoring.stations;
export const getMarkedHives = state => state.actionsMonitoring.markedHives;

export const getIsCurrentBhomeOnline = state => state.app.isCurrentBhomeOnline;

export const getCurrentBhomeActivities = state => state.app.currentBhomeActivities;
