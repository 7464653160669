import React, { forwardRef } from 'react';

const Beehome = forwardRef((props, ref) => (
  <svg
    ref={ref}
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.0712 15.3176L16.0712 6.27368H3.92831V15.3176H16.0712Z"
      stroke="black"
      strokeWidth="0.8"
      strokeLinejoin="round"
    />
    <path
      d="M4.6875 15.3177V16.6458H6.45833V15.3177"
      stroke="black"
      strokeWidth="0.8"
      strokeLinejoin="round"
    />
    <path
      d="M13.5417 15.3177V16.6458H15.3125V15.3177"
      stroke="black"
      strokeWidth="0.8"
      strokeLinejoin="round"
    />
    <path
      d="M5.27777 4.25004L14.7222 4.25L17.0833 6.27381H2.91666L5.27777 4.25004Z"
      stroke="black"
      strokeWidth="0.8"
      strokeLinejoin="round"
    />
    <path d="M3.92831 10.3214H16.0712" stroke="black" strokeWidth="0.8" strokeLinecap="round" />
    <path
      d="M3.99976 13.3571L4.99976 13.3574"
      stroke="black"
      strokeWidth="0.8"
      strokeLinecap="round"
    />
    <path d="M6.99976 13.3572H8.99976" stroke="black" strokeWidth="0.8" strokeLinecap="round" />
    <path d="M10.9998 13.3572H12.9998" stroke="black" strokeWidth="0.8" strokeLinecap="round" />
    <path
      d="M14.9998 13.3574L15.9998 13.3571"
      stroke="black"
      strokeWidth="0.8"
      strokeLinecap="round"
    />
  </svg>
));

Beehome.displayName = 'Beehome';

export default Beehome;
