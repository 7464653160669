import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@beewise/modal';
import Button from '@beewise/button';
import TextField from '@beewise/text-field';

const SaveViewModal = ({ isOpen, handleOnClose, handleSaveView }) => {
    const [newView, setNewView] = useState('');
    return (
        <Modal header="Save current view" isOpen={isOpen} onClose={handleOnClose}>
            <TextField label="view name" value={newView} onChange={setNewView} size="small" />
            <div className="btn-block">
                <Button className="secondary-blue" onClick={handleOnClose}>
                    Cancel
                </Button>
                <Button className="primary-blue" onClick={() => handleSaveView(newView)}>
                    Done
                </Button>
            </div>
        </Modal>
    );
};

SaveViewModal.propTypes = {
    isOpen: PropTypes.bool,
    handleOnClose: PropTypes.func,
    handleSaveView: PropTypes.func,
};

export default SaveViewModal;
