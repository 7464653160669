/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TextField from '@beewise/text-field';
import analytics from 'utils/analytics';
import { getUrlParam } from '@beewise/utils';
import TechButton from 'components/reusables/TechButton';
import { setPasswordAndUsername as setPasswordAndUsernameAction } from './actions';

const PasswordSetup = () => {
    const isForgotPassword = getUrlParam('isForgotPassword');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (password.length >= 8 && password === confirmPassword && (username || isForgotPassword)) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    }, [password, confirmPassword, username, isForgotPassword]);

    const submitChangePassword = () => {
        analytics.fireGeneralEvent({
            action: 'Setting Up Username and Password',
        });
        dispatch(setPasswordAndUsernameAction(password, username, getUrlParam('confirmationToken')));
    };

    return (
        <div className="change-password">
            <div className="change-password-container">
                <h2 className="change-password-header">Setup Your Password</h2>
                <div className="change-password-form">
                    {!isForgotPassword && (
                        <TextField label="Full Name" value={username} onChange={setUsername} size="small" />
                    )}
                    <TextField type="password" label="Password" value={password} onChange={setPassword} size="small" />
                    <TextField
                        type="password"
                        label="Re-enter Password"
                        value={confirmPassword}
                        onChange={setConfirmPassword}
                        size="small"
                    />
                    <TechButton
                        className="btn change-password-button btn-primary"
                        onClick={submitChangePassword}
                        disabled={isSubmitDisabled}
                        alwaysEnabled={!isSubmitDisabled}
                    >
                        Done
                    </TechButton>
                </div>
            </div>
        </div>
    );
};

export default PasswordSetup;
