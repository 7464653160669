import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getJenkinsResultRenderers } from '../../utils';

const ResultTabs = ({ testS3Data }) => {
    const { tabList, tabContents } = useMemo(() => getJenkinsResultRenderers(testS3Data), [testS3Data]);

    return (
        <Tabs>
            <TabList>
                {tabList.map(tab => (
                    <Tab>{tab}</Tab>
                ))}
            </TabList>
            {tabContents.map(content => (
                <TabPanel>{content}</TabPanel>
            ))}
        </Tabs>
    );
};

ResultTabs.propTypes = {
    testS3Data: PropTypes.shape(),
};

export default ResultTabs;
