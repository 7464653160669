export default (frame) =>
  Object.keys(frame).reduce((acc, key) => {
    if (key.includes('side') && frame[key]) {
      const side = Object.keys(frame[key]).reduce((sideAcc, sideKey) => {
        if (sideKey === 'cell') {
          return sideAcc + frame[key][sideKey];
        }
        return sideAcc;
      }, 0);
      return acc + side;
    }
    return acc;
  }, 0);
