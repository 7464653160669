import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@beewise/text-field';
import Button from '@beewise/button';
import { SelectField } from '@beewise/select-field';
import { UI_ENV } from 'config';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { DATE_TIME_FORMAT } from 'components/views/SpecialFeatures/components/BeehomeLogs/logsConstants';
import constants from 'appConstants';
import { usePermission } from '@beewise/react-utils';
import EditFrameContent from './EditFrameContent';

const EditFrame = ({
    currentBhome,
    handleGetByRfidClick,
    currentFrame,
    error,
    handleUpdateFrame,
    slotStatusOptions,
    frameOptions,
}) => {
    const [rfid, setRfid] = useState('');
    const [editRfid, setEditRfid] = useState('');
    const [status, setStatus] = useState(null);
    const [frameType, setType] = useState(null);
    const [sideA, setSideA] = useState(null);
    const [sideB, setSideB] = useState(null);
    const [prevSideA, setPrevSideA] = useState(null);
    const [prevSideB, setPrevSideB] = useState(null);

    const cleanUpValues = useCallback(() => {
        setStatus(null);
        setEditRfid(null);
        setType(null);
        setSideA(null);
        setSideB(null);
        setPrevSideA(null);
        setPrevSideB(null);
    }, []);

    useEffect(() => {
        const status = currentFrame && (currentFrame.status || currentFrame.slot?.status);
        if (status) {
            setStatus(status);
            if (currentFrame.rfid) {
                setEditRfid(currentFrame.rfid);
                setType(currentFrame.type);
                setSideA(JSON.stringify(currentFrame.side_a));
                setSideB(JSON.stringify(currentFrame.side_b));
                setPrevSideA(JSON.stringify(currentFrame.previous_side_a));
                setPrevSideB(JSON.stringify(currentFrame.previous_side_b));
            } else {
                setEditRfid(null);
                setType(null);
            }
        } else {
            cleanUpValues();
        }
    }, [cleanUpValues, currentFrame]);

    useEffect(() => {
        cleanUpValues();
    }, [cleanUpValues, currentBhome.id]);

    const handleChangeSideData = useCallback(
        side => e => {
            if (side === 'a') {
                setSideA(e.target.value);
            } else {
                setSideB(e.target.value);
            }
        },
        []
    );

    const canEditFrameSides = usePermission('canEditFrameSides', 'technician');

    const showSideEdit = UI_ENV === 'dev' || UI_ENV === 'lab' || canEditFrameSides;

    return (
        <>
            <div className="frames-content">
                <div className="frames-rfid">
                    <TextField label="Rfid" value={rfid} onChange={setRfid} size="small" />
                    <Button className="btn-primary" onClick={handleGetByRfidClick(rfid)} disabled={!rfid}>
                        Get Frame By Rfid
                    </Button>
                </div>
                {error && <div className="error">{error}</div>}
            </div>
            {!isEmpty(currentFrame) && (
                <>
                    <div className="frames-form">
                        <h3 className="header">{currentFrame.id ? 'Frame Data' : 'Slot Status'}:</h3>
                        {!!currentFrame.id && (
                            <EditFrameContent
                                currentFrame={currentFrame}
                                editRfid={editRfid}
                                frameOptions={frameOptions}
                                frameType={frameType}
                                handleEditRfidChange={setEditRfid}
                                handleTypeChange={setType}
                            />
                        )}
                        <SelectField value={status} options={slotStatusOptions} onChange={setStatus} size="small" />
                        {frameType === constants.FRAME_TYPES.COMB_FRAME && showSideEdit && (
                            <>
                                <div className="frames-sides">
                                    <div className="frames-side">
                                        <h3>Side A:</h3>
                                        <textarea
                                            className="frames-textarea"
                                            onChange={handleChangeSideData('a')}
                                            value={sideA}
                                        />
                                    </div>
                                    <div className="frames-side">
                                        <h3>Side B:</h3>
                                        <textarea
                                            className="frames-textarea"
                                            onChange={handleChangeSideData('b')}
                                            value={sideB}
                                        />
                                    </div>
                                </div>
                                <div className="frames-sides">
                                    <div className="frames-side">
                                        <h3>Previous Side A:</h3>
                                        <textarea
                                            className="frames-textarea"
                                            onChange={e => setPrevSideA(e.target.value)}
                                            value={prevSideA}
                                        />
                                    </div>
                                    <div className="frames-side">
                                        <h3>Previous Side B:</h3>
                                        <textarea
                                            className="frames-textarea"
                                            onChange={e => setPrevSideB(e.target.value)}
                                            value={prevSideB}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="edit-submit-buttons">
                            <Button
                                className="primary-blue"
                                onClick={handleUpdateFrame({
                                    editRfid,
                                    status,
                                    type: frameType,
                                    sideA,
                                    sideB,
                                    prevSideA,
                                    prevSideB,
                                    cleanUpFrameId: false,
                                    isEditingRfid: currentFrame.rfid !== editRfid,
                                })}
                            >
                                Update {currentFrame.id ? 'Frame' : 'Slot'}
                            </Button>
                            {currentFrame.id && (
                                <Button
                                    className="primary-blue"
                                    onClick={handleUpdateFrame({
                                        editRfid: null,
                                        status,
                                        type: frameType,
                                        sideA,
                                        sideB,
                                        prevSideA,
                                        prevSideB,
                                        cleanUpFrameId: true,
                                    })}
                                >
                                    Turn to empty slot
                                </Button>
                            )}
                        </div>
                    </div>
                    {currentFrame?.slot?.rfidData?.link && (
                        <div className="frames-form">
                            <div className="bad-rfid-header">
                                <h2>Bad RFID Scan data</h2>
                                <div className="bad-rfid-time">
                                    {dayjs.unix(currentFrame?.slot?.rfidData?.timestamp).format(DATE_TIME_FORMAT)}
                                </div>
                            </div>
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img
                                className="bad-rfid-image"
                                src={currentFrame?.slot?.rfidData?.link}
                                alt="No image found on S3"
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

EditFrame.propTypes = {
    currentBhome: PropTypes.shape(),
    handleGetByRfidClick: PropTypes.func,
    handleUpdateFrame: PropTypes.func,
    currentFrame: PropTypes.shape(),
    error: PropTypes.string,
    slotStatusOptions: PropTypes.arrayOf(PropTypes.shape()),
    frameOptions: PropTypes.arrayOf(PropTypes.shape()),
};

export default EditFrame;
