import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const BhomeOnline = ({ isOnline }) => (
    <div className="bhome-online">
        <div className={cx('bhome-online-dot', { 'is-online': isOnline })} />
        {isOnline ? 'Online' : 'Offline'}
    </div>
);

BhomeOnline.propTypes = {
    isOnline: PropTypes.bool,
};

export default BhomeOnline;
