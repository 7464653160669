import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { fetchUpdateLastVisit } from './actions';

const AnalProvider = ({ children, UI_ENV, trackingCode, ignoreLastVisitUpdate = false }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user, shallowEqual);

  useEffect(() => {
    if (!ignoreLastVisitUpdate && user?.id) {
      dispatch(fetchUpdateLastVisit());
    }
  }, [dispatch, ignoreLastVisitUpdate, user]);

  useEffect(() => {
    if (UI_ENV === 'prod' && trackingCode) {
      ReactGA.initialize(trackingCode, {
        debug: false
      });
    }
  }, []);

  useEffect(() => {
    if (UI_ENV === 'prod') {
      // Initialize google analytics page view tracking
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    }
  }, [location]);

  useEffect(() => {
    if (UI_ENV === 'prod') {
      // set GA user
      ReactGA.set({
        userId: user && user.id ? user.id : null
      });
    }
  }, [user]);

  return children;
};

AnalProvider.propTypes = {
  UI_ENV: PropTypes.string.isRequired,
  trackingCode: PropTypes.string.isRequired,
  children: PropTypes.node,
  ignoreLastVisitUpdate: PropTypes.bool
};

export default AnalProvider;
