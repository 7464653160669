import { FETCH_CREATE_PLACEHOLDER_HIVES } from '../actionTypes';

export const fetchCreatePlaceholderHives = (body, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: FETCH_CREATE_PLACEHOLDER_HIVES.default,
        url: '/bhomes/insert-hives',
        body,
        resolver,
        toastText: 'Hives have been successfully created',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});
