import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Star = forwardRef(({ size = 12, ...rest }, ref) => (
  <svg
    ref={ref}
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M6 0C2.68636 0 0 2.68636 0 6C0 9.31364 2.68636 12 6 12C9.31364 12 12 9.31364 12 6C12 2.68636 9.31364 0 6 0ZM9.15409 5.36114L7.86102 6.62114L8.16682 8.40136C8.22136 8.72046 7.88523 8.9642 7.5992 8.81352L6 7.97318L4.40114 8.81352C4.11477 8.96523 3.77898 8.72011 3.83352 8.40136L4.13932 6.62114L2.84625 5.36114C2.61375 5.13477 2.74227 4.73966 3.06273 4.6933L4.85011 4.43284L5.64886 2.81284C5.7208 2.66693 5.85989 2.595 5.99932 2.595C6.13943 2.595 6.27954 2.66795 6.35148 2.81284L7.15023 4.43284L8.93761 4.6933C9.25807 4.73966 9.38659 5.13477 9.15409 5.36114Z"
      fill="#222222"
    />
  </svg>
));

Star.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Star.displayName = 'Star';

export default Star;
