import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Dashboard = forwardRef(({ size = 18, color = '#4E4540', ...rest }, ref) => (
  <svg
    ref={ref}
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M0 10H8V0H0V10ZM0 18H8V12H0V18ZM10 18H18V8H10V18ZM10 0V6H18V0H10Z" fill={color} />
  </svg>
));

Dashboard.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
};

Dashboard.displayName = 'Dashboard';

export default Dashboard;
