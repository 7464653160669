import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual, loading, useAsyncPoll, useToggle } from '@beewise/react-utils';
import { useIdleTimer } from 'react-idle-timer';
import Grid from 'components/reusables/Grid';
import { getBhomes } from 'components/reusables/AppHeader/selectors/bhome.ids.selector';
import TextField from '@beewise/text-field';
import { fetchAllBhomes, fetchAllViews, fetchCreateView, fetchDeleteView } from 'actions/app.actions';
import { useNavigate } from 'react-router-dom';
import { fetchBhomeConnectivityMap, fetchConnectivityMapData, fetchLatestBhomesErrors } from './actions';
import { getDataToDisplay, columnMonitoringDefs } from './utils';
import {
    FETCH_CONNECTIVITY_MAP_DATA,
    FETCH_BHOME_CONNECTIVITY_MAP,
    FETCH_LATEST_BHOMES_ERRORS,
    FETCH_UPDATE_BHOME_NOTE,
} from './actionTypes';
import { isLimitedBayUser } from '../../../utils';
import BulkSActions from './components/BulkActions';
import SaveViewModal from './components/SaveViewModal';
import Chip from './components/Chip';
import CheckboxFilters from './components/CheckboxFilters';

const Monitoring = () => {
    const navigate = useNavigate();
    const [filters, setFilters] = useState([]);
    const [isOpen, toggleModal] = useToggle();
    const gridRef = useRef();

    const dispatch = useDispatch();
    const user = useSelector(state => state.app.user, shallowEqual);
    const connectivity = useSelector(state => state.monitoring.connectivity, arrayOfObjectsShallowEqual);
    const errors = useSelector(state => state.monitoring.errors, arrayOfObjectsShallowEqual);
    const data = useSelector(state => state.monitoring.data, shallowEqual);
    const bhomes = useSelector(getBhomes, arrayOfObjectsShallowEqual);
    const views = useSelector(state => state.app.views, arrayOfObjectsShallowEqual);
    const [selectedBhomeIds, setSelectedBhomeIds] = useState([]);
    const [selectedView, setSelectedView] = useState(null);
    const [filterModel, setFilterModel] = useState({});
    const [bhomeIds, setBhomeIds] = useState(null);
    const [columnDefs, setColumnDefs] = useState(columnMonitoringDefs);

    const handleOnClose = useCallback(() => {
        toggleModal();
    }, [toggleModal]);

    useIdleTimer({
        onIdle: () => navigate('/'),
        timeout: 5 * 60 * 1000,
    });

    const isBayUser = useMemo(() => isLimitedBayUser(user), [user]);

    const handleInitialFetch = useCallback(() => {
        dispatch(fetchAllBhomes());
        dispatch(fetchBhomeConnectivityMap());
        dispatch(fetchLatestBhomesErrors());
        dispatch(fetchConnectivityMapData());
        dispatch(fetchAllViews());
    }, [dispatch]);

    useEffect(() => {
        handleInitialFetch();
    }, [handleInitialFetch]);

    useAsyncPoll(() => {
        handleInitialFetch();
    }, 60 * 1000);

    const handleUpdate = useCallback(() => {
        handleInitialFetch();
    }, [handleInitialFetch]);

    const toggleFilter = useCallback(
        name => () => {
            const filter = filters.includes(name) ? filters.filter(item => item !== name) : [...filters, name];
            setFilters(filter);
        },
        [filters]
    );

    const dataToDisplay = useMemo(
        () =>
            getDataToDisplay({
                bhomes,
                errors,
                data,
                connectivity,
                filters,
                isBayUser,
                selectedBhomeIds,
            }),
        [bhomes, errors, data, connectivity, filters, isBayUser, selectedBhomeIds]
    );

    const resetView = useCallback((resetFilters = true) => {
        setColumnDefs(columnMonitoringDefs);
        setSelectedView(null);
        if (resetFilters) {
            setFilters([]);
            gridRef?.current?.api?.setFilterModel?.(null);
        }
        if (!gridRef?.current?.columnApi) {
            return;
        }
        gridRef?.current?.columnApi.resetColumnState();
    }, []);

    const setActiveView = useCallback(
        view => {
            if (selectedView?.id === view?._id) {
                resetView();
            } else {
                const stateToApply = view.view
                    .map(colState => {
                        const defState = columnMonitoringDefs.find(def => colState.colId === def.field);
                        return defState
                            ? {
                                  ...defState,
                                  sort: colState.sort,
                                  sortIndex: colState.sortIndex,
                              }
                            : null;
                    })
                    .filter(col => col !== null);

                setSelectedView({ id: view._id, data: stateToApply, filters: view?.filters });
                gridRef?.current?.api?.setFilterModel?.(view?.filters);
                setColumnDefs(stateToApply);
            }
        },
        [resetView, selectedView?.id]
    );

    const getRowId = useCallback(({ data }) => data.id, []);

    const onFilterChanged = useCallback(
        ({ api }) => {
            setFilterModel(api.getFilterModel());
            const selectedIds = [];
            api.forEachNodeAfterFilter(node => {
                if (selectedBhomeIds.includes(node.data.id)) {
                    selectedIds.push(node.data.id);
                }
            });
            setSelectedBhomeIds(selectedIds);
            setBhomeIds(selectedIds.join(' '));
        },
        [selectedBhomeIds]
    );

    const onSelect = useCallback(
        ({ api }) => {
            const selected = api?.getSelectedRows();
            const bhomeIds = selected?.map(({ id }) => id);

            if (selectedBhomeIds.length === bhomeIds.length) {
                return;
            }
            setSelectedBhomeIds(bhomeIds);
            setBhomeIds(bhomeIds.join(' '));
        },
        [selectedBhomeIds?.length]
    );

    const handleSaveView = useCallback(
        newView => {
            if (!gridRef?.current?.columnApi || !newView) return;

            const columnState = gridRef?.current?.columnApi?.getColumnState();
            const visibleColumns = columnState.filter(colState => colState.hide !== true);
            dispatch(
                fetchCreateView({
                    body: {
                        viewData: visibleColumns,
                        filters: filterModel,
                        title: newView,
                    },
                    resolver: data => setActiveView(data?.result),
                })
            );
            handleOnClose();
        },
        [dispatch, filterModel, handleOnClose, setActiveView]
    );

    const handleDeleteView = viewId => {
        dispatch(fetchDeleteView(viewId));
    };

    const validateAndSetBhomeIds = useCallback(
        enteredIds => {
            setBhomeIds(enteredIds);
            const idsArray = enteredIds.split(' ').map(Number);
            const validIds = idsArray.filter(id => bhomes.some(bhome => bhome.id === id));
            setSelectedBhomeIds(validIds);
        },
        [bhomes]
    );

    return (
        <div className="connectivity-map-view">
            {views.length !== 0 && (
                <>
                    <div className="connectivity-map-view-title">Views</div>
                    {views.map(view => (
                        <Chip
                            key={view?._id}
                            title={view.title}
                            handleDeleteView={() => handleDeleteView(view._id)}
                            isActiveView={selectedView?.id === view._id}
                            setActiveView={() => setActiveView(view)}
                        />
                    ))}
                </>
            )}
            <CheckboxFilters columnApi={gridRef?.current?.columnApi} filters={filters} toggleFilter={toggleFilter} />
            <Grid
                ref={gridRef}
                rowData={dataToDisplay}
                getRowId={getRowId}
                columnDefs={columnDefs}
                onSelect={onSelect}
                handleUpdate={handleUpdate}
                isAbsoluteHeader
                onFilterChanged={onFilterChanged}
                hideSearch
                selectedBhomeIds={selectedBhomeIds}
                actionChildren={
                    <>
                        <BulkSActions selectedBhomeIds={selectedBhomeIds} setSelectedBhomeIds={setSelectedBhomeIds} />
                        <TextField
                            label="Enter Beehome IDs"
                            value={bhomeIds}
                            onChange={validateAndSetBhomeIds}
                            size="small"
                        />
                    </>
                }
                handleOnClose={handleOnClose}
                handleOnResetView={resetView}
            />
            <SaveViewModal isOpen={isOpen} handleOnClose={handleOnClose} handleSaveView={handleSaveView} />
        </div>
    );
};

export default loading([
    FETCH_CONNECTIVITY_MAP_DATA.default,
    FETCH_BHOME_CONNECTIVITY_MAP.default,
    FETCH_LATEST_BHOMES_ERRORS.default,
    FETCH_UPDATE_BHOME_NOTE.default,
])(Monitoring);
