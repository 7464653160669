import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import TechButton from 'components/reusables/TechButton';
import { getCurrentBhome } from 'selectors';
import { SelectField } from '@beewise/select-field';
import { loading } from '@beewise/react-utils';
import constants from 'appConstants';
import TextField from '@beewise/text-field';
import Ansi from '@cocalc/ansi-to-react';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import { clearScriptsView } from './actions';
import JenkinsTests from './components/JenkinsTests';
import {
    FETCH_RUN_JENKINS_BUILD,
    FETCH_GET_MANUFACTURING_TESTS,
    FETCH_GET_TESTS_S3_DATA,
    FETCH_CANCEL_JENKINS_JOB,
} from './actionTypes';
import JenkinsResults from './components/JenkinsTests/JenkinsResults';

const ScriptExecutor = () => {
    const [selectedScript, setSelectedScript] = useState(null);

    const dispatch = useDispatch();
    const bhome = useSelector(getCurrentBhome, shallowEqual);
    const scripts = useSelector(state => state.scriptExecutor.scripts, shallowEqual);
    const log = useSelector(state => state.scriptExecutor.log);
    const logInfo = useSelector(state => state.scriptExecutor.logInfo);

    useEffect(
        () => () => {
            dispatch(clearScriptsView());
        },
        [dispatch]
    );

    const scriptsOptions = useMemo(() => {
        if (scripts && Object.keys(scripts)?.length) {
            return Object.keys(scripts).map(script => ({
                label: script,
                value: script,
            }));
        }
    }, [scripts]);

    const handleGetScripts = useCallback(() => {
        dispatch(
            fetchSendTechnicianAction(bhome.id, {
                type: constants.COMMANDS.GET_AVAILABLE_TESTS,
            })
        );
    }, [bhome?.id, dispatch]);

    const handleAbortScript = useCallback(() => {
        dispatch(
            fetchSendTechnicianAction(bhome.id, {
                type: constants.COMMANDS.ABORT_TEST,
            })
        );
    }, [bhome?.id, dispatch]);

    const handleGetTestStatus = useCallback(() => {
        dispatch(
            fetchSendTechnicianAction(bhome.id, {
                type: constants.COMMANDS.GET_TEST_STATUS,
            })
        );
    }, [bhome?.id, dispatch]);

    const handleScriptChange = useCallback(value => setSelectedScript({ ...scripts[value], name: value }), [scripts]);

    const handleChangeArgumentToSend = useCallback(
        argument => value => {
            setSelectedScript({
                ...selectedScript,
                arguments: {
                    ...selectedScript?.arguments,
                    [argument]: {
                        ...selectedScript?.arguments?.[argument],
                        value,
                    },
                },
            });
        },
        [selectedScript]
    );

    const handleRunScript = useCallback(() => {
        dispatch(
            fetchSendTechnicianAction(bhome.id, {
                type: 'executeTest',
                params: {
                    test_name: selectedScript.name,
                    arguments: Object.keys(selectedScript.arguments).reduce((acc, argument) => {
                        acc[argument] = selectedScript.arguments[argument].value;
                        return acc;
                    }, {}),
                },
            })
        );
    }, [bhome?.id, dispatch, selectedScript?.arguments, selectedScript?.name]);

    return (
        <div className="script-executor">
            <JenkinsTests bhome={bhome} />
            <JenkinsResults bhome={bhome} />
            <div className="script-executor-controls">
                <TechButton alwaysEnabled onClick={handleGetScripts} showToManufacturer>
                    Get Scripts
                </TechButton>
                <TechButton alwaysEnabled onClick={handleAbortScript} showToManufacturer>
                    Abort Script
                </TechButton>
                <TechButton alwaysEnabled onClick={handleGetTestStatus} showToManufacturer>
                    Get Script Status
                </TechButton>
            </div>
            <div className="script-executor-content">
                {scriptsOptions?.length && (
                    <SelectField
                        value={selectedScript?.name || null}
                        options={scriptsOptions}
                        onChange={handleScriptChange}
                        placeholder="Choose script"
                        size="small"
                    />
                )}
                {selectedScript && (
                    <div className="script-executor-script">
                        {selectedScript?.arguments &&
                            Object.keys(selectedScript.arguments).map((argument, index) => (
                                <div key={`argument-${argument}-${index}`} className="script-executor-argument">
                                    <div className="script-executor-argument-name">{argument}</div>
                                    <div className="script-executor-argument-form">
                                        <TextField
                                            value={selectedScript.arguments[argument]?.value || ''}
                                            className="script-executor-argument-input"
                                            onChange={handleChangeArgumentToSend(argument)}
                                            size="small"
                                        />
                                        <div className="script-executor-argument-description">
                                            {selectedScript.arguments[argument]?.description}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        <TechButton
                            alwaysEnabled
                            className="script-executor-send"
                            onClick={handleRunScript}
                            showToManufacturer
                        >
                            Run
                        </TechButton>
                    </div>
                )}
                {logInfo && (
                    <div className="script-executor-log-info">
                        <div>
                            Executing Test Name: <span className="bold">{logInfo.executing_test_name}</span>
                        </div>
                        <div>
                            Last Query Time: <span className="bold">{logInfo.last_query_time}</span>
                        </div>
                        <div>
                            Start Time: <span className="bold">{logInfo.start_time}</span>
                        </div>
                    </div>
                )}
                {log && (
                    <div className="script-executor-logs">
                        <Ansi className="logs">{log}</Ansi>
                    </div>
                )}
            </div>
        </div>
    );
};

export default loading([
    FETCH_RUN_JENKINS_BUILD.default,
    FETCH_GET_MANUFACTURING_TESTS.default,
    FETCH_GET_TESTS_S3_DATA.default,
    FETCH_CANCEL_JENKINS_JOB.default,
])(ScriptExecutor);
