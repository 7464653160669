import React from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';

const ReleaseRenderer = ({ node, setHiveToLock, setBhomeId }) => {
    const handleReleaseClick = () => {
        setHiveToLock(node.data.hive);
        setBhomeId(node.data.id);
    };

    return <Button onClick={handleReleaseClick}>Release</Button>;
};

ReleaseRenderer.propTypes = {
    node: PropTypes.shape({
        data: PropTypes.shape({
            id: PropTypes.number,
            hive: PropTypes.shape({
                hiveId: PropTypes.string,
                lockedAt: PropTypes.number,
                lockedBy: PropTypes.string,
                lockedReason: PropTypes.string,
            }),
        }),
    }),
    setHiveToLock: PropTypes.func.isRequired,
    setBhomeId: PropTypes.func.isRequired,
};

export default ReleaseRenderer;
