import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getCurrentBhome } from 'selectors';
import MasterImageDownloader from '../MasterImageDownloader';

const PrivateRoute = ({ component: Component, isBhomeRequired = false, ...rest }) => {
    const isLoggedIn = useSelector(state => state.app.isLoggedIn);
    const isBhomePicked = useSelector(getCurrentBhome, shallowEqual);

    if (!isLoggedIn) {
        return <Navigate to="/signin" />;
    }

    if (isBhomeRequired && !isBhomePicked) {
        return (
            <div className="no-bhome-view">
                <div>Please pick Beehome before continue.</div>
                <MasterImageDownloader />
            </div>
        );
    }

    return <Component {...rest} />;
};

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    isBhomeRequired: PropTypes.bool,
};

export default PrivateRoute;
