import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import { showToast } from '@beewise/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons';

const DecodedRenderer = ({ value }) => {
    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(value);
        showToast('Copied', {
            toastType: 'toast-success',
        });
    }, [value]);

    return (
        <div>
            {value}
            {!!value && !isEmpty(value) && <FontAwesomeIcon className="icon-copy" icon={faCopy} onClick={handleCopy} />}
        </div>
    );
};

DecodedRenderer.propTypes = {
    value: PropTypes.string,
};

export default DecodedRenderer;
