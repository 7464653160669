import React, { useState, createElement, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import Error from './Error';

import './TextField.scss';

const largeHeight = 54;
const smallHeight = 40;

const paddingHorizontal = 14;

const getFieldValue = (type, e) => (type === 'file' ? e.target.files?.[0] : e.target.value);

const TextField = React.forwardRef(
  (
    {
      error,
      name,
      label,
      required,
      value,
      onFocus,
      onBlur,
      onChange,
      multiline,
      size = 'normal',
      type = 'text',
      inputProps,
      className,
      placeholder,
      info,
      ...rest
    },
    ref
  ) => {
    const inputRef = useRef(null);

    const initialActiveState =
      (value !== undefined && value !== null && value !== '') ||
      placeholder ||
      inputRef.current?.value;
    const [active, setActive] = useState(initialActiveState);
    const [isPasswordShown, setIsPasswordShown] = useState(false);

    let currentInputType = type;

    if (type === 'password' && isPasswordShown) {
      currentInputType = 'text';
    }

    let displayValue = value;
    if (type === 'percentage') {
      displayValue = `${value}%`;
    } else if (type === 'file') {
      displayValue = value?.fileName;
    }

    const isLabelDisplayedOnTop = inputProps?.position === 'start' || initialActiveState;

    const handleOnFocus = (e) => {
      setActive(true);
      if (onFocus) {
        onFocus(e);
      }
    };

    const handleOnBlur = (e) => {
      const current = { ...inputRef.current };

      if (!isLabelDisplayedOnTop || !current.value) {
        setActive(false);
      }
      if (onBlur) {
        onBlur(e);
      }
    };

    const handleOnChange = (e) => {
      let val = getFieldValue(type, e);
      if (type === 'percentage') {
        val = val.replace('%', '');
      }
      onChange && onChange(val, e);
    };

    const togglePasswordVisibility = () => {
      setIsPasswordShown((prevState) => !prevState);
    };

    const setRef = (r) => {
      if (ref) {
        ref.current = r;
      }
      inputRef.current = r;
    };

    const Component = createElement(multiline ? 'textarea' : 'input', {
      ref: setRef,
      name,
      id: name,
      type: currentInputType === 'percentage' ? 'text' : currentInputType,
      value: displayValue,
      placeholder,
      className: cn('text-field', `text-field--${size}`, {
        'text-field--active': active,
        'text-field--error': error
      }),
      onFocus: handleOnFocus,
      onBlur: handleOnBlur,
      onChange: handleOnChange,
      ...rest
    });

    const iconUsed =
      type === 'password' ? (
        <FontAwesomeIcon
          icon={isPasswordShown ? faEyeSlash : faEye}
          onClick={togglePasswordVisibility}
          className="password-icon"
        />
      ) : (
        inputProps?.component
      );

    const transformLabel = {
      '--height': size === 'normal' ? largeHeight : smallHeight,
      '--is-with-icon': iconUsed ? paddingHorizontal * 2 : 0
    };

    return (
      <fieldset className={cn('text-field-root', className)}>
        <div
          className={cn('text-field-container', {
            'text-field--error': error,
            'text-field-container-reverse': inputProps?.position === 'start' && type !== 'password'
          })}
        >
          {Component}
          {iconUsed && <div className="text-field-icon">{iconUsed}</div>}
        </div>
        {label && (
          <label
            htmlFor={name}
            className={cn('text-field-label', {
              'text-field--active': active || initialActiveState,
              'text-field--error': error,
              multiline
            })}
            style={transformLabel}
          >
            <span className="text-field-label--item">
              {label}
              {required && ' *'}
            </span>
          </label>
        )}
        <Error info={info} error={error} />
      </fieldset>
    );
  }
);

TextField.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'small']),
  inputProps: PropTypes.shape({
    component: PropTypes.element.isRequired,
    position: PropTypes.oneOf(['start', 'end'])
  }),
  info: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'percentage', 'file'])
};

export default TextField;
