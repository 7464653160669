import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from '@beewise/modal';
import Grid from 'components/reusables/Grid';
import { isEmpty } from 'lodash-es';
import { sequenceColDefs } from './utils';

import './SequencesModal.scss';

const SequencesModal = ({ sequenceMessage, setSequenceMessage }) => {
    const rows = useMemo(() => sequenceMessage?.payload?.sequences, [sequenceMessage]);
    const getRowId = useCallback(({ data }) => `${data.sequenceId}`, []);

    const handleClose = () => setSequenceMessage(null);

    return (
        <Modal
            size="extra-large"
            isOpen={!isEmpty(sequenceMessage)}
            header={`${sequenceMessage?.id} Subsequences`}
            onClose={handleClose}
            className="sequences-modal"
        >
            <Grid hideSearch hideDownload rowData={rows} getRowId={getRowId} columnDefs={sequenceColDefs} />
        </Modal>
    );
};

SequencesModal.propTypes = {
    sequenceMessage: PropTypes.shape(),
    setSequenceMessage: PropTypes.func,
};

export default SequencesModal;
